import React from 'react';
import './index.scss'
import {useBootstrapBreakpoint} from "../../../hooks/useBootstrapBreakpoint";
import classNames from 'classnames';

interface PaidNewsBoxProps {
    isTerminePageTeaserbox ?: boolean;
}

export function PaidNewsBox(props:PaidNewsBoxProps) {
   const containerWidth = useBootstrapBreakpoint({
       xl: '98%',
       md: '100%',
       lg: '100%',
       sm: '360px'
   })
    return (
        <>
            <div className={classNames('paid-news-container px-xl-0 pl-xl-2 pt-2', props.isTerminePageTeaserbox || "pl-md-2" )} style={{width:containerWidth}}>
                <div className={classNames('news-stories-area container-wrapper', props.isTerminePageTeaserbox && "termine_teaserbox_news-section")}>
                    <iframe frameBorder="0"
                            className={'frameRef'}
                            allowFullScreen
                            src={'https://media.newstool.de/publishers/template/2f1d3c973e62435ebb49119dab7a26ad/html/'}
                            height={'100%'}
                            width={'100%'}
                            style={{overflow: 'hidden'}}
                    ></iframe>
                </div>
            </div>
        </>
    )

}
