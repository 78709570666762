import moment from "moment/moment";
import {Chart, ChartPoint, ChartScope, ChartSeries, HistoryQuoteAggregation, HistoryQuoteEdge, Instrument, QuoteType} from "../../../../generated/graphql";
import {numberFormat, shortNumberFormat} from "../../../../utils";
import {findMaxPoint, findMinPoint} from "../ChartComponent";
import {ChartOptionsType, ChartSeriesType} from "./ChartToolsComponentModal";

export function getIndicatorParams(type: string) {
    let period = 5;
    switch (type) {
        case 'priceenvelopes' :
            period = 5;
            break;
        case 'pc' :
            period = 3;
            break;
    }
    return {
        period: period,
        volumeSeriesID: "volume-series"
    }
}

export enum ChartTypes {
    Area = "area",
    Line = "line",
    CandleStick = "candlestick",
    OHLC = "ohlc"
}

const getSmaIndicatorSeriesColor = (smaIndicator: technicalAverageType): string => {
    let color = '#000'
    let matched = smaIndicators.filter(value => value.period == smaIndicator.period)
    if (matched.length > 0) {
        color = matched[0].color;
    }
    return color;
};
const addSmaSeries = (indicatorArray: any[], smaIndicator: technicalAverageType, data: Chart, linkedTo: string) => {
    data?.series?.forEach((serie: ChartSeries) => {
        indicatorArray.push(
            getSmaSeries(smaIndicator, linkedTo)
        )
    })
}
export const getSmaSeries = (smaIndicator: technicalAverageType, linkedTo: string) => {
    return {
        color: getSmaIndicatorSeriesColor(smaIndicator),
        name: 'SMA (' + smaIndicator.period + ')',
        xAxis: 'instrumentAxis',
        id: 'sma' + smaIndicator.period,
        type: 'sma',
        linkedTo: linkedTo,
        visible: true,
        lineWidth:1.5,
        marker: {
            enabled: true,
            radius: 1,
            symbol: 'circle'
        },
        showInNavigator: false,
        params: {
            period: smaIndicator.period
        },
    }
}
function createSeries(seriesOptions: ChartSeriesType) {
    const {data, mappedData, volumeData, state, chartThreshold, comparisonData} = seriesOptions;
    const {currentOscillator, currentIndicator,} = state;
    let seriesPrefix = 0;
    let indicatorArray: any[];
    let volumeSeriesArray: any[];
    let currentOscillatorArray: any[];
    let volumeSeriesData: { x: number; y: number | null; }[] = [];
    let ohlcSeriesData: any[] = [];
    let compareInstrumentSeries: any[] = []
    for (let i = 0; i < volumeData?.edges?.length; i++) {
        let curr: HistoryQuoteEdge = volumeData?.edges[i];
        ohlcSeriesData.push([new Date(curr?.node?.start).getTime(), curr?.node?.lowPrice, curr?.node?.highPrice, curr.node?.firstPrice, curr?.node?.lastPrice]);
        volumeSeriesData.push({x: new Date(new Date(curr?.node?.start).valueOf()).getTime(), y: curr?.node?.cumulativeVolume})
    }
    let ohlcSeries = data?.series.map((serie: ChartSeries) => {
        return {
            id: 'ohlcseries' + serie.type,
            name: serie.type.charAt(0).toUpperCase() + serie.type?.slice(1),
            type: 'ohlc',
            data: ohlcSeriesData,
            visible: false
        }
    })
    compareInstrumentSeries = comparisonData.map(value => value.series);
    indicatorArray = data?.series?.map((serie: ChartSeries) => {
        return {
            lineWidth: 1.5,
            name: currentIndicator.name,
            //threshold: chartThreshold,
            yAxis: 0,
            id: currentIndicator.value,
            type: currentIndicator.value,
            linkedTo: 'ohlcseries' + serie.type,
            visible: true,
            params: getIndicatorParams(currentIndicator.value)
        }
    })
    volumeSeriesArray = [
        {
            colorByPoint: true,
            colors: ['red', 'green'],
            data: volumeSeriesData,
            name: "Volume",
            yAxis: 1,
            id: 'volume-series',
            type: "column",
            //linkedTo: 'dataseries' + QuoteType.Trade + 1,
            tooltip: {
                xDateFormat: '%Y-%m-%d %H:%M:%S',
                pointFormatter: function (): any {
                    // @ts-ignore
                    return `<br><strong>Volume</strong>: ${shortNumberFormat(this.y)}<br>`
                },
            }
        }
    ]
    let current = data?.series.map((serie: ChartSeries, i: number) => {
        seriesPrefix++;
        return {
            //yAxis: 0,
            id: 'dataseries' + serie.type + 1,
            name: serie.type === QuoteType.Trade ? 'Kurs' : 'NAV',
            type: serie.type === QuoteType.Trade ? 'area' : 'line',
            data: mappedData[i],
            //threshold: chartThreshold,
            color: serie.type === QuoteType.Trade ? (!!chartThreshold ? 'rgba(31, 220, 162, 1)' : "#4888C7") :
                (serie.type === QuoteType.Bid ? 'rgba(255, 77, 125, 1)' : 'rgba(31, 220, 162, 1)'),
            fillColor: serie.type === QuoteType.Trade ? (!!chartThreshold ? 'rgba(31, 220, 162, 0.3)' : 'rgba(255, 255, 255, 0)') :
                (serie.type === QuoteType.Bid ? 'rgba(31, 220, 162, 0.3)' : undefined),
            //
            negativeColor: serie.type === QuoteType.Trade ? 'rgba(255, 77, 125, 1)' : undefined,
            negativeFillColor: serie.type === QuoteType.Trade ? 'rgba(255, 77, 125, 0.3)' : undefined,
            lineWidth: 1.5,
            trackByArea: false,
            connectNulls: true,
            dataGrouping: {
                forced: false,
                groupAll: false,
                enabled: true
            }
        }
    });
    currentOscillatorArray = data?.series.map((serie: ChartSeries) => {
        return {
            lineWidth: 1.5,
            name: currentOscillator.name,
            //threshold: chartThreshold,
            yAxis: 2,
            id: currentOscillator.value,
            type: currentOscillator.value,
            linkedTo: 'ohlcseries' + serie.type,
            visible: true,
            params: {
                period: 5,
                volumeSeriesID: 'volume-series'
            },
            connectNulls: true
        }
    })

    const linkedTo = 'smaLineSeries';
    if (state.sma20Selected) {
        let smaSelectedIndicator: technicalAverageType = smaIndicators.filter(value => value.period == 20)[0];
        addSmaSeries(indicatorArray, smaSelectedIndicator, data, linkedTo)
    }
    if (state.sma50Selected) {
        let smaSelectedIndicator: technicalAverageType = smaIndicators.filter(value => value.period == 50)[0];
        addSmaSeries(indicatorArray, smaSelectedIndicator, data, linkedTo)
    }
    if (state.sma100Selected) {
        let smaSelectedIndicator: technicalAverageType = smaIndicators.filter(value => value.period == 100)[0];
        addSmaSeries(indicatorArray, smaSelectedIndicator, data, linkedTo)
    }
    if (state.sma200Selected) {
        let smaSelectedIndicator: technicalAverageType = smaIndicators.filter(value => value.period == 200)[0];
        addSmaSeries(indicatorArray, smaSelectedIndicator, data, linkedTo)
    }
    if (compareInstrumentSeries.length > 1) {
        current = current && [...volumeSeriesArray, ...compareInstrumentSeries];
    } else {
        current = current && [...current, ...ohlcSeries, ...indicatorArray, ...volumeSeriesArray, ...currentOscillatorArray];
        if (state.smaSeries) {
            let smaSeries: any[] = [state.smaSeries];
            current = current && [...current, ...smaSeries]
        }
    }
    return current;
}

function createYAxis(minPointData: number, maxPointData: number, chartThreshold: number, props: ChartComponentProps, OSC: technicalIndicatorType) {
    let arr: any[] = [];
    arr.push({
            type: 'linear',
            title: {text: ''},
            lineColor: 'rgba(255, 255, 255, 0.3)',
            gridLineColor: 'rgba(255, 255, 255, 0.14)',
            min: minPointData,
            max: maxPointData,
            plotLines: createPlotLines(chartThreshold),
            opposite: true,
            height: '70%'
        },
        {
            type: 'column',
            opposite: true,
            height: '15%',
            top: '70%'
        }
    )
    if (OSC) {
        arr.push({
            type: OSC.value,
            opposite: true,
            height: '20%',
            top: '85%'
        });
    }
    return arr;
}

function populateSeriesData(serie: ChartSeries, maxPointData: number, foundMax: boolean, data: Chart, minPointData: number, foundMin: boolean) {
    let array: any[] = [];
    serie.data.forEach((entry: ChartPoint) => {
        let dataObj = {
            x: moment(entry.when).valueOf(),
            y: entry.value,
        };
        if (entry.value === maxPointData && !foundMax && data.series.length === 1) {
            dataObj = Object.assign(dataObj, {
                marker: {
                    enabled: true,
                    lineWidth: 2,
                    radius: 5,
                    symbol: 'circle',
                    lineColor: '#383838',
                    fillColor: 'transparent',
                    fillOpacity: 0,
                },
                dataLabels: {
                    enabled: true,
                    style: {
                        color: 'var(--green)',
                        fontSize: '10px',
                        fontWeight: 'bold',
                        textOutline: 0,
                    },
                    formatter: function () {
                        return '<span class="chart-marker-label">hoch</span>';
                    },
                    y: -3,
                    verticalAlign: 'bottom',
                    useHTML: true,
                },
            });
            foundMax = true;
        }
        if (entry.value === minPointData && !foundMin && data.series.length === 1) {
            dataObj = Object.assign(dataObj, {
                marker: {
                    enabled: true,
                    lineWidth: 2,
                    radius: 5,
                    symbol: 'circle',
                    lineColor: '#383838',
                    fillColor: 'transparent',
                    fillOpacity: 0,
                },
                dataLabels: {
                    enabled: true,
                    style: {
                        color: 'var(--pink)',
                        fontSize: '10px',
                        fontWeight: 'bold',
                        textOutline: 0,
                    },
                    formatter: function () {
                        return '<span class="chart-marker-label">tief</span>';
                    },
                    y: 3,
                    verticalAlign: 'top',
                    useHTML: true,
                },
            });
            foundMin = true;
        }
        array.push(dataObj);
    });
    return array;
}

function createPoints(data: Chart) {
    let points: any[] = [];
    if (data?.series.length === 1) {
        points.push(createPoint());
    }
    return points;
}

function createPoint() {
    return {
        marker: {
            enabled: true,
            lineWidth: 2,
            radius: 5,
            symbol: 'circle',
            lineColor: '#383838',
            fillColor: 'transparent',
            fillOpacity: 0,
        },
        dataLabels: {
            enabled: true,
            style: {
                color: 'var(--green)',
                fontSize: '10px',
                fontWeight: 'bold',
                textOutline: 0,
            },
            formatter: function () {
                return '<span class="chart-marker-label">hoch</span>';
            },
            y: -3,
            verticalAlign: 'bottom',
            useHTML: true,
        },
    }
}

function calculateMaForPeriod(state: ChartToolModalState, period: number) {
    const startTime = moment(calculatePeriodFromChartScope(state.chartScope)).valueOf();
    let smaLength = state.smaSeries.data.length;
    let ma = 0;
    for (let i = smaLength - 1; i !== 0; i--) {
        if (state.smaSeries.data[i].x < startTime) {
            for (let j = 0; j < period; j++) {
                if (state.smaSeries.data[i]) {
                    ma = ma + state.smaSeries.data[i].y;
                    i--;
                }
            }
            ma = ma / period;
            break;
        }
    }
    return ma;
}

function findMinMaxFromSmaSeries(state: ChartToolModalState, minPointData: number, maxPointData: number) {
    if (state.smaSeries && state.smaSeries.data.length > 0) {
        let period = 0;
        let ma = 0;
        if (state.sma20Selected) {
            period = 20;
            ma = calculateMaForPeriod(state, period);
            minPointData = Math.min(minPointData, ma);
            maxPointData = Math.max(maxPointData, ma);
        }
        if (state.sma50Selected) {
            period = 50;
            ma = calculateMaForPeriod(state, period);
            minPointData = Math.min(minPointData, ma);
            maxPointData = Math.max(maxPointData, ma);
        }
        if (state.sma100Selected) {
            period = 100;
            ma = calculateMaForPeriod(state, period);
            minPointData = Math.min(minPointData, ma);
            maxPointData = Math.max(maxPointData, ma);
        }
        if (state.sma200Selected) {
            period = 200;
            ma = calculateMaForPeriod(state, period);
            minPointData = Math.min(minPointData, ma);
            maxPointData = Math.max(maxPointData, ma);
        }
    }
    return {minPointData, maxPointData};
}

export const createOptions = (options: ChartOptionsType) => {
    const {data, props, state, setState, volumeData, setToggleChartOptions, toggleChartOptions, comparisonData} = options;
    let chartThreshold = data?.threshold;
    const mappedData: any[][] = [];
    let minPointData = chartThreshold?.value || 99999999;
    let maxPointData = chartThreshold?.value || 0;
    let foundMin = false;
    let foundMax = false;
    const timezone = new Date().getTimezoneOffset()
    if(comparisonData.length > 1) {
        minPointData = 100;
        maxPointData = 0;
        comparisonData.forEach(data => {
            data.series.data.map((val:any) => val.y).forEach((val:number) => {
                minPointData = Math.min(minPointData, val);
                maxPointData = Math.max(maxPointData, val);
            })
        })
    } else {
        // find min max from main series
        data?.series.forEach((serie: ChartSeries) => {
            minPointData = findMinPoint(minPointData, data);
            maxPointData = findMaxPoint(maxPointData, data);
            mappedData.push(populateSeriesData(serie, maxPointData, foundMax, data, minPointData, foundMin));
        });
        const minMax = findMinMaxFromSmaSeries(state, minPointData, maxPointData);
        minPointData = minMax.minPointData;
        maxPointData = minMax.maxPointData;
    }

    const seriesOptions: ChartSeriesType = {
        data,
        mappedData,
        chartThreshold: chartThreshold?.value || 0,
        state,
        volumeData,
        comparisonData: options.comparisonData
    }
    const series = createSeries(seriesOptions);
    const chartOptions = {
        chart: {
            animation: false,
            backgroundColor: null,
            margin: [0, 0, 30, 0],
            points: createPoints(data),
            zoomType: 'xy',
            resetZoomButton: {
                position: {
                    align: 'right', // by default
                    verticalAlign: 'top', // by default
                    x: -40,
                    y: 0
                }
            }
        },
        rangeSelector: {
            enabled: false,
            inputEnabled: false
        },
        scrollbar: {
            enabled: true,
        },
        navigator: {
            enabled: true,
        },
        navigation: {
            bindingsClassName: 'custom-tools-container'
        },
        time: {
            timezoneOffset: timezone
        },

        legend: {
            enabled: false
        },

        tooltip: {
            shared: true,
            split: false,
            dateTimeLabelFormats: {
                millisecond: '%H:%M:%S.%L',
                second: '%d.%m.%Y %H:%M:%S',
                minute: '%d.%m.%Y %H:%M:%S',
                hour: '%d.%m.%Y %H:%M:%S',
                day: '%d.%m.%Y',
                week: '%d.%m.%Y',
                month: '%d.%m.%Y',
                year: '%Y'
            }
        },

        xAxis: {
            id: 'instrumentAxis',
            lineColor: 'rgba(255, 255, 255, 0.3)',
            type: "datetime",
            month: "%m",
            //    tickInterval: Infinity,
            offset: 8,
        },
        yAxis: createYAxis(minPointData, maxPointData, chartThreshold?.value || 0, props, state.currentOscillator),

        plotOptions: {
            series: {
                marker: {
                    //  enabled: false,
                },
                label: {
                    connectorAllowed: true,
                    enabled: true
                },
                series: {
                    connectNulls: true
                }
            }
        },

        credits: {
            enabled: false,
        },
        stockTools: {
            gui: {
                enabled: false
            }
        },
        series: series
    };
    return chartOptions;
}

export interface ChartComponentProps {
    instrumentId: number,
    showStockTools: boolean
    setToggleChartOptions: (val: boolean) => void;
    toggleChartOptions: boolean
    setShowStockTools: (val: boolean) => void;
}

const createPlotLines = (chartThreshold?: number | null) => {
    let plotLines: any[] = [];
    if (chartThreshold) {
        plotLines.push({
            value: chartThreshold,
            color: 'rgba(255, 255, 255, 0.7)',
            width: 2,
            label: {
                text: 'Schlusskurs ' + numberFormat(chartThreshold || 0),
                style: {
                    color: 'rgba(255, 255, 255, 0.7)',
                }
            }
        })
    }
    return plotLines;
}

export const calculatePeriodFromChartScope = (scope: ChartScope): moment.Moment => {
    switch (scope) {
        case ChartScope.Intraday: return moment().local(true).startOf('date')
        case ChartScope.Week: return moment().local(true).subtract(7, 'days').startOf('day')
        case ChartScope.Month: return moment().local(true).subtract(1, 'months').startOf('day')
        case ChartScope.ThreeMonth: return moment().local(true).subtract(3, 'months').startOf('day')
        case ChartScope.SixMonth: return moment().local(true).subtract(6, 'months').startOf('day')
        case ChartScope.Year: return moment().local(true).subtract(1, 'year').startOf('day')
        case ChartScope.ThreeYear: return moment().local(true).subtract(3, 'years').startOf('day')
        case ChartScope.FiveYear: return moment().local(true).subtract(5, 'years').startOf('day')
        case ChartScope.TenYear: return moment().local(true).subtract(10, 'years').startOf('day')
        default: return moment().local(true).subtract(1, 'days').startOf('day')
    }
}

export const calculateXMinForSmaFromChartScope = (scope: ChartScope, data: any): moment.Moment => {
    if (scope == ChartScope.Intraday) {
        // rely on backend data to find x min as we sometimes show last available trading data in charts
        let startTime = moment().local(true).startOf('date');
        if (data && data.instrument?.chart?.series && data.instrument?.chart?.series.length > 0) {
            data.instrument?.chart?.series
                .forEach((serieData: any) => {
                    serieData.data
                        .forEach((current: any): any => {
                                let when = moment(current.when).valueOf();
                                if (when < startTime.valueOf()) {
                                    startTime = moment(current.when);
                                }
                            }
                        );
                })
        }
        return startTime;
    }
    return calculatePeriodFromChartScope(scope);
}

export const aggregationFromChartScope = (scope: ChartScope): HistoryQuoteAggregation | null => {
    switch (scope) {
        case ChartScope.Intraday: return HistoryQuoteAggregation.Minute
        case ChartScope.Week:
        case ChartScope.Month:
            return HistoryQuoteAggregation.Hour
        case ChartScope.ThreeMonth: return HistoryQuoteAggregation.Day
        default: return null
    }
}

export type technicalIndicatorType = {
    id: number
    name: string
    value: string
}

export type technicalAverageType ={
    id : number,
    name: string,
    value: string,
    period : number,
    color : string,
    ivw_code: string
}

export type annotationType = {
    id: number
    name: string
    label: string
    icon?: string
}

export const smaIndicators: technicalAverageType[] =[
    {
        id: 0, value: "sma", name: '20 Tage', period :20, color: "#FFC300", ivw_code: 'gd_20'
    },
    {
        id: 1, value: "sma", name: '50 Tage', period :50, color: "#FF6600", ivw_code: 'gd_50'
    },
    {
        id: 2, value: "sma", name: '100 Tage', period :100, color: "#E65456", ivw_code: 'gd_100'
    },
    {
        id: 3, value: "sma", name: '200 Tage', period :200, color: "#B6325F", ivw_code: 'gd_200'
    },
]

export const indicators: technicalIndicatorType[] = [
    {
        id: 0, value: "", name: 'Bitte auswählen'
    },
    {
        id: 1, name: "Acceleration Bands", value: "abands"
    },
    {
        id: 2, name: "Bollinger Bands", value: "bb"
    },
    {
        id: 3, name: "DEMA (Double Exponential Moving Average)", value: "dema"
    },
    {
        id: 4, name: "EMA (Exponential Moving Average)", value: "ema"
    },
    // {
    //     id: 5, name: "Ichimoku Kinko Hyo", value: "ikh"
    // },
    // {
    //     id: 6, name: "Keltner Channels", value: "keltnerchannels"
    // },
    {
        id: 7, name: "Linear Regression", value: "linearRegression"
    },
    {
        id: 8, name: "Pivot Points", value: "pivotpoints"
    },
    {
        id: 9, name: "Price Channel", value: "pc"
    },
    {
        id: 10, name: "Price Envelopes", value: "priceenvelopes"
    },
    {
        id: 11, name: "PSAR (Parabolic SAR)", value: "psar"
    },
    {
        id: 12, name: "SMA (Simple Moving Average)", value: "sma"
    },
    // {
    //     id: 13, name: "Super Trend", value: "supertrend"
    // },
    {
        id: 14, name: "TEMA (Triple Exponential Moving Average)", value: "tema"
    },
    {
        id: 17, name: "VWAP (Volume Weighted Average Price)", value: "wma"
    },
    {
        id: 18, name: "Zig Zag", value: "zigzag"
    },
]

export const oscillators: technicalIndicatorType[] = [
    {
        id: 0, value: "", name: 'Bitte auswählen'
    },
    {
        id: 1, name: "Absolute price indicator", value: "apo"
    },
    {
        id: 2, name: "A/D (Accumulation/Distribution)", value: "ad"
    },
    {
        id: 3, name: "Aroon", value: "aroon"
    },
    {
        id: 4, name: "Aroon oscillator", value: "aroonoscillator"
    },
    {
        id: 5, name: "ATR (Average True Range)", value: "atr"
    },
    {
        id: 6, name: "Awesome oscillator", value: "ao"
    },
    {
        id: 7, name: "CCI (Commodity Channel Index)", value: "cci"
    },
    {
        id: 8, name: "Chaikin", value: "chaikin"
    },
    {
        id: 9, name: "CMF (Chaikin Money Flow)", value: "cmf"
    },
    {
        id: 13, name: "Detrended price", value: "dpo"
    },
    {
        id: 14, name: "Linear Regression Angle", value: "linearRegressionAngle"
    },
    {
        id: 15, name: "Linear Regression Intercept", value: "linearRegressionIntercept"
    },
    {
        id: 16, name: "Linear Regression Slope", value: "linearRegressionSlope"
    },
    {
        id: 18, name: "MACD (Moving Average Convergence Divergence)", value: "macd"
    },
    {
        id: 19, name: "MFI (Money Flow Index)", value: "mfi"
    },
    {
        id: 20, name: "Momentum", value: "momentum"
    },
    {
        id: 21, name: "NATR (Normalized Average True Range)", value: "natr"
    },
    {
        id: 23, name: "Percentage Price oscillator", value: "ppo"
    },
    {
        id: 24, name: "RoC (Rate of Change)", value: "roc"
    },
    {
        id: 25, name: "RSI (Relative Strength Index)", value: "rsi"
    },
    {
        id: 26, name: "Slow Stochastic", value: "slowstochastic"
    },
    {
        id: 27, name: "Stochastic", value: "stochastic"
    },
    {
        id: 28, name: "TRIX", value: "trix"
    },
    {
        id: 29, name: "Williams %R", value: "williamsr"
    },
]

export const shapes: annotationType[] = [
    {id: 0, name: "highcharts-label-annotation", label: 'Label'},
    {id: 1, name: "highcharts-circle-annotation", label: 'Circle'},
    {id: 2, name: "highcharts-rectangle-annotation", label: 'Rectangle'},
    {id: 3, name: "highcharts-ellipse-annotation", label: 'Ellipse'}
]

export const lines: annotationType[] = [
    {id: 0, name: "highcharts-segment", label: 'Segment'},
    {id: 1, name: "highcharts-arrow-segment", label: 'Arrow Line'},
    {id: 2, name: "highcharts-ray", label: 'Ray'},
    {id: 3, name: "highcharts-arrow-ray", label: 'Arrow Ray'},
    {id: 4, name: "highcharts-infinity-line", label: 'Infinity Line'},
    {id: 5, name: "highcharts-arrow-infinity-line", label: 'Infinity Line'},
    {id: 6, name: "highcharts-horizontal-line", label: 'Horizontal Line'},
    {id: 7, name: "highcharts-vertical-line", label: 'Vertical Line'}
]

export const crookedLines: annotationType[] = [
    {id: 0, name: "highcharts-elliott3", label: 'Elliott 3 line'},
    {id: 1, name: "highcharts-elliott5", label: 'Elliott 5 line'},
    {id: 2, name: "highcharts-crooked3", label: 'Crooked 3 line'},
    {id: 3, name: "highcharts-crooked5", label: 'Crooked 5 line'}
]

export const measure: annotationType[] = [
    {id: 0, name: "highcharts-measure-x", label: 'Measure x'},
    {id: 1, name: "highcharts-measure-y", label: 'Measure y'},
    {id: 2, name: "highcharts-measure-xy", label: 'Measure xy'}
]

export const advanced: annotationType[] = [
    {id: 0, name: "highcharts-fibonacci", label: 'Fibonnacci'},
    {id: 1, name: "highcharts-pitchfork", label: 'Pitchfork'},
    {id: 2, name: "highcharts-parallel-channel", label: 'Parallel Channel'}
]

export const verticalLabels: annotationType[] = [
    {id: 0, name: "highcharts-vertical-counter", label: 'Counter'},
    {id: 1, name: "highcharts-vertical-label", label: 'Label'},
    {id: 2, name: "highcharts-vertical-arrow", label: 'Arrow'}
]

export const flags: annotationType[] = [
    {id: 0, name: "highcharts-flag-circlepin", label: 'Flag circle'},
    {id: 1, name: "highcharts-flag-diamondpin", label: 'Flag diamond'},
    {id: 2, name: "highcharts-flag-squarepin", label: 'Flag square'},
    {id: 3, name: "highcharts-flag-simplepin", label: 'Flag simple'}
]

export const compareSeriesColors = [
    "#0D5A94",
    "#FFAF73",
    "#802353",
    "#E65456",
    "#159C8C",
    "#A66486",
    "#f86c95",
    "#DAD361",
    "#63BD5C",
    "#CAC11F",
    "#ffcc00",
    "#18B589",
    "#FF8D38",
    "#B6325F",
    "#521849",
    "#797988",
    "#117F8F",
    "#558BB4",
    "#57A5B0",
    "#5AB9AE",
    "#5CCBAC",
    "#91D08C",
    "#DAD361",
    "#ED8788",
    "#E97695",
    "#CB6F8E",
    "#855C7F",
    "#767696",
];

export function createComparisonSeries(instrument: Instrument, visibility: boolean, color: string): any {
    let serie: any = instrument?.chart?.series ? instrument?.chart?.series[0] : {}
    let firstCourse = (serie.data[0] && serie.data[0].value) ? serie.data[0].value : 0;
    return {
        id: instrument?.id,
        name: instrument?.group?.name,
        color: color ? color : "black",
        type: 'line',
        visible: visibility,
        lineWidth: 1.5,
        tooltip: {
            shared: true,
            split: false,
            valueSuffix: "%",
            style: {
                fontFamily: "Roboto"
            }
        },
        data: serie.data
            .map((current: any, index: number): any => {
                    let when = moment(current.when).toDate().valueOf();
                    return ({
                        y: Math.round(((current.value - firstCourse) / firstCourse) * 10000) / 100,
                        x: when
                    })
                }
            ),
    };
}

export function createSmaSeries(instrument: Instrument) {
   let history:HistoryQuoteEdge[] = instrument?.historyQuote?.edges;
    let smaSeries = history && history.length > 0 ? history : null;
    let smaData: any[] = [];
    if (smaSeries) {
        smaSeries.forEach((item: HistoryQuoteEdge) => {
            let node = item?.node;
            if(node) {
                smaData.push({x: moment(node?.start).valueOf(), y: node.lastPrice});
            }
        });
    }
    let todayData = instrument?.chart?.series && instrument?.chart?.series.length > 0 ? instrument?.chart?.series[0].data : [];
    let todayLastPrice: ChartPoint = todayData[todayData.length-1];
    smaData.push({x: moment(todayLastPrice?.when).valueOf(), y: todayLastPrice?.value})
    let sortedSmaData = smaData.sort((smaDataFirst:any, smaDataSecond:any) => smaDataFirst.x - smaDataSecond.x);
    return {
        id: 'smaLineSeries',
        name: 'smaLineSeries',
        turboThreshold: 0,
        visible: false,
        data: sortedSmaData,
        xAxis: 'instrumentAxis',
        showInNavigator: false
    }
}

export interface ChartToolModalState {
    currentIndicator: technicalIndicatorType
    currentOscillator: technicalIndicatorType
    chartScope: ChartScope
    chartType: ChartTypes
    showIndicator: boolean
    showOscillator: boolean
    activeRangeSelectorButtonId: number
    showAnnotations: boolean
    sma20Selected: boolean
    sma50Selected: boolean
    sma100Selected: boolean
    sma200Selected: boolean
    smaSeries?: any
}