import {gql, useQuery} from "@apollo/client";
import {Spinner} from "react-bootstrap";
import {useEffect} from "react";
import classNames from "classnames";
import {Instrument, Query} from "../../../generated/graphql";
import {QuoteValueType} from "../../../utils";
import {SnapQuoteValue} from "components/common/misc/SnapQuoteValue";

const INSTRUMENT_INFO_QUERY = gql`
    query getInstrumentSnapQuote($instrumentId:Int!) {
        instrument(id:$instrumentId) {
            isin
            id
            wkn
            currency{
                id
                displayCode
            }
            group {
                assetGroup
            }
            exchange {
                name
                id
            }
            snapQuote {
                lastChange
                instrumentId
                delay
                quotes {
                    type
                    value
                    change
                    percentChange
                    when
                }
            }
        }
    }
`;

interface EditorialFeedInstrumentInfoProps {
    instrument: Instrument | undefined;
    onInstrumentLoaded?: (instrument: Instrument|null|undefined) => any;
    className?: string;
}

export const InstrumentInfo = ({instrument, onInstrumentLoaded, className}: EditorialFeedInstrumentInfoProps) => {
    useEffect(() =>{
        if(onInstrumentLoaded) onInstrumentLoaded(data?.instrument);
    });
    const {loading, data} = useQuery<Query>(INSTRUMENT_INFO_QUERY, {variables: {instrumentId: instrument?.id}});
    
    if (loading) {
        return <div className="mr-4 my-auto"><Spinner animation="border" size="sm" as="span"/></div>;
    }

    const currentInstrument = data?.instrument || instrument
    const snapQuote = currentInstrument?.snapQuote

    return (<>
        <div className={classNames("d-flex", className)}>
            <div className="text-right">
                { instrument &&
                    <span className="bg-orange text-white font-size-12px px-1 mr-1 mr-md-2">
                    {snapQuote?.delay && (snapQuote?.delay === 1 ? 'RT' : '+15')}
                </span>}
                <SnapQuoteValue instrument={currentInstrument} snapQuote={currentInstrument?.snapQuote} quoteValueType={QuoteValueType.VALUE} className="mr-2 mr-md-3 font-weight-bold" />
                <SnapQuoteValue instrument={currentInstrument} snapQuote={currentInstrument?.snapQuote} quoteValueType={QuoteValueType.PERCENT_CHANGE} blink={true} className="mr-3 text-nowrap font-weight-bold" />
                <SnapQuoteValue instrument={currentInstrument} snapQuote={currentInstrument?.snapQuote} quoteValueType={QuoteValueType.WHEN} suffix=" Uhr" className="d-none d-md-inline mr-3 text-nowrap" />
                <span>{instrument?.exchange?.name}</span>
            </div>
            {
                instrument &&
                <div className="text-right ml-2">
                <span>WKN: {instrument?.wkn}</span>
                <span className="ml-3 d-none d-xl-inline">ISIN: {instrument?.isin}</span>
            </div>}
        </div>
    </>);
}

