import { useQuery } from "@apollo/client";
import { Query } from "generated/graphql";
import { loader } from "graphql.macro";
import { Button, Spinner } from "react-bootstrap";
import { LocationState } from "components/common/news/NewsModal/NewsModal";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";

function BlogTeaser() {
  const location = useLocation();
  let { loading, data } = useQuery<Query>(
      loader('../../../news/getNewsFeed.graphql'),
      {
          variables: {
              criteria: {
                  source: [
                    "BLOG_FEED"
                  ]
              },
          }
      }
  );
  if (loading) {
    return <div className={"mt-4 d-flex justify-content-center"} style={{ height: "70px" }}><Spinner animation="border" /></div>
  }
  const locationState: LocationState = { news: data?.newsSearch?.edges![0]?.node, searchCriteria: { source: ["BLOG_FEED"] }, pathname: location.pathname, hash: location.hash }
  return (
    <>    
    {data && data?.newsSearch && data?.newsSearch?.edges![0] && data?.newsSearch?.edges![0]?.node  &&
      <>
      <Link className="text-decoration-none"
        to={{
          key: '',
          pathname: "/nachrichten/" + data?.newsSearch?.edges![0]?.node?.id + "/",
          state: locationState
        }}>
          <div className="blog-teaser-image mw-100" style={{backgroundImage: `url(${data?.newsSearch?.edges![0]?.node?.medias![0]?.source?.toString()})`}}>
          </div>
          <div className="blog-teaser-details">
            <div className="d-flex justify-content-between pb-2">
              <div className="blog-teaser-title">
                {data?.newsSearch?.edges![0]?.node?.source?.name}
              </div>
              <div className="blog-teaser-timeago">
                {moment.duration(moment(data?.newsSearch?.edges![0]?.node?.when).diff(moment())).humanize(true)}
              </div>
            </div>
            <div className="blog-teaser-headline">
              {data?.newsSearch?.edges![0]?.node?.headline}
            </div>
            <div className="d-flex justify-content-end">
              <Button className="blog-teaser-open-button" >Weiterlesen im finanztreff.de Blog</Button>
            </div>
          </div>
      </Link>
      <div className="mt-2 mb-sm-2">
        <Link className="text-decoration-none"
          to={{
            key: '',
            pathname: "/nachrichten/",
            hash: data?.newsSearch?.edges![0]?.node?.keywords![0].toLowerCase()
          }}>
          Alle Blogartikel...
        </Link>
      </div>
    </>
    }
</>
  )
}

export default BlogTeaser