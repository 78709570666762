import classNames from "classnames";
import { Option, OptionItem } from "components/common/SearchCard/FilterDropdownItem";
import { PageHeaderFilterComponent } from "components/layout/PageHeaderFilterComponent/PageHeaderFilterComponent";
import PageHeaderFilterContext from "components/layout/PageHeaderFilterComponent/PageHeaderFilterContext";
import { useCallback, useContext } from "react";
import { Button, Card } from "react-bootstrap";
import './SimpleOptionFilter.scss';

interface SimpleOptionFilterContentProps<OptionType> {
    className?: string;
    title: string;
    onSelect?: (type: OptionSelectEvent<OptionType>) => void;
    options: OptionType[];
    selectedOption: OptionType;
}

interface OptionSelectEvent<OptionType> {
    option: OptionType;
}


function SimpleOptionFilterContent<OptionType extends Option<any> = OptionItem>({className, title, options, onSelect, selectedOption}: SimpleOptionFilterContentProps<OptionType>) {
    let context  = useContext(PageHeaderFilterContext);

    let closeAction = useCallback(() => {
        if (context) {
            context.close();
        }
    }, [context]);

    return (
        <Card className={classNames(className, "countries-regions-filter px-3 pt-1 border-0")}>
            <Card.Header className="bg-white pb-0 pt-1 px-0 d-flex justify-content-between">
                <h6 className="font-weight-bold pt-2">{title}</h6>
                <span className="close-modal-butt svg-icon mt-n1 mr-n1 cursor-pointer" onClick={() => closeAction()}>
                    <img src={process.env.PUBLIC_URL + "/static/img/svg/icon_close_dark.svg"} alt="" className="svg-convert svg-blue" />
                </span> 
            </Card.Header>
            <Card.Body className="d-flex justify-content-center px-0">
                <div className="d-flex flex-wrap button-container">
                {
                    options.map(current =>
                        <Button variant={'inline-inverse'} 
                            key={current.id}
                            disabled={current.disabled}
                            onClick={() => {
                                if (onSelect) {
                                    onSelect({option: current});
                                }
                                closeAction();
                            }} className={selectedOption.id === current.id ? "btn active m-1" : "btn m-1"}>
                            {current.name}
                        </Button>
                    )
                }
                </div>
            </Card.Body>
        </Card>
    );
}

interface SimpleOptionFilterProps<OptionType> {
    selectedOption: OptionType;

    options: OptionType[];
    onSelect?: (type: OptionSelectEvent<OptionType>) => void;
}


export function SimpleOptionFilter<OptionType extends Option<any> = OptionItem>(props: SimpleOptionFilterProps<OptionType>) {

    return (
        <PageHeaderFilterComponent
            variant={"dropdown-panel"}
            toggleVariant={"panel-button"}
            toggleIcon={process.env.PUBLIC_URL + "/static/img/svg/icon_direction_down_white.svg"}
            title={"Zeitraum"}
            description={props.selectedOption.name}>
            <SimpleOptionFilterContent<OptionType>
                title={"Ansicht auswählen"}
                selectedOption={props.selectedOption}
                onSelect={ev => {
                    if (props.onSelect) {
                        props.onSelect(ev);
                    }
                }}
                options={props.options}
            />
        </PageHeaderFilterComponent>
    );
}


