import {ApolloError, ApolloQueryResult, DocumentNode, FetchMoreOptions, FetchMoreQueryOptions, NetworkStatus, ObservableQuery, OperationVariables, QueryHookOptions, QueryResult, TypedDocumentNode, useApolloClient} from "@apollo/client";
import {Subscription} from "zen-observable-ts";
import {useQuery, useSubscription} from "@apollo/client"; // for JSDoc
import {useSnapQuoteSubscription} from "./useSnapQuoteSubscription"; // for JSDoc

import {useRef, useState} from "react";
import {isEqual, omit} from "underscore";

type SmartCacheQueryState<TData = any, TVariables = any> = {
    data?: TData
    loading: boolean
    error?: ApolloError
    networkStatus?: NetworkStatus
    fetchMore?: (fetchMoreOptions: FetchMoreQueryOptions<TVariables, TData> & FetchMoreOptions<TData, TVariables>) => Promise<ApolloQueryResult<TData>>
    refetch?: (variables?: any) => Promise<ApolloQueryResult<any>>
}
export interface SmartCacheQueryHookOptions<TData = any, TVariables = OperationVariables> extends QueryHookOptions<TData, TVariables> {
    log?: boolean
}

type ResultObjectInfo = {
    paginated: boolean
    resultObjectKey?: string
}
const getResultObjectInfo = <TData,>(result: TData): ResultObjectInfo => {
    const resultData: object | undefined = (result === undefined || typeof(result) !== "object") ? undefined : result as unknown as object
    const keys: string[] | undefined = resultData === undefined ? undefined : Object.keys(resultData)
    const returnedObject: object | undefined = (keys === undefined || resultData === undefined) ? undefined : (resultData as object)[keys[0] as keyof typeof resultData]

    return {
        paginated: returnedObject !== undefined && 'pageInfo' in returnedObject,
        resultObjectKey: keys?.[0]
    }
}
const areOptionsEqual = <TData, TVariables>(previousOptions: SmartCacheQueryHookOptions<TData, TVariables>, currentOptions: SmartCacheQueryHookOptions<TData, TVariables>) =>
    isEqual(omit(previousOptions, ["onCompleted", "onError"]), omit(currentOptions, ["onCompleted", "onError"]))
const areVariablesEqual = <TVariables,>(previousVariables: TVariables, currentVariables: TVariables) =>
    isEqual(previousVariables, currentVariables)

const defaultState: SmartCacheQueryState = {
    data: undefined,
    loading: false,
    error: undefined,
    networkStatus: undefined,
    fetchMore: undefined,
    refetch: undefined
}
/**
 * An {@link useQuery} hook that is to be used with {@link useSubscription}/{@link useSnapQuoteSubscription} to avoid unnecessary re-renders
*/
export function useSmartCacheQuery<TData = any, TVariables = OperationVariables>(query: DocumentNode | TypedDocumentNode<TData, TVariables>, options?: SmartCacheQueryHookOptions<TData, TVariables>): Partial<QueryResult<TData, TVariables>> {
    const apolloClient = useApolloClient()
    const watchQuery = useRef<ObservableQuery<TData, TVariables>>()
    const watchQuerySubscription = useRef<Subscription>()

    const resultObjectInfo = useRef<ResultObjectInfo>()
    const optionsRef = useRef(options)
    if (options && optionsRef.current && !areOptionsEqual(options, optionsRef.current))
        optionsRef.current = options // using ref because callback functions can't access the last props values.

    const prevOptionsRef = useRef(optionsRef.current)
    const optionsChanged = useRef(false)

    const [state, setState] = useState<SmartCacheQueryState>(defaultState)
    const stateRef = useRef<SmartCacheQueryState>(state)
    stateRef.current = state

    if ((optionsRef.current?.variables && prevOptionsRef.current?.variables) && !areVariablesEqual(prevOptionsRef.current.variables, optionsRef.current.variables) && watchQuery.current?.hasObservers()) {
        prevOptionsRef.current = optionsRef.current
        optionsChanged.current = true
        watchQuery.current.setVariables(optionsRef.current.variables)
        // not wiping data. The original useQuery hook also doesn't do it
        setState((currentState: SmartCacheQueryState) => ({
            ...currentState,
            loading: true,
            error: undefined,
            networkStatus: undefined
        }))
    }

    if ((watchQuerySubscription.current === undefined || watchQuerySubscription.current.closed) && !optionsRef.current?.skip) {
        watchQuery.current = apolloClient.watchQuery({query, variables: optionsRef.current?.variables})

        watchQuerySubscription.current = watchQuery.current.subscribe((result: ApolloQueryResult<TData>) => {
            if (resultObjectInfo.current === undefined)
                resultObjectInfo.current = getResultObjectInfo(result.data)
            const {paginated, resultObjectKey} = resultObjectInfo.current
            const newPageFetched = paginated && resultObjectKey !== undefined && stateRef.current.data !== undefined &&
                ((result.data[resultObjectKey as keyof typeof result.data] as any).pageInfo.endCursor !== stateRef.current.data[resultObjectKey].pageInfo.endCursor)

            const skip: boolean = (stateRef.current.data != null && !optionsChanged.current && !newPageFetched)
                || (optionsRef.current?.skip || false)

            if (skip)
                return

            optionsChanged.current = false
            setState((currentState: SmartCacheQueryState) => ({
                ...currentState,
                data: result.data,
                loading: result.loading,
                error: result.error,
                networkStatus: result.networkStatus
            }))
            if (result.error)
                optionsRef.current?.onError && optionsRef.current.onError(result.error)
            else
                optionsRef.current?.onCompleted && optionsRef.current.onCompleted(result.data)
        }, (error: Error) => {
            if (!error.hasOwnProperty('graphQLErrors'))
                throw error
            const hasPreviousResult = state.data !== undefined && state.networkStatus !== undefined
            if (!hasPreviousResult || (hasPreviousResult && state.loading) || isEqual(state.error, error))
                setState((currentState: SmartCacheQueryState) => ({
                    ...currentState,
                    error: error as ApolloError,
                    loading: false,
                    networkStatus: NetworkStatus.error
                }))
            watchQuerySubscription.current?.unsubscribe()
            optionsRef.current?.onError && optionsRef.current.onError(error as ApolloError)
        }, () => {
            // onComplete
        })
        setState((currentState: SmartCacheQueryState) => ({
            ...currentState,
            fetchMore: watchQuery.current!.fetchMore.bind(watchQuery.current),
            refetch: watchQuery.current!.refetch.bind(watchQuery.current)
        }))
    }

    return state
}
