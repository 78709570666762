import {Link} from "react-router-dom";
import {SnapQuoteValue} from "components/common/misc/SnapQuoteValue";
import {formatPrice, getTradeQuote, numberFormat, numberFormatWithSign, QuoteValueType} from "utils";
import {useQuery} from "@apollo/client";
import {loader} from "graphql.macro";
import {Spinner} from "react-bootstrap";
import {Instrument, Query, SnapQuote} from "../../../../graphql/types";
import classNames from "classnames";
import {useSnapQuoteSubscription} from "hooks/useSnapQuoteSubscription";

interface EditorialFeedInstrumentInfoProps {
    id?: number;
    name?: string;
    url?: string | null;
    showPrice?: boolean;
    showCurrency?: boolean;
    className?: string;
    onReceived?: (snapQuote: SnapQuote | null) => void;
    instrument?: Instrument;
    snapQuote?: SnapQuote | null| undefined;
}

export const NewsInstrumentInfo = (props: EditorialFeedInstrumentInfoProps) => {
    let {loading, data} = useQuery<Query>(
            loader('./getInstrumentSnapQuote.graphql'),
        {
            skip: !!props.snapQuote || !props.id,
            variables: {instrumentId: props.id}
        }
        );
    let snapQuote = null;
    if (loading) {
        return <div className="instrument-info-loading"><Spinner animation="border" size="sm"/></div>;
    }
    if (data?.instrument != null) {
        snapQuote = data?.instrument.snapQuote;
        if (props.onReceived) {
            props.onReceived(snapQuote || null);
        }
    }
    return (
        <NewsInstrumentInfoComponent
            {...props}
            snapQuote={props.snapQuote || snapQuote}
            currencyCode={props.showCurrency ? data?.instrument?.currency?.displayCode || undefined : undefined}
        />
    );
}

interface NewsInstrumentInfoComponentProps {
    instrument?: Instrument;
    snapQuote?: SnapQuote | null| undefined;
    name?: string;
    url?: string | null;
    showPrice?: boolean;
    currencyCode?: string;
    className?: string;
}

export function NewsInstrumentInfoComponent(props: NewsInstrumentInfoComponentProps) {
    const pushEvent = useSnapQuoteSubscription(props.snapQuote?.instrumentId, QuoteValueType.TRADE)
    const currentSnapQuote = pushEvent?.value || props.snapQuote
    const quote = getTradeQuote(props.instrument, currentSnapQuote)
    
    return (
        <div className={classNames("d-flex gap-2", props.className, {'positive-movement': (quote?.percentChange || 0) > 0, 'negative-movement': (quote?.percentChange || 0) <= 0})}>
            {props.url && (
                <Link to={props.url} className="asset-name asset-link pr-2 text-truncate text-gray-dark font-weight-bold">{props.name}</Link>
            )}
            {(props.name && !props.url) && (
                <span>{props.name}</span>
            )}
            {quote && (
                <div className="stock-information d-flex flex-nowrap gap-2">
                    {(props.showPrice && quote.value != null) && (
                        <div className="stock">
                            <span className="stock-value text-gray-dark">{formatPrice(quote.value)}</span>
                            {props.currencyCode && (
                                <span className="stock-currency news-currency-code ml-1 text-gray-dark">{props.currencyCode}</span>
                            )}
                        </div>
                    )}
                    {quote.percentChange != null && (
                        <div className="stock-movement d-flex flex-nowrap">
                            <SnapQuoteValue quoteValueType={QuoteValueType.PERCENT_CHANGE} snapQuote={props.snapQuote} instrument={props.instrument} blink={true} />
                            <SnapQuoteValue quoteValueType={QuoteValueType.PERCENT_CHANGE_ICON} snapQuote={props.snapQuote} instrument={props.instrument} />
                        </div>
                    )}
                </div>
            )}
        </div>
        
    )
}

