import React from "react";
import {guessInfonlineSection, trigInfonline} from "../../InfonlineService";
import {advanced, annotationType, crookedLines, flags, lines, measure, shapes, verticalLabels} from "./utils";

export function ChartAnnotationComponent() {

    return <>
        <div className={"custom-tools-container ml-1"}>
            <label className={"charttool-annotation-label font-weight-bold ml-2 mt-1 mb-0"} htmlFor={"shapes"}>Zeichnen</label>
            <div className="highcharts-stocktools-toolbar">
                <div className="chart-wrapper d-flex">
                    <ul className="highcharts-stocktools-toolbar stocktools-toolbar">
                        <li className="highcharts-label-annotation" title="Simple shapes">
                            <span className="highcharts-menu-item-btn">
                            </span>
                            <ul className="highcharts-submenu-wrapper">
                                {shapes.map((shape: annotationType) => (
                                    <li className={shape.name} title={shape.label} style={{ width: 40 }} key={shape.id}
                                        onClick={() => trigInfonline(guessInfonlineSection(), "annotation_tool")}
                                        data-testid="annotation_tool"

                                    >
                                        <span className="highcharts-menu-item-btn">
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        </li>
                    </ul>
                    <ul className="highcharts-stocktools-toolbar stocktools-toolbar">
                        <li className="highcharts-segment" title="Simple shapes">
                            <span className="highcharts-menu-item-btn">
                            </span>
                            <ul className="highcharts-submenu-wrapper">
                                {lines.map((line: annotationType) => (
                                    <li className={line.name} title={line.label} style={{ width: 40 }} key={line.id}
                                        onClick={() => trigInfonline(guessInfonlineSection(), "annotation_tool")}
                                        data-testid="annotation_tool"

                                    >
                                        <span className="highcharts-menu-item-btn">
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        </li>
                    </ul>
                    <ul className="highcharts-stocktools-toolbar stocktools-toolbar">
                        <li className="highcharts-elliott3" title="Simple shapes">
                            <span className="highcharts-menu-item-btn">
                            </span>
                            <ul className="highcharts-submenu-wrapper">
                                {crookedLines.map((line: annotationType) => (
                                    <li className={line.name} title={line.label} style={{ width: 40 }} key={line.id}
                                        onClick={() => trigInfonline(guessInfonlineSection(), "annotation_tool")}
                                        data-testid="annotation_tool"
                                    >
                                        <span className="highcharts-menu-item-btn">
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        </li>
                    </ul>
                    <ul className="highcharts-stocktools-toolbar stocktools-toolbar">
                        <li className="highcharts-measure-x" title="Simple shapes">
                            <span className="highcharts-menu-item-btn">
                            </span>
                            <ul className="highcharts-submenu-wrapper">
                                {measure.map((line: annotationType) => (
                                    <li className={line.name} title={line.label} style={{ width: 40 }} key={line.id}
                                        onClick={() => trigInfonline(guessInfonlineSection(), "annotation_tool")}
                                        data-testid="annotation_tool"

                                    >
                                        <span className="highcharts-menu-item-btn">
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        </li>
                    </ul>
                    <ul className="highcharts-stocktools-toolbar stocktools-toolbar">
                        <li className="highcharts-fibonacci" title="Simple shapes">
                            <span className="highcharts-menu-item-btn">
                            </span>
                            <ul className="highcharts-submenu-wrapper">
                                {advanced.map((line: annotationType) => (
                                    <li className={line.name} title={line.label} style={{ width: 40 }} key={line.id}
                                        onClick={() => trigInfonline(guessInfonlineSection(), "annotation_tool")}
                                        data-testid="annotation_tool"

                                    >
                                        <span className="highcharts-menu-item-btn">
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        </li>
                    </ul>
                    <ul className="highcharts-stocktools-toolbar stocktools-toolbar">
                        <li className="highcharts-vertical-counter" title="Simple shapes">
                            <span className="highcharts-menu-item-btn">
                            </span>
                            <ul className="highcharts-submenu-wrapper">
                                {verticalLabels.map((line: annotationType) => (
                                    <li className={line.name} title={line.label} style={{ width: 40 }} key={line.id}
                                        onClick={() => trigInfonline(guessInfonlineSection(), "annotation_tool")}
                                        data-testid="annotation_tool"

                                    >
                                        <span className="highcharts-menu-item-btn">
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        </li>
                    </ul>
                    <ul className="highcharts-stocktools-toolbar stocktools-toolbar">
                        <li className="highcharts-flag-circlepin" title="Simple shapes">
                            <span className="highcharts-menu-item-btn">
                            </span>
                            <ul className="highcharts-submenu-wrapper">
                                {flags.map((line: annotationType) => (
                                    <li className={line.name} title={line.label} style={{ width: 40 }} key={line.id}
                                        onClick={() => trigInfonline(guessInfonlineSection(), "annotation_tool")}
                                        data-testid="annotation_tool"

                                    >
                                        <span className="highcharts-menu-item-btn">
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </>
}