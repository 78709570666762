import { Carousel, CarouselItem, Col, Container, Row, Spinner } from "react-bootstrap";
import './HotSection.scss';
import { useQuery } from "@apollo/client";
import { AssetClass, Instrument, Query } from "../../../generated/graphql";
import SvgImage from "../../common/image/SvgImage";
import { CarouselWrapper } from "../../common";
import { createChunk } from "../../common/utils";
import { HotSectionInstrumentCard } from "../../common/card/InstrumentCard/HotSectionInstrumentCard";
import { formatPrice } from "../../../utils";
import { useBootstrapBreakpoint } from "../../../hooks/useBootstrapBreakpoint";
import { Maybe } from 'graphql/jsutils/Maybe';
import { HotSectionModal } from './HotSectionModal';
import { loader } from "graphql.macro";
import LazyLoad from "react-lazyload";
import classNames from "classnames";
import InfoModal from "./InfoModal";
import {useDesktopMediaQuery, useMobileMediaQuery, useTabletMediaQuery} from "hooks/useMediaQuery";
import {useMemo} from "react";

interface HotSectionProps {
    carouselIconColor: string
    isSharePage: boolean
    isHomePage?:  boolean
}

export function getAbsPercentChange(a: Maybe<Instrument>) {
    return a?.snapQuote?.quote?.percentChange ? Math.abs(a.snapQuote.quote.percentChange) : 0;
}

function median(values: number[]) {
    values.sort(function (a, b) { return a - b; });
    var half = Math.floor(values.length / 2);
    if (values.length % 2) {
        return values[half];
    } else {
        return (values[half - 1] + values[half]) / 2.0;
    }
}

export const HotSection = ({ isSharePage, carouselIconColor, isHomePage }: HotSectionProps) => {
    return (
        <LazyLoad>
            <HotSectionInternal isSharePage={isSharePage} carouselIconColor={carouselIconColor} isHomePage={isHomePage} />
        </LazyLoad>
    );
}

const HotSectionInternal = ({ isSharePage, carouselIconColor, isHomePage }: HotSectionProps) => {
    const { data, loading } = useQuery<Query>(loader('./getHotInstruments.graphql'), {
        variables: { id: "hot_instruments", chartScope: 'INTRADAY' },
        fetchPolicy: "no-cache"
    });
    const { data: assetClassesData } = useQuery<Query>(loader("../../../components/derivative/search/getDerivativeTypeInput.graphql"))
    const assetClasses: AssetClass[] = useMemo(() => ([
        ...assetClassesData?.classification.filter(type => type.leveraged) || [],
        ...assetClassesData?.classification.filter(type => !type.leveraged) || []
    ]), [assetClassesData])
    
    const isMobile = useMobileMediaQuery()
    const isTablet = useTabletMediaQuery()
    const isDesktop = useDesktopMediaQuery()

    const carouselItemsSize = useBootstrapBreakpoint({
        md: 6,
        sm: 4,
        default: 4
    })

    const trades: number[] = data?.list?.content ? data.list.content.map(x => x?.snapQuote?.cumulativeTrades ? x.snapQuote.cumulativeTrades : 0).filter(x => x != 0) : [0];
    const medianTrades = median(trades);

    return (
        <section className={`${isSharePage ? "" : "pt-5"} main-section mt-0`}>
            <Container className="px-lg-2 hot-section-container">
                <div className="section-heading d-flex ml-n2 mx-md-1 mx-xl-2 font-weight-bold" style={{ color: isSharePage ? "#383838" : "#fff" }}>
                    <div className={"mt-n1"}><SvgImage icon="icon_hot_flame_orange.svg" spanClass="arrow top-move"
                        imgClass="flame-icon pb-1 mb-1" convert={false} width={"28px"}/></div>
                    <div className="w-100 d-flex justify-content-between ml-n2 ml-md-0">
                        <h2 className="section-heading">finanztreff.de HOT! Aktien</h2>
                        <InfoModal />
                    </div>
                </div>
                {/*<Container className="content-wrapper p-lg-0 m-0" style={{backgroundColor: backgroundColorValue}}>*/}
                <Container className="p-xl-0 m-0 px-0 px-md-3 hot-section-inner-container" style={{ backgroundColor: isSharePage ? "#F1F1F1" : "#383838" }}>
                    {loading ?
                        <div className="text-center py-2">
                            <Spinner animation="border" role="status" style={{ color: isSharePage ? "black" : "white" }}>
                            </Spinner>
                        </div>
                        :
                        <>
                          {isDesktop ? 
                            <Row className="mx-0 d-xl-flex">
                                {data?.list?.content && data.list.content
                                    .map((instrument, index) => {
                                        return (
                                            <Col lg={4} md={4} xl={3} className="p-2" key={index}>
                                                {instrument && instrument.group && instrument.snapQuote && instrument.group.id &&
                                                <HotSectionInstrumentCard
                                                    key={instrument.id}
                                                    name={instrument.group.name}
                                                    id={instrument.id}
                                                    wkn={instrument.wkn}
                                                    group={instrument.group}
                                                    displayPrice={formatPrice(instrument.snapQuote.quote?.value, instrument.group.assetGroup)}
                                                    performance={instrument.snapQuote?.quote?.percentChange}
                                                    currency={instrument.currency.displayCode}
                                                    trend={getAbsPercentChange(instrument) > 1.0}
                                                    interest={instrument.snapQuote?.cumulativeVolume > medianTrades}
                                                    activity={false}
                                                    snapQuote={instrument.snapQuote}
                                                    chart={instrument.chart || undefined}
                                                    bestWarrantRnd={instrument.bestWarrantRnd}
                                                    assetClasses={assetClasses}
                                                    isHomePage={isHomePage}
                                                />
                                                }
                                            </Col>
                                        )
                                    }
                                    )
                                }
                            </Row>
                            :
                            <Carousel
                                className=""
                                touch={true}
                                controlclass={isSharePage ? "dark-version pt-5 mb-0" : ""}
                                prevIcon={
                                    <SvgImage icon={`icon_direction_left_${carouselIconColor}.svg`}
                                        spanClass="move-arrow d-none d-xl-block" convert={false} />
                                }
                                nextIcon={
                                    <SvgImage icon={`icon_direction_right_${carouselIconColor}.svg`}
                                        spanClass="move-arrow d-none d-xl-block" convert={false} />
                                }
                                controls={(data?.list?.content && data.list.content.length > 6) || false}
                                indicators={(data?.list?.content && data.list.content.length > 6) || false}
                                as={CarouselWrapper}
                            >
                                {
                                    createChunk(data?.list?.content || [], carouselItemsSize).map((group: any, index: number) =>
                                        <CarouselItem key={index} className={classNames("pb-5", {'mb-sm-n5': isSharePage})}>
                                            <Row>
                                                {
                                                    group.map((instrument: Maybe<Instrument> | undefined, index: number) => (
                                                        <Col lg={4} md={4} className="px-md-1">
                                                            {
                                                                instrument?.group?.id && instrument?.snapQuote &&
                                                                    <HotSectionInstrumentCard
                                                                        key={instrument.id}
                                                                        className={isSharePage ? "my-1" : "my-2"}
                                                                        name={instrument.group.name}
                                                                        id={instrument.id}
                                                                        wkn={instrument.wkn}
                                                                        group={instrument.group}
                                                                        displayPrice={formatPrice(instrument.snapQuote.quote?.value, instrument.group.assetGroup)}
                                                                        performance={instrument.snapQuote?.quote?.percentChange}
                                                                        currency={instrument.currency.displayCode}
                                                                        snapQuote={instrument.snapQuote}
                                                                        chart={instrument.chart || undefined}
                                                                        assetClasses={assetClasses}
                                                                        bestWarrantRnd={instrument.bestWarrantRnd}
                                                                    />
                                                            }
                                                        </Col>
                                                    )
                                                    )
                                                }
                                            </Row>

                                        </CarouselItem>
                                    )
                                }
                            </Carousel>      }
                            
                        </>
                    }
                    {data?.list?.content && (
                        <div className={classNames("d-flex gap-3 mx-0 mx-md-n3 mx-xl-0 mt-lg-3 mb-3 px-md-1 px-xl-2 fnt-size-14 line-height-20px", isSharePage ? "mt-4" : "mt-0", isMobile ? "justify-content-center" : "justify-content-between")}>
                            {isTablet && (
                                <div className="d-flex flex-column gap-2px mr-n1">
                                    <div className="px-2 bg-red font-weight-bold text-white">Put</div>
                                    <div className="px-2 bg-green font-weight-bold text-white">Call</div>
                                </div>
                            )}
                            {!isMobile && (
                                <span>Mit <span className="font-weight-bold"><span className="text-success">Call</span> & <span className="text-warning">Put</span></span> Optionsscheinen können Sie überdurchschnittlich an der Kursentwicklung der Hot-Werte partizipieren.</span>
                            )}
                            <HotSectionModal isSharePage={isSharePage} medianTrades={medianTrades} assetClasses={assetClasses} className="text-nowrap" />
                        </div>
                    )}
                </Container>
            </Container>
        </section>
    );
}
