import {AnalysisDetailModal} from "./AnalysisDetailModal";
import {useLocation, useParams, useHistory} from "react-router-dom";
import {Analysis, Query, SnapQuote} from "../../../generated/graphql";
import React from "react";
import {useQuery} from "@apollo/client";
import {loader} from "graphql.macro";
import {Spinner} from "react-bootstrap";
import {Page404} from "../../common/page404/Page404";

export default function AnalysisModal() {
    const param = useParams<{ id: string }>();
    const location: any = useLocation();
    const history = useHistory();
    const analysis: Analysis = location?.state?.analysis;
    const snapQuote: SnapQuote | undefined = analysis?.group?.main?.snapQuote || undefined;
    const currencyCode: string = analysis?.group?.main?.currency?.displayCode || "";

    let {data: analysisData, loading: analysisDataLoading} = useQuery<Query>(
        loader('./analysis.graphql'),
        {variables: {id: param.id}, skip: !param.id || !!analysis}
    );

    if (param.id && snapQuote && analysis && currencyCode) {
        return (
            <>
                <AnalysisDetailModal
                    snapQuote={snapQuote}
                    analysis={analysis}
                    currencyCode={currencyCode}
                    isOpen={true}
                    closeModal={() => {
                        if (location.state) {
                            window.history.replaceState({}, "");
                        }
                        history.goBack();
                    }}
                />
            </>
        )
    }

    else if (param.id && !analysis) {
        if (analysisData?.analysis && analysisData?.analysis?.group?.main?.snapQuote && analysisData &&
            analysisData?.analysis?.group?.main?.currency?.displayCode) {
            return <AnalysisDetailModal analysis={analysisData.analysis}
                                        isOpen={true}
                                        closeModal={() => {
                                            history.goBack()
                                        }}
                                        snapQuote={analysisData?.analysis?.group?.main?.snapQuote}
                                        currencyCode={analysisData?.analysis?.group?.main?.currency?.displayCode}
            />
        } else return ( analysisDataLoading ? <Spinner animation={"border"}/> : <Page404/>)

    }

    return (
        <Page404/>
    )
}