import {useMediaQuery} from "react-responsive";
import classNames from "classnames";
import "./TerminePageTeaserBox.scss";
import {PaidNewsBox} from "../../common/PaidNewsBox";
import {useHistory} from "react-router-dom";
import {useLazyQuery, useQuery} from "@apollo/client";
import {AssetGroup, EventType, Query, useGetHomeCalendarEventsQuery} from "../../../generated/graphql";
import {loader} from "graphql.macro";
import moment from "moment/moment";
import {Spinner, Button} from "react-bootstrap";
import {Link} from "react-router-dom";
import {formatDate, formatTime, getFinanztreffAssetLink} from "../../../utils";
import {useEffect, useState} from "react";


// mock data for events
// const corporateEvents = [
//     {
//         eventId: "1Ad",
//         date: "14.12.2022",
//         company: "Adidas",
//         regionFlag: "/static/img/svg/flags/eur.svg",
//         region: "EU",
//         event: "S&P Global Einkaufsmanagerindex verarbeitendes Gewerbe"
//     },
//     {
//         eventId: "2Al",
//         date: "13.12.2022",
//         company: "Allianz",
//         regionFlag: "/static/img/svg/flags/jpy.svg",
//         region: "JA",
//         event: "S&P Global/CIPS verarbeitendes Gewerbe"
//     },
//     {
//         eventId: "3Ho",
//         date: "13.12.2022",
//         company: "Hot Pot Haidilao International Holding",
//         regionFlag: "/static/img/svg/flags/usd.svg",
//         region: "US",
//         event: "Arbeitslosenquote"
//     },
//     {
//         eventId: "4Ba",
//         date: "13.12.2022",
//         company: "Bayer",
//         regionFlag: "/static/img/svg/flags/aud.svg",
//         region: "AU",
//         event: "S&P Global PMI Herstellung"
//     }
// ]
//
// const economicEvents = [
//     {
//         eventId: "1Ad",
//         date: "14.12.2022",
//         time: "08:00 Uhr",
//         regionFlag: "/static/img/svg/flags/eur.svg",
//         region: "EU",
//         event: "Verbraucherpreisindex (Monat)"
//     },
//     {
//         eventId: "2Al",
//         date: "13.12.2022",
//         time: "11:30 Uhr",
//         regionFlag: "/static/img/svg/flags/jpy.svg",
//         region: "JA",
//         event: "Verbraucherpreisindex (EU-Norm) ( Monat )"
//     },
//     {
//         eventId: "3Ho",
//         date: "13.12.2022",
//         time: "12:00 Uhr",
//         regionFlag: "/static/img/svg/flags/usd.svg",
//         region: "US",
//         event: "ADP Beschäftigungsänderung"
//     },
//     {
//         eventId: "4Ba",
//         date: "13.12.2022",
//         time: "12:30 Uhr",
//         regionFlag: "/static/img/svg/flags/aud.svg",
//         region: "AU",
//         event: "Bruttoinlandsprodukt annualisiert (Quartal)"
//     }
// ]

interface CalendarTeaseEventCompany {
    assetGroup: AssetGroup;
    seoTag: string;
    name: string;
}

interface CalendarTeaseEvent {
    eventId: string;
    type: EventType;
    when: moment.Moment;
    countryAlpha3: string | null;
    countryAlpha2: string | null;
    company: CalendarTeaseEventCompany | null;
    event: string;
}


const CalendarEventHomeTeaserContent = () => {
    const [eventDetails, setEventDetails] = useState<{ date : moment.Moment , eventId :string}>();
    const isDesktop = useMediaQuery({
        query: '(min-width: 1100px)'
    });

    const isTablet = useMediaQuery({
        query: '(max-width: 1099px)'
    })

    const isMobile = useMediaQuery({
        query: '(max-width: 698px)'
    })

    const history = useHistory();
    let {data, loading} = useGetHomeCalendarEventsQuery({
            variables: {from: moment().format('YYYY-MM-DD'), count: 8},
        });

    let [getElementsCount, {data: calendarSummary, loading: calendarSummaryLoading}] = useLazyQuery<Query>(
        loader('../calendarEventSummary.graphql')
    );

    useEffect(()=>{
        if(calendarSummary && !calendarSummaryLoading && eventDetails ) {

             const count = calendarSummary?.calendarEventSummary[0]?.count ;
            history.push("/termine",  { eventId : eventDetails?.eventId, eventDate : eventDetails?.date?.format("YYYY-MM-DD"), eventCount : count})
        }

    },[calendarSummary])

    if (loading) {
        return (<Spinner animation={"border"}/>)
    }

    let events: CalendarTeaseEvent[] = [...(data?.economicEvents?.edges || []), ...(data?.companyEvents?.edges || [])]
        .map(current => current.node)
        .map(current => ({
            eventId: current.id || "",
            when: moment(current.when),
            type: current.eventType || EventType.Economic,
            countryAlpha2: current?.country?.country?.isoAlpha2 || null,
            countryAlpha3: current?.country?.country?.isoAlpha3 || null,
            company: ((products) =>
                    !!products && products.length > 0
                        && !!products[0]?.group?.assetGroup
                        && !!products[0]?.group?.seoTag
                        && !!products[0]?.group?.name ?
                    ({assetGroup: products[0]?.group?.assetGroup, seoTag: products[0]?.group?.seoTag || "", name: products[0]?.group?.name || ""} as CalendarTeaseEventCompany) : null
            )(current.products),
            event: current.title || ""
        }));

    let corporateEvents = events.filter(current => !!current.company);
    let economicEvents = events.filter(current => !current.company);

    if (corporateEvents.length < 4) {
        let transfer = 4 - corporateEvents.length;
        corporateEvents = corporateEvents.concat(economicEvents.slice(0, transfer));
        economicEvents = economicEvents.slice(transfer, 4 + transfer);
    } else {
        corporateEvents = corporateEvents.slice(0, 4);
        economicEvents = economicEvents.slice(0, 4);
    }

    return (
        <div
            className={classNames(isMobile ? "m-termine_teaserbox_container " : isTablet ? "t-termine_teaserbox_container" : "termine_teaserbox_container mt-2")}>
            <div className={classNames("d-flex justify-content-between", isMobile &&!isDesktop&& "flex-column")}>
                {
                    [corporateEvents, economicEvents].map((group, i) =>
                        <div key={i}
                            className={classNames(isDesktop ? "termine_teaserbox_container_economic-events" : isMobile ? "m-termine_teaserbox_container_corporate-events" : "t-termine_teaserbox_container_corporate-events")}>
                            {group.map((current) => (
                                <div key={current.eventId} className="termine_teaserbox_container_events-wrapper"
                                     style={{borderBottom: "1px solid #dee2e6"}}>
                                    <div
                                        className={classNames("d-flex justify-content-start", isMobile ? "m-termine_teaserbox_container_events_region-wrapper" : isTablet ? "t-termine_teaserbox_container_events_region-wrapper" : "termine_teaserbox_container_events_region-wrapper")}>
                                        <p>{formatDate(current.when)}</p>
                                        {current.type == "ECONOMIC" && <p>{formatTime(current.when)}</p>}
                                        <p className="d-flex termine_teaserbox_container_events_region">
                                            {!!current.countryAlpha3 &&
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/static/img/svg/flags/${current.countryAlpha3.toLowerCase()}.svg`}
                                                    className="flag-icon" alt="flagIcon" height="13px" width="20px"/>
                                            }
                                            <span>{current.countryAlpha2}</span>
                                        </p>
                                        {current.company &&
                                            <p className="termine_teaserbox_container_events-company">
                                                <Link  to={getFinanztreffAssetLink(current?.company?.assetGroup || "", current?.company?.seoTag || "")} >
                                                <span>{current.company?.name}</span>
                                                </Link>
                                            </p>
                                        }
                                    </div>
                                    {
                                        current.type === "ECONOMIC" ?
                                            <div
                                                className="termine_teaserbox_container_events-event cursor-pointer text-truncate ">
                                                <span onClick={()=>{
                                                    setEventDetails({
                                                        date: current?.when,
                                                        eventId: current?.eventId
                                                    });
                                                    getElementsCount({variables :{from : current?.when?.format("YYYY-MM-DD"),
                                                        to : current?.when?.format("YYYY-MM-DD")}})
                                                }}>{current.event}</span>
                                            </div> :
                                            <div
                                                className="termine_teaserbox_container_events-event text-truncate">{current.event}</div>

                                    }
                                </div>
                            ))
                            }
                            {i > 0 &&
                                <div
                                    className={classNames("d-flex justify-content-end termine_teaserbox_container-button", isDesktop && "termine_teaserbox-button")}>
                                    <Button variant="inline" className="btn" onClick={() => {history.push("/termine/", {scrollToTop: "true"})}}>
                                        <span>alle Termine...</span>
                                    </Button>
                                </div>

                            }
                        </div>
                    )
                }
            </div>
        </div>
    );
}

export function CalendarEventHomeTeaser() {
    const isDesktop = useMediaQuery({
        query: '(min-width: 1100px)'
    });

    const isMobile = useMediaQuery({
        query: '(max-width: 698px)'
    })

    return (
        <div className={classNames(isDesktop ? "termine_teaserbox-news_container" :
            isMobile ? "m-termine_teaserbox-news_container" : "t-termine_teaserbox-news_container")}>
            <h2 className={classNames(isMobile ? "m-termine_teaserbox-news_container_heading" : "termine_teaserbox-news_container_heading")}>Nächste
                Termine</h2>
            <div className={classNames("d-flex", !isDesktop && "flex-column")}>
                <CalendarEventHomeTeaserContent/>
                <PaidNewsBox isTerminePageTeaserbox={true}/>
            </div>
        </div>

    )
}
