import {SharePage, IndexPage, OptionPage, CertificatePage} from './components';
import './App.css';
import {LayoutComponent} from './components';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import {ReactKeycloakProvider} from '@react-keycloak/web';
import {creatApolloClient} from './graphql/client';
import keycloak from "./keycloak";
import 'moment/locale/de';
import DerivativeBasiswert from "./components/derivative/DerivativeBasiswert";
import { CommodityOverviewPage } from 'components/commodity/CommodityOverviewPage';
import { CommodityPage } from 'components/Assets/CommodityPage/CommodityPage';
import { CurrencyOverviewPage } from 'components/devisen/CurrencyOverviewPage';
import { CurrencyPage } from 'components/Assets/CurrencyPage/CurrencyPage';
import { EtfPage } from 'components/Assets/EtfPage/EtfPage';
import { EtcAdnEtnPage } from 'components/Assets/EtcAdnEtnPage/EtcAdnEtnPage';
import { KnockPage } from 'components/Assets/KnockPage/KnockPage';
import { DerivativeSearch } from 'components/derivative/DerivativeSearch';
import { FundsSearch } from 'components/funds/FundSearchPage/FundsSearch';
import { EtfSearch } from "./components/etf/search/EtfSearch";
import { BondPortraitPage } from 'components/bond/BondPortrait/BondPortraitPage';
import { BondSearch } from 'components/bond/BondSearchPage/BondSearch';
import {ShareSearch} from 'components/shares/search/ShareSearch'
import { ApolloProvider } from '@apollo/client';
import { FuturePage } from 'components/Assets/FeaturePage/FuturePage';
import { Page404 } from 'components/common/page404/Page404';
import { MultiMarketPortraitPage } from 'components/Assets/MultiMarketPortraitPage/MultiMarketPortraitPage';
import { HomePage } from 'components/Home/HomePage';
import { SearchPage } from 'components/common/search/SearchPage';
import TerminePage from 'components/termine/TerminePage';
import { ApplicationStateProvider } from "./ApplicationContext";
import AnalysisModal from "./components/analyses/AnalysisDetailModal/AnalysisModal";
import { PortfolioPageStateProvider } from 'components/profile/portfolio/PortfolioPageContext';
import { WatchlistPageStateProvider } from 'components/profile/watchlist/WatchlistPageContext';
import {lazy, Suspense} from "react";
import {Spinner} from "react-bootstrap";

const IndexOverviewPage = lazy(() => import(/* webpackChunkName: 'idx-ovrvw-page' */'./components/index/IndexOverviewPage'));
const DerivativePage = lazy(() => import(/* webpackChunkName: 'derivative-page' */'./components/derivative/DerivativePage'));
const FundsPage =  lazy(() => import(/* webpackChunkName: 'funds-page' */'./components/Assets/FundsPage/FundsPage'));
const NewsPage = lazy(() => import(/* webpackChunkName: 'news-page' */'./components/news/NewsPage'));
const ShareOverview = lazy(() => import(/* webpackChunkName: 'share-ovrvw-page' */'./components/shares/overview/ShareOverview'));
const AnalysesPage = lazy(() => import(/* webpackChunkName: 'analyses-page' */'./components/analyses/AnalysesPage'));

const OverviewPage = lazy(() => import(/* webpackChunkName: 'ovrvw-page' */'./components/profile/OverviewPage'));
const PortfolioPage = lazy(() => import(/* webpackChunkName: 'ovrvw-page' */'./components/profile/portfolio/PortfolioPage'));
const WatchlistPage = lazy(() => import(/* webpackChunkName: 'ovrvw-page' */'./components/profile/watchlist/WatchlistPage'));
const LimitsPage = lazy(() => import(/* webpackChunkName: 'ovrvw-page' */'./components/profile/LimitsPage/LimitsPage'));

function App() {
    return (
        <ReactKeycloakProvider authClient={keycloak}
                            LoadingComponent={<></>}
                            initOptions={{
                                flow: 'implicit', onLoad: 'check-sso', checkLoginIframe: true,
                                redirectUri: window.location.origin + '/mein-finanztreff/',
                                silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html'
                            }}>
            <ApolloProvider client={creatApolloClient(keycloak)}>
                <ApplicationStateProvider>
                    <Router>
                        <LayoutComponent>
                            <Switch>
                                <Route exact path="/">
                                    <HomePage/>
                                </Route>
                                <Route exact path="/suche/">
                                    <SearchPage/>
                                </Route>
                                <Route strict path="/aktien/:section/:seoTag/">
                                    <main>
                                        <SharePage/>
                                    </main>
                                </Route>
                                <Route strict path="/future/:section/:seoTag/">
                                    <main><FuturePage/></main>
                                </Route>
                                <Route strict path="/fonds/:section/:seoTag/">
                                    <Suspense fallback={<Spinner animation={'border'}/>}>
                                        <main><FundsPage /></main>
                                    </Suspense>
                                </Route>
                                <Route exact path="/fonds/suche/">
                                    <main><FundsSearch/></main>
                                </Route>
                                <Route strict path="/etf/:section/:seoTag/">
                                    <main><EtfPage/></main>
                                </Route>
                                <Route strict path="/etc/:section/:seoTag/">
                                    <main><EtcAdnEtnPage/></main>
                                </Route>
                                <Route exact path="/anleihen/suche/">
                                    <main><BondSearch /></main>
                                </Route>
                                <Route strict path="/anleihen/:section/:seoTag/">
                                    <main><BondPortraitPage /></main>
                                </Route>
                                <Route strict path="/geldmarktsatz/:section/:seoTag">
                                    <main><MultiMarketPortraitPage /></main>
                                </Route>
                                <Route strict path="/zertifikate/kurse/:seoTag/">
                                    <main><CertificatePage /></main>
                                </Route>
                                <Route exact path="/hebelprodukte/suche/" component={(props: any) => <DerivativeSearch {...props} />}/>
                                <Route exact path="/hebelprodukte/basiswert/">
                                    <DerivativeBasiswert/>
                                </Route>
                                <Route exact path="/hebelprodukte/suche/:section/dax/" component={(props: any) => <DerivativeSearch {...props}/>} />
                                <Route strict path="/hebelprodukte/kurse/:seoTag/">
                                    <main><KnockPage /></main>
                                </Route>
                                <Route path="/hebelprodukte/">
                                    <Suspense fallback={<Spinner animation={'border'}/>}>
                                        <main><DerivativePage /></main>
                                    </Suspense>
                                </Route>
                                <Route exact path="/indizes/indexzusammensetzung/:seoTag/">
                                    <Suspense fallback={<Spinner animation={'border'}/>}>
                                        <main><IndexOverviewPage /></main>
                                    </Suspense>
                                </Route>
                                <Route strict path="/indizes/:section/:seoTag/">
                                    <main><IndexPage /></main>
                                </Route>
                                <Route strict path="/optionsschein/kurse/:seoTag/">
                                    <main><OptionPage /></main>
                                </Route>
                                <Route exact path="/indizes/">
                                    <Suspense fallback={<Spinner animation={'border'}/>}>
                                        <main><IndexOverviewPage /></main>
                                    </Suspense>
                                </Route>
                                <Route exact path={"/etf/suche/"}>
                                    <main style={{overflowY: 'hidden'}}><EtfSearch/></main>
                                </Route>
                                <Route strict path="/aktien/suche/">
                                    <main style={{overflowY: 'hidden'}}><ShareSearch/></main>
                                </Route>
                                <Route exact path="/aktien/">
                                    <Suspense fallback={<Spinner animation={'border'}/>}>
                                        <ShareOverview/>
                                    </Suspense>
                                </Route>
                                <Route strict path="/devisen/:section/:seoTag/">
                                    <main><CurrencyPage/></main>
                                </Route>
                                <Route exact path="/devisen/">
                                    <main><CurrencyOverviewPage/></main>
                                </Route>
                                <Route strict path="/rohstoffe/:section/:seoTag/">
                                    <main><CommodityPage/></main>
                                </Route>
                                <Route exact path="/rohstoffe/">
                                    <main><CommodityOverviewPage/></main>
                                </Route>
                                <Route path="/nachrichten/:id?">
                                    <Suspense fallback={<Spinner animation={'border'}/>}>
                                        <main className = "overflow-remove"><NewsPage /></main>
                                    </Suspense>
                                </Route>
                                <Route path="/analyse/:id?">
                                    <main className = "overflow-remove"><AnalysisModal /></main>
                                </Route>
                                <Route exact path="/analysen/">
                                    <Suspense fallback={<Spinner animation={'border'}/>}>
                                        <main className = "overflow-remove"><AnalysesPage /></main>
                                    </Suspense>
                                </Route>

                                <Route path="/mein-finanztreff/portfolio/:id">
                                    <Suspense fallback={<Spinner animation={'border'}/>}>
                                        <main>
                                            <PortfolioPageStateProvider>
                                                <PortfolioPage />
                                            </PortfolioPageStateProvider>
                                        </main>
                                    </Suspense>
                                </Route>
                                <Route path="/mein-finanztreff-realportfolios/portfolio/:id"> <div style={{backgroundColor: 'yellow'}}>//test realportfolio</div>
                                    <Suspense fallback={<Spinner animation={'border'}/>}>
                                        <main>
                                            <PortfolioPageStateProvider>
                                                <PortfolioPage realportfolio={true}/>
                                            </PortfolioPageStateProvider>
                                        </main>
                                    </Suspense>
                                </Route>
                                <Route path="/mein-finanztreff/watchlist/:id">
                                    <Suspense fallback={<Spinner animation={'border'}/>}>
                                        <main>
                                            <WatchlistPageStateProvider>
                                                <WatchlistPage />
                                            </WatchlistPageStateProvider>
                                        </main>
                                </Suspense>
                                </Route>
                                <Route exact path="/mein-finanztreff/portfolios/">
                                    <Suspense fallback={<Spinner animation={'border'}/>}>
                                        <main><OverviewPage page={"portfolios"}  /></main>
                                    </Suspense>
                                </Route>
                                <Route exact path="/mein-finanztreff/watchlisten/">
                                    <Suspense fallback={<Spinner animation={'border'}/>}>
                                        <main><OverviewPage page={"watchlists"}  /></main>
                                    </Suspense>
                                </Route>
                                <Route exact path="/mein-finanztreff/limits/">
                                    <Suspense fallback={<Spinner animation={'border'}/>}>
                                        <main><LimitsPage /></main>
                                    </Suspense>
                                </Route>
                                <Route exact strict path="/mein-finanztreff/">
                                    <Suspense fallback={<Spinner animation={'border'}/>}>
                                        <main><OverviewPage /></main>
                                    </Suspense>
                                </Route>
                                <Route exact path="/mein-finanztreff-realportfolios"> <div style={{backgroundColor: 'yellow'}}>//test realportfolio</div>
                                    <Suspense fallback={<Spinner animation={'border'}/>}>
                                        <main><OverviewPage realportfolio={true}/></main>
                                    </Suspense>
                                </Route>
                                <Route exact path="/termine/">
                                    <main><TerminePage/></main>
                                </Route>
                                <Route>
                                    <Page404/>
                                </Route>
                            </Switch>
                        </LayoutComponent>
                    </Router>
                </ApplicationStateProvider>
            </ApolloProvider>

        </ReactKeycloakProvider>
    );
}

export default App;
