import classNames from "classnames";
import moment from "moment";
import {Col, Container, Row, Button, Modal} from "react-bootstrap";
import {QuoteType, LimitEntry, Mutation} from "../../../generated/graphql";
import {
    formatPrice,
    getTextColorByValue,
    numberFormatWithSign,
    quoteFormat,
    numberFormat,
    formatPriceWithSign
} from "../../../utils";
import SvgImage from "../../common/image/SvgImage";
import {AssetLinkComponent} from "../common/AssetLinkComponent";
import {NewsModalMeinFinanztreff} from "../modals/NewsModal/NewsModalMeinFinanztreff";
import {CopyWKN} from "components/profile/common/CopyWKNComponent";
import {filterLimits, getAssetGroup} from "../utils";
import {getPercentOfChange} from "./LimitChartComponent";
import {LimitEditDelete} from "./LimitsBanner/LimitEditDelete";
import {LimitsAdd} from "./LimitsBanner/LimitsAdd";
import {useEffect, useState} from "react";
import {useMutation} from "@apollo/client";
import {LIMIT_ENTRY_DELETE} from "../query";

interface LimitTableComponentStateProps {
    limitCount: number;
    selectedAll: boolean;
    isInActive: boolean;
    open: boolean,
    showHit: boolean,
}

export function LimitsTableComponent(props: LimitsGridComponentProps) {

    let limits = filterLimits(props.limits, props.isActive, props.isUpper, props.sort.sortType, props.sort.direction).filter((current: LimitEntry) => current.initialValue && current.instrument);
    let [state, setState] = useState<LimitTableComponentStateProps>({
        limitCount: limits.length,
        selectedAll: false,
        isInActive: false,
        open: false,
        showHit : false,
    })
    let [selected, setSelected] = useState<any>([])
    let [deleteLimit, {loading: deleteLimitLoading}] = useMutation<Mutation>(LIMIT_ENTRY_DELETE);

    function ColumnName({size, name, className}: { size?: number, name: string, className?: string }) {
        function setEntriesOrder(order: string) {
            props.handleSort({id: order, name: order}, props.sort.sortType === order ? !props.sort.direction : true);
        }

        return (
            <Col xs={size} onClick={() => setEntriesOrder("Table" + name)}
                 className={classNames("text-blue cursor-pointer", name === "Abstand zum Limit" ? "" : "text-nowrap", className)}>
                {name}
                {props.sort.sortType === "Table" + name &&
                    <img src="/static/img/svg/icon_arrow_short_fullup_blue.svg" width={20}
                         className="svg-convert img-dropdown"
                         style={!props.sort.direction ? {transform: "rotate(180deg)"} : {}}/>}
            </Col>
        )
    }

    function handleSelectionAll() {
        const data = limits.map(item => item.id);
        setSelected(data);
    }

    function handleSelectionForHit() {
        const data = limits.filter(item => item.hitStatus === true).map(item => item.id);
        setSelected(data)
    }

    function handleModalClose() {
        setState({...state, open: false});
    }

    function handleSubmit() {
        let selectedLimits = selected;
        let items: any[] = [];
        selectedLimits.map((item: number) => {
            let entry = limits.filter((it) => it.id === item);
            items.push(...entry);
        })
            items.map((item: any) => {
                    deleteLimit({
                        variables: {
                            limitId: item.id,
                        },
                        update(cache) {
                            const normalizedId = cache.identify({item, __typename: 'LimitEntry'});
                            cache.evict({id: normalizedId});
                            cache.gc();
                        }
                    })
                        .then(() => {
                            setState({...state, open: false,showHit:false,selectedAll:false});
                            setSelected([]);
                            props.refetch()
                        });
                }
            )
        }


    function handleFilterSelection(limitId: number, shouldRemove: boolean) {
        if (limitId) {
            if (shouldRemove) {
                let ids = selected.filter((item: number) => item != limitId)
                setSelected(ids);
            } else {
                let entry: number[] = selected;
                entry.push(limitId);
                let finalLimits = entry.filter(function (item, pos) {
                    return entry.indexOf(item) == pos;
                })
                setSelected(finalLimits);
            }
        }
    }

    useEffect(() => {
        setState({...state, selectedAll: false, showHit:false , isInActive:false });
        setSelected([]);
    }, [props.reset])

    return (
        <>
            <Container className="bg-white pl-0 overflow-auto mt-3 p-1" style={{boxShadow: "#00000029 0px 3px 6px", maxWidth:"inherit"}}>
              <div className={'m-3'}>
                <Container className="mw-100">
                    <Row className="justify-content-start py-3  ">
                        <span className=" mr-2">
                    {selected.length > 0 || limits.length == 0  ? <Button style={{border: 'none'}}
                                                                        onClick={() => {
                                                                            setState({
                                                                                ...state,
                                                                                selectedAll: false,
                                                                                isInActive: false,
                                                                                showHit:false
                                                                            })
                                                                           props.handleReset && props?.handleReset();
                                                                            props.refetch();
                                                                            setSelected([])
                                                                        }}
                                                                        className={' button-fixed-results m-1 bg-border-gray btn-short-button btn-limit-comp'}>Alle
                            abwählen</Button>
                        :
                        <Button style={{border: 'none'}} onClick={() => {
                            setState({...state, selectedAll: true})
                            handleSelectionAll()
                        }}
                                className={' button-fixed-results m-1 bg-border-gray btn-short-button btn-limit-comp'}>Alle
                            auswählen</Button>
                    }
                        </span>
                        <span className=" mr-2">
                        <Button style={{border: 'none'}} disabled={state.isInActive} onClick={() => {
                           handleSelectionForHit();
                            setState({...state, isInActive: true,showHit:true,selectedAll:false})
                        }
                        }
                                className={' button-fixed-results m-1 bg-border-gray btn-short-button btn-limit-comp-right'}>Getroffene Limits auswählen</Button>
                        </span>
                        {
                            selected.length > 0 && limits.length > 0 && <>
                                <span
                                    className={'mr-2 font-size-14px align-self-center'}>{`${selected.length} von ${limits.length} Limits auswählen`}</span>
                                <span>
                     <Button variant={'danger'} style={{width: 98}} onClick={() => setState({...state, open: true})}
                             className={'px-2 button-fixed-results m-1 btn-short-button btn-limit-comp-delete'}>
                                <SvgImage icon="icon_close_white.svg" width="10" className="pr-2 mr-3"/> Löschen
                     </Button>

                </span>
                            </>
                        }
                    </Row>

                </Container>
                <Container className="pr-4" style={{maxWidth: "1216px", width: "1216px"}}>
                    <Row className="bg-gray-light py-2 pl-1">
                    <Col xs={12} className={'pl-4 ml-1'} style={{position:'absolute',height:'20px'}}>
                    <Row><ColumnName className="font-weight-bold" name="Bezeichnung"/></Row>
                    </Col>
                        <Col xs={3} className="pl-4 ml-1 mt-20px">
                            <Row><ColumnName className="font-size-13px" name="Gattung"/> </Row>
                            <Row><Col className="font-size-13px"> WKN </Col></Row>
                        </Col>
                        <Col xs={2} className="mt-3  pl-4">
                            <Row><ColumnName className="font-weight-bold kurse-font-color" name="Kurs aktuell"/></Row>
                            <Row><ColumnName size={4} name="Zeit" className="font-size-13px"/> <ColumnName size={4}
                                                                                                    className="text-right font-size-13px"
                                                                                                    name="Börse"/></Row>
                        </Col>
                        <Col xs={2} className="text-right pr-0 mt-3 pt-1 ">
                            <Row><ColumnName className="font-weight-bold" name="Limit absolut"/></Row>
                            <Row><ColumnName className="font-weight-bold" name="Limit %"/></Row>
                        </Col>
                        <ColumnName size={1} name="Art" className="text-left pr-0 font-weight-bold mt-3 pt-1 ml-2"/>
                        <ColumnName size={1} name="Abstand zum Limit"
                                    className="text-center pr-2 pl-0 font-weight-bold mt-3 pt-1"/>

                        <ColumnName size={1} name="Status"
                                    className="text-left font-weight-bold mt-3 pt-1 ml-md-n3"/>
                        <Col xs={1}></Col>
                    </Row>
                    {limits.map((entry, index) => <LimitTableDataRow entry={entry} index={index} refetch={props.refetch}
                                                                     showMemo={props.showMemo}
                                                                     selected={(id: number, select: boolean) => {
                                                                         handleFilterSelection(id, select)
                                                                     }}
                                                                     reset={props.reset}
                                                                     selectedAll={state.selectedAll}
                                                                     showHit={state.showHit}
                        />
                    )}
                </Container>
                <div className={'d-flex  justify-content-between py-4 pr-1'}>
                    <div className={'d-md-flex flex-md-column d-xl-block'}>
                    <span className=" mr-2 ">
                    {selected.length > 0 || limits.length == 0   ? <Button style={{border: 'none'}}
                                                                        onClick={() => {
                                                                            setState({
                                                                                ...state,
                                                                                selectedAll: false,
                                                                                isInActive: false,
                                                                                showHit:false
                                                                            })
                                                                           props.handleReset && props?.handleReset();
                                                                            props.refetch();
                                                                            setSelected([])
                                                                        }}
                                                                        className={' button-fixed-results m-1 bg-border-gray btn-short-button btn-limit-comp'}>Alle
                            abwählen</Button>
                        :
                        <Button style={{border: 'none'}} onClick={() => {
                            setState({...state, selectedAll: true})
                            handleSelectionAll()
                        }}
                                className={'button-fixed-results m-1 bg-border-gray btn-short-button btn-limit-comp'}>Alle
                            auswählen</Button>
                    }
                        </span>
                        <span className=" mr-2">
                        <Button style={{border: 'none'}} disabled={state.isInActive} onClick={() => { handleSelectionForHit();
                            setState({...state, isInActive: true,showHit:true,selectedAll:false})
                        }
                        }
                                className={'button-fixed-results m-1 bg-border-gray btn-short-button btn-limit-comp-right'}>Getroffene Limits auswählen</Button>
                        </span>
                        {
                            selected.length > 0 && limits.length > 0 && <>
                                <span
                                    className={'mr-2 font-size-14px align-self-center'}>{`${selected.length} von ${limits.length} Limits auswählen`}</span>
                                <span>
                     <Button variant={'danger'} style={{width: 98}} onClick={() => setState({...state, open: true})}
                             className={'px-2 button-fixed-results m-1 btn-short-button btn-limit-comp-delete'}>
                                <SvgImage icon="icon_close_white.svg" width="10" className="pr-2 mr-3"/> Löschen
                     </Button>

                </span>
                            </>
                        }
                    </div>
                    <div className={'d-md-flex d-xl-block flex-column justify-content-between h-100 mt-xl-1'}>
                        <span className="pl-2 mt-xl-1 mt-md-0 mt-sm-2">
                            <LimitsAdd
                                refreshTrigger={() => props.refetch()}
                                variant="primary" className="text-white py-0 px-3">
                                <span className="svg-icon action-icons d-flex py-0">
                                    <SvgImage icon="icon_add_limit_white.svg" convert={false} width="26"
                                              imgClass="ml-n2 mr-1"/>
                                    <span className="d-flex mt-1">Limit hinzufügen</span>
                                </span>
                            </LimitsAdd>
                        </span>
                    </div>
                </div>
            </div>
            </Container>

            <Container className="font-size-13px px-1 pt-2 mw-100">
                <span className="pr-4">
                    <SvgImage icon="icon_absolute.svg" width="20" className="pr-2"/> Absolutes Limit
                </span>
                <span className="pr-4">
                    <SvgImage icon="icon_percent.svg" width="20" className="pr-2"/> Relatives Limit
                </span>
                <span className="">
                    <SvgImage icon="icon_repeat.svg" width="20" className="pr-2"/> Trailing Limit (Zahl gibt an wie oft getroffen)
                </span>
            </Container>
            <div className={'w-100'}>
                <LimitsRemoveModal handleModalClose={handleModalClose} handleSubmit={handleSubmit}
                                   count={selected.length} limitCount={limits.length} open={state.open}/>
            </div>
        </>

    );

}

interface LimitsModalProps {
    open: boolean;
    handleModalClose: () => void;
    count: number;
    limitCount: number;
    handleSubmit: () => void;
}
function LimitsRemoveModal({open, handleModalClose, handleSubmit, count, limitCount}: LimitsModalProps) {
    return (
        <Modal show={open} onHide={() => handleModalClose()}
               className={'modal-background modal-dialog-sky-placement modal-dialog-container'}>
            <Modal.Header>
                <div className={'font-size-18px font-weight-bold'}>Löschen bestätigen</div>
            </Modal.Header>
            <Modal.Body className={'bg-white p-3'}>

                <div className={'d-flex limits-table-modal-body'}>
                    <div> Hiermit werden <span className={'font-weight-bold'}> {count} </span> Ihrer  {limitCount} Limits endgültig gelöscht.</div>
                    <div> Möchten Sie fortfahren?</div>
                </div>

                <div className={'d-flex justify-content-end'}>

                    <Button style={{border: 'none'}} onClick={() => handleModalClose()}
                            className={' py-2 button-fixed-results m-1 bg-border-gray text-primary btn-short-button btn-limit-modal-left'}>Abbrechen</Button>

                    <Button variant={'danger'} style={{width: 98}} onClick={() => handleSubmit()}
                            className={'px-2 button-fixed-results m-1 btn-short-button btn-limit-modal-right'}>
                        <SvgImage icon="icon_close_white.svg" width="10" className="pr-2 mr-3"/> Löschen
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

interface LimitTableDataRowProps {
    entry: any;
    index: number;
    showMemo: boolean;
    selected: (id:number,select :boolean) => void;
    selectedAll: boolean;
    reset: boolean | undefined;
    refetch: () => void;
    showHit:boolean;
}

function LimitTableDataRow(
    {
        entry, index, showMemo, selected, selectedAll, reset, refetch , showHit
    }
        : LimitTableDataRowProps) {
    const [isSelected, setSelected] = useState<boolean>(selectedAll);
    let [selectLimit,setSelectLimit] = useState<boolean>(false);
    const assetType: string = getAssetGroup(entry?.instrument?.group.assetGroup);
    const quote = entry && entry.instrument && entry.instrument.snapQuote && entry.instrument.snapQuote.quotes.find((current: { type: QuoteType; }) => current?.type === QuoteType.Trade || QuoteType.NetAssetValue);
    const oldAssetNoTradding = moment().subtract(10, "d").isAfter(moment(quote && quote.when)) || !quote;
    let delay = quote && quote?.delay && quote?.delay > 1 ? 15 : 1;
    const showInPercent = entry.percent || entry.trailing;
    const percentToTarger = entry.hitStatus ? 0 : entry.effectiveLimitValue && entry.effectiveLimitValue > 0 && quote && quote.value ? (Math.abs(Math.abs(entry.effectiveLimitValue - quote.value) / quote.value) * 100) : 0;
    const [show, requestShow] = useState<boolean>(false);
    useEffect(() => {
        setSelected(false);
    }, [reset])
    useEffect(() => {
        if (selectedAll) {
            setSelected(true)
        } else {
            setSelected(false);
        }
    }, [selectedAll])
    return (
        <>
            <Row key={index} className="border-bottom-1 border-gray-light align-items-center py-1">
               <Col xs={12} className={'mt-n5'} style={{
            position: "absolute",
            height: showMemo && entry.memo ? "40px" : "10px",
       
            paddingTop:showMemo&&entry.memo && "1px"
          }}>
                <Row>
                        {
                            (   isSelected || (showHit && entry.hitStatus) )  ? <SvgImage onClick={() => {
                                    setSelected(false);
                                    selected(entry.id, true);
                                }} convert={false} icon={"icon_checkbox_checked_dark.svg"} width="24"
                                                   spanClass={"svg-white"} imgClass={"svg-white"}/> :
                                <SvgImage onClick={() => {
                                    setSelected(true);
                                    selected(entry.id, false);
                                }} convert={false} icon={"icon_checkbox_unchecked_dark.svg"}
                                          width={"24"} spanClass={"svg-white"} imgClass={"svg-white"}/>
                        }
                        {oldAssetNoTradding && <img className="align-middle" style={{marginTop: "-4px"}}
                                                    src={process.env.PUBLIC_URL + "/static/img/svg/icon_alert_red.svg"}
                                                    width="20"
                                                    alt="search news icon"/>
                        }
                        <div className={'w-75 ml-2'}>
                            <AssetLinkComponent instrument={entry.instrument}
                                                className="font-weight-bold font-size-14px"
                                                size={37}/>
                        </div>
                    </Row>
               </Col>
                <Col xs={3} className="text-left text-nowrap ml-3 mt-4">

                    <Row className="font-size-12px align-items-center">
                        <Col className={'pl-4 font-size-12px mt-2 ml-n2'}><span
                            className={"asset-type-text-color font-weight-bold " + entry.instrument?.group.assetGroup}>{assetType.toUpperCase()}</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col className={'ml-2 ml-n1px'}>
                            {entry.instrument && entry.instrument.wkn ?
                                <>
                                    <span className={'font-size-12px'}>{entry.instrument.wkn}</span>
                                    <CopyWKN wkn={entry.instrument.wkn || ""} className="p-0 mt-n1"/>
                                </>
                                :
                                entry.instrument && entry.instrument.isin &&
                                <span className={'font-size-12px'}>{entry.instrument.isin}</span>
                            }
                        </Col>
                    </Row>
                </Col>
                <Col xs={2} className="text-left font-weight-bold pr-0 text-nowrap py-1 font-size-14px mt-4">
                    <Row className="align-items-center">
                        <Col>
                                                <span>
                                                    {quote && quote.delay === 1 &&
                                                        <span className="font-size-11px text-white px-2 bg-orange"
                                                              style={{width: "15px"}}>RT</span>
                                                    }
                                                    {quote && quote.delay !== 1 &&
                                                        <span className="font-size-11px text-white px-2 bg-gray-dark"
                                                              style={{width: "15px"}}>{delay}</span>
                                                    }
                                                </span>
                            <span
                                className="px-1">{quote && quote.value && formatPrice(quote.value, entry.instrument?.group.assetGroup)} {entry.instrument?.currency.displayCode || ""}</span>
                            <span className="svg-icon move-arrow">
                                                    {quote && quote.percentChange ?
                                                        quote.percentChange > 0 ?
                                                            <img src="/static/img/svg/icon_arrow_short_up_green.svg"
                                                                 alt="" className="mt-n1" width={12}/>
                                                            : quote.percentChange < 0 ?
                                                                <img src="/static/img/svg/icon_arrow_short_down_red.svg"
                                                                     alt="" className="mt-n1" width={12}/>
                                                                : <img src="/static/img/svg/icon_arrow_short_right_grey.svg"
                                                                       alt="" width={28}/>
                                                        : ""
                                                    }
                                                </span>
                            <span
                                className={classNames("pl-2", getTextColorByValue(quote?.percentChange))}>{formatPriceWithSign(quote?.percentChange, entry.instrument?.group.assetGroup, quote?.value, "%")}</span>
                        </Col>
                    </Row>
                    <Row className="font-size-12px align-items-center">
                        <Col>
                            <div className="font-weight-light">
                                {quote && quoteFormat(quote.when)}
                                <span className="ml-2 mx-3">{entry.instrument?.exchange.code}</span>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col xs={2} className="text-right font-size-14px pl-0 font-weight-bold cursor-pointer mt-4"
                     onClick={() => !show && requestShow(!show)}>
                    {showInPercent ?
                        <>
                            <Row
                                className={classNames("justify-content-end", entry.upper ? "text-green" : "text-pink")}>
                                <Col className="text-right px-0">
                                    {formatPrice(entry.effectiveLimitValue, entry.instrument?.group.assetGroup) + " " + entry.instrument?.currency.displayCode || ""}
                                </Col>
                            </Row>
                            <Row className={classNames("text-white justify-content-end")}>
                                                    <span
                                                        className={classNames("px-2 rounded", entry.upper ? "bg-green" : "bg-pink")}>
                                                        {entry.upper ?
                                                            <SvgImage icon="icon_limit_top_white.svg" width="24"/>

                                                            :
                                                            <SvgImage icon="icon_limit_bottom_white.svg" width="24"/>
                                                        }
                                                        {formatPriceWithSign(entry.limitValue)} %
                                                    </span>
                            </Row>
                        </>
                        :

                        <>
                            <Row className={classNames("text-white justify-content-end")}>
                                                    <span
                                                        className={classNames("px-2 rounded", entry.upper ? "bg-green" : "bg-pink")}>
                                                        {entry.upper ?
                                                            <SvgImage icon="icon_limit_top_white.svg" width="24"/>

                                                            :
                                                            <SvgImage icon="icon_limit_bottom_white.svg" width="24"/>
                                                        }
                                                        {formatPrice(entry.limitValue, entry.instrument?.group.assetGroup)} {entry.instrument?.currency.displayCode || ""}
                                                    </span>
                            </Row>
                            <Row
                                className={classNames("justify-content-end", entry.upper ? "text-green" : "text-pink")}>
                                <Col className="text-right pr-0">
                                    {entry.effectiveLimitValue ?

                                        formatPriceWithSign(getPercentOfChange(entry.effectiveLimitValue, entry.initialValue || 1), entry.instrument?.group.assetGroup) + "%"
                                        :
                                        entry.effectiveLimitValue === 0 ? "-100%" : "-"
                                    }
                                </Col>
                            </Row>
                        </>
                    }
                </Col>
                <Col xs={1}>
                    {entry.trailing ?
                        <SvgImage icon="icon_repeat.svg" width="20"/>
                        :
                        entry.percent ?
                            <SvgImage icon="icon_percent.svg" width="20"/>
                            :
                            <SvgImage icon="icon_absolute.svg" width="20"/>
                    }
                </Col>
                <Col xs={1} className="text-center font-size-14px">
                    {formatPrice(percentToTarger)}%
                </Col>
                <Col xs={2} className="pl-2 pr-n2 mt-2">
                    <Row className="pl-1 align-items-center">
                        <Col xs={2} className="pl-0">
                            {entry.hitStatus ?
                                <img src="/static/img/svg/icon_alert_red.svg" className="svg-convert cursor-default"
                                     width="32" alt=""/>
                                :
                                entry.instrument?.snapQuote &&
                                <div className="pulse mb-n3"></div>
                            }
                        </Col>
                        <Col xs={10} className="font-size-12px pl-2 pr-0 mt-2">
                            {entry.upper ?
                                <span className="text-green font-weight-bold">Oberes Limit</span>
                                :
                                <span className="text-pink font-weight-bold">Unteres Limit</span>
                            }
                            {entry.hitStatus ?
                                <span> am {quoteFormat(entry.hitTime)} <b>durchbrochen</b></span>
                                :
                                <span> noch nicht durchbrochen </span>
                            }
                        </Col>
                    </Row>
                </Col>
                <Col xs={1} className="text-right px-0 ml-n3 mt-1">
                    <Row className="align-items-center justify-content-end">
                        {entry.instrument?.isin ?
                            <div className="pt-1">
                                <NewsModalMeinFinanztreff isins={[entry.instrument.isin]}/>
                            </div>
                            :
                            <Button variant="link px-0 pl-n1 pr-1 pb-n1" className="" disabled>
                                <SvgImage icon={"icon_news.svg"} convert={false} width="26"/>
                            </Button>
                        }
                        <LimitEditDelete
                            limit={entry}
                            refreshTrigger={refetch}
                            variant="link" className="text-white"
                            open={show}
                        >
                            <SvgImage icon={"icon_menu_horizontal_blue.svg"} spanClass="cursor-pointer"
                                      className="ml-n3" convert={false} width="27"/>
                        </LimitEditDelete>
                    </Row>
                </Col>
                <Col xs={12}>

                    {showMemo && !!entry.memo && entry.memo.length > 0 &&
                        <Row>
                                                <span className="svg-icon">
                                                    <img src={process.env.PUBLIC_URL + "/static/img/svg/icon_note.svg"}
                                                         width="28" alt="" className=""/>
                                                </span>
                            <span className="pl-2"><i>{entry.memo}</i></span>
                        </Row>

                    }
                </Col>
            </Row>
        </>
    )
}


interface LimitsGridComponentProps {
    showMemo: boolean
    sort: {
        sortType: string, direction
            :
            boolean
    }
    handleSort: (value: { id: string, name: string }, order: boolean) => void;
    limits: LimitEntry[];
    refetch: () => void;
    description?: string;
    direction?: boolean;
    inModal?: boolean;
    isUpper?: boolean;
    isActive?: boolean;
    handleChange ?: () => void;
    handleReset ?: () => void;
    reset?: boolean
}
