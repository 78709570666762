export const columns = [
    {
        Header: '',
        accessor: 'currency',
        sticky: 'left',
    },
    {
        Header: 'EUR',
        accessor: 'eur',
    },
    {
        Header: 'USD',
        accessor: 'usd',
    },
    {
        Header: 'JPY',
        accessor: 'jpy',
    },
    {
        Header: 'GBP',
        accessor: 'gbp',
    },
    {
        Header: 'CHF',
        accessor: 'chf',
    },
    {
        Header: 'AUD',
        accessor: 'aud',
    },
    {
        Header: 'HKD',
        accessor: 'hkd',
    },
    {
        Header: 'RUB',
        accessor: 'rub',
    },
    {
        Header: 'ZAR',
        accessor: 'zar',
    },
    {
        Header: 'CNY',
        accessor: 'cny',
    },
];
export const correlationMockData =  [
    {
        currency: 'EUR/USD',
        eur: '-',
        usd: '-0,03',
        jpy: '-0,92',
        gbp: '-0,95',
        chf: '0,04',
        aud: '-0,49',
        hkd: '0,48',
        rub: '0,33',
        zar: '0,86',
        cny: '-0.56',
    },
    {
        currency: 'EUR/CHF',
        eur: '0,34',
        usd: '-',
        jpy: '-0,53',
        gbp: '0,56',
        chf: '0,13',
        aud: '-0,28',
        hkd: '-0,29',
        rub: '0,22',
        zar: '0,51',
        cny: '-0,31',
    },
    {
        currency: 'EUR/GBP',
        eur: '-0,0077',
        usd: '0,0092',
        jpy: '-',
        gbp: '0,0066',
        chf: '0,8488',
        aud: '-0,0119',
        hkd: '0,0712',
        rub: '0,6701',
        zar: '-0,1368',
        cny: '0,0596',
    },
    {
        currency: 'EUR/JPY',
        eur: '1,1669',
        usd: '-1,3898',
        jpy: '151,564',
        gbp: '-',
        chf: '1,2865',
        aud: '1,7975',
        hkd: '-10,7925',
        rub: '101,587',
        zar: '20,7153',
        cny: '9,0355',
    },
    {
        currency: 'EUR/HKD',
        eur: '0,9069',
        usd: '-1,0803',
        jpy: '117,814',
        gbp: '0,7769',
        chf: '-',
        aud: '1,3968',
        hkd: '-8,3852',
        rub: '78,928',
        zar: '16,1099',
        cny: '7,0201',
    },
    {
        currency: 'EUR/CNY',
        eur: '0,6492',
        usd: '-0,7732',
        jpy: '84,32',
        gbp: '0,5563',
        chf: '0,7157',
        aud: '-',
        hkd: '-6,0032',
        rub: '56,5072',
        zar: '11,5243',
        cny: '-5,0259',
    },
    {
        currency: 'EUR/RUB',
        eur: '-0,1081',
        usd: '0,129',
        jpy: '-14,0432',
        gbp: '0,0927',
        chf: '0,1195',
        aud: '0,1666',
        hkd: '-',
        rub: '9,4128',
        zar: '1,9212',
        cny: '-0,8372',
    },
    {
        currency: 'USD/EUR',
        eur: '0,0115',
        usd: '-0,0137',
        jpy: '1,4923',
        gbp: '-0,0098',
        chf: '0,0127',
        aud: '0,0177',
        hkd: '0,1062',
        rub: '-',
        zar: '-0,2041',
        cny: '0,0889',
    },
    {
        currency: 'USD/JPY',
        eur: '0,0563',
        usd: '0,0671',
        jpy: '-7,317',
        gbp: '0,0483',
        chf: '0,0621',
        aud: '-0,0867',
        hkd: '0,5205',
        rub: '4,8993',
        zar: '-',
        cny: '-0,4358',
    },
    {
        currency: 'USD/CNY',
        eur: '0,1291',
        usd: '-0,1538',
        jpy: '16,7739',
        gbp: '0,1107',
        chf: '0,1424',
        aud: '-0,199',
        hkd: '1,1944',
        rub: '11,2432',
        zar: '-2,2948',
        cny: '-',
    },
];
export const correlationColumns = [
    {
        Header: '',
        accessor: 'currency',
        sticky: 'left',
    },
    {
        Header: 'EUR/USD',
        accessor: 'eur',
    },
    {
        Header: 'EUR/CHF',
        accessor: 'usd',
    },
    {
        Header: 'EUR/GBP',
        accessor: 'jpy',
    },
    {
        Header: 'EUR/JPY',
        accessor: 'gbp',
    },
    {
        Header: 'EUR/HKD',
        accessor: 'chf',
    },
    {
        Header: 'EUR/CNY',
        accessor: 'aud',
    },
    {
        Header: 'EUR/RUB',
        accessor: 'hkd',
    },
    {
        Header: 'USD/EUR',
        accessor: 'rub',
    },
    {
        Header: 'USD/JPY',
        accessor: 'zar',
    },
    {
        Header: 'USD/CNY',
        accessor: 'cny',
    },
];