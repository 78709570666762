import {OptionItem} from "../../common/SearchCard/FilterDropdownItem";
import {usePageHeaderFilterState} from "./PageHeaderFilterBaseComponent";
import React, {useCallback, useContext} from "react";
import PageHeaderFilterContext from "./PageHeaderFilterContext";
import {Button, Card} from "react-bootstrap";
import classNames from "classnames";

interface ListSelectorContentComponentState<Type extends OptionItem> {
    selected: Type;
}

interface ListContentComponentProps<Type extends OptionItem> {
    options: Type[];
    onSelect?: (ev: Type) => void;
    render?: (opt: Type) => React.ReactNode;
}

export function ListContentComponent<Type extends OptionItem>({options, onSelect, render}: ListContentComponentProps<Type>) {
    let [state, setState] = usePageHeaderFilterState<ListSelectorContentComponentState<Type>>({selected: options[0]});
    let context  = useContext(PageHeaderFilterContext);

    let closeDropDown = useCallback(() => {
        if (context && context.close) {
            context.close();
        }
    }, [context]);

    return (
        <Card className={"border-0 institute-selector"}>
            <Card.Body className="border-0 p-0 d-flex justify-content-center">
                <div className="card-body-wrapper overflow-auto" style={{height: '300px'}}>
                    {options?.map(current =>
                        <React.Fragment key={current.id}>
                            <hr className={"m-0"}/>
                            <Button variant='inline-action'
                                    className={classNames("text-left w-100 py-2 font-weight-bold", state.selected.id === current.id ? 'active' : '')}
                                    onClick={() => {
                                        setState({...state, selected: current});
                                        if (onSelect) {
                                            onSelect(current);
                                        }
                                        closeDropDown();
                                    }}
                            >{(render || defaultRender)(current)}</Button>
                        </React.Fragment>
                    )}
                </div>
            </Card.Body>
        </Card>
    );
}

export function defaultRender<Type extends OptionItem>(opt: Type) {
    return opt.name;
}
