import {
    AssetGroup,
    ChartScope,
    DerivativeInstrumentKeyFigures,
    Instrument,
    InstrumentGroup,
    InstrumentGroupUnderlying
} from "../../../generated/graphql";
import {useEffect, useState} from "react";
import {ChartComponent} from "./ChartComponent";
import {ChartKeyfiguresSection} from "./ChartKeyfiguresSection";
import {getAssetForUrl} from "components/profile/utils";
import {Link, useParams} from "react-router-dom";
import {useBootstrapBreakpoint} from "../../../hooks/useBootstrapBreakpoint";
import {Button} from "react-bootstrap";
import SvgImage from "../image/SvgImage";
import { guessInfonlineSection, trigInfonline } from "../InfonlineService";

interface BannerChartComponentProps {
    instrument: Instrument
    underlyings: InstrumentGroupUnderlying[] | undefined
    keyFigures?: DerivativeInstrumentKeyFigures | undefined
    investmentFund?: boolean;
    group: InstrumentGroup
    setEntireInstrument: (val: Instrument) => void;
    setChartScope: (value: ChartScope) => void;
}

function calculateDefaultChartScope(assetGroup: AssetGroup | undefined | null) {
    if (!assetGroup) {
        return ChartScope.Intraday;
    }
    switch (assetGroup){
        case AssetGroup.Bond:
        case AssetGroup.Fund:
        case AssetGroup.Etf:
        case AssetGroup.Mmr:
        case AssetGroup.Vwl:
            return ChartScope.Year;
        default:
            return ChartScope.Intraday;
    }
}

export function BannerChartComponent(props: BannerChartComponentProps) {
    const [chartScope, setChartScope] = useState(calculateDefaultChartScope(props.group.assetGroup));
    const [isStrikeChecked, setIsStrikeChecked] = useState<boolean>(false)
    const [isUnderlyingChecked, setIsUnderlyingChecked] = useState<boolean>(false)
    const [isStopLossChecked, setIsStopLossChecked] = useState<boolean>(false)
    const [underlyingInstrumentId, setUnderlyingInstrumentId] = useState<number | null | undefined>(props.underlyings && props.underlyings[0]?.instrumentId);
    const [strike, setStrike] = useState<number | null | undefined>(props.underlyings && props.underlyings[0]?.strike);
    const [stopLoss, setStopLoss] = useState<number | null | undefined>(props.underlyings && props.underlyings[0]?.knockOut);
    const [isDerivativePage, setIsDerivativePage] = useState<boolean>(true);
    const [gd50Average, setgd50Average] = useState<boolean>(false)
    const [gd200Average, setgd200Average] = useState<boolean>(false)
    const showChartTools = useBootstrapBreakpoint({
        xl: true,
        md: false,
        lg: false,
        sm: false,
        default: false
    })

    const handleStrikeClick = () => {
        let val = !isStrikeChecked;
        setIsStrikeChecked(!isStrikeChecked);
        if (val) {
            setStrike(props.underlyings && props.underlyings[0].strike);
        } else {
            setStrike(undefined);
        }
    };

    const handleStopLossClick = () => {
        let val = !isStopLossChecked;
        setIsStopLossChecked(!isStopLossChecked)
        if (val) {
            setStopLoss(props.underlyings && props.underlyings[0]?.knockOut);
        } else {
            setStopLoss(undefined);
        }
    };

    useEffect(() => {
        const {assetGroup} = props.group;
        if (assetGroup === AssetGroup.Knock || assetGroup === AssetGroup.Cert || assetGroup === AssetGroup.Warr) {
            setIsDerivativePage(false);
        }
    }, [isDerivativePage])

    useEffect(() => {
        if (!isUnderlyingChecked) {
            // uncheck other checkboxes
            setIsStopLossChecked(false);
            setIsStrikeChecked(false);
            setUnderlyingInstrumentId(undefined);
            setStrike(undefined);
            setStopLoss(undefined);
        } else {
            setUnderlyingInstrumentId(props.underlyings && props.underlyings[0].instrumentId);
        }
    }, [isUnderlyingChecked, setStrike, setStopLoss]);
    const pathParam = useParams<{ section: string, seoTag: string }>();
    function toggleGdCheckbox() {
        if(!gd200Average && !gd50Average) {
            setChartScope(ChartScope.Year)
        }
    }
    useEffect(()=>{
        const {assetGroup} = props.group;
        if(assetGroup !== AssetGroup.Bond && assetGroup !== AssetGroup.Fund && assetGroup !== AssetGroup.Etf && assetGroup !== AssetGroup.Mmr && assetGroup  && AssetGroup.Vwl && !gd200Average && !gd50Average) {
            setChartScope(ChartScope.Intraday)
        }
    },[gd50Average,gd200Average])
    return (
        <div className="carousel-item-right-part pr-xl-3 pr-0 col-xl-5 col-lg-12 pt-md-32px" data-testid="banner-chart">
            <div className="top">
                <div className="time-interval">
                    {
                        !props.investmentFund &&
                        <>
                            <span className={`${chartScope === ChartScope.Intraday ? "active" : ""}`}
                                onClick={() => setChartScope(ChartScope.Intraday)}>1 T</span>
                            <span className={`${chartScope === ChartScope.Week ? "active" : "1"}`}
                                onClick={() => setChartScope(ChartScope.Week)}>1W</span>
                        </>
                    }

                    <span className={`${chartScope === ChartScope.Month ? "active" : ""}`}
                        onClick={() => setChartScope(ChartScope.Month)}>1M</span>
                    <span className={`${chartScope === ChartScope.ThreeMonth ? "active" : ""}`}
                        onClick={() => setChartScope(ChartScope.ThreeMonth)}>3M</span>
                    <span className={`${chartScope === ChartScope.SixMonth ? "active" : ""}`}
                        onClick={() => setChartScope(ChartScope.SixMonth)}>6M</span>
                    <span className={`${chartScope === ChartScope.Year ? "active" : ""}`}
                        onClick={() => setChartScope(ChartScope.Year)}>1J</span>
                    <span className={`${chartScope === ChartScope.ThreeYear ? "active" : ""}`}
                        onClick={() => setChartScope(ChartScope.ThreeYear)}>3J</span>
                    <span className={`${chartScope === ChartScope.FiveYear ? "active" : ""}`}
                        onClick={() => setChartScope(ChartScope.FiveYear)}>5J</span>
                    <span className={`${chartScope === ChartScope.TenYear ? "active" : ""}`}
                        onClick={() => setChartScope(ChartScope.TenYear)}>10J</span>
                </div>
            </div>
            {(isDerivativePage && showChartTools) ?
                <Link className="text-blue mt-1 mr-4" onClick={()=>trigInfonline(guessInfonlineSection(),"chart_analyse")} data-testid="chart_analyse"
                   to={{
                       key: '',
                       pathname: "/" + getAssetForUrl(props.group.assetGroup).toLowerCase() + "/chart-analyse/" + pathParam.seoTag + "/",
                       state: {
                           instrumentId: props.instrument.id,
                           chartType: chartScope,
                           underlyingInstrumentId: underlyingInstrumentId,
                           strike: strike,
                           stoploss: stopLoss
                       }
                   }}>
                <ChartComponent instrumentId={props.instrument.id}
                                chartType={chartScope}
                                underlyingInstrumentId={underlyingInstrumentId}
                                strike={strike}
                                stoploss={stopLoss}
                                gd50Average={gd50Average}
                                gd200Average={gd200Average}
                                />
                </Link> : (
                <ChartComponent instrumentId={props.instrument.id}
                                chartType={chartScope}
                                underlyingInstrumentId={underlyingInstrumentId}
                                strike={strike}
                                stoploss={stopLoss}
                                gd50Average={gd50Average}
                                gd200Average={gd200Average}
                />
            )}
            <ChartKeyfiguresSection instrument={props.instrument}
                onUnderlyingChecked={() => setIsUnderlyingChecked(!isUnderlyingChecked)}
                underlyingChecked={isUnderlyingChecked}
                onStrikeChecked={handleStrikeClick}
                strikeChecked={isStrikeChecked}
                onStopLossChecked={handleStopLossClick}
                stopLossChecked={isStopLossChecked}
            />
            {(isDerivativePage && showChartTools) ?
            <div className="d-flex justify-content-between w-100">
                <div className="d-flex align-self-center mt-n3 w-30">
                    <div className="d-flex cursor-pointer" data-testid="toggleGdCheckbox" onClick={() => { setgd50Average(!gd50Average); toggleGdCheckbox()}}>
                        {gd50Average ?
                            <SvgImage convert={false} icon={"icon_checkbox_checked_orange.svg"} width="24"
                                spanClass={"svg-white"} imgClass={"svg-white"} /> :
                            <SvgImage convert={false} icon={"icon_checkbox_unchecked_white.svg"}
                                width={"24"} spanClass={"svg-white"} imgClass={"svg-white"} />
                        }
                        <span className="d-sm-block" style={{ fontSize: "15px", paddingTop: "2px" }}>GD 50</span>
                    </div>
                    <div className="d-flex ml-4 cursor-pointer" data-testid="toggleGdCheckbox" onClick={() => { setgd200Average(!gd200Average); toggleGdCheckbox()}}>
                        {gd200Average ?
                            <SvgImage convert={false} icon={"icon_checkbox_checked_darkpink.svg"} width="24"
                                spanClass={"svg-white"} imgClass={"svg-white"} /> :
                            <SvgImage convert={false} icon={"icon_checkbox_unchecked_white.svg"}
                                width={"24"} spanClass={"svg-white"} imgClass={"svg-white"} />
                        }
                        <span className="d-sm-block" style={{ fontSize: "15px", paddingTop: "2px" }}>GD 200</span>
                    </div>
                </div>
                <div className="d-flex justify-content-end w-70">
                <Link className="mt-n3 mr-2" onClick={()=>trigInfonline(guessInfonlineSection(),"chart_comparison")} data-testid="chartvergleich"
                      to={{
                          key: '',
                          pathname: "/" + getAssetForUrl(props.group.assetGroup).toLowerCase() + "/chart-analyse/" + pathParam.seoTag + "/",
                          state: {
                              instrumentId: props.instrument.id,
                              chartType: chartScope,
                              underlyingInstrumentId: underlyingInstrumentId,
                              strike: strike,
                              stoploss: stopLoss,
                              showSearchModal: true
                          }
                      }}>
                    <Button className="border-none" style={{backgroundColor: "#505050"}}>Chartvergleich</Button>
                </Link>
                <Link className="mt-n3" onClick={()=>trigInfonline(guessInfonlineSection(),"chart_analyse")} data-testid="Chartanalyse"
                      to={{
                          key: '',
                          pathname: "/" + getAssetForUrl(props.group.assetGroup).toLowerCase() + "/chart-analyse/" + pathParam.seoTag + "/",
                          state: {
                              instrumentId: props.instrument.id,
                              chartType: chartScope,
                              underlyingInstrumentId: underlyingInstrumentId,
                              strike: strike,
                              stoploss: stopLoss,
                              showSearchModal: false
                          }
                      }}>
                    <Button className="border-none" style={{backgroundColor: "#505050"}}>Chartanalyse</Button>
                </Link>
            </div>
            </div> : 
                isDerivativePage &&
                <div className="d-flex align-self-center justify-content-center mt-4">
                    <div className="d-flex" data-testid="toggleGdCheckbox" onClick={() => { setgd50Average(!gd50Average); toggleGdCheckbox()}}>
                        {gd50Average ?
                            <SvgImage convert={false} icon={"icon_checkbox_checked_orange.svg"} width="24"
                                spanClass={"svg-white"} imgClass={"svg-white"} /> :
                            <SvgImage convert={false} icon={"icon_checkbox_unchecked_white.svg"}
                                width={"24"} spanClass={"svg-white"} imgClass={"svg-white"} />
                        }
                        <span className="d-sm-block" style={{ fontSize: "15px", paddingTop: "2px"}}>GD 50</span>
                    </div>
                    <div className="d-flex ml-3" data-testid="toggleGdCheckbox" onClick={() => { setgd200Average(!gd200Average); toggleGdCheckbox()}}>
                        {gd200Average ?
                            <SvgImage convert={false} icon={"icon_checkbox_checked_darkpink.svg"} width="24"
                                spanClass={"svg-white"} imgClass={"svg-white"} /> :
                            <SvgImage convert={false} icon={"icon_checkbox_unchecked_white.svg"}
                                width={"24"} spanClass={"svg-white"} imgClass={"svg-white"} />
                        }
                        <span className="d-sm-block" style={{ fontSize: "15px", paddingTop: "2px" }}>GD 200</span>
                    </div>
                </div>
            }
        </div>
    )
}
