import { Button, Container } from "react-bootstrap";
import "./CurrentNews.scss";
import { NavLink } from "react-router-dom";
import { NewsCarouselComponent } from "../../common/news/NewsCarouselComponent/NewsCarouselComponent";
import { trigInfonline } from "../../common/InfonlineService";
import { EDITORIAL_SOURCE_CODE } from "../../news/constants";

export const CurrentNews = (props: any) => {
  return (
    <section className="main-section news-overview news-overview-wrapper">
      <Container className="px-0 px-md-2 news-overview-container">
        <h2 className="section-heading font-weight-bold ml-0 mb-n3 ml-md-2">
          Aktuelles
        </h2>
        <div className="carouse-container">
          <NewsCarousel
            isHomePage={props.isHomePage}
            showAllNewsButton={true}
          />
        </div>
      </Container>
    </section>
  );
};

export function NewsCarousel(props: any) {
  return (
    <>
      {/* ISSUE: Rerender caused by query executed in this component */}
      {/* TODO: Merge all news queries into a single one */}
      <NewsCarouselComponent
        isHomePage={props.isHomePage}
        topic={props.topic}
        clearTopics={props.clearTopics}
        setSearchTopic={props.setSearchTopic}
        setSearchLabels={props.setSearchLabels}
      />
      {props.showAllNewsButton && (
        <div className="text-center mt-sm-n1 my-lg-1">
          <NavLink
            to={{
              // state: {
              //     keyword: {id: NewsFeed.EditorialFeed, name: 'finanztreff.de'},
              //     showValueInDropdown: true
              // },
              pathname: "/nachrichten/",
              hash: "#" + EDITORIAL_SOURCE_CODE,
            }}
            onClick={() => trigInfonline("homepage", "load_more_news")}
          >
            <Button
              className=" float-md-right mr-2 d-xl-block d-none"
              style={{ marginTop: "-35px" }}
            >
              Mehr aus der finanztreff.de Redaktion
            </Button>
            <Button className="mx-auto d-xl-none d-flex mt-30px mt-md-0">
              Mehr aus der finanztreff.de Redaktion
            </Button>
          </NavLink>
        </div>
      )}
    </>
  );
}
