import { useQuery } from "@apollo/client";
import SvgImage from "components/common/image/SvgImage";
import { CrossRateSortField, Query } from "generated/graphql";
import { loader } from "graphql.macro";
import {useEffect, useState} from "react";
import { Button, Col, Modal, Row, Spinner } from "react-bootstrap";
import classNames from "classnames";
import { useBootstrapBreakpoint } from "hooks/useBootstrapBreakpoint";
import { ALL_CURRENCIES, DEFAULT_CURRENCY_OPTION, CurrencyEntriesDropdownComponent, CURRENCY_OPTIONS} from "../CurrencyPageDropdown/CurrencyEntriesDropdown";
import { CrossRateInstrumentCard } from "./CrossRateInstrumentCard";
import { getInfonlineTag, guessInfonlineSection, trigInfonline } from "../../common/InfonlineService";
import { OptionItem} from "../../common/SearchCard/FilterDropdownItem";
import { GenericSortFilter, SortDirection } from "./filters/GenericSortFilter";
import { SimpleOptionFilter } from "./filters/SimpleOptionFilter";
import { mapPeriod, OptionItemWithPeriod, PERIOD_OPTIONS, SORTING_OPTIONS, extractQuote, PERIOD_INTRADAY_OPTION_ID, PERFORMANCE_OPTION_ID } from "./utils";

import './FindIndexSection.scss';
import './CurrencyFindSection.scss'
import './FindCurrencyModal.scss'

interface FindCrossRateModalProps {
    buttonName?: string;
    className?: string;
    showCardFooter?: boolean;

    sortingOption: OptionItemWithPeriod;
    sortingDirection: SortDirection;
    periodOption: OptionItemWithPeriod;
    currencyCode: string;
}

interface FindCrossRateModalState {
    isOpen: boolean
}

export function FindCurrencyModal({currencyCode, sortingOption, sortingDirection, periodOption, ...props}: FindCrossRateModalProps) {
    const indexCardsBreakpoint = useBootstrapBreakpoint({
        xl: 6,
        md: 3,
        sm: 3,
        default: 3
    });

    const [state, setState] = useState<FindCrossRateModalState>({isOpen: false});
    const [homeCurrency, setHomeCurrency] = useState<OptionItem>(CURRENCY_OPTIONS.find(current => current.id ===currencyCode) || DEFAULT_CURRENCY_OPTION);
    const [sorting, setSorting] = useState<{option: OptionItemWithPeriod, direction: SortDirection}>({option: sortingOption, direction: sortingDirection});
    const [period, setPeriod] = useState<{option: OptionItemWithPeriod}>({option: periodOption});


    const closeModal = () => {
        setState({ isOpen: false, });
    }

    const [loadingMore, setLoadingMore] = useState(false);

    useEffect(() => {
        if (state.isOpen){
            trigInfonline(guessInfonlineSection(), "find_currency_modal");
        }
    }, [state.isOpen])

    let { loading, data, fetchMore } = useQuery<Query>(loader('./getCardPairsCrossRates.graphql'), {
        variables: {
            homeCurrencyCode: homeCurrency.id !== ALL_CURRENCIES ? homeCurrency.id : undefined,
            first: 9,
            sortField: sorting.option.field || period.option.field || CrossRateSortField.Name,
            descending: sorting.direction === 'desc',
            chartScope: mapPeriod(period.option.field),
            after: null
        },
        skip: !state.isOpen
    });

    const getValueForInfolineTag = ()=> {
        let tagValue = "find_currency_";
        tagValue+= (homeCurrency.id)?.toLowerCase();
        return tagValue;
    }

    function handleLocalHomeCurrencySelection(v: OptionItem) {
        if (v.id !== ALL_CURRENCIES) {
            trigInfonline(guessInfonlineSection(),getInfonlineTag(guessInfonlineSection(), 'find_currency') + '_' + v.id);
        } else {
            trigInfonline(guessInfonlineSection(), "find_currency_alle");
        }
        setHomeCurrency(v);
    }

    return (
        <>
            <Row className={classNames(props.className, "button-row d-flex pr-lg-2")} onClick={() => setState({isOpen: true})}>
                <Col className="d-flex justify-content-center justify-content-lg-end" xs={12}>
                    <Button variant="primary">
                        {props.buttonName}
                    </Button>
                </Col>
            </Row>
            <Modal
                show={state.isOpen}
                onHide={closeModal}
                dialogClassName="currency-finden-modal"
                id="find-currency-modal"
                className="modal-dialog-sky-placement"
            >
                <Modal.Header className="modal-header-fix">
                    <Row className="row-cols-1">
                        <div className="col d-flex justify-content-between">
                            <Modal.Title>
                                {/*
                                <h5 className="section-heading font-weight-bold button-styling">
                                    Devisen -
                                    <CurrencyEntriesDropdownComponent name={""}
                                        withAllButton
                                        description={state4.idCurrency || props.idCurrency}
                                        onSelect={handleLocalHomeCurrencySelection}
                                    />
                                </h5>
                                */}
                            </Modal.Title>
                            <button type="button" className="close text-blue" onClick={closeModal}>
                                <span>schließen</span>
                                <SvgImage icon="icon_close_blue.svg" width={"27"} spanClass="close-modal-butt"
                                    imgClass="svg-blue" convert={false} />
                            </button>
                        </div>
                    </Row>
                </Modal.Header>
                <Modal.Body className="mt-sm-2">
                    <div className="d-flex flex-wrap pt-lg-2 pl-2 justify-content-end" style={{ fontFamily: "Roboto" }}>
                        <div className="flex-grow-1 m-auto pb-sm-2">
                            <h5 className="fix-line-height section-heading font-weight-bold button-styling">Devisen -</h5>
                            <CurrencyEntriesDropdownComponent toggleVariant="fix-modal-dropdown" name={""}
                                withAllButton
                                description={homeCurrency.id}
                                onSelect={handleLocalHomeCurrencySelection}
                            />
                        </div>
                        <div className="filters-panel text-nowrap d-none d-xl-inline-flex">
                            <GenericSortFilter<OptionItemWithPeriod>
                                onSelect={(v) => {
                                    setSorting({option: v.option, direction: v.direction});
                                    if (period.option.id === PERIOD_INTRADAY_OPTION_ID && v.option.id === PERFORMANCE_OPTION_ID) {
                                        setPeriod({option: PERIOD_OPTIONS.filter(current => current.id !== PERIOD_INTRADAY_OPTION_ID)[0]});
                                    }
                                    }}
                                options={SORTING_OPTIONS}
                                selectedDirection={sorting.direction}
                                selectedOption={sorting.option}
                            />
                            <SimpleOptionFilter<OptionItemWithPeriod>
                                options={PERIOD_OPTIONS}
                                selectedOption={period.option}
                                onSelect={v => {
                                    setPeriod({option: v.option})
                                }}
                            />
                        </div>
                    </div>
                    {loading ? <div className="text-center py-2"><Spinner animation="border" /></div> :
                        data && data.searchCrossRate.edges.length > 0 ?
                            <>
                                <Row className="mx-0 d-flex">
                                    {data && data.searchCrossRate.edges
                                        .map(current => current.node)
                                        .filter(current =>
                                            !!current && current.group && current.group.id
                                                && current.group.seoTag && current.group.assetGroup
                                        )
                                        .map(current =>
                                                <Col lg={6} xl={4} xs={12} className="p-lg-2 p-sm-0"
                                                     key={current.group.seoTag}>
                                                    <CrossRateInstrumentCard withDerivativeProducts
                                                         id={current.id} name={current.name}
                                                         homeCurrencyCode={current.group.crossRate?.homeCurrency.displayCode || ""}
                                                         foreignCurrencyCode={current.group.crossRate?.foreignCurrency.displayCode || ""}
                                                         chart={current.group.main?.chart || undefined}
                                                         quote={extractQuote(current.group?.main || undefined, mapPeriod(period.option.field))}
                                                         groupId={current.group.id!}
                                                         seoTag={current.group.seoTag!}
                                                         assetGroup={current.group.assetGroup!}
                                                         className="currency-find-instrument-card"
                                                     />
                                                </Col>
                                        )
                                    }
                                </Row>
                                {
                                    loadingMore ?
                                        <div className="text-center py-2"><Spinner animation="border" /></div>
                                        :
                                        data && data.searchCrossRate?.pageInfo?.hasNextPage &&
                                        <div className="text-center">
                                            <Button variant="link"
                                                onClick={() => {
                                                    trigInfonline(guessInfonlineSection(), getValueForInfolineTag());
                                                    if (!loading && data?.searchCrossRate?.pageInfo?.endCursor) {
                                                        setLoadingMore(true);
                                                        if (fetchMore) {
                                                            fetchMore({
                                                                variables: {
                                                                    first: indexCardsBreakpoint,
                                                                    after: data.searchCrossRate?.pageInfo.endCursor,
                                                                }
                                                            })
                                                                .finally(() => setLoadingMore(false));
                                                        }
                                                    }
                                                }}>
                                                Mehr anzeigen
                                                <SvgImage spanClass="top-move" convert={false} width={"27"}
                                                    icon="icon_direction_down_blue_light.svg"
                                                    imgClass="svg-primary" />
                                            </Button>
                                        </div>
                                }
                            </>
                            : <div className="text-center font-size-16px font-weight-bold text-red">Keine weiteren Ergebnisse gefunden!</div>
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}
