import { useState, useEffect } from 'react';
import { Carousel, Col, Container, Row, Spinner } from "react-bootstrap";
import { useQuery } from "@apollo/client";
import { CarouselWrapper } from 'components/common';
import SvgImage from 'components/common/image/SvgImage';
import { createChunk } from 'components/common/utils';
import { Instrument, CrossRateSortField, Query, ChartScope  } from 'generated/graphql';
import { loader } from 'graphql.macro';
import { FindCurrencyModal } from './FindCurrencyModal';
import { OptionItem } from "../../common/SearchCard/FilterDropdownItem";
import { CurrencyEntriesDropdownComponent, ALL_CURRENCIES, DEFAULT_CURRENCY_OPTION } from '../CurrencyPageDropdown/CurrencyEntriesDropdown';
import { CrossRateInstrumentCard } from './CrossRateInstrumentCard';
import { triggerInfonlineAuto } from "../../common/InfonlineService";
import {useScreenMode, ScreenMode} from "../../../hooks/useMediaQuery";

import './FindIndexSection.scss';
import '../../Home/HotSection/HotSection.scss';
import './CurrencyFindSection.scss';

import { GenericSortFilter, SortDirection } from './filters/GenericSortFilter';
import { SimpleOptionFilter } from './filters/SimpleOptionFilter';
import { OptionItemWithPeriod, PERIOD_OPTIONS, SORTING_OPTIONS, extractQuote, mapPeriod, getPeriodOptions } from './utils';
import { PERIOD_INTRADAY_OPTION_ID, PERFORMANCE_OPTION_ID } from './utils';


function renderOptionItem(opt: OptionItem) {
    if (opt.id === ALL_CURRENCIES) {
        return opt.name;
    }
    return `${opt.name} zu...`
}


export function CurrencyFindSection() {
    const [homeCurrency, setHomeCurrency] = useState<OptionItem>(DEFAULT_CURRENCY_OPTION);
    const [sorting, setSorting] = useState<{option: OptionItemWithPeriod, direction: SortDirection}>({option: SORTING_OPTIONS[0], direction: 'asc'});
    const [period, setPeriod] = useState<{option: OptionItemWithPeriod}>({option: PERIOD_OPTIONS[0]});

    let { loading, data } = useQuery<Query>(
        loader('./getCardPairsCrossRates.graphql'),
        {
            variables: {
                homeCurrencyCode: homeCurrency.id !== ALL_CURRENCIES ? homeCurrency.id : undefined,
                first: 9,
                sortField: sorting.option.field || period.option.field || CrossRateSortField.Name,
                descending: sorting.direction === 'desc',
                chartScope: mapPeriod(period.option.field),
                after: null
            }
        }
    );

    useEffect(
        () => {
            triggerInfonlineAuto(`find_currency_${homeCurrency.id}`);
        },
        [homeCurrency]
    );

    return (
        <section className="main-section mt-0 pt-5 pb-4" id='currency-find-section'>
            <Container className="currency-find-section-container">
                <div className="d-flex flex-wrap" style={{ fontFamily: "Roboto" }}>
                    <div className="flex-grow-1 m-auto pb-sm-2">
                        <h2 className="section-heading font-weight-bold button-styling">Devisen -</h2>
                        <CurrencyEntriesDropdownComponent
                            withAllButton
                            description={renderOptionItem(homeCurrency)}
                            render={renderOptionItem}
                            onSelect={setHomeCurrency}
                        />
                    </div>
                    <div className="filters-panel text-nowrap d-flex ml-2">
                        <GenericSortFilter<OptionItemWithPeriod>
                            onSelect={(v) => {
                                setSorting({option: v.option, direction: v.direction});
                                if (period.option.id === PERIOD_INTRADAY_OPTION_ID && v.option.id === PERFORMANCE_OPTION_ID) {
                                    setPeriod({option: PERIOD_OPTIONS.filter(current => current.id !== PERIOD_INTRADAY_OPTION_ID)[0]});
                                }
                            }}
                            options={SORTING_OPTIONS}
                            selectedDirection={sorting.direction}
                            selectedOption={sorting.option}
                        />
                        <SimpleOptionFilter<OptionItemWithPeriod>
                            options={getPeriodOptions(sorting.option.id)}
                            selectedOption={period.option}
                            onSelect={v => {
                                setPeriod({option: v.option})
                            }}
                        />
                    </div>
                </div>
                <div className=" mx-lg-n2">
                    {loading && <div className="text-center py-2"><Spinner animation="border" /></div> }
                    {!loading &&
                        (!!data && data.searchCrossRate.edges.length > 0 ?
                            <>
                                <CurrencyFindSectionContent
                                    instruments={
                                        data.searchCrossRate.edges
                                            .filter(current => !!current.node)
                                            .map(current => current.node)
                                    }
                                    chartScope={mapPeriod(period.option.field)}
                                />
                                <FindCurrencyModal
                                    currencyCode={homeCurrency.id}
                                    sortingOption={sorting.option}
                                    sortingDirection={sorting.direction}
                                    periodOption={period.option}
                                    showCardFooter={true}
                                    buttonName={"Weitere Devisenkurse..."}
                                    className="pt-2" />
                            </> :
                            <div className="text-center font-size-16px font-weight-bold text-red">
                                Keine weiteren Ergebnisse gefunden!
                            </div>
                        )
                    }
                </div>
            </Container>
        </section>
    );
}

interface CurrencyFindSectionContentProps {
    instruments: Instrument[];
    chartScope: ChartScope;
}

function CurrencyFindSectionContent({instruments, chartScope}: CurrencyFindSectionContentProps) {
    const screenMode = useScreenMode();
    if (screenMode >= ScreenMode.TABLET) {
        return (
            <Row className="mx-0">
                {instruments
                    .slice(0, 9)
                    .map((current: Instrument, index: number) =>
                        <Col lg={6} xl={4} className="p-2" key={index}>
                            <CrossRateInstrumentCard
                                key={current.id}
                                homeCurrencyCode={current.group.crossRate?.homeCurrency.displayCode || ""}
                                foreignCurrencyCode={current.group.crossRate?.foreignCurrency.displayCode || ""}
                                withDerivativeProducts
                                name={current.name}
                                id={current.id}
                                chart={current.group.main?.chart || undefined}
                                quote={extractQuote(current.group?.main || undefined, chartScope)}
                                groupId={current.group.id!}
                                seoTag={current.group.seoTag!}
                                assetGroup={current.group.assetGroup!}
                                className="currency-find-instrument-card font-size-24px"
                            />
                        </Col>
                )}
            </Row>
        )
    }
    return (
        <Carousel
            touch={true}
            prevIcon={
                <SvgImage icon="icon_direction_left_white.svg"
                          spanClass="move-arrow d-none d-xl-block" convert={true} />
            }
            nextIcon={
                <SvgImage icon="icon_direction_right_white.svg"
                          spanClass="move-arrow d-none d-xl-block" convert={true} />
            }
            controls={instruments.length > 4}
            indicators={instruments.length > 4}
            as={CarouselWrapper}
        >
            {
                createChunk(instruments, 4)
                    .map((group: Instrument[], index: number) =>
                        <Carousel.Item key={index} className="pb-5">
                            {
                                group.map(current =>
                                    <CrossRateInstrumentCard
                                        id={current.id}
                                        key={current.group.seoTag}
                                        homeCurrencyCode={current.group.crossRate?.homeCurrency.displayCode || ""}
                                        foreignCurrencyCode={current.group.crossRate?.foreignCurrency.displayCode || ""}
                                        withDerivativeProducts={true}
                                        name={current.name}
                                        chart={current?.group?.main?.chart || undefined}
                                        quote={extractQuote(current.group.main || undefined, chartScope)}
                                        groupId={current.group.id!}
                                        seoTag={current.group.seoTag!}
                                        assetGroup={current.group.assetGroup!}
                                        className="currency-find-instrument-card"
                                    />
                                )
                            }
                        </Carousel.Item>
                    )
            }
        </Carousel>
    )
}
