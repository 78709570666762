import  classNames from "classnames";
import { AssetGroup } from "graphql/types";
import {formatPrice, numberFormatDecimals} from "utils";


interface CrossRateQuoteInformationProps {
    classNames?: string;
    value: number;
    change: number;
    percentChange: number;
}

export function CrossRateQuoteInformation({value, change, percentChange, ...props}: CrossRateQuoteInformationProps) {
    return (
        <div>
            <div className={classNames(props.classNames, "font-weight-bold")}>
                {formatPrice(value, AssetGroup.Cross)}
                <span className="arrow flex-nowrap svg-icon arrow-movement pl-1">
                    {percentChange !== 0 ?
                        (percentChange > 0 ?
                            <img
                                src={process.env.PUBLIC_URL + "/static/img/svg/icon_arrow_long_up_green.svg"}
                                alt="" className="move-arrow-icon pl-1 pb-1" width="24px" /> :
                            <img
                                src={process.env.PUBLIC_URL + "/static/img/svg/icon_arrow_long_down_red.svg"}
                                alt="" className="move-arrow-icon pl-1 pb-1" width="24px" />
                        ) :
                        <img src={process.env.PUBLIC_URL + "/static/img/svg/icon_arrow_short_right_grey.svg"}
                            alt="" className="move-arrow-icon pl-1 pb-1" width="24px" />
                    }
                </span>
            </div>
            <div className="mt-n1 text-truncate font-size-15px">
                <span className={"mr-2 font-weight-bold " + (change > 0 ? 'text-green' : 'text-pink')}>
                    {change > 0 ? "+" : ""}
                    {formatPrice(change, AssetGroup.Cross)}
                </span>
                <span className={"mr-2 " + (percentChange > 0 ? 'text-green' : 'text-pink')}>
                    {percentChange > 0 ? "+" : " "}
                    {numberFormatDecimals(percentChange || 0, 4)}%
                </span>
            </div>
        </div>
    );
}
