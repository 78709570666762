import React, {useEffect, useState} from 'react';
import Collapse from 'react-bootstrap/Collapse';
import {Helmet} from "react-helmet";
import {Breadcrumb, Button, Container, Modal, Spinner} from "react-bootstrap";
import SvgImage from "../common/image/SvgImage";

import './TerminePage.scss'
import CalendarCard from "./CalendarCard";
import Table from 'react-bootstrap/Table';
import classNames from "classnames";
import {DatePickerInput} from "../common/DatePickerInput/DatePickerInput";
import {CategorySelectDropdown, CountrySelectDropdown} from "./TerminePageDropdown";
import {useMediaQuery} from "react-responsive";
import {Link, useLocation} from "react-router-dom";
import {useQuery} from "@apollo/client";
import {loader} from "graphql.macro";
import {CalendarEventEdge, EventRelevance, Query} from "../../graphql/types";
import moment from 'moment';
import Interweave from "interweave";
import InfiniteScroll from "../common/scroller/InfiniteScroller";
import {getFinanztreffAssetLink, numberFormatShort, shortNumberFormat} from 'utils';
import { guessInfonlineSection, trigInfonline } from 'components/common/InfonlineService';

interface TableContentProps {
    name?: string | undefined | null,
    day?: string | undefined | null,
    date?: string | undefined | null,
    event?: string | undefined | null,
    countryId?: string | undefined | null,
    flag?: string | undefined | null,
    relevance?: string | null | undefined,
    company?: string | null | undefined,
    appointment?: string | undefined | null,
    eventType: string | undefined | null,
    seoTag ?: string | null | undefined,
    assetGroup?: string | null | undefined,
}

interface CollapsableTableContent extends TableContentProps {
    time?: string,
    actual?: number | null | undefined,
    forecast?: number | null | undefined,
    previous?: number | null | undefined,
    currencyCode: string | null,
    currencySign: string | null,
    description?: string | null | undefined,
    expand?: boolean | undefined | "",
    id?: string | undefined
}

interface EventDateEntry {
    key?: string | null | undefined,
    date?: string | null | undefined,
    value?: CollapsableTableContent[]
}

const TerminePageTableContent = (props: TableContentProps) => {
    const isDesktop = useMediaQuery({
        query: '(min-width: 1100px)'
    });

    const isTablet = useMediaQuery({
        query: '(max-width: 1099px)'
    })

    const isMobile = useMediaQuery({
        query: '(max-width: 698px)'
    })



    return (
        <>
            <tr className={classNames(isMobile || 'border-bottom-1 border-border-gray')}>
                <td className={classNames("termine-page-table-region", isMobile && "m-termine-page-table-body-flag-container")}>
                    <span><img
                        src={process.env.PUBLIC_URL + "/static/img/svg/flags/" + props?.flag?.toLowerCase() + ".svg"}
                        alt="img" width="24px" height="16px"
                        className="termine-page-table-flag"/></span><span> {props.countryId}</span></td>
                <td className={classNames("termine-page-table-relevence relevanceColor")}>{props.relevance}</td>
                <td className={classNames("termine-page-table-company", !isDesktop && "d-none")}>
                <Link to={(getFinanztreffAssetLink(props?.assetGroup ? props?.assetGroup : "", props?.seoTag ? props?.seoTag : ""))}>{props.company}</Link>
                </td>
                <td
                    className={classNames("termine-page-table-event", isDesktop ? "termine-page-table-event-wide" : "d-flex flex-column", !isMobile && isTablet ? "font-weight-normal" : "font-weight-bold")}>
                    {isDesktop || <span className="termine-page-table-company"><Link to={(getFinanztreffAssetLink(props?.assetGroup ? props?.assetGroup : "", props?.seoTag ? props?.seoTag : ""))}>{props.company}</Link></span>}
                    {isMobile ||
                        <span className={classNames(isTablet && "font-weight-bold")}>{props.appointment}</span>}
                </td>
            </tr>
            {
                isMobile &&
                <tr className={"border-bottom-1 border-border-gray m-termine-page-table-body-termin-row"}>
                    <td className="pt-0 font-weight-bold" colSpan={3}>
                        <span className="m-termine-page-table-termin-text"
                              style={{fontWeight: "bold"}}>{props.appointment}</span>
                    </td>
                </tr>
            }
        </>
    )
}

function formatEventValue(value: number | null | undefined, currencyCode: string | null, currencySign: string | null) {
    if (value !== undefined && value !== null) {
        if (currencyCode == "XXZ") {
            return value + (currencySign && " " + currencySign || "");
        }
        return shortNumberFormat(value) + (currencySign && " " + currencySign || "");
    }
    return "--";
}

const TerminePageCollapsableTable = (props: CollapsableTableContent) => {
    const [open, setOpen] = useState(false);
    const isDesktop = useMediaQuery({
        query: '(min-width: 1100px)'
    });

    const isTablet = useMediaQuery({
        query: '(max-width: 1099px)'
    })

    const isMobile = useMediaQuery({
        query: '(max-width: 698px)'
    })

    const toggleIcon = <img src={process.env.PUBLIC_URL + "/static/img/svg/icon_direction_down_dark.svg"}
                            className="toggle-icon" alt="Dropdown arrow down"/>;
    const thumbUpIcon = <img src={process.env.PUBLIC_URL + "/static/img/icon_thumb_up_green.svg"}
                             style={{height: "100%", width: "100%"}} alt="Thumb up icon"/>
    const thumbDownIcon = <img src={process.env.PUBLIC_URL + "/static/img/icon_thumb_down_red.svg"}
                               style={{height: "100%", width: "100%"}} alt="Thumb down icon"></img>

    const thumbGrey = <img src={process.env.PUBLIC_URL + "/static/img/icon_thumb_up_grey.svg"}
                           style={{height: "100%", width: "100%"}} alt="Thumb up icon"/>

    const relevanceColor = props?.relevance && (props.relevance === "Hoch" ? "relevance-high" : props.relevance === "Niedrig" ? "relevance-low" : "relevance-medium");

    useEffect(() => {
        if (props?.expand === true) {
            setOpen(true);
        }
    }, [props?.expand]);

    useEffect(() => {
        if (props?.expand === true) {
            const target = document.getElementsByClassName("termine-page-collapsable-table-border-top-dark")[0];
            if (target) {
                target.scrollIntoView({behavior: "smooth", block: isMobile ? "start" : "center"});
            }
        }
    });

   

    return (
        <>
            <tr
                className={classNames("cursor-pointer", open ? "termine-page-collapsable-table-border-top-dark " : "termine-page-collapsable-table-border-light")}
               onClick={() => {setOpen(!open);trigInfonline(guessInfonlineSection(),"termine_page")}}>
                <td className="termine-page-collapsable-table-body-time"><span>{props.time}</span></td>
                <td className={classNames(isMobile ? "m-termine-page-table-region flex-column" : "termine-page-table-region flex-column", isTablet && "t-termine-page-collapsable-table-body-region flex-column pr-md-0 pl-md-0")}>
                    <span style={{width: "100px"}}>
                    <img src={process.env.PUBLIC_URL + "/static/img/svg/flags/" + props?.flag?.toLowerCase() + ".svg"}
                         alt="flag_icon" width="24px" height={isTablet ? "12.64px" : "16px"}
                         className={classNames( isTablet  ? "t-termine-page-collapsable-table-flag" :"termine-page-table-flag")} />
                        {props.countryId ? props.countryId : "--"}</span>
                </td>
                <td className="termine-page-table-relevence">
                    <div className="d-flex justify-content-between">
                        <span className={classNames(relevanceColor)}>{props.relevance ? props.relevance : "--"}</span>
                        <span
                            className={classNames(!isMobile && "d-none", open ? "termine-page-collapsable-table-event-icon_upward" : "termine-page-collapsable-table-event-icon_downward")}
                            onClick={() => {setOpen(!open)}}
                            aria-controls="example-collapse-text"
                            aria-expanded={open}>
                         {toggleIcon}
                     </span>
                    </div>
                </td>
                {isMobile ||
                    <td className={classNames("termine-page-collapsable-table-event font-weight-bold", isTablet && "t-termine-page-collapsable-table-description-event")}>
                        <div className={classNames("d-flex", isTablet && "justify-content-between")}>
                    <span className="termine-page-table-termine-text">
                         {props.appointment}
                        </span>
                            <span
                                className={classNames(open ? "termine-page-collapsable-table-event-icon_upward" :
                                        "termine-page-collapsable-table-event-icon_downward", isDesktop && "d-none",
                                    isTablet && open ? "t-termine-page-collapsable-table-description-event-icon-downward" : "t-termine-page-collapsable-table-description-event-icon-upward")}
                                onClick={() => {setOpen(!open)}}
                                aria-controls="example-collapse-text"
                                aria-expanded={open}>
                         {toggleIcon}
                     </span>
                        </div>
                    </td>}
                <td style={{textAlign: "end"}}
                    className={classNames("termine-page-table-data", isDesktop || "d-none")}>{formatEventValue(props.actual, props.currencyCode, props.currencySign)}</td>
                <td style={{textAlign: "end"}}
                    className={classNames("termine-page-table-data", isDesktop || "d-none")}>{formatEventValue(props.forecast, props.currencyCode, props.currencySign)}</td>
                <td style={{textAlign: "end"}}
                    className={classNames("termine-page-table-data", isDesktop || "d-none")}>
                    {formatEventValue(props.previous, props.currencyCode, props.currencySign)}
                </td>
                <td className={classNames(isDesktop ? "termine-page-collapsable-table-event-icon-container" : "d-none")}>
                    <span
                        className={classNames("termine-page-collapsable-table-event-icon", open ? "termine-page-collapsable-table-event-icon_upward" : "termine-page-collapsable-table-event-icon_downward")}
                        onClick={() => {setOpen(!open);}}
                        aria-controls="example-collapse-text"
                        aria-expanded={open}>
                         {toggleIcon}
                     </span>
                </td>
            </tr>
            {
                isDesktop &&
                <tr
                    className={classNames("termine-page-collapsable-table-dropdown-row", open ? "termine-page-collapsable-table-border-bottom-dark" : "d-none")}>
                    <td></td>
                    <td></td>
                    <td></td>
                    <Collapse in={open}>
                        <td className="termine-page-collapsable-table-description-cell">

                            <div
                                style={{lineHeight: "18px"}}
                                id="example-collapse-text"
                                className="termine-page-table-event-description font-weight-normal align-self-end px-10">
                                <div className="mr-2">
                                    <Interweave content={props?.description}/>
                                </div>
                            </div>

                        </td>
                    </Collapse>

                    <td colSpan={3}
                        className={classNames(open || "d-none", "termine-page-collapsable-table-indicator")}>
                        {(props.actual !== undefined && props.actual !== null && props.forecast !== undefined && props.forecast !== null) && (

                            (props?.actual > props?.forecast) ?

                                <div className="termine-page-indicator-green d-flex justify-content-between">
                                    <span style={{paddingTop: "5px"}}> Prognose übertroffen </span>
                                    <div style={{marginRight: "15px"}}
                                         className="termine-page-indicator-icon">{thumbUpIcon}</div>
                                </div>

                                : (props.actual < props.forecast) ?

                                    <div className="termine-page-indicator-red d-flex justify-content-between">
                                        <span>Prognose nicht erreicht</span>
                                        <div className="termine-page-indicator-icon">{thumbDownIcon}</div>
                                    </div>

                                    :
                                    <div className="termine-page-indicator-grey d-flex justify-content-between">
                                        <span style={{paddingTop: "5px"}}>Prognose getroffen</span>
                                        <div className="termine-page-indicator-icon">{thumbGrey}</div>
                                    </div>
                        )
                        }
                    </td>
                </tr>
            }
            {isTablet && !isMobile &&
                <tr className={classNames(open ? "termine-page-collapsable-table-border-bottom-dark" : "d-none")}>
                    <td colSpan={4} style={{paddingBottom: "20px"}}>
                        <Collapse in={open}>
                            <div id="example-collapse-text"
                                 className="font-weight-normal">
                                <div
                                    className="d-flex t-termine-page-collapsable-table-description-container">
                                    <span
                                        className="t-termine-page-collapsable-table-description-container-text">
                                        <Interweave content={props?.description}/>
                                    </span>
                                    <div
                                        className="t-termine-page-collapsable-table-description-container-table">
                                        <Table
                                            className="t-termine-page-collapsable-table-description-container-table-table">
                                            <thead>
                                            <tr className="t-termine-page-collapsable-table-description-container-table-header">
                                                <th>Aktuell</th>
                                                <th>Prognose</th>
                                                <th>Vorherig</th>
                                            </tr>
                                            </thead>
                                            <tbody
                                                className="t-termine-page-collapsable-table-description-container-table-body">
                                            <tr className=" termine-page-table-data">
                                                <td className="p-0">{formatEventValue(props.actual, props.currencyCode || null, props.currencySign || null)}</td>
                                                <td className="p-0">{formatEventValue(props.forecast, props.currencyCode || null, props.currencySign || null)}</td>
                                                <td className="p-0">{formatEventValue(props.previous, props.currencyCode || null, props.currencySign || null)}</td>
                                            </tr>
                                            </tbody>
                                        </Table>
                                        {
                                            (props.actual !== undefined && props.actual !== null && props.forecast !== undefined && props.forecast !== null) && (
                                                (props.actual > props.forecast) ?
                                                    <div
                                                        className="t-termine-page-indicator-green d-flex justify-content-around">
                                                        <span style={{paddingTop: "7px"}}> Prognose übertroffen </span>
                                                        <div style={{marginRight: "16px"}}
                                                             className="termine-page-indicator-icon">{thumbUpIcon}</div>
                                                    </div>
                                                    : (props.actual < props.forecast) ?
                                                        <div
                                                            className="t-termine-page-indicator-red d-flex justify-content-around">
                                                            <span>Prognose nicht erreicht</span>
                                                            <div className="termine-page-indicator-icon">{thumbDownIcon}</div>
                                                        </div>
                                                        :
                                                        <div
                                                            className="t-termine-page-indicator-grey d-flex justify-content-around">
                                                            <span style={{paddingTop: "5px"}}>Prognose getroffen</span>
                                                            <div className="termine-page-indicator-icon">{thumbGrey}</div>
                                                        </div>
                                            )}
                                    </div>

                                </div>
                            </div>
                        </Collapse>
                    </td>
                </tr>
            }
            {
                isMobile &&
                <tr className={classNames(open && "termine-page-collapsable-table-border-bottom-dark")}>
                    <td className={classNames("m-termine-page-collapsable-table-termin-text")} colSpan={3}>
                        <div>{props.appointment}</div>

                        <Collapse in={open}>
                            <div id="example-collapse-text"
                                 className="m-termine-page-collapsable-table-description font-weight-normal">
                                <div className={classNames("d-flex flex-column")}>
                                    <span
                                        className="termine-page-table-description-text"><Interweave
                                        content={props?.description}/></span>
                                    <div
                                        className="m-termine-page-collapsable-table-description-table-container d-flex justify-content-center align-items-center flex-column">
                                        <Table className="table-borderless" style={{width: "270px"}}>
                                            <thead>
                                            <tr className="m-termine-page-collapsable-table-description-table-header">
                                                <th>Aktuell</th>
                                                <th>Prognose</th>
                                                <th>Vorherig</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr className="m-termine-page-collapsable-table-description-table-body">
                                                <td>{formatEventValue(props.actual, props.currencyCode || null, props.currencySign || null)}</td>
                                                <td>{formatEventValue(props.forecast, props.currencyCode || null, props.currencySign || null)}</td>
                                                <td>{formatEventValue(props.previous, props.currencyCode || null, props.currencySign || null)}</td>
                                            </tr>
                                            </tbody>
                                        </Table>
                                        {(props.actual !== undefined && props.actual !== null && props.forecast !== undefined && props.forecast !== null) && (
                                            (props.actual > props.forecast) ?
                                                <div
                                                    className="m-termine-page-collapsable-table-description-indicator-green d-flex">
                                                    <span className="mt-1"> Prognose übertroffen </span>
                                                    <div
                                                        className="termine-page-indicator-icon ml-2">{thumbUpIcon}</div>

                                                </div>
                                                :
                                                props.actual < props.forecast ?

                                                    <div
                                                        className="m-termine-page-collapsable-table-description-indicator-red d-flex">
                                                        <span>Prognose nicht erreicht</span>
                                                        <div
                                                            className="termine-page-indicator-icon ml-2">{thumbDownIcon}</div>
                                                    </div> :
                                                    <div
                                                        className="m-termine-page-collapsable-table-description-indicator-grey d-flex">
                                                        <span style={{paddingTop: "5px"}}>Prognose getroffen</span>
                                                        <div
                                                            className="termine-page-indicator-icon ml-2">{thumbGrey}</div>
                                                    </div>

                                        )}
                                    </div>

                                </div>
                            </div>
                        </Collapse>
                    </td>

                </tr>
            }
        </>
    )
}


export default function TerminePage() {

    const [miniCardState, setMiniCardState] = useState<{ day: string, date: string, count: number | undefined | null, details: moment.Moment }[]>();
    const [cardStateForTablet, setCardStateForTablet] = useState<{ day: string, date: string, count: number | undefined | null, details: moment.Moment }[]>();
    const [cardStateForMobile, setCardStateForMobile] = useState<{ day: string, date: string, count: number | undefined | null, details: moment.Moment }[]>();
    let [loadingMore, setLoadingMore] = useState(false);
    const [country, setCountry] = useState<{ name: string | undefined | null, id: string | undefined | null }>({
        name: null,
        id: null
    });
    const [category, setCategory] = useState<{ name: string | undefined | null, id: string | undefined | null }>({
        name: null,
        id: null
    });
    const [relevance, setRelevance] = useState<EventRelevance[]>([EventRelevance.Low, EventRelevance.Medium, EventRelevance.High]);

    const [showFilterModal, setShowFilterModal] = useState(false);
    const [homePageEventID , setHomePageEventId] = useState("");
    const [eventCount, setEventCount] = useState(60);

    const location: any = useLocation();

    const isDesktop = useMediaQuery({
        query: '(min-width: 1100px)'
    });
    const isTablet = useMediaQuery({
        query: '(max-width: 1099px)'
    })
    const isMobile = useMediaQuery({
        query: '(max-width: 698px)'
    })


    const calendarState =
        {
            currentWeekStartDate: moment().startOf("week"),
            currentWeekEndDate: moment().endOf("week"),
            nextWeekStartDate: moment().clone().add(1, "week").startOf("week"),
            nextWeekEndDate: moment().clone().add(1, "week").endOf("week"),
            monthStartDate: moment(new Date()).startOf("month"),
            monthEndDate: moment(new Date()).endOf("month"),
            presentDay: moment(),
            minDate : moment().clone().subtract(30,"day"),
            maxDate: moment().clone().add(30, "day")
        };

    const [calendarInputState, setCalendarInputState] = useState(
        {
            from: calendarState.presentDay,
            to: calendarState.presentDay
        });

    let {data: calendarSummary, loading: calendarSummaryLoading} = useQuery<Query>(
        loader('./calendarEventSummary.graphql'),
        {
            variables: {
                from: calendarInputState.from.format("YYYY-MM-DD"),
                to: calendarInputState.to.format("YYYY-MM-DD")
            }
        }
    );

    function handleRelevance(type: EventRelevance) {
        let data: EventRelevance[] = relevance;
        let currentRelevances = relevance.filter(item => item === type);
        if (currentRelevances.length === 0) {
            let existingEvents: EventRelevance[] = data
            existingEvents.push(type);
            setRelevance(existingEvents);
        } else {
            let filteredEvents: EventRelevance[] = data.filter((item: string) => item !== type)
            setRelevance(filteredEvents)
        }
        refetch();
    }

    let {
        data: eventData,
        loading: loadingEvents,
        refetch,
        fetchMore
    } = useQuery<Query>(loader('./searchCalendarEvents.graphql'), {
        variables: {
            first: eventCount ,
            after: null,
            from: calendarInputState.from.format("YYYY-MM-DD"),
            to: calendarInputState.to.format("YYYY-MM-DD"),
            countryId: country.id,
            categoryId: category.id,
            relevance: relevance
        }
    });
    const loadMoreEvents = () => {
        if (!loadingEvents && eventData && eventData.searchCalendarEvents && eventData.searchCalendarEvents.pageInfo) {
            const endCursor = eventData.searchCalendarEvents.pageInfo?.endCursor;
            setLoadingMore(true);
            fetchMore && fetchMore({variables: {after: endCursor}}).finally(() => setLoadingMore(false));
        }
    }
    const weekNumber = (date: string): number => {
        return moment(date).weeks();
    }


    const totalEvents: number | undefined = calendarSummary?.calendarEventSummary?.reduce((total, current) => (total + (current?.count ? current.count : 0)), 0);

    const getNextDay = (today : moment.Moment) : number => {

        if( today.format("dddd") === "Freitag" ) return 3 ;
        else if ( today.format("dddd") === "Samstag" ) return 2 ;
        else return 1 ;
    }

    const getPreviousDay = (today : moment.Moment) : number => {

        if( today.format("dddd") === "Sonntag" ) return  2;
        else if (today.format("dddd") === 'Montag') return 3;
        else return 1;
    }

    const initialiseWeekMiniCards = () => {
        const today = moment();
        const yesterday = today.clone().subtract(getPreviousDay(today),"day");
        const tomorrow = today.clone().add(getNextDay(today),"day");
        const dayAfterTomorrow = tomorrow.clone().add(getNextDay(tomorrow),"day");
        const lastDay = dayAfterTomorrow.clone().add(getNextDay(dayAfterTomorrow), "day");
        const week = [yesterday, today ,tomorrow, dayAfterTomorrow, lastDay];
        const weekFinalState = week.map((data)=>(
            {
                date : data.format("D"),
                day : data.format("dddd"),
                count:0,
                details: data
            }
            )
        )
        setMiniCardState(weekFinalState);
        setCardStateForTablet(weekFinalState.slice(0,-1));
        setCardStateForMobile(weekFinalState.slice(0,-2));
    }

    useEffect(() => {
        initialiseWeekMiniCards();
    }, [])


    useEffect(() => {
        if (location.state && location.state?.scrollToTop) {
            if (window.pageYOffset > 400) {
                window.scrollTo({top: 0, behavior: 'auto'})
            }
        }
        if( location?.state && location?.state?.eventId){
            const startDate : string = location?.state?.eventDate || "";
            const endDate : string = location?.state?.eventDate || "" ;
            const count : number | undefined | null = location?.state?.eventCount ;
            setEventCount(count ? count : 60);
            setCalendarInputState({from:moment(startDate), to:moment(endDate)})
            setHomePageEventId(location?.state?.eventId);
            refetch();
        }
    }, [location]);

    useEffect(() => {
        if (location?.state) {
            window.history.replaceState({}, "");
        }
    }, []);
    const activeMobileFilters = (): number => {
        let count = 0;
        if (country.name) count++;
        if (category.name) count++;
        count += relevance.length;
        return count;
    }

    function mapToCalendarEvent(element: CalendarEventEdge): CollapsableTableContent {
        const eventTime = moment.utc(element?.node?.when).local();
        return {
            id: element?.node?.id?.toString(),
            name: element?.node?.category?.name,
            day: eventTime.format("dddd"),
            date: eventTime.format("DD.MM.YYYY"),
            time: eventTime.format("HH:mm"),
            countryId: element?.node?.country?.id,
            event: element?.node?.title,
            description: element?.node?.description,
            actual: element?.node?.actual,
            previous: element?.node?.previous,
            forecast: element?.node?.consensus,
            relevance: element?.node?.relevance,
            eventType: element?.node?.eventType,
            currencyCode: element.node.currency?.id || null,
            currencySign: element.node.currency?.currency?.sign || null,
            company: element?.node?.products?.length && element?.node?.products[0]?.group?.name ? element?.node?.products[0]?.group?.name : "--",
            seoTag : element?.node?.products?.length && element?.node?.products[0]?.group?.seoTag ? element?.node?.products[0]?.group?.seoTag : "",
            assetGroup : element?.node?.products?.length && element?.node?.products[0]?.group?.assetGroup ? element?.node?.products[0]?.group?.assetGroup : "",
        }
    }

    const getEventData = (): EventDateEntry[] => {
        if (!loadingEvents && eventData?.searchCalendarEvents?.edges) {
            const data  = eventData?.searchCalendarEvents?.edges
            let result: EventDateEntry[] = [];
            data?.forEach(element => {
                let key = moment.utc(element?.node?.when).local().format("YYYY-MM-DD");
                let displayDate = moment.utc(element?.node?.when).local().format("dddd, DD.MM.YYYY");
                let currentItem = result.find(value => value.key === key);
                if (currentItem) {
                    currentItem?.value?.push(mapToCalendarEvent(element));
                } else {
                    let valueArr = [];
                    valueArr.push(mapToCalendarEvent(element));
                    let item = {key: key, date: displayDate, value: valueArr} as EventDateEntry;
                    result.push(item);
                }
            })
            return result;
        }
        return [];
    }


    const eventTableData = getEventData();
    const relevanceString = (relevance: string | null | undefined): string => {

        return (relevance === "HIGH" ? "Hoch" : relevance === "MEDIUM" ?
            "Mittel" : relevance === "LOW" ? "Niedrig" : "--")
    }
    const isExpanded = (eventId : string | null | undefined ) : boolean => {
        return (eventId === homePageEventID) ? true : false ;
    }

    useEffect(() => {
        trigInfonline(guessInfonlineSection(), "termine_page");
    }, [])

    useEffect(() => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.id = 'refresh-script';
        script.innerHTML = 'Ads_BA_refresh();';
        document.body.appendChild(script);


    }, []);
    

    return (
        <main className="page-container mb-8px termine-page-content">
            <Helmet>
                <title>finanztreff.de - Termine</title>

            </Helmet>

            <div className={classNames(isTablet || "pb-lg-5", "page-header pb-sm-4")}>
                <Container className="pt-2 d-md-flex justify-content-between ">
                    {isMobile || <Breadcrumb>
                        <Breadcrumb.Item href="#">Indizes</Breadcrumb.Item>
                        <Breadcrumb.Item href="#">Termine</Breadcrumb.Item>
                    </Breadcrumb>
                    }
                    <div className={classNames(isDesktop ? "action-icons-holder d-flex mb-sm-2" : "d-none")}>
                        <SvgImage icon={"icon_share_white.svg"} convert={false} spanClass={"share-butt-icon"}/>

                    </div>
                </Container>
                <div
                    className={classNames(isMobile ? "m-termine-page-header-content" : isTablet ? "t-termine-page-header-content" : "termine-page-header-content")}>
                    <h1 className="text-light pb-xl-1 mt-xl-n1">Unternehmenstermine und
                        Wirtschaftskalender </h1>

                    <div className={classNames("d-flex justify-content-between mb-xl-1", isDesktop || "flex-wrap", "inner-container-width")}>

                        <div
                            className={classNames("d-flex justify-content-between", isDesktop || "w-100 justify-content-between", "width-75")}>
                            <div
                                className={classNames("d-flex justify-content-between", isMobile ? "m-termine-page-calender-card" : isTablet ? "t-termine-page-calender-card" : "termine-page-calender-card")}>
                                {

                                    (isMobile ? cardStateForMobile : isTablet ? cardStateForTablet : miniCardState)?.map((item,index) => (
                                        <CalendarCard
                                            key={index}
                                            day={item.day}
                                            date={item.date}
                                            setCalendarInputState={setCalendarInputState}
                                            isWeekCard={false}
                                            isActive={item.details.format("YYYY-MM-DD") === calendarInputState.from.format("YYYY-MM-DD") &&
                                                item.details.format("YYYY-MM-DD") === calendarInputState.to.format("YYYY-MM-DD")}
                                            currentDate={item.details}
                                            isCurrentDay={index === 1}
                                        />

                                    ))
                                }
                            </div>
                            <div className={classNames(isMobile ? "mr-6 ml-6" : "d-none")}
                                 style={{borderLeft: "2px solid #6B6B6B"}}></div>
                            <div
                                className={classNames("d-flex justify-content-between", isMobile ? "m-termine-page-calender-card-description" : isTablet ? "t-termine-page-calender-card-description" : "termine-page-calender-card-description")}>
                                <span className="t-termine-page-calender-card-description-element">
                                <CalendarCard day={isMobile ? "KW" : "Diese Woche"}
                                              description={isMobile ? `${weekNumber(calendarState.currentWeekStartDate.format("YYYY-MM-DD"))}` :
                                                  `KW ${weekNumber(calendarState.currentWeekStartDate.format("YYYY-MM-DD"))}`}
                                              wideCard={isTablet}
                                              isWeekCard={true}
                                              weekInputDates={{
                                                  start: calendarState.currentWeekStartDate,
                                                  end: calendarState.currentWeekEndDate
                                              }}
                                              setCalendarInputState={setCalendarInputState}
                                              isActive={calendarState.currentWeekStartDate.format("YYYY-MM-DD") === calendarInputState.from.format("YYYY-MM-DD") &&
                                                  calendarState.currentWeekEndDate.format("YYYY-MM-DD") === calendarInputState.to.format("YYYY-MM-DD")}


                                />
                                </span>
                                <span className="t-termine-page-calender-card-description-element">
                                <CalendarCard day={isMobile ? "KW" : "Nachste Woche"}
                                              description={isMobile ? `${weekNumber(calendarState.nextWeekStartDate.format("YYYY-MM-DD"))}` :
                                                  `KW ${weekNumber(calendarState.nextWeekStartDate.format("YYYY-MM-DD"))}`}
                                              wideCard={isTablet}
                                              isWeekCard={true}
                                              weekInputDates={{
                                                  start: calendarState.nextWeekStartDate,
                                                  end: calendarState.nextWeekEndDate
                                              }}
                                              setCalendarInputState={setCalendarInputState}
                                              isActive={calendarState.nextWeekStartDate.format("YYYY-MM-DD") === calendarInputState.from.format("YYYY-MM-DD") &&
                                                  calendarState.nextWeekEndDate.format("YYYY-MM-DD") === calendarInputState.to.format("YYYY-MM-DD")}
                                />
                                    </span>
                            </div>
                        </div>
                        <div className={
                            classNames(
                                isMobile ? "d-none" : isTablet ?
                                    "t-termine-page-header-content-card d-flex justify-content-between align-items-center p-2 "
                                    : "termine-page-header-content-card d-flex flex-column align-items-center ml-2")}
                        >
                            <span className="card-heading">Eigener Zeitraum</span>
                            <div
                                className={classNames(isTablet ? "t-termine-page-datepicker-container pr-2 mr-4" : "w-100 px-2", "d-flex justify-content-between")}>
                                <div
                                    className={classNames("d-flex", isDesktop ? "flex-column" : "justify-content-center align-items-center")}>
                                    <span
                                        className={classNames("termine-page-header-content-card-date-label", isTablet && "pt-1 mr-1")}> von </span>
                                    <span className="termine-page-header-content-card-date">
                                    <DatePickerInput height={'29px'} width={'114px'} customBorderRadius={'3px'}
                                                     isTerminePage={true}
                                                     format="DD.MM.YYYY"
                                                     value={calendarInputState.from}
                                                     minDate = {calendarState.minDate}
                                                     maxDate = {calendarState.maxDate}
                                                     onChange={value => {
                                                        trigInfonline(guessInfonlineSection(),"termine_page");

                                                         setCalendarInputState({...calendarInputState, from: value})
                                                         if(homePageEventID) {
                                                             setHomePageEventId("");
                                                         }
                                                     }}
                                    ></DatePickerInput>
                                    </span>
                                </div>
                                <div
                                    className={classNames("d-flex", isDesktop ? "flex-column" : "justify-content-center align-items-center ")}>
                                    <span
                                        className={classNames("termine-page-header-content-card-date-label", isTablet && "pt-1 mr-1")}>bis</span>
                                    <span className="termine-page-header-content-card-date">
                                        <DatePickerInput height={'29px'} width={'114px'} customBorderRadius={'3px'}
                                                         isTerminePage={true} format="DD.MM.YYYY"
                                                         value={calendarInputState.to}
                                                         minDate = {calendarState.minDate}
                                                         maxDate = {calendarState.maxDate}
                                                         onChange={value => {
                                                            trigInfonline(guessInfonlineSection(),"termine_page");

                                                             setCalendarInputState({...calendarInputState, to: value})
                                                             if(homePageEventID) {
                                                                 setHomePageEventId("");
                                                             }
                                                         }} 
                                        ></DatePickerInput>
                                    </span>

                                </div>
                            </div>
                            {isTablet ? <span className="card-value">{totalEvents} Termine</span> :

                                (
                                    <span className="card-value"> {totalEvents}</span>
                                )
                            }
                        </div>

                    </div>
                </div>
            </div>
            <section
                className={classNames("main-section  mt-0", isMobile ? "m-content-section" : isTablet && "t-content-section pt-0 mt-2")}>
                <div className="container termine-page-container">
                    <div
                        className={classNames("d-flex justify-content-between align-items-center ", isTablet && !isMobile && "flex-column", isMobile && "px-2 my-n2")}>
                        <h2 className={classNames(isMobile ? "m-termine-page-section-heading" : "termine-page-section-heading", isTablet ? "mt-n1" : "my-n2", isTablet && !isMobile && " align-self-baseline order-2")}>
                            { (!calendarSummaryLoading && eventData?.searchCalendarEvents?.count) ? eventData?.searchCalendarEvents?.count  : 0   } Ergebnisse
                        </h2>
                        {
                            isMobile && <div className={'pt-4 pl-4 pb-0 pr-1'}>
                                <Button variant={'primary'} className={'customFilterBtn'}
                                        onClick={() => {
                                            trigInfonline(guessInfonlineSection(),"termine_page");
                                            setShowFilterModal(!showFilterModal)
                                        }}>

                                    <div className={'d-flex'}>
                                        <div className={'py-2'}>
                                            <div className="my-auto">
                                                <SvgImage spanClass="float-left" icon={"icon_filter_white.svg"}
                                                          convert={true} imgClass="svg-white"
                                                          style={{height: 30, width: 30}}/>
                                            </div>
                                        </div>
                                        <div className={'d-flex flex-column px-1'}>
                                            <div>
                                                <h6 className={'mb-0'}>Filter</h6>
                                            </div>
                                            <div>
                                                <p className={'font-size-16px'}> {activeMobileFilters()} aktiv </p>
                                            </div>
                                        </div>
                                    </div>
                                </Button>
                                <Modal show={showFilterModal} scrollable
                                       onHide={() => {
                                           setShowFilterModal(!showFilterModal)
                                       }}
                                       transition={Collapse}
                                       className="inner-modal profile-modals bottom modal-dialog-sky-placement"
                                       dialogClassName={"mw-100 bg-white termine-custom-modal"}
                                >
                                    <Modal.Header className="border-0">
                                        <div className="d-flex justify-content-between w-100">
                                            <h5 className="modal-title">{"Ergebnisse filtern"}</h5>
                                            <Button variant={'inline'} className="close text-blue"
                                                    onClick={() => {
                                                        trigInfonline(guessInfonlineSection(),"termine_page");
                                                        setShowFilterModal(!showFilterModal)
                                                    }} id="close-button">
                                                <span className="pb-2">schließen</span>
                                                <span className="close-modal-butt svg-icon mr-1 mt-1 pb-n1">
                                    <img src={process.env.PUBLIC_URL + "/static/img/svg/icon_close_blue.svg"}
                                         alt="schliessen" className="svg-convert svg-blue" width={"25"}/>
                                </span>
                                            </Button>
                                        </div>
                                    </Modal.Header>
                                    <Modal.Body className="bg-white py-0 px-1">
                                        <div
                                            className="m-termine-page-modal d-flex mt-4 flex-column align-items-center">
                                            <h2 className="m-termine-page-modal-heading">{"Relevanz"}</h2>
                                            <div className="d-flex justify-content-between w-100 mb-4">

                                                <Button variant={'inline'}
                                                        className={classNames(" btn termine-page-button", relevance.filter(item => item === EventRelevance.Low).length === 0 ? "termine-page-button-inactive" : "termine-page-button-active-negative")}
                                                        onClick={() => {handleRelevance(EventRelevance.Low); trigInfonline(guessInfonlineSection(),"termine_page")}}>
                                                    Niedrig
                                                </Button>
                                                <Button variant={'inline'}
                                                        className={classNames(" btn  termine-page-button", relevance.filter(item => item === EventRelevance.Medium).length === 0 ? "termine-page-button-inactive" : "termine-page-button-active-medium")}
                                                        onClick={() => {handleRelevance(EventRelevance.Medium); trigInfonline(guessInfonlineSection(),"termine_page")}}>
                                                    Mittel
                                                </Button>
                                                <Button variant={'inline'}
                                                        className={classNames(" btn termine-page-button", relevance.filter(item => item === EventRelevance.High).length === 0 ? "termine-page-button-inactive" : "termine-page-button-active-heigh")}
                                                        onClick={() => {handleRelevance(EventRelevance.High); trigInfonline(guessInfonlineSection(),"termine_page")}}>
                                                    Hoch
                                                </Button>
                                            </div>
                                            <div className="w-100">
                                                <CategorySelectDropdown category={category}
                                                                        setCategory={setCategory} 
                                                                        />
                                                <CountrySelectDropdown country={country}
                                                                       setCountry={setCountry}
                                                                       />
                                            </div>

                                        </div>
                                        <div className="d-flex justify-content-end py-2 bg-white">
                                            <Button variant={'inline-action'}
                                                    className="m-termine-page-modal-footer-button mb-4"
                                                    onClick={() => {
                                                        trigInfonline(guessInfonlineSection(),"termine_page");
                                                        refetch();
                                                        setShowFilterModal(!showFilterModal)
                                                    }}>

                                                <img className="check_icon mr-1"
                                                     src={process.env.PUBLIC_URL + "/static/img/svg/icon_check_hook_green.svg"}
                                                     width="19" height="21" alt="Green check icon"/>
                                                <span>Ergebnisse anzeigen</span>
                                            </Button>
                                        </div>
                                    </Modal.Body>

                                </Modal>
                            </div>
                        }
                        <div
                            className={classNames(isMobile ? "d-none" :
                                "d-flex justify-content-between align-items-center termine-page-filter-button-section mb-xl-n2", isTablet && "align-self-end mb-3")}>
                            <h2> Relevanz </h2>
                            <div
                                className={classNames("d-flex justify-content-between align-content-center termine-page-button-container")}>

                                <Button variant={'inline'}
                                        className={classNames(" btn termine-page-button", relevance.filter(item => item === EventRelevance.Low).length === 0 ? "termine-page-button-inactive" : "termine-page-button-active-negative")}
                                        onClick={() => {handleRelevance(EventRelevance.Low); trigInfonline(guessInfonlineSection(),"termine_page")}}>
                                        Niedrig
                                </Button>
                                <Button variant={'inline'}
                                        className={classNames(" btn  termine-page-button", relevance.filter(item => item === EventRelevance.Medium).length === 0 ? "termine-page-button-inactive" : "termine-page-button-active-medium")}
                                        onClick={() => {handleRelevance(EventRelevance.Medium); trigInfonline(guessInfonlineSection(),"termine_page")}}>
                                        Mittel
                                </Button>
                                <Button variant={'inline'}
                                        className={classNames(" btn termine-page-button", relevance.filter(item => item === EventRelevance.High).length === 0 ? "termine-page-button-inactive" : "termine-page-button-active-heigh")}
                                        onClick={() => {handleRelevance(EventRelevance.High); trigInfonline(guessInfonlineSection(),"termine_page")}}>
                                        Hoch
                                </Button>
                            </div>
                            <div className="d-flex mt-3 termine-page-filter-container justify-content-between">

                                <div className="termine-page-filter-region">
                                    <CountrySelectDropdown
                                        country={country}
                                        setCountry={setCountry}
                                        />
                                </div>
                                <div className="termine-page-filter-category">
                                    <CategorySelectDropdown
                                        category={category}
                                        setCategory={setCategory}
                                        />
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        loadingEvents && <span><Spinner animation={'border'}></Spinner></span>
                    }
                    <InfiniteScroll
                        className={isMobile ? "mt-3" : "mt-0"}
                        style={isDesktop ? {minHeight: "500px"} : {}}
                        dataLength={eventData ? eventData.searchCalendarEvents.edges.length : 0}
                        next={() => loadMoreEvents()}
                        hasMore={!!(eventData && eventData.searchCalendarEvents && eventData.searchCalendarEvents.pageInfo && eventData.searchCalendarEvents.pageInfo.hasNextPage)}
                        loader={
                            <div className="text-center py-2">
                                <Spinner animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </Spinner>
                            </div>
                        }
                    >
                        <>
                            {eventTableData.map((element) =>
                                <>
                                    <div
                                        style={{marginTop: "-4px", marginBottom: "20px"}}
                                        className={classNames(isMobile && "mt-0", isTablet ? "t-termine-page-content-wrapper" : "content-wrapper")}>

                                        <h2 className={classNames("termine-page-date", isTablet ? "my-1" : "my-0")}>{element.date}</h2>
                                        {element?.value?.find(val => val?.eventType === 'COMPANY') &&
                                            <>
                                                <h2 className="termine-page-table-heading mb-0">Unternehmenstermine</h2>


                                                <Table className="table-borderless mb-0">
                                                    <thead
                                                        className={classNames("thead-light", isMobile ? "m-termine-page-table-header" : isTablet ? "t-termine-page-table-header" : "termine-page-table-header")}>
                                                    <tr>
                                                        <th>Land</th>
                                                        {isMobile ? <th>Relev.</th> :
                                                            <th>Relevanz</th>}
                                                        {isDesktop ? <th>Unternehmen</th> : isMobile &&
                                                            <th>Unternehmen</th>}
                                                        {isMobile ||
                                                            <th className={classNames(isTablet && "mw-100")}>Termin</th>}
                                                    </tr>
                                                    </thead>
                                                    <tbody
                                                        className={classNames(isMobile ? "m-termine-page-table-body" : isTablet ? "t-termine-page-table-body" : "termine-page-table-body")}>
                                                    {
                                                        element?.value &&
                                                        element.value?.filter(element => element.eventType === 'COMPANY').map((data: any) => (
                                                            <TerminePageTableContent
                                                                countryId={data.countryId}
                                                                flag={data.countryId}
                                                                relevance={relevanceString(data?.relevance)}
                                                                appointment={data.event}
                                                                company={data?.company}
                                                                seoTag = {data?.seoTag}
                                                                assetGroup = {data?.assetGroup}
                                                                eventType={data?.eventType}
                                                            />
                                                        ))
                                                    }
                                                    </tbody>

                                                </Table>
                                            </>}
                                        {element?.value?.find(val => val.eventType === 'ECONOMIC') &&
                                            <>
                                                <h2 className="termine-page-table-heading mb-0"
                                                    style={{
                                                        marginTop: "8px",
                                                        marginBottom: "16px"
                                                    }}>Wirtschaftstermine</h2>

                                                <Table
                                                    className="table-borderless  border-border-gray border-bottom-1 mb-0">
                                                    <thead
                                                        className={classNames("thead-light", isMobile ? "m-termine-page-collapsable-table-header" : isTablet ? "t-termine-page-collapsable-table-header" : "termine-page-collapsable-table-header")}>
                                                    <tr className={classNames(
                                                        isTablet ? "t-termine-page-collapsable-table-heading" : "termine-page-collapsable-table-heading")}>
                                                        <th>Zeit</th>
                                                        <th className={classNames(isTablet && !isMobile && "t-termine-page-land")}>Land</th>
                                                        {isTablet && !isMobile ? <th>Rel.</th> : <th>Relevanz</th>}
                                                        {isMobile ||
                                                            <th className={classNames(isTablet && "t-termine-page-collapsable-table-heading-termin")}>Termin</th>}
                                                        <th className={classNames(isDesktop || "d-none")}>Aktuell</th>
                                                        <th className={classNames(isDesktop || "d-none")}>Prognose</th>
                                                        <th className={classNames(isDesktop || "d-none")}
                                                            colSpan={2}>Vorherig
                                                        </th>
                                                    </tr>
                                                    </thead>

                                                    <tbody
                                                        className={classNames(isMobile ? "m-termine-page-collapsable-table-body" : isTablet ? "t-termine-page-collapsable-table-body" : "termine-page-collapsable-table-body")}>

                                                    {
                                                        element?.value &&
                                                        element.value?.filter(val => val.eventType === 'ECONOMIC').map((data: any) => (
                                                            <TerminePageCollapsableTable
                                                                time={data.time}
                                                                countryId={data.countryId}
                                                                flag={data.countryId}
                                                                description={data.description}
                                                                actual={data.actual}
                                                                forecast={data.forecast}
                                                                appointment={data.event}
                                                                previous={data?.previous}
                                                                relevance={relevanceString(data?.relevance)}
                                                                eventType={data.eventType}
                                                                currencyCode={data.currencyCode}
                                                                currencySign={data.currencySign}
                                                                expand= { homePageEventID ?  isExpanded( data?.id || 0  ) : false}
                                                            />
                                                        ))
                                                    }
                                                    </tbody>

                                                </Table>
                                            </>}
                                    </div>
                                </>
                            )}

                        </>

                    </InfiniteScroll>
                </div>

            </section>
        </main>
    )
}
