import React, {Component} from "react";

export class CompanySupervisors extends Component<CompanySupervisorsProps, any> {
    render() {
        return (
            <>
                <div className="font-weight-bold">Aufsichtsrat</div>
                <p> { this.props.names.join(', ') } </p>
            </>
        );
    }
}

export interface CompanySupervisorsProps {
    names: string[];
}

