import {useQuery} from "@apollo/client";
import {loader} from "graphql.macro";
import moment from "moment/moment";
import {useEffect} from "react";
import {ChartScope, HistoryQuoteAggregation, Instrument} from "../../../../generated/graphql";
import {aggregationFromChartScope, calculatePeriodFromChartScope} from "./utils";

interface CompareSeriesLoaderProps {
    onInstrumentDataLoaded: (instrument: Instrument) => void,
    instrumentId: number,
    chartScope: ChartScope,
}

export const CompareSeriesLoader = (props: CompareSeriesLoaderProps) => {
    const {loading, data, error} = useQuery(
        loader('./getInstrumentChartForCompare.graphql'),
        {
            variables: {
                instrumentId: props.instrumentId,
                chartScope: props.chartScope
            },
            skip: !props.instrumentId || !props.chartScope
        }
    );
    useEffect(() => {
        if (data && !loading && !error) {
            props.onInstrumentDataLoaded(data.instrument);
        }
    }, [data, loading, error, props.onInstrumentDataLoaded]);
    return null;
}