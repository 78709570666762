import SvgImage from "components/common/image/SvgImage";
import {useState} from "react";
import {Button} from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";

export function CopyWKN({ wkn, className, width, fixImagePadding = false }: { wkn: string, className?: string, width?: number, fixImagePadding?: boolean }) {
    const [wknCopied, setWknCopied] = useState<boolean>(false);
    return (
        <CopyToClipboard text={wkn} onCopy={() => {
            setWknCopied(true);
        }}>
            <Button variant="link" className={className} title={wknCopied ? "Copied" : "Copy"} >
                <SvgImage icon="icon_copy_dark.svg" convert={fixImagePadding} spanClass="copy-icon" width={width?.toString() || "28"} fixImagePadding={fixImagePadding} />
            </Button>
        </CopyToClipboard>
    );
}
