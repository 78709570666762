import { useQuery } from "@apollo/client";
import classNames from "classnames";
import { loader } from "graphql.macro";
import { Query } from "graphql/types";
import moment from "moment";
import { Spinner, Button } from "react-bootstrap";

interface NewsButtonIconProps {
    className?: string;
    isins: string[];
    isOpen: boolean;
    iconWidth?: number;
    handleOpen: () => void
}

export function NewsButtonIcon(props: NewsButtonIconProps) {
    let { loading, data } = useQuery<Query>(
        loader('./getNewsInfo.graphql'),
        { variables: { isins: props.isins }, skip: props.isins.length < 1 }
    );

   if (loading || !data) {
        return <div className="text-center py-2" role="loader"><Spinner size={"sm"} animation="border" /></div>;
    }

   let newsDate = (data?.newsSearch?.edges?.length > 0) ? data?.newsSearch.edges[0].node?.when : null;
   if (!newsDate) {
       return (
           <div className={classNames("news", props.className)}>
               <img src={process.env.PUBLIC_URL + "/static/img/svg/icon_news.svg"} className="svg-convert" alt="" width={props.iconWidth ?? 25} />
           </div>
       );
   }
   let isToday = moment(newsDate).isAfter(moment().startOf('day'));
   return (
        <div className={classNames("news", props.className)}>
            <Button variant="inline" className="p-0 icon-news svg-icon" onClick={props.handleOpen}>
                {isToday ?
                    <img src={process.env.PUBLIC_URL + "/static/img/svg/icon_news_orangedot_color.svg"} className="svg-convert" alt="" width={props.iconWidth ?? 25} /> :
                    <img src={process.env.PUBLIC_URL + "/static/img/svg/icon_news.svg"} className="svg-convert" alt="" width={props.iconWidth ?? 25} />
                }
            </Button>
        </div>
   );
}
