import { useQuery } from "@apollo/client";
import classNames from "classnames";
import { Container, Dropdown, Row, Spinner } from "react-bootstrap";
import moment from 'moment';
import { loader } from "graphql.macro";
import { useState } from "react";
import {formatDateMonthAndYear, formatDateToYear} from "utils";
import './CurrencyPerformanceChart.scss';
import ReactDOM from "react-dom";
import { PieChartColors } from "components/profile/utils";
import { useMediaQuery } from "react-responsive";
import FTLChartComponent from "../../../common/charts/base/FTLChartComponent";

export function CurrencyPerformanceChart(props: { className?: string, title?: string, trim?: boolean, hasLegendFlags?: boolean, idCurrency?: string, currencyList?: number[] }) {
    let period = "FIVE_YEAR";
    let { loading, data } = useQuery(
        loader('./getComparisonList.graphql'), { variables: { listId: 'currency_compare_'+props.idCurrency, chartScope: period } }
    );

    let chartOptions = {}
    if (loading) {
        return <><Spinner animation="border" /></>;
    }
    if (data && data.list && data.list.content && data.list.content.length > 0) {
        let series = data.list.content
            .map((content: any, index: number) => createItem(content.chart.series, index, content.name)).map((serie:DataPerformanceIndex) => createSeries(serie, true))
            .sort((a: { index: number; }, b: { index: number; }) => a.index - b.index)
        let yAxisData= series.map((serie:any) => serie.data).flatMap((data:any) => data.map((item:any) => item.y));
        let min =  Number.parseFloat(Math.min(...yAxisData).toFixed(0));
        let max = Number.parseFloat(Math.max(...yAxisData).toFixed(0));
        chartOptions = createOptions(series, min, max);
        return (
            <section className={classNames("main-section mx-lg-3", props.className)}>
                <Container className="mw-100">
                    <h2 className="section-heading font-weight-bold performance-comparison">{props.title}</h2>
                    <div className="content-wrapper row">
                        <div className="highcharts-containter col-12 col-xl-9 ">
                            <FTLChartComponent
                                constructorType='stockChart'
                                options={chartOptions}
                                callback={highchartsCallback}
                            />

                        </div>
                        <Row className="pt-sm-3 legend col-xl-3 legendArea">
                        </Row>
                    </div>
                </Container>
            </section>
        );
    }
    return <></>;

    function highchartsCallback(chart: any) {
        const legendArea = document.querySelector(".legendArea")
        chart.series.forEach((item: any, index: number) => {
            if (index < 9) {
                const myButton = document.createElement("div");
                myButton.className = "text-sm-truncate text-nowrap col-xl-12 col-lg-3 col-md-3 col-sm-6 col-6 cursor-pointer ";
                ReactDOM.render(<LegendItem item={item} />, myButton);
                myButton.addEventListener("click", function () {
                    item.setVisible(!item.visible, true);
                    item.visible ? myButton.classList.remove("inactive") : myButton.classList.add("inactive")
                });
                legendArea?.appendChild(myButton);
            }
        })
        const dropdownContainer = document.createElement("div");
        ReactDOM.render(<LegendDropdownItem series={chart?.series} />, dropdownContainer);

        legendArea?.appendChild(dropdownContainer);
    }

    function createItem(data: any, index: number, name: string) : DataPerformanceIndex {
        let instrumentName = '';
        name.split(" ").slice(-2).forEach((current:any) => {
            if(current !== "/"){
                instrumentName += current + " ";
            }
        })
        return {
            data: data && data[0] ? data[0].data : [],
            index: index,
            name: name.slice(4, 8) + "( " + instrumentName + ")"
        }
    }
}

function createSeries(serie: any, visibility: boolean): any {
    let firstCourse = (serie.data[0] && serie.data[0].value) ? serie.data[0].value : 0;
    let dates: number[] = [];
    return {
        name: serie.name,
        color: (serie.index && serie.index > 8) ? "black" : "",
        type: 'area',
        visible: serie.index < 10 && visibility,
        fillColor: "transparent",
        lineWidth: (serie.index && serie.index > 8) ? 2.5 : 1.5,
        dataLabels: {
            enabled: false
        },
        data: serie.data
            .filter((current: any) => {
                if (!dates.includes(current.when.slice(0, 7))) {
                    dates.push(current.when.slice(0, 7));
                    return true;
                }
                return false;
            })
            .map((current: any, index: number): any => {
                    let when = moment(current.when.slice(0, 10)).toDate().setDate(1);
                    return ({
                        y: Math.round(((current.value - firstCourse) / firstCourse) * 10000) / 100,
                        x: when,
                        z: formatDateMonthAndYear(current.when)
                    })
                }
            ),
    };
}

function createOptions(series: any, min: number, max: number) {
    return {
        stockTools: {
            gui: {
                enabled: false
            }
        },
        colors: PieChartColors,
        chart: {
            type: 'linear',
            // marginTop: 0,
            // marginBottom: 0,
        },
        rangeSelector: {
            enabled: false
        },
        scrollbar: {
            enabled: false
        },
        navigator: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        title: { text: '' },
        yAxis: {
            min: min * 1.1,
            max: max * 1.1,
            // min: min* (min < 0 ? 1.2 : 0.8),
            // max: max*0.95,
            labels: {
                enabled: false,
            },
            gridLineWidth: 0,
        },
        xAxis: {
            offset: - (300 / (Math.abs(max) + Math.abs(min)) * Math.abs(min)) - 33,
            //-(Math.abs(max)-Math.abs(min)) * .55 / 2,
            labels: {
                style: {
                    fontWeight: 'bold',
                },
                step: 2,
                formatter: function (this:any) {
                    return Number.parseInt(formatDateToYear(this.value)).toString();
                }
            },
            lineWidth: 2,
            tickWidth: 0,
        },
        tooltip: {
            headerFormat: '<small>{point.z}</small><table><br/>',
            //pointFormat: '{series.name}: <span class="text-red">{point.y}</span><br/>',
            shared: true,
            split: false,
            valueSuffix: "%",
            style: {
                fontFamily: "Roboto"
            },
        },
        series: series,
    }
}
interface DataPerformanceIndex {
    data: {
        value: number;
        when: any
    }[]
    name: string;
    index: number;
}

function LegendItem(props: any) {
    const isDesktop = useMediaQuery({
        query: '(min-width: 1100px)'
    })
    
    return (
        <>
            <span className="dot mr-1" style={{ backgroundColor: props.item.color }}></span>
            {isDesktop && <span className="font-weight-bold text-truncate" style={{ maxWidth: "220px" }}> {props.item.name} </span>}
            {!isDesktop && <span className="font-weight-bold text-truncate" style={{ maxWidth: "220px" }}> {props.item && props.item.name && props.item.name.slice(0, 3)} </span>}
            {props.hasFlags ? <></> : <img src={process.env.PUBLIC_URL + "/static/img/svg/flags/" + (props.item && props.item.name && props?.item?.name?.slice(0, 3).toLowerCase()) + ".svg"} alt="" className=" svg-blue table-flags-style" width="24px" height="16px"></img>}
        </>
    )
}

function LegendDropdownItem(props: any) {
    const [state, setState] = useState(props.series[9])
    const isDesktop = useMediaQuery({
        query: '(min-width: 1100px)'
    })
    return (
        <>
        <Dropdown>
            <Dropdown.Toggle className="dropdown-legend-button" id="dropdown-basic">
                {isDesktop && <><span className="dot mr-1"></span>{state.name}</>}
                {!isDesktop && <><span className="dot mr-1"></span>{state.name?.slice(0, 3)}</>}
            </Dropdown.Toggle>
            <img src={process.env.PUBLIC_URL + "/static/img/svg/flags/" + state.name?.slice(0, 3).toLowerCase() + ".svg"} alt="" className="svg-blue table-flags-style dropdown-flag-style" width="24px" height="16px"></img>

            <Dropdown.Menu className="dropdown-legend-menu" >
                {
                    props.series.map((current: any, index: number) => {
                        if (current.index > 8 && current !== state) {
                            current.setVisible(false);
                            return (
                                <>
                                    <Dropdown.Item key={index} className="py-0 my-0 line-height-1 font-size-15px" id={index} onClick={() => { setState(current); current.setVisible(true) }}>
                                        {current.name}
                                    </Dropdown.Item>
                                    <Dropdown.Divider />
                                </>
                            );
                        }
                    })

                }
            </Dropdown.Menu>
        </Dropdown>
        </>
    )
}
