import {Dependancy, FilterOption, OptionNew} from "components/common/SearchCard/FilterDropdownItem";
import {calculatePortfolio, calculatePortfolioErtrage, calculatePortfolioErtrageToDate} from "components/profile/utils";
import {Maybe} from "graphql/jsutils/Maybe";
import {AccountEntry, Portfolio, PortfolioPerformanceEntry} from "graphql/types";
import moment from "moment";
import {uniq} from "underscore";
import {deepCopy, getBootstrapColor, getDaysInMonth, getObjectTypedKeys} from "utils";

export const createYearsFilterDropdownOptions = (accountEntries: AccountEntry[], portfolioPerformanceEntries: PortfolioPerformanceEntry[]): Array<FilterOption<number>> => {
    const years = uniq([
        ...accountEntries.map((entry: AccountEntry) => new Date(entry.entryTime).getFullYear()),
        ...portfolioPerformanceEntries.map((performanceEntry: PortfolioPerformanceEntry) => new Date(performanceEntry.date).getFullYear())
    ].sort())

    const yearOptions: Array<OptionNew<number>> = years.reduce((accumulator: Array<OptionNew<number>>, year: number) => {
        const existingYear = accumulator.find(yearObject => yearObject.id === year)
        if (existingYear === undefined) {
            accumulator.push({
                id: year,
                name: year.toString(),
                enabled: true,
                valid: true
            })
        }
        return accumulator
    }, []).sort((option1, option2) => option2.id - option1.id)

    return [
        {
            id: "year",
            name: "Jahr",
            options: yearOptions
        }
    ]
}
export const createFilterDropdownOptions = (accountEntries: AccountEntry[]): Array<FilterOption<number>> => {
    let yearOptions: Array<OptionNew<number>> = [
        {
            id: 0,
            name: "Alle",
            enabled: true,
            valid: false
        }
    ]
    let monthOptions: Array<OptionNew<number>> = [
        {
            id: -1,
            name: "Alle",
            enabled: true,
            valid: false
        }
    ];
    ["Jan","Feb","Mrz","Apr","Mai","Jun","Jul","Aug","Sep","Okt","Nov","Dez"].forEach((month: string, idx: number) => {
        monthOptions.push({
            id: idx,
            name: month,
            enabled: false,
            valid: true,
            dependsOn: []
        })
    })

    accountEntries.map((entry: AccountEntry) => entry.entryTime).forEach((entryTime: string) => {
        const year: number = moment(entryTime).year()
        const month = moment(entryTime).month()

        const existingYear = yearOptions.find(yearObject => yearObject.id === year)
        if (existingYear === undefined) {
            yearOptions.push({
                id: year,
                name: year.toString(),
                enabled: true,
                valid: true
            })
        }
        const monthOption = monthOptions.find(monthObject => monthObject.id === month)
        const dependancy = monthOption?.dependsOn?.find((dependancy: Dependancy<number>) => dependancy.filterId === "year" && dependancy.optionId === year)

        if (dependancy === undefined && monthOption?.dependsOn !== undefined) {
            monthOption.enabled = true
            monthOption.dependsOn.push({
                filterId: "year",
                optionId: year
            })
        }
    })
    yearOptions = yearOptions.sort(year => year.id)
    monthOptions = monthOptions.sort((month1, month2) => month1.id - month2.id)
    return [
        {
            id: "year",
            name: "Jahr",
            options: yearOptions
        },
        {
            id: "month",
            name: "Monat",
            options: monthOptions,
            dependsOn: ["year"]
        }
    ]
}

export enum TransactionType {
    UNKNOWN,
    PURCHASE,
    SALE,
    SPLIT
}
export const getTxType = (accountEntry: AccountEntry): TransactionType => {
    switch (accountEntry.accountTypeDescriptionEn) {
        case "Wertpapierkauf" :
        case "Zukauf" :
            return TransactionType.PURCHASE
        case "Wertpapierverkauf":
        case "Teilverkauf" :
            return TransactionType.SALE
        case "Split":
            return TransactionType.SPLIT
        default:
            return TransactionType.UNKNOWN
    }
}

export const getTransactionTypeDisplayText = (transactionType: TransactionType): string => {
    let text = ""
    switch (transactionType) {
        case TransactionType.PURCHASE:
            text = "Kauf"
        break;
        case TransactionType.SALE:
            text = "Verkauf"
        break
        case TransactionType.SPLIT:
            text = "Split"
        break
    }

    return text
}

export const getTransactionTypeDisplayTextColor = (transactionType: TransactionType): string => {
    let color = ""
    switch (transactionType) {
        case TransactionType.PURCHASE:
            color = "text-success"
            break
        case TransactionType.SALE:
            color = "text-warning"
            break
        case TransactionType.SPLIT:
            color = "text-blue"
            break
    }

    return color
}
export const getTransactionTypeIcon = (transactionType: TransactionType): string => {
    let icon = ""
    switch (transactionType) {
        case TransactionType.PURCHASE:
            icon = "icon_transactions_plus.svg"
            break
        case TransactionType.SALE:
            icon = "icon_transactions_minus.svg"
            break
        case TransactionType.SPLIT:
            icon = "icon_transactions_split_blue.svg"
            break
    }

    return icon
}
export const getTransactionTypeIconColor = (transactionType: TransactionType): string => {
    let color = ""
    switch (transactionType) {
        case TransactionType.PURCHASE:
            color = "svg-shape-green"
            break
        case TransactionType.SALE:
            color = "svg-shape-red"
            break
        case TransactionType.SPLIT:
            color = "svg-blue"
            break
    }

    return color
}

/**
 * Sorts account entries by date in ascending order.
 * Warning: Mutates the passed array!
 */
export const sortAccountEntriesByDate = (accountEntries: AccountEntry[]): AccountEntry[] =>
    accountEntries.sort((accountEntry1: AccountEntry, accountEntry2: AccountEntry) => {
        const accountEntry1Time = new Date(accountEntry1.entryTime)
        const accountEntry2Time = new Date(accountEntry2.entryTime)
        if (accountEntry1Time.getTime() === accountEntry2Time.getTime())
            return accountEntry1.id - accountEntry2.id

        return accountEntry1Time.getTime() - accountEntry2Time.getTime()
    })

export const getTransactionAccountEntries = (accountEntries: Maybe<AccountEntry[]> | undefined): AccountEntry[] =>
    accountEntries?.filter((current) =>
                getTxType(current) === TransactionType.PURCHASE ||
                getTxType(current) === TransactionType.SALE ||
                getTxType(current) === TransactionType.SPLIT
    ) || [];

// Doesn't return expenseAccountEntries that have amount === 0
export const getExpenseAccountEntries = (accountEntries: AccountEntry[]): {[key: number]: AccountEntry} => {
    const expenseAccountEntries: {[key: number]: AccountEntry} = {}
    const sortedAccountEntriesByDate = sortAccountEntriesByDate(accountEntries.slice(0))
    sortedAccountEntriesByDate.forEach((accountEntry: AccountEntry, idx: number, entries: AccountEntry[]) => {
        if (accountEntry.accountTypeDescriptionEn !== "Kaufspesen" && accountEntry.accountTypeDescriptionEn !== "Verkaufspesen")
            return
        const entryTime = new Date(accountEntry.entryTime)
        const expenseType = accountEntry.accountTypeDescriptionEn === "Kaufspesen" ? TransactionType.PURCHASE : TransactionType.SALE
        let belongingAccountEntry = undefined
        const entryName = accountEntry.securityDescription
        const entryInstId = accountEntry.instrumentId
        const entryPortId = accountEntry.portfolioEntryId
        const prevEntry = entries[idx - 1] && entries[idx - 1].accountTypeDescriptionEn !== "Kaufspesen" && entries[idx - 1].accountTypeDescriptionEn !== "Verkaufspesen" ? entries[idx - 1] : undefined
        const nextEntry = entries[idx + 1] && entries[idx + 1].accountTypeDescriptionEn !== "Kaufspesen" && entries[idx + 1].accountTypeDescriptionEn !== "Verkaufspesen" ? entries[idx + 1] : undefined
        const prevEntryType = prevEntry === undefined ? TransactionType.UNKNOWN : getTxType(prevEntry)
        const nextEntryType = nextEntry === undefined ? TransactionType.UNKNOWN : getTxType(nextEntry)
        const prevEntryName = prevEntry?.securityDescription
        const nextEntryName = nextEntry?.securityDescription
        const prevEntryInstId = prevEntry?.instrumentId
        const nextEntryInstId = nextEntry?.instrumentId
        const prevEntryPortId = prevEntry?.portfolioEntryId
        const nextEntryPortId = nextEntry?.portfolioEntryId
        const prevEntryTime = prevEntry ? new Date(prevEntry.entryTime) : undefined
        const nextEntryTime = nextEntry ? new Date(nextEntry.entryTime) : undefined
        const expenseEntryTimeMatchesWithPreviousEntry = prevEntryTime?.getTime() === entryTime.getTime() 
        const expenseEntryTimeMatchesWithNextEntry = nextEntryTime?.getTime() === entryTime.getTime()

        // Most of the time the expense is with the same time
        if (expenseEntryTimeMatchesWithNextEntry && nextEntryPortId != null && entryPortId === nextEntryPortId && nextEntryType === expenseType) {
            belongingAccountEntry = nextEntry
        } else if (expenseEntryTimeMatchesWithPreviousEntry && prevEntryPortId != null && entryPortId === prevEntryPortId && prevEntryType === expenseType) {
            belongingAccountEntry = prevEntry
        } else if (expenseEntryTimeMatchesWithNextEntry && nextEntryInstId != null && entryInstId === nextEntryInstId &&  nextEntryType === expenseType) {
            belongingAccountEntry = nextEntry
        } else if (expenseEntryTimeMatchesWithPreviousEntry && prevEntryInstId != null && entryInstId === prevEntryInstId && prevEntryType === expenseType) {
            belongingAccountEntry = prevEntry
        } else if (expenseEntryTimeMatchesWithNextEntry && nextEntryName != null && entryName === nextEntryName &&  nextEntryType === expenseType) {
            belongingAccountEntry = nextEntry
        } else if (expenseEntryTimeMatchesWithPreviousEntry && prevEntryName != null && entryName === prevEntryName && prevEntryType === expenseType) {
            belongingAccountEntry = prevEntry
        }
        // Spesen is after the entry most of the time
        else if (nextEntryPortId != null && entryPortId === nextEntryPortId && nextEntryType === expenseType) {
            belongingAccountEntry = nextEntry
        } else if (prevEntryPortId != null && entryPortId === prevEntryPortId && prevEntryType === expenseType) {
            belongingAccountEntry = prevEntry
        } else if (nextEntryInstId != null && entryInstId === nextEntryInstId && nextEntryType === expenseType) {
            belongingAccountEntry = nextEntry
        } else if (prevEntryInstId != null && entryInstId === prevEntryInstId && prevEntryType === expenseType) {
            belongingAccountEntry = prevEntry
        } else if (nextEntryName != null && entryName === nextEntryName && nextEntryType === expenseType) {
            belongingAccountEntry = nextEntry
        } else if (prevEntryName != null && entryName === prevEntryName && prevEntryType === expenseType) {
            belongingAccountEntry = prevEntry
        }

        if (belongingAccountEntry !== undefined && accountEntry.amount !== 0)
            expenseAccountEntries[belongingAccountEntry.id] = accountEntry
    })

    return expenseAccountEntries
}

export const calculatePortfolioExpense = (portfolio: Portfolio): number => {
    return portfolio.accountEntries ?
        Object.values(getExpenseAccountEntries(portfolio.accountEntries)).reduce((totalExpense: number, currentValue: AccountEntry) => totalExpense + currentValue.amount, 0)
            : 0
}

/** @deprecated */
export const oldGetTxType = (accountTypeDescriptionEn: any) => {
    switch (accountTypeDescriptionEn) {
        case "Wertpapierkauf" :
        case "Zukauf" :
            return "Kauf";
        case "Wertpapierverkauf":
        case "Teilverkauf" :
            return "Verkauf";
        // TODO check for split
    }
    return "Verkauf";

};

export enum PurchaseAccountEntriesCategory {
    PORTFOLIO_ENTRY_ID,
    INSTRUMENT_ID,
    SECURITY_DESCRIPTION
}
export type AccountEntryData = {
    entry: AccountEntry,
    entryPrice: number, // Price for 1 quantity
    remainingQuantity: number
}
export type PurchaseAccountEntries = {
    categorizedBy: PurchaseAccountEntriesCategory
    key: number | string
    accountEntriesData: AccountEntryData[]
}
/**
 * @returns All purchase AccountEntries grouped by portfolioEntryId or instrumentId or securityDescription in ascending order by date
 */
export const generatePurchasesForInstrumentList = (accountEntries: AccountEntry[]): PurchaseAccountEntries[] => {
    /*
     * Old transactions don't have portfolioEntryId so we rely on instrumentId
     * Some transactions contain invalid instruments (instruments that don't exist anymore) so we have to fallback to instrument name
     */
    const sortedAccountEntriesByDate = sortAccountEntriesByDate(accountEntries.slice(0))
    const purchases: PurchaseAccountEntries[] = []
    sortedAccountEntriesByDate.forEach((accountEntry: AccountEntry) => {
        // instrumentId and portfolioEntryId are different for different exchanges
        if (getTxType(accountEntry) !== TransactionType.PURCHASE)
            return

        let purchasesForAccountEntry: PurchaseAccountEntries | undefined
        let found = false
        if (accountEntry.portfolioEntryId != null) {
            purchasesForAccountEntry = purchases.find(purchasesForAccountEntry => purchasesForAccountEntry.categorizedBy === PurchaseAccountEntriesCategory.PORTFOLIO_ENTRY_ID && purchasesForAccountEntry.key === accountEntry.portfolioEntryId)
            found = purchasesForAccountEntry !== undefined
            if (!found) {
                purchasesForAccountEntry = {
                    categorizedBy: PurchaseAccountEntriesCategory.PORTFOLIO_ENTRY_ID,
                    key: accountEntry.portfolioEntryId,
                    accountEntriesData: []
                }
            }
        } else if (accountEntry.instrumentId != null) {
            purchasesForAccountEntry = purchases.find(purchasesForAccountEntry => purchasesForAccountEntry.categorizedBy === PurchaseAccountEntriesCategory.INSTRUMENT_ID && purchasesForAccountEntry.key === accountEntry.instrumentId)
            found = purchasesForAccountEntry !== undefined
            if (!found) {
                purchasesForAccountEntry = {
                    categorizedBy: PurchaseAccountEntriesCategory.INSTRUMENT_ID,
                    key: accountEntry.instrumentId,
                    accountEntriesData: []
                }
            }
        } else if (accountEntry.securityDescription != null) {
            purchasesForAccountEntry = purchases.find(purchasesForAccountEntry => purchasesForAccountEntry.categorizedBy === PurchaseAccountEntriesCategory.SECURITY_DESCRIPTION && purchasesForAccountEntry.key === accountEntry.securityDescription)
            found = purchasesForAccountEntry !== undefined
            if (!found) {
                purchasesForAccountEntry = {
                    categorizedBy: PurchaseAccountEntriesCategory.SECURITY_DESCRIPTION,
                    key: accountEntry.securityDescription,
                    accountEntriesData: []
                }
            }
        }
        if (purchasesForAccountEntry !== undefined) {
            const accountEntryData = {
                remainingQuantity: accountEntry.quantity || 0,
                entryPrice: accountEntry.quantity ? accountEntry.amount / accountEntry.quantity : 0,
                entry: accountEntry
            }
            purchasesForAccountEntry.accountEntriesData.push(accountEntryData)
            if (!found)
                purchases.push(purchasesForAccountEntry)
        }
    })
    purchases.forEach((purchase: PurchaseAccountEntries) => {
        purchase.accountEntriesData.sort((data1: AccountEntryData, data2: AccountEntryData) => {
            const entry1Time = new Date(data1.entry.entryTime)
            const entry2Time = new Date(data2.entry.entryTime)
            if (entry1Time.getTime() === entry2Time.getTime())
                return data1.entry.id - data2.entry.id

            return entry1Time.getTime() - entry2Time.getTime()
        })
    })

    return purchases
}

const findPurchasesForSale = (saleAccountEntry: AccountEntry, purchasesForInstruments: PurchaseAccountEntries[]): PurchaseAccountEntries | undefined => {
    const purchasesForSale: PurchaseAccountEntries | undefined = purchasesForInstruments.find((purchasesForInstrument: PurchaseAccountEntries) => {
        switch (purchasesForInstrument.categorizedBy) {
            case PurchaseAccountEntriesCategory.PORTFOLIO_ENTRY_ID:
                return saleAccountEntry.portfolioEntryId === purchasesForInstrument.key
            case PurchaseAccountEntriesCategory.INSTRUMENT_ID:
                return saleAccountEntry.instrumentId === purchasesForInstrument.key
            case PurchaseAccountEntriesCategory.SECURITY_DESCRIPTION:
                return saleAccountEntry.securityDescription === purchasesForInstrument.key
            default:
                return false
        }
    })

    return purchasesForSale
}

/**
 * @param {PurchaseAccountEntries} purchasesForInstrument All purchases for the sale's portfolioEntryId / instrument / securityDescription. Includes purchases after the sale!
 */
const calculateProfitLossForSale = (salePrice: number, saleQuantity: number, saleExpense: number, saleEntry: AccountEntry, purchasesForInstrument: PurchaseAccountEntries, expenseEntries: {[key: number]: AccountEntry}) => {
    saleExpense = Math.abs(saleExpense) || 0 // undefined slips through somehow. Expense is a negative number. Turning it into positive for easier calculations
    const saleExpensePerPiece = saleExpense / saleQuantity
    let saleRemainingQuantity = saleQuantity
    let saleAmount = saleQuantity * salePrice
    let profitLoss = saleAmount - saleExpense
    let profitLossPercentage = 0
    let calculatedPurchases = 0
    for (const purchaseAccountEntryData of purchasesForInstrument.accountEntriesData) {
        if (saleRemainingQuantity === 0)
            break
        if (new Date(purchaseAccountEntryData.entry.entryTime).getTime() > new Date(saleEntry.entryTime).getTime())
            continue
        if (purchaseAccountEntryData.remainingQuantity === 0)
            continue

        const purchaseEntryQuantity = purchaseAccountEntryData.entry.quantity == null ? 1 : purchaseAccountEntryData.entry.quantity // XXX: should it be 0 or 1 if doesn't have quantity?
        const purchasedQuantity = Math.min(saleRemainingQuantity, purchaseAccountEntryData.remainingQuantity)

        purchaseAccountEntryData.remainingQuantity -= purchasedQuantity
        saleRemainingQuantity -= purchasedQuantity
        const purchasedAmount = purchasedQuantity * (-purchaseAccountEntryData.entryPrice) // purchase entry price is negative number. Turning in into a positive for easier calculations
        const purchaseExpenseEntry = expenseEntries[purchaseAccountEntryData.entry.id]
        const purchaseExpense = -purchaseExpenseEntry?.amount || 0 // expense is a negative number. Turning it into a positive for easier calculations
        const purchaseExpensePerPiece = purchaseExpense / purchaseEntryQuantity
        const salePriceForPurchasedQuantity = purchasedQuantity * salePrice
        profitLoss -= purchasedAmount + (purchaseExpensePerPiece * purchasedQuantity)
        profitLossPercentage += (
            (salePriceForPurchasedQuantity - (saleExpensePerPiece * purchasedQuantity) - purchasedAmount - (purchaseExpensePerPiece * purchasedQuantity))
            / (purchasedAmount + (purchaseExpensePerPiece * purchasedQuantity))
        ) * 100 // profitLoss / (purchasedAmount + (purchaseExpensePerPiece * purchasedQuantity)   But profitLoss is recalculated because it has to be for current transaction only
        calculatedPurchases++
    }

    profitLossPercentage /= calculatedPurchases
    return {
        profitLoss,
        profitLossPercentage
    }
}
export type CalculatedAccountEntryType = {
    entryId: number
    txType: TransactionType
    price: number
    totalAmount: number
    profitLoss?: number
    profitLossPercentage?: number
    expense?: number
}

/**
 * Calculated AccountEntry TransactionType, total amount, profit/loss, expense.
 * WARNING: Modifies the purchasesForInstruments entries
 */
export const calculateAccountEntry = (entry: AccountEntry, expenseEntries: {[key: number]: AccountEntry}, purchasesForInstruments: PurchaseAccountEntries[]): CalculatedAccountEntryType => {
    const entryId = entry.id
    const price: number = entry.quantity ? Math.abs(entry.amount / entry.quantity) : 0;
    const totalAmount = entry.amount
    const txType = getTxType(entry);
    const expenseEntry: AccountEntry | undefined = expenseEntries[entryId]
    const expense: number | undefined = expenseEntry?.amount // negative number
    if (txType === TransactionType.SALE) {
        const purchasesForSale = findPurchasesForSale(entry, purchasesForInstruments)
        const {profitLoss, profitLossPercentage} = purchasesForSale === undefined ?
            {profitLoss: undefined, profitLossPercentage: undefined} :
            calculateProfitLossForSale(price, entry.quantity || 1, expense, entry, purchasesForSale, expenseEntries)

        return {entryId, txType, price, totalAmount, profitLoss, profitLossPercentage, expense}
    }

    return {entryId, txType, price, totalAmount, profitLoss: undefined, profitLossPercentage: undefined, expense}
}

export const calculateAccountEntries = (entries: AccountEntry[], expenseEntries: {[key: number]: AccountEntry}): CalculatedAccountEntryType[] => {
    const purchasesForInstrumentsCopy: PurchaseAccountEntries[] = deepCopy(generatePurchasesForInstrumentList(entries), 3) as PurchaseAccountEntries[] // If it's going to be used in more than one place the object has to be cloned because it gets modified later
    const sortedAccountEntriesByDate = sortAccountEntriesByDate(entries.slice(0).filter((accountEntry: AccountEntry) => getTxType(accountEntry) !== TransactionType.SPLIT))

    const calculatedAccountEntries: CalculatedAccountEntryType[] =
        sortedAccountEntriesByDate.map((accountEntry: AccountEntry) =>
                                       calculateAccountEntry(accountEntry, expenseEntries, purchasesForInstrumentsCopy)) // important to perform calculations on a sortted array by date ascending

    return calculatedAccountEntries
}

export type CalculatedAccountEntryTotalType = {
    profitEntriesCount: number,
    lossEntriesCount: number,
    totalProfit: number,
    totalLoss: number,
    totalProfitLoss: number,
    totalExpense: number,
    totalAmount: number
}
export const calculateAccountEntriesTotal = (calculatedAccountEntries: CalculatedAccountEntryType[]): CalculatedAccountEntryTotalType => {
    const total = calculatedAccountEntries.map(({totalAmount, profitLoss, expense, ...rest}: CalculatedAccountEntryType) => {
        return {
            profit: (profitLoss !== undefined && profitLoss > 0) ? profitLoss : 0,
            loss: (profitLoss !== undefined && profitLoss < 0) ? profitLoss : 0,
            profitLoss: profitLoss || 0,
            expense: expense || 0,
            totalAmount: totalAmount
        }
    }).reduce((accumulator, currentValue) => ({
        profitEntriesCount: accumulator.profitEntriesCount + (currentValue.profit > 0 ? 1 : 0),
        lossEntriesCount: accumulator.lossEntriesCount + (currentValue.loss < 0 ? 1 : 0),
        totalProfit: accumulator.totalProfit + currentValue.profit,
        totalLoss: accumulator.totalLoss + currentValue.loss,
        totalProfitLoss: accumulator.totalProfitLoss + currentValue.profitLoss,
        totalExpense: accumulator.totalExpense + currentValue.expense,
        totalAmount: accumulator.totalAmount + currentValue.totalAmount
    }), {profitEntriesCount: 0, lossEntriesCount: 0, totalProfit: 0, totalLoss: 0, totalProfitLoss: 0, totalExpense: 0, totalAmount: 0})
    return total
}

export type CalculatedAccountEntriesTotalPerYearType = {
    [key: number]: CalculatedAccountEntryTotalType
}
export type CalculatedAccountEntriesPerYearType = {
    [key: number]: CalculatedAccountEntryType[]
}

export const groupCalculatedAccountEntriesByYear = (calculatedAccountEntries: CalculatedAccountEntryType[], accountEntries: AccountEntry[]): CalculatedAccountEntriesPerYearType => {

  type AccountEntriesPerYearType = {
    [key: number]: AccountEntry[]
  }
  const accountEntriesPerYear: AccountEntriesPerYearType = accountEntries.reduce((accumulator: AccountEntriesPerYearType, currentAccountEntry: AccountEntry) => {
      const year = moment(currentAccountEntry.entryTime).year()
      if (!(year in accumulator))
          accumulator[year] = []
      accumulator[year].push(currentAccountEntry)
      return accumulator
  }, {})

  return Object.keys(accountEntriesPerYear).reduce((accumulator: CalculatedAccountEntriesPerYearType, yearString: string) => {
      const year = Number(yearString)
      accumulator[year] = accountEntriesPerYear[year].map((accountEntry: AccountEntry) =>
                                                          calculatedAccountEntries.find((calculatedAccountEntry: CalculatedAccountEntryType) =>
                                                                                        calculatedAccountEntry.entryId === accountEntry.id))
                                                          .filter((calculatedAccountEntry): calculatedAccountEntry is CalculatedAccountEntryType => !!calculatedAccountEntry)
      return accumulator
  }, {})
}
export const groupYearCalculatedAccountEntriesByMonth = (yearCalculatedAccountEntries: CalculatedAccountEntryType[], accountEntries: AccountEntry[], year: number): CalculatedAccountEntriesPerYearType => {
  type AccountEntriesPerMonthType = {
    [key: number]: AccountEntry[]
  }
  const accountEntriesPerMonth: AccountEntriesPerMonthType = accountEntries.filter((accountEntry: AccountEntry) => moment(accountEntry.entryTime).year() === year)
      .reduce((accumulator: AccountEntriesPerMonthType, currentAccountEntry: AccountEntry) => {
          const month = moment(currentAccountEntry.entryTime).month()
          if (!(month in accumulator))
              accumulator[month] = []
          accumulator[month].push(currentAccountEntry)
          return accumulator
      }, {})

  return getObjectTypedKeys(accountEntriesPerMonth).reduce((accumulator: CalculatedAccountEntriesPerYearType, month: number) => {
      accumulator[month] = accountEntriesPerMonth[month].map((accountEntry: AccountEntry) =>
                                                            yearCalculatedAccountEntries.find((calculatedAccountEntry: CalculatedAccountEntryType) =>
                                                                                        calculatedAccountEntry.entryId == accountEntry.id))
                                                            .filter((calculatedAccountEntry): calculatedAccountEntry is CalculatedAccountEntryType => !!calculatedAccountEntry)
        return accumulator
  }, {})

}
export type PerformanceEntriesPerPeriod = {[key: number]: PortfolioPerformanceEntry[]}
export const groupPortfolioPerformanceEntriesByYear = (portfolioPerformanceEntries: PortfolioPerformanceEntry[]): PerformanceEntriesPerPeriod => {
    const result = portfolioPerformanceEntries.reduce((accumulator: PerformanceEntriesPerPeriod, currentValue: PortfolioPerformanceEntry) => {
        const entryYear = moment(currentValue.date).year()
        if (!(entryYear in accumulator))
            accumulator[entryYear] = []
        accumulator[entryYear].push(currentValue)
        return accumulator
    }, {})
    return result
}
export const groupYearPortfolioPerformanceEntriesByMonth = (yearPortfolioPerformanceEntries: PortfolioPerformanceEntry[]): PerformanceEntriesPerPeriod =>
    yearPortfolioPerformanceEntries.reduce((accumulator: PerformanceEntriesPerPeriod, currentValue: PortfolioPerformanceEntry) => {
        const entryMonth = moment(currentValue.date).month()
        if (!(entryMonth in accumulator))
            accumulator[entryMonth] = []
        accumulator[entryMonth].push(currentValue)
        return accumulator
    }, {})

export type FirstLastPerformanceEntriesForPeriod = [PortfolioPerformanceEntry, PortfolioPerformanceEntry]
export type FirstLastPerformanceEntriesPerPeriod = {[key: number]: FirstLastPerformanceEntriesForPeriod}
export const extractFirstAndLastPerformanceEntriesPerPeriod = (performanceEntries: PerformanceEntriesPerPeriod): FirstLastPerformanceEntriesPerPeriod => 
    getObjectTypedKeys(performanceEntries).reduce((accumulator: FirstLastPerformanceEntriesPerPeriod, period: number) => {
          performanceEntries[period].sort((performanceEntry1: PortfolioPerformanceEntry, performanceEntry2: PortfolioPerformanceEntry) => 
                                                moment(performanceEntry1.date).isBefore(moment(performanceEntry2.date)) ? -1 : 1
                                               )
          const performanceEntriesForPeriod: FirstLastPerformanceEntriesForPeriod = [performanceEntries[period][0], performanceEntries[period][performanceEntries[period].length - 1]]
          accumulator[period] = performanceEntriesForPeriod
          return accumulator
      }, {})

/**
 * Calculates portfolio value for the date of give PortfolioPerformanceEntry
 * @param initialPortfolioValue The initial portfolio value
 * @param performanceEntry PortfolioPerformanceEntry for the date to calculate portfolio value for
 * @param portfolio 
 * @returns Portfolio value for the given date (not in %)
 */
export type PortfolioValueForDate = {
    date: Date
    value: number
    valueIncludingEarnings: number
}
export const calculatePortfolioValueForDate = (initialPortfolioValue: number, performanceEntry: PortfolioPerformanceEntry, portfolio: Portfolio): PortfolioValueForDate => {
    const change = initialPortfolioValue * (performanceEntry.value / 100)
    const valueAmount = initialPortfolioValue + change
    const earnings = calculatePortfolioErtrageToDate(portfolio, performanceEntry.date);

    const valueIncludingEarnings = valueAmount + earnings
    return {date: performanceEntry.date, value: valueAmount, valueIncludingEarnings}
}

export enum Period {
    YEAR,
    MONTH,
    DAY
}
export type FirstLastPortfolioValueForPeriod = [PortfolioValueForDate, PortfolioValueForDate]
export type FirstLastPortfolioValuePerPeriod = {[key: number]: FirstLastPortfolioValueForPeriod}
export const calculateFirstLastPortfolioValuePerPeriod = (firstLastPerformanceEntriesPerPeriod: FirstLastPerformanceEntriesPerPeriod, period: Period, portfolio: Portfolio): FirstLastPortfolioValuePerPeriod => {
    const [initial, , ] = calculatePortfolio(portfolio);
    return getObjectTypedKeys(firstLastPerformanceEntriesPerPeriod).reduce((accumulator: FirstLastPortfolioValuePerPeriod, performanceEntriesPeriod: number) => {
        const firstLastPerformanceEntriesForPeriod: FirstLastPerformanceEntriesForPeriod = firstLastPerformanceEntriesPerPeriod[performanceEntriesPeriod]

        let lastPeriodLastDate: Date;
        const lastPerformanceEntryForPeriod = firstLastPerformanceEntriesForPeriod[1]
        const lastPerformanceEntryForPeriodDate = new Date(lastPerformanceEntryForPeriod.date)
        switch (period) {
            case Period.YEAR:
                lastPeriodLastDate = new Date(performanceEntriesPeriod - 1, 11, 31)
                break
            case Period.MONTH:
                const previousMonth = lastPerformanceEntryForPeriodDate.getMonth() === 0 ? 11 : lastPerformanceEntryForPeriodDate.getMonth() - 1 
                const year = previousMonth === 11 ? lastPerformanceEntryForPeriodDate.getFullYear() - 1 : lastPerformanceEntryForPeriodDate.getFullYear()
                lastPeriodLastDate = new Date(year, previousMonth, getDaysInMonth(previousMonth, year))
                break
            case Period.DAY:
                lastPeriodLastDate = new Date(lastPerformanceEntryForPeriodDate.getFullYear(), lastPerformanceEntryForPeriodDate.getMonth(), lastPerformanceEntryForPeriodDate.getDate() - 1)
                break
        }
        const earningsTillCurrentPeriod: number = calculatePortfolioErtrageToDate(portfolio, lastPeriodLastDate)
        
        accumulator[performanceEntriesPeriod] = calculateFirstLastPortfolioValueForPeriod(firstLastPerformanceEntriesForPeriod, period, portfolio, initial, earningsTillCurrentPeriod)
        return accumulator
    }, {})
}
export const calculateFirstLastPortfolioValueForPeriod = (firstLastPerformanceEntriesForPeriod: FirstLastPerformanceEntriesForPeriod, period: Period, portfolio: Portfolio, initialPortfolioValue: number, earningsTillCurrentPeriod: number): FirstLastPortfolioValueForPeriod => {
    const currentDate = new Date()
    const firstPortfolioValueForPeriod: PortfolioValueForDate = calculatePortfolioValueForDate(initialPortfolioValue, firstLastPerformanceEntriesForPeriod[0], portfolio)

    const [,, portfolioLast] = calculatePortfolio(portfolio)
    const portfolioEarnings = calculatePortfolioErtrage(portfolio)
    let entryIsForCurrentDatePeriod: boolean
    const lastPerformanceEntryForPeriodDate = new Date(firstLastPerformanceEntriesForPeriod[1].date)
    switch (period) {
        case Period.YEAR:
            entryIsForCurrentDatePeriod = currentDate.getFullYear() === lastPerformanceEntryForPeriodDate.getFullYear()
        break
        case Period.MONTH:
            entryIsForCurrentDatePeriod = currentDate.getFullYear() === lastPerformanceEntryForPeriodDate.getFullYear() &&
            currentDate.getMonth() === lastPerformanceEntryForPeriodDate.getMonth()
        break
        case Period.DAY:
            entryIsForCurrentDatePeriod = currentDate.getFullYear() === lastPerformanceEntryForPeriodDate.getFullYear() &&
            currentDate.getMonth() === lastPerformanceEntryForPeriodDate.getMonth() &&
            currentDate.getDate() === lastPerformanceEntryForPeriodDate.getDate()
        break
        default:
            entryIsForCurrentDatePeriod = false
        break;
    }

    const lastPortfolioValueForPeriod: PortfolioValueForDate = entryIsForCurrentDatePeriod ? {
        date: currentDate,
        value: portfolioLast,
        valueIncludingEarnings: portfolioLast + portfolioEarnings
    } :
        calculatePortfolioValueForDate(initialPortfolioValue, firstLastPerformanceEntriesForPeriod[1], portfolio)
    lastPortfolioValueForPeriod.value += earningsTillCurrentPeriod

    return [firstPortfolioValueForPeriod, lastPortfolioValueForPeriod]
}
export const changeTextDecoration = (checkedBox: boolean, isLabel: boolean) => {
    return {
        textDecoration: !checkedBox ? "line-through" : "none",
        color: !checkedBox ? "#C2C2C2" : (!isLabel ? "text-green" : "#383838")
    };
};
