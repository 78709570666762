import { useQuery } from "@apollo/client";
import classNames from "classnames";
import { CarouselWrapper } from "components/common";
import { OptionItem } from "components/common/SearchCard/FilterDropdownItem";
import SvgImage from "components/common/image/SvgImage";
import { ProfileInstrumentAddPopup } from "components/common/modals/ProfileInstrumentAddPopup";
import { NewsInstrumentInfoComponent } from "components/common/news/NewsInstrumentInfo";
import { createChunk } from "components/common/utils";
import { SelectBottomBox } from "../../common/select/SelectBottomBox/SelectBottomBox";
import { loader } from "graphql.macro";
import { AssetGroup, Query, ReportPeriod, SnapQuote } from "graphql/types";
import { useBootstrapBreakpoint } from "hooks/useBootstrapBreakpoint";
import { useState } from "react";
import { Button, ButtonGroup, Card, Carousel, Col, Container, Modal, Row, Spinner } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import { Link } from "react-router-dom";
import { formatNewsDate, getFinanztreffAssetLink } from "utils";
import { ModalHeaderMeinFinanztreff } from "components/profile/modals/ModalHeaderMeinFinanztreff";
import { ExposeModalBody } from "components/common/news/NewsGridModal/NewsGridModal";
import { ScreenMode, useScreenMode } from "hooks/useMediaQuery";

interface SecuritiesInFocusSectionCardProps {
    instrumentId: number;
    groupId: number;
    name: string;
    isin: string;
    wkn: string;
    seoTag: string;
    assetGroup: AssetGroup;
    snapQuote: SnapQuote | null;
    newsId: string;
    lastNewsDate: string;
    newsSource: string;
    newsTitle: string;
    count: number;
}

export function SecuritiesInFocusSectionCard(props: SecuritiesInFocusSectionCardProps) {
    const [wknCopied, setWknCopied] = useState<boolean>(false);
    const onWknCopy = () => setWknCopied(true);
    const maxAssetNameLength = getMaxAsssetNameLength(useScreenMode());

    return (
        <Card className="mx-0 my-1 rounded-0 border-0"
            style={{boxShadow: "0px 3px 6px #00000029"}}>
            <Card.Body className="p-2 ml-2">
                <div className="content-row mb-2" style={{borderBottom: "2px solid #f1f1f1"}}>
                    <div className="d-flex justify-content-between">
                        <span style={{fontWeight: "bolder", fontSize: "18px"}}>
                            <Link to={getFinanztreffAssetLink(props.assetGroup, props.seoTag)} className="asset-name asset-link text-truncate font-weight-bold"
                                style={{color:"rgba(38, 38, 38)"}}>{props.name.length > maxAssetNameLength ? `${props.name.substring(0, maxAssetNameLength)}...` : props.name}</Link>
                        </span>
                        <div className="d-flex justify-content-end">
                            <div className="d-block d-md-none ">
                                <NewsInstrumentInfoComponent
                                    snapQuote={props?.snapQuote}
                                    currencyCode={"EUR"}
                                    showPrice={true}
                                    className={"w-100"}
                                />
                            </div>
                            <ProfileInstrumentAddPopup
                                instrumentId={props.instrumentId}
                                instrumentGroupId={props.groupId}
                                name={props.name}
                                className="p-0 mr-n1"
                                watchlist={true} portfolio={true}
                                direction="up"
                                productIsin={props.isin}
                                nameWkn={props.wkn}>
                                <SvgImage icon="icon_plus_blue.svg" convert={false} imgClass="shrink-08" width="28" />
                            </ProfileInstrumentAddPopup>
                        </div>
                    </div>
                    <div className="d-none d-md-flex justify-content-between">
                        <div className="d-none d-xl-block">
                            {!!props.wkn &&
                                <>
                                    <span> {props.wkn}</span>
                                    <CopyToClipboard text={props.wkn} onCopy={onWknCopy}>
                                        <Button className="move-arrow svg-icon top-move copy-button" title={wknCopied ? "Copied" : "Copy"}>
                                            <SvgImage icon='icon_copy_dark.svg' convert={false} spanClass="copy-icon" width={'22'} />
                                        </Button>
                                    </CopyToClipboard>
                                </>
                            }
                        </div>
                        <div>
                            <NewsInstrumentInfoComponent
                                snapQuote={props?.snapQuote}
                                currencyCode={"EUR"}
                                // name={props.name}
                                url={getFinanztreffAssetLink(props.assetGroup, props.seoTag)}
                                showPrice={true}
                                className={"w-100"}
                            />
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-between" style={{color: "rgba(56,56,56, 0.6)"}}>
                    <span className="font-size-13px">{props.newsSource}</span>
                    <span className="font-size-13px">{formatNewsDate(props.lastNewsDate, ' min', 'vor ')}</span>
                </div>
                <Link className="font-weight-bolder" to={{key: '', pathname: "/nachrichten/" + props.newsId + "/"}}>
                    {props.newsTitle.length > 53 ? props.newsTitle.substring(0, 50) + "..." : props.newsTitle}
                </Link>
                <p className="py-2 m-0">
                    <SecurityInFocusModal name={props.name} count={props.count} isin={props.isin}/>
                </p>
            </Card.Body>
        </Card>
    )
}

function getMaxAsssetNameLength(screenMode: ScreenMode) {
    switch(screenMode) {
        case ScreenMode.DESKTOP:
            return 25;
        case ScreenMode.TABLET:
            return 18;
        case ScreenMode.PHONE:
        default:
            return 12;
    }
}

interface SecuritiesInFocusSectionContentProps {
    period: ReportPeriod;

}
export function SecuritiesInFocusSectionContent({period, ...props}: SecuritiesInFocusSectionContentProps) {
    const { data, loading } = useQuery<Query>(loader('./getSecuritiesInFocus.graphql'), {
        "variables": {period}
    });
    const chunkSize = useBootstrapBreakpoint({
        xl: 4,
        default: 3
    });
    if (loading) {
        return <div className="text-center py-2"><Spinner animation="border" /></div>;
    }

    let items = (data?.newsProductCount || []).filter(current => !!current.group).slice(0, 20);

    return (
        <div className="carouse-container px-2">
            <Carousel
                controlclass={"mb-n4 dark-version"}
                controls={true}
                indicators={true}
                prevIcon={
                    <SvgImage  icon="icon_direction_left_dark_big.svg" spanClass="move-arrow d-none d-xl-block"/>
                }
                nextIcon={
                    <SvgImage icon="icon_direction_right_dark_big.svg" spanClass="move-arrow d-none d-xl-block"/>
                }
                as={CarouselWrapper}
            >
                {
                    createChunk(items || [], chunkSize)
                        .map(chunk => (
                            <Carousel.Item>
                                <Row xl={4} md={3} sm={1} className="px-3 pt-1 pb-3">
                                {chunk.map(current => (
                                    <Col className="p-1">
                                        <SecuritiesInFocusSectionCard
                                            instrumentId={current.group?.main?.id || 0}
                                            groupId={current.group?.id || 0}
                                            isin={current.isin}
                                            name={current.group?.name || ""}
                                            wkn={current.group?.wkn || ""}
                                            snapQuote={current.group?.main?.snapQuote || null}
                                            seoTag={current.group?.seoTag || ""}
                                            assetGroup={current.group?.assetGroup || AssetGroup.Share}
                                            newsId={current.group?.news.edges[0].node.id || ""}
                                            lastNewsDate={current.group?.news.edges[0].node.when}
                                            newsSource={current.group?.news.edges[0].node.source?.name || ""}
                                            newsTitle={current.group?.news.edges[0].node.headline || ""}
                                            count={current.count}
                                        />
                                    </Col>
                                ))}
                                </Row>
                            </Carousel.Item>
                        ))
                }
            </Carousel>
        </div>
    )
}

export function SecuritiesInFocusSection() {
    const [period, setReportPeriod] = useState<ReportPeriod>(ReportPeriod.Last_24H);


    return (
        <section className="main-section">
            <Container className="px-lg-2 index-page-container">
                <div className="d-flex justify-content-between">
                    <h2 className="section-heading font-weight-bold pl-xl-2 ml-n2 ml-md-1">Wertpapiere im Newsfokus</h2>
                    <div>
                        <ButtonGroup className="d-none d-md-block">
                        {
                            PERIOD_OPTIONS.map(current => (
                                <Button key={current.id} variant="inline"
                                        active={current.period === period}
                                        className={
                                            classNames(
                                                "text-blue font-size-16px mx-2 px-0 pb-0 border-button-blue font-weight-bold",
                                                current.period === period && "button-active-undeline"
                                            )
                                        }
                                        onClick={() => {
                                            setReportPeriod(current.period)
                                        }}
                                >
                                        {current.name}
                                </Button>
                            ))
                        }
                        </ButtonGroup>
                        <SelectBottomBox<String, ReportPeriod>
                            className="font-weight-bold text-blue d-md-none"
                            defaultValue={period}
                            title="Zeitraum"
                            icon={<SvgImage spanClass={"drop-arrow-image top-move indicator ml-2"} icon={"icon_direction_down_blue.svg"} width="17" />}
                            onSelect={value => setReportPeriod(value)}
                            options={
                                PERIOD_OPTIONS.map(current => {
                                    return {
                                        value: current.period,
                                        name: current.name
                                    }
                                })
                            }
                        />
                    </div>
                </div>
                <SecuritiesInFocusSectionContent period={period}/>
            </Container>
        </section>
    )
}

interface SecurityInFocusModalProps {
    name: string;
    count: number;
    isin: string;
}

export function SecurityInFocusModal({ name, count, isin }: SecurityInFocusModalProps) {
    const [isOpen, setOpen] = useState<boolean>(false);

    const openModal = () => setOpen(true);
    const closeModal = () => setOpen(false);
    return (
        <>
            <Button variant="link" className="p-0 text-blue cursor-pointer" onClick={openModal}>
                {count} weitere Nachrichten...
            </Button>
            <Modal show={isOpen} onHide={closeModal} className="modal-dialog-sky-placement modal-background">
                <div className="modal-content bg-white">
                    <ModalHeaderMeinFinanztreff title={`Nachrichten - ${name}`} close={closeModal} />
                    <ExposeModalBody key={`security-in-focus-${isin}`} isin={isin}/>
                </div>
            </Modal>
        </>
    );
}


interface OptionItemReportPeriod extends OptionItem {
    period: ReportPeriod;
}

const PERIOD_OPTIONS: OptionItemReportPeriod[] = [
    {id: "24h", name: "24h", period: ReportPeriod.Last_24H},
    {id: "48h", name: "48h", period: ReportPeriod.Last_48H},
    {id: "7d", name: "7 Tage", period: ReportPeriod.Last_7D}
];
