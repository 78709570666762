import classNames from "classnames";
import SvgImage from "components/common/image/SvgImage";
import {Instrument} from "graphql/types";
import {Button, Modal} from "react-bootstrap";
import {UnderlyingSearch} from "../underlying/filter/UnderlyingSearch";

export type UnderlyingSearchModalPropsType = {
    className?: string,
    dialogClassName?: string,
    show: boolean,
    close: () => void,
    onAssetSelected: (asset: Instrument) => void,
    clearInput: boolean
}
export default function UnderlyingSearchModal({className, dialogClassName, show, close, onAssetSelected, clearInput}: UnderlyingSearchModalPropsType) {
    return (
        <Modal className={classNames("bottom modal-dialog-sky-placement", className)} dialogClassName={classNames("underlying-search-modal-dialog all-white-modal h-75", dialogClassName)} contentClassName="h-100 overflow-none" backdrop="static" show={show}>
            <Modal.Header className="overflow-none d-flex flex-row">
                <h5 className="modal-title" id="">Basiswert auswählen</h5>
                <button type="button" className="close text-blue" onClick={close}>
                    <span>schließen</span>
                    <SvgImage icon="icon_close_dark.svg" spanClass="close-modal-butt"
                        imgClass="svg-blue" convert={true}/>
                </button>
            </Modal.Header>
            <Modal.Body className="overflow-hidden">
                <div className="filter-section-modal">
                    <UnderlyingSearch onAssetSelected={onAssetSelected} clearInput={clearInput} />
                </div>
            </Modal.Body>
        </Modal>
    )
}

