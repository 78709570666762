import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import SvgImage from "components/common/image/SvgImage";
import { ProfileInstrumentAddPopup } from "components/common/modals/ProfileInstrumentAddPopup";
import { AssetGroup, Chart, Quote } from "generated/graphql";
import { getFinanztreffAssetLink } from "utils";
import classNames from "classnames";
import './CurrencyFindInstrumentCard.scss';
import React from 'react';
import FTLChartComponent from "../../common/charts/base/FTLChartComponent";
import {CrossRateQuoteInformation} from "./CurrencyTradeAndQuoteInfoCard";
import {createIntraDayOptions} from "../../common/charts/utils";
import {mapToCountryFlag} from "./utils";



interface IndexFindInstrumentCardProps {
    id: number;
    homeCurrencyCode: string;
    foreignCurrencyCode: string;
    name: string;
    displayPrice?: string | null;

    assetGroup: AssetGroup;
    seoTag?: string;
    groupId: number;

    chart?: Chart | undefined;
    quote?: Quote | undefined;

    className?: string;
    withDerivativeProducts?: boolean;
}

interface IndexFindInstrumentCardContentProps extends IndexFindInstrumentCardProps {
    children?: React.ReactNode;
}

export function CrossRateInstrumentCard(props: IndexFindInstrumentCardProps) {
    return (
        <CurrencyFindInstrumentCardContent {...props}>
            <div className="m-n3 d-flex justify-content-between pb-0 align-items-end">
                { !!props.quote &&
                    <CrossRateQuoteInformation
                        value={props.quote.value || 0}
                        change={props.quote.change || 0}
                        percentChange={props.quote.percentChange || 0}
                    />
                }
                { !!props.chart &&
                    <FTLChartComponent
                        constructorType='stockChart'
                        options={createIntraDayOptions(props?.chart)}
                    />
                }
            </div>
        </CurrencyFindInstrumentCardContent>
    );
}
export function CurrencyFindInstrumentCardContent({id, name, groupId, assetGroup, seoTag, homeCurrencyCode, foreignCurrencyCode, children, ...props}: IndexFindInstrumentCardContentProps) {
    return (
        <>
            <Card className={classNames("text-dark p-2 borderless mb-sm-3 mb-xl-0 mb-md-0", props.className)}>
                <div id="currency-find-instrument-card">
                    <div className="d-flex justify-content-between">
                        <div className="d-inline-flex">
                            <img src={process.env.PUBLIC_URL + `/static/img/svg/flags/${mapToCountryFlag(homeCurrencyCode)}.svg`} alt="" className="svg-blue" width="24px" height="16px" />
                            {!!seoTag ?
                                    <Link
                                        to={getFinanztreffAssetLink(assetGroup, seoTag)}
                                        className="font-size-20px text-dark font-weight-bold line-height-16px text-truncate mx-1">
                                        {homeCurrencyCode}/{foreignCurrencyCode}
                                    </Link> :
                                    <div className="font-size-20px text-dark font-weight-bold line-height-16px text-truncate mx-1">
                                        {homeCurrencyCode}/{foreignCurrencyCode}
                                    </div>
                            }
                            <img src={process.env.PUBLIC_URL + `/static/img/svg/flags/${mapToCountryFlag(foreignCurrencyCode)}.svg`} alt="" className="svg-convert svg-blue" width="24px" height="16px" />
                        </div>
                        <div className="d-flex justify-content-end">
                            <ProfileInstrumentAddPopup
                                instrumentId={id}
                                instrumentGroupId={groupId}
                                name={name}
                                className="p-0 mr-n1"
                                watchlist={true} portfolio={true}>
                                    <SvgImage icon="icon_plus_blue.svg" convert={false}
                                              imgClass="shrink-08" width="28"
                                              style={{ marginTop: "-5px", marginBottom: "5px" }}
                                    />
                            </ProfileInstrumentAddPopup>
                        </div>
                        
                    </div>
                </div>
                <Card.Body className="mt-n2 mx-n1">
                    {children}
                </Card.Body>
                { props.withDerivativeProducts &&
                    <Card.Footer className="d-flex border-top-1 p-0 bg-white justify-content-between align-items-center borderless shadow-none">
                        <div className="ml-0 font-size-13px">Passende Produkte</div>
                        <div>
                            <span className="mt-1 mx-1 text-blue font-weight-bold font-size-13px currency-instrument-card-disabled-fix">Zertifikate</span>
                            <span className="mt-1 mx-1 text-blue font-weight-bold font-size-13px currency-instrument-card-disabled-fix">KO</span>
                            <span className="mt-1 mx-1 text-blue font-weight-bold font-size-13px currency-instrument-card-disabled-fix">OS</span>
                        </div>
                    </Card.Footer>
                }
            </Card>
        </>
    );
}
