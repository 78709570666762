import classNames from "classnames"
import {Instrument, QuoteType, SnapQuote} from "generated/graphql"
import {SnapQuoteSubscriptionResult, useSnapQuoteSubscription} from "hooks/useSnapQuoteSubscription"
import {ReactElement, useRef} from "react"
import {formatPrice, formatTime, getTextColorByValue, numberFormatWithSign, QuoteValueType, shortNumberFormat} from "utils"
import {getQuoteValue, getTradeQuote} from "utils"

type SnapQuoteValueType = {
    ({quoteValueType, snapQuote, instrument, className, currencyCodeClassName, blink, suffix}: {quoteValueType: QuoteValueType, snapQuote: SnapQuote | null | undefined, instrument: Instrument | null | undefined, className?: string, currencyCodeClassName?: string, blink?: boolean, suffix?: string}): ReactElement
}

export const SnapQuoteValue: SnapQuoteValueType = ({quoteValueType, snapQuote, instrument, className, currencyCodeClassName, blink = false, suffix}) => {
    const blinkOpacity = blink && (quoteValueType === QuoteValueType.CHANGE || quoteValueType === QuoteValueType.PERCENT_CHANGE)
    const blinkColor = blink && (quoteValueType === QuoteValueType.BID || quoteValueType === QuoteValueType.ASK)
    const {value, toggle}: SnapQuoteSubscriptionResult = useSnapQuoteSubscription(snapQuote?.instrumentId, quoteValueType, { blink: blinkColor })
    const assetGroup = instrument?.group?.assetGroup
    const currentSnapQuote = value || snapQuote
    const quote = getTradeQuote(instrument, currentSnapQuote);
    let quoteValue: number | null | undefined
    const oldValue = useRef<number | null | undefined>(quoteValue)
    switch (quoteValueType) {
        case QuoteValueType.VALUE:
            quoteValue = quote?.value
            break
        case QuoteValueType.WHEN:
            quoteValue = quote?.when
            break
        case QuoteValueType.BID:
            quoteValue = getQuoteValue(currentSnapQuote as SnapQuote, QuoteType.Bid)?.valueOf()
            break
        case QuoteValueType.ASK:
            quoteValue = getQuoteValue(currentSnapQuote as SnapQuote, QuoteType.Ask)?.valueOf()
            break
        case QuoteValueType.CHANGE:
            quoteValue = quote?.change
            break
        case QuoteValueType.PERCENT_CHANGE:
        case QuoteValueType.PERCENT_CHANGE_ICON:
            quoteValue = quote?.percentChange
            break
        case QuoteValueType.TURNOVER:
            quoteValue = currentSnapQuote?.cumulativeTurnover
            break
        case QuoteValueType.VOLUME:
            quoteValue = currentSnapQuote?.cumulativeVolume
            break
        default:
            break
    }

    let displayValue
    switch (quoteValueType) {
        case QuoteValueType.VALUE:
            displayValue = (
                <>
                    <span>{formatPrice(quoteValue, assetGroup, quote?.value)}</span>
                    <span className={classNames("ml-2px", currencyCodeClassName)}>{instrument?.currency.displayCode || suffix}</span>
                </>
            )
            break
        case QuoteValueType.WHEN:
            displayValue = formatTime(quoteValue, suffix)
            break
        case QuoteValueType.BID:
        case QuoteValueType.ASK:
            displayValue = formatPrice(quoteValue, assetGroup, undefined, suffix)
            break
        case QuoteValueType.CHANGE:
            displayValue = numberFormatWithSign(quoteValue, suffix)
            break
        case QuoteValueType.PERCENT_CHANGE:
            displayValue = numberFormatWithSign(quoteValue, suffix || "%")
            break
        case QuoteValueType.TURNOVER:
        case QuoteValueType.VOLUME:
            displayValue = shortNumberFormat(quoteValue)
            break
        default:
            displayValue = ""
            break
    }
    let resultElement
    if (quoteValueType === QuoteValueType.PERCENT_CHANGE_ICON) {
        resultElement = (
            <span className="svg-icon move-arrow">
                {quoteValue ? (quoteValue > 0 ?
                    <img src="/static/img/svg/icon_arrow_short_up_green.svg" alt="" className="mt-n1" width={12} />
                    : quoteValue < 0 ?
                        <img src="/static/img/svg/icon_arrow_short_down_red.svg" alt="" className="mt-n1" width={12} />
                        : <img src="/static/img/svg/icon_arrow_short_right_grey.svg" alt="" width={28} />)
                        : ""
                }
            </span>
        )
    } else {
        const blinkColorRed = blinkColor && quoteValue && !!oldValue.current && oldValue.current > quoteValue
        const blinkColorGreen = blinkColor && quoteValue && !!oldValue.current && oldValue.current < quoteValue

        const updateElement = oldValue.current !== quoteValue
        // Element blinks even though it's visually the same value. This is because value is different but only 2 decimal points are displayed 
        if (updateElement)
            oldValue.current = quoteValue

        resultElement = (
            <span key={quoteValue} className={classNames(className, {
                'asset-value-movement-blinker': blinkOpacity,
                'asset-value-movement-color-blinker-font--red': toggle && blinkColorRed,
                'asset-value-movement-color-blinker-font--geeen': toggle && blinkColorGreen,
                [getTextColorByValue(quoteValue)]: blinkOpacity
            })}>{displayValue}</span>
        )
    }

    return resultElement
}
