import classNames from 'classnames';
import { Issuer } from 'generated/graphql';

export function PartnerLogo({fixedImageSize = true, ...props}: { issuer?: Issuer, svgColor?: string, className?: string, fixedImageSize?: boolean, overrideClassName?: boolean, dynamicFontSize?: boolean, dynamicLogoSize?: boolean }) {
    const issuerId: number = props.issuer?.id || 0;

    const modifiedSpanClassName = props.overrideClassName ? props.className : classNames("fnt-size-18 font-weight-bold", props.className)

    if (props.issuer?.partner || LOGOMAP[issuerId] !== undefined)
        return (
            <img src={process.env.PUBLIC_URL + "/static/img/svg/issuer-logos/emi_logo_" +
                LOGOMAP[issuerId].name + "_" + (props.svgColor ? props.svgColor : "color") + ".svg"}
                alt={props.issuer?.name || ""} width={fixedImageSize ? LOGOMAP[issuerId].width : "100%"}
                className={props.className} style={!fixedImageSize ? {maxWidth: LOGOMAP[issuerId].width} : {}} />
        )
    else
        return (<span className={modifiedSpanClassName}>{props.issuer?.name}</span>);

}

// IF fixedImageSize == true: width is the width of the logo ELSE: width is the max width of the logo
const LOGOMAP: { [key: number]: { name: string, width: number } } = {
    46: { name: "bnp", width: 70 },
    30: { name: "citi", width: 40 },
    4: { name: "ubs", width: 58 },
    22: { name: "morganstanley", width: 75 },
    26: { name: "socgen", width: 70 },
    3: { name: "vontobel", width: 70 },
    8: { name: "dzbank", width: 70 },
    52: { name: "hsbc", width: 70 },
    23: { name: "jpm", width: 70 }
};
