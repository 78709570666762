import classNames from "classnames";
import SvgImage from "components/common/image/SvgImage";
import { usePageHeaderFilterState } from "components/layout/PageHeaderFilterComponent/PageHeaderFilterBaseComponent";
import { PageHeaderFilterComponent } from "components/layout/PageHeaderFilterComponent/PageHeaderFilterComponent";
import PageHeaderFilterContext from "components/layout/PageHeaderFilterComponent/PageHeaderFilterContext";
import { ReactNode, useCallback, useContext } from "react";
import { Button, Card } from "react-bootstrap";
import { Option, OptionItem } from "../../../common/SearchCard/FilterDropdownItem";

export type SortDirection =  'asc' | 'desc';


interface SortFilterContentProps<OptionType> {
    className?: string;
    title: string;
    onSelect?: (event: SortChangeEvent<OptionType>) => void;
    options: OptionType[];
    selectedOption: OptionType;
    selectedDirection: SortDirection;
}

interface SortChangeEvent<OptionType> {
    option: OptionType;
    direction: SortDirection;
}


interface SortFilterContentState<OptionType> {
    option: OptionType | null;
    direction: SortDirection | null;    
}

function SortFilterContent<OptionType extends Option<any> = OptionItem>({ className, title, onSelect, options, selectedOption, selectedDirection }: SortFilterContentProps<OptionType>) {
    let [state, setState] = usePageHeaderFilterState<SortFilterContentState<OptionType>>({ option: null, direction: null });
    let context = useContext(PageHeaderFilterContext);

    let closeAction = useCallback(() => {
        if (context) {
            context.close();
        }
        setState({option: null, direction: null});
    }, [context, setState]);

    let currentOption = state.option || selectedOption;
    let currentDirection = state.direction || selectedDirection;

    return (
        <Card className={classNames(className, "sort-filter px-3 pt-1 border-0")}>
            <Card.Header className="bg-white pb-0 pt-1 px-0 d-flex justify-content-between">
                <h6 className="font-weight-bold pt-2">
                    {title}
                </h6>
                <span className="close-modal-butt svg-icon mt-n1 mr-n1 cursor-pointer" onClick={() => closeAction()}>
                    <SvgImage icon="icon_close_blue.svg" imgClass="svg-blue" convert={false} width="27" />
                </span>
            </Card.Header>
            <Card.Body className="d-flex flex-wrap py-3 px-0">
                {
                    options.map(current =>
                        <Button variant={'inline-inverse'} onClick={() => {
                            setState({ ...state, option: current })
                        }} className={currentOption.id === current.id ? "btn active" : "btn"}>
                            {current.name}
                        </Button>
                    )
                }
            </Card.Body>
            <Card.Footer className="px-0 bg-white">
                <Button variant={'inline-inverse'} onClick={() => {
                    setState({ ...state, direction: 'asc' })
                }} className={currentDirection === 'asc' ? "active btn" : "btn"}>
                    <SvgImage icon={state.direction ? "icon_arrow_short_fullup_white.svg" : "icon_arrow_short_fullup_blue.svg"} width="20" />
                    Aufsteigend
                </Button>
                <Button variant={'inline-inverse'} onClick={() => {
                    setState({ ...state, direction: 'desc' })
                }} className={currentDirection === 'desc' ? "active btn" : "btn"}>
                    <SvgImage icon={!state.direction ? "icon_arrow_short_fullup_white.svg" : "icon_arrow_short_fullup_blue.svg"} style={{ transform: "rotate(180deg)" }} width="20" />
                    Absteigend
                </Button>
                <div className="d-flex justify-content-end pb-1 pt-3 pr-0 bg-white">
                    <Button variant={'inline-action'} className="px-0"
                        onClick={() => {
                            if (onSelect) {
                                onSelect({option: currentOption, direction: currentDirection});
                            }
                            closeAction();
                        }}
                    >
                        <img className="check_icon mr-1" src={process.env.PUBLIC_URL + "/static/img/svg/icon_check_hook_green.svg"}
                            width="12" alt="Green check icon" />
                        Anwenden
                    </Button>
                </div>
            </Card.Footer>
        </Card>
    );
}

interface SortFilterProps<OptionType> {
    onSelect?: (event: SortChangeEvent<OptionType>) => void;
    options: OptionType[];
    selectedOption: OptionType;
    selectedDirection: SortDirection;

    title?: ReactNode
    className?: string;
}

export function GenericSortFilter<OptionType extends Option<any> = OptionItem>(props: SortFilterProps<OptionType>) {
    return (
        <PageHeaderFilterComponent
            variant={"dropdown-panel"}
            toggleVariant={"panel-button"}
            toggleIcon={process.env.PUBLIC_URL + "/static/img/svg/icon_direction_down_white.svg"}
            title={props.title ? props.title : "Sortierung"}
            className={classNames("mx-xl-0 mx-lg-n2", props.className)}
            description={
                <>
                    <img src="/static/img/svg/icon_arrow_short_fullup_white.svg" 
                        style={{ transform: props.selectedDirection === 'asc' ? "" : "rotate(180deg)" }}
                        width="20" className="svg-convert" alt="" />
                    {props.selectedOption.name}
                </>
            }
        >
            <SortFilterContent<OptionType>
                title={"Sortierung"}
                options={props.options}
                selectedOption={props.selectedOption}
                selectedDirection={props.selectedDirection}
                onSelect={v => {
                    if (props.onSelect) {
                        props.onSelect(v);
                    }
                }}
            />
        </PageHeaderFilterComponent>
    );
}

