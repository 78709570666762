import React, {Suspense} from "react";
import {Spinner} from "react-bootstrap";
const FTChartComponent = React.lazy(() =>
    import("./FTChartComponent")
);
interface FTLChartComponentProps {
    options: any;
    callback?: any;
    constructorType?: any;
    containerProps?: any;
    thresholds?: any;
    style?: any;
    className?: any;
}

/**
 * Lazy loaded chart component
 * Recommended to use it instead of highcharts-react to keep the code splitting optimizations intact
 * Extend it if some props are not available from highcharts-react or highcharts
 * @param props
 * @constructor
 */
export default function FTLChartComponent (props: FTLChartComponentProps) {
    return(
        <Suspense fallback={<div style={{display:"flex",justifyContent:"center"}}><Spinner animation='border'/></div>}>
            <FTChartComponent
                options={props.options}
                callback={props?.callback}
                thresholds={props?.thresholds}
                constructorType={props?.constructorType}
                containerProps={props?.containerProps}
                style={props?.style}
                className={props?.className}
            />
        </Suspense>
    );

}