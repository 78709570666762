import Interweave from "interweave";

function OutageAlert(props: { message: string }) {
    return (
        <>
            {props.message && props.message.length > 0 &&
                <div className="text-white font-size-14px" role="alert-header" id="show-alert-text">
                    <Interweave content={props.message}/>
                </div>
            }
        </>
    );
}

export default OutageAlert;