import classNames from "classnames";
import { PageHeaderFilterComponent } from "components/layout/PageHeaderFilterComponent/PageHeaderFilterComponent";
import PageHeaderFilterContext from "components/layout/PageHeaderFilterComponent/PageHeaderFilterContext";
import {useCallback, useContext, useState} from "react";
import { Button, Card } from "react-bootstrap";
import {guessInfonlineSection, trigInfonline} from "../InfonlineService";
import {useMobileMediaQuery, useTabletMediaQuery, useDesktopMediaQuery} from "hooks/useMediaQuery";

type IdType = number | string
export type OptionItem = Option<string>;

export interface Option<ID> {
    id: ID;
    name: string;
    disabled?: boolean;
}

// Supports only 1 row of options for now
export function DefaultFilterBody<OptionIdType extends IdType, FilterOptionType extends FilterOption<OptionIdType>>({ className, title, options, onSelect, selected, style }: TransactionFiltersFundProps<OptionIdType, FilterOptionType>) {
    const context = useContext(PageHeaderFilterContext);
    const closeAction = useCallback(() => {
        if (context) {
            context.close();
        }
    }, [context]);

    return (
        <>
            <Card className={classNames(className, "options-filter px-3 pt-1 border-0")}>
                <Card.Header className="bg-white pb-0 pt-1 px-0 d-flex justify-content-between d-none d-xl-flex d-sm-none d-md-none d-lg-none">
                    <h6 className="font-weight-bold pt-2">{title}</h6>
                    <span className="close-modal-butt svg-icon mt-1 mr-n1 cursor-pointer" onClick={() => closeAction()}>
                        <img src={process.env.PUBLIC_URL + "/static/img/svg/icon_close_dark.svg"} alt="" className="svg-convert svg-blue" />
                    </span>
                </Card.Header>
                <Card.Body className="d-flex justify-content-center justify-content-xl-start px-0" style={style}>
                    <section className="d-flex flex-column">
                        <div className="d-flex flex-wrap button-container">
                            {
                                options[0].options.map(current =>
                                    <Button key={current.id}
                                        variant={'inline-inverse text-truncate'}
                                        onClick={() => {
                                            if (onSelect) {
                                                onSelect({
                                                    [options[0].id]: current.id
                                                })
                                            }
                                            closeAction();
                                            trigInfonline(guessInfonlineSection(), "search_result_Drop_Down")
                                        }}
                                        className={classNames("m-1", selected?.id === current.id && "active")}
                                    >
                                        {current.name}
                                    </Button>
                                           )
                            }
                        </div>
                    </section>
                </Card.Body>
            </Card>
        </>
    );
}

export type SelectedOptionsType<OptionIdType extends IdType> = {
    [key: string]: OptionIdType
}
interface TransactionFiltersFundProps<OptionIdType extends IdType, FilterOptionType extends FilterOption<OptionIdType>> {
    className?: string;
    title: string;
    onSelect: (filters: SelectedOptionsType<OptionIdType>) => void;
    options: Array<FilterOptionType>;
    selected?: SelectedOptionsType<OptionIdType>;
    style?: any;
}
// FR: TODO: Rename to PortfolioFilterBody and probably DefaultFilterBody
export function TransactionFilterBody<OptionIdType extends IdType, FilterOptionType extends FilterOption<OptionIdType>>({ className, options, onSelect, selected, style }: TransactionFiltersFundProps<OptionIdType, FilterOptionType>) {
    const context = useContext(PageHeaderFilterContext);
    const [selectedOptions, setSelectedOptions] = useState<SelectedOptionsType<OptionIdType>>(selected as SelectedOptionsType<OptionIdType>)

    const isMobile = useMobileMediaQuery()
    const isTablet = useTabletMediaQuery()
    const isDesktop = useDesktopMediaQuery()

    const closeAction = useCallback(() => {
        if (context) {
            context.close();
        }
    }, [context]);

    return (
        <>
            <Card className={classNames(className, "mt-0 mt-md-60px mt-xl-0 pt-0 pt-md-30px pt-xl-10px pb-0 pb-md-3 px-0 px-xl-3 border-0", {'h-100': isMobile})}>
                {isDesktop &&
                <>
                    <div className="d-flex justify-content-between bg-white line-height-24">
                        <h6 className="my-0 font-weight-bold">Zeitraum auswählen</h6>
                        <span className="mt-n1 mb-n2 close-modal-butt svg-icon cursor-pointer" onClick={() => closeAction()}>
                            <img src={process.env.PUBLIC_URL + "/static/img/svg/icon_close_dark.svg"} alt="" className="svg-convert svg-blue" style={{color:""}}/>
                        </span>

                    </div>
                    <hr className="my-0 mx-auto" style={{width:"95%"}}/>
                </>
                }
                <Card.Body className={classNames("d-flex flex-column px-0 pt-0 pb-0 pb-md-2 pb-xl-10px line-height-18px", {'mx-auto': isTablet})}  style={{width: isDesktop ? "452px" : "280px", ...style}}>
                    {options.map((optionsRow: FilterOptionType, index: number) => {
                        let showOptionRow = true
                        if (optionsRow.dependsOn) {
                            optionsRow.dependsOn.forEach((dependancy: string) => {
                                const selectedOptionId = selectedOptions[dependancy]
                                const optionIsValid = options.find((optRow: FilterOptionType) => optRow.id === dependancy)?.options.find((option: OptionNew<OptionIdType>) => option.id === selectedOptionId)?.valid === true
                                if (!selectedOptions.hasOwnProperty(dependancy) || !optionIsValid)
                                    showOptionRow = false
                            });
                        }
                        return (
                            <>
                                {showOptionRow &&
                                <section className="d-flex flex-column pt-1">
                                    <div className="mt-10px font-size-14px font-weight-bold">{optionsRow.name}</div>
                                    <section className="d-flex flex-wrap gap-2 mt-1 pt-2px">
                                        { optionsRow.options.map((option: OptionNew<OptionIdType>, optionIdx: number) => {
                                            let active = option.enabled && (!option.dependsOn || option.dependsOn.length === 0)
                                            if (option.dependsOn && option.dependsOn.length > 0) {
                                                option.dependsOn.forEach((dependancy: Dependancy<OptionIdType>) => {
                                                    const optionIsSelectFromDependancyOptionRow = selectedOptions.hasOwnProperty(dependancy.filterId)
                                                    const dependancyMet = selectedOptions[dependancy.filterId] === dependancy.optionId
                                                    if (optionIsSelectFromDependancyOptionRow && dependancyMet)
                                                        active = true
                                                })
                                            }
                                            return (
                                                <Button key={option.id}
                                                    disabled={!active}
                                                    variant={selectedOptions[optionsRow.id] === option.id ? "primary" : "inline-inverse"}
                                                    className={classNames("my-0 mx-0 border-0 text-truncate", {'text-gray': !active})}
                                                    onClick={() => {
                                                        setSelectedOptions((currentSelected: SelectedOptionsType<OptionIdType>) => {
                                                            const newSelected = {
                                                                ...currentSelected,
                                                                [optionsRow.id]: option.id
                                                            }
                                                            // If the dependancy for the row was changed select the default (first invalid) option of that row
                                                            Object.keys(currentSelected).forEach((optionRowId: string) => {
                                                                const selectedValue = currentSelected[optionRowId]
                                                                const selectedOptionOptionRow: FilterOptionType = options.find(optionRow => optionRow.id === optionRowId)!
                                                                const selectedOption: OptionNew<OptionIdType> = selectedOptionOptionRow.options.find(option => option.id === selectedValue)!
                                                                selectedOption.dependsOn?.forEach(selectedOptionDependancy => {
                                                                    const dependancyOptionWasChanged = newSelected[selectedOptionDependancy.filterId] !== currentSelected[selectedOptionDependancy.filterId]
                                                                    if (dependancyOptionWasChanged) {
                                                                        const optionsInRow = selectedOptionOptionRow.options
                                                                        newSelected[optionRowId] = optionsInRow.find(option => !option.valid)!.id
                                                                    }
                                                                })
                                                            })
                                                            return newSelected
                                                        })
                                                    }}>{option.name}</Button>
                                            )
                                        }) }
                                    </section>
                                </section>}
                            </>
                        )
                    })}
                </Card.Body>
                <div className="d-flex align-items-center justify-content-end mb-0 mb-md-3 mb-xl-0 cursor-pointer line-height-18px">
                    <button className="d-flex p-0 border-none bg-transparent" onClick={() => {onSelect(selectedOptions); closeAction()}}>
                        <img className="check_icon" src="/static/img/svg/icon_check_hook_green.svg" width="12" alt="Green check icon" />
                        <p className="m-0 font-size-14px" style={{color:"#326EAC"}}>Anwenden</p>
                    </button>
                </div>

            </Card>
        </>
    );
}

interface FilterProps<Type extends OptionItem> {
    activeId?: string;
    onSelect: (e: Type) => void;
    options: Type[]; // Type = {id: string, name: string, ...}
    style?: any;
}

/** @deprecated Use `FilterDropdownMultiItem` instead */
export function FilterDropdownItem<Type extends OptionItem>(props: FilterProps<Type>) {
    const {options, onSelect, activeId, style} = props
    if (options.length < 1) {
        return <></>;
    }
    const transformedOptions: FilterOption<string>[] = [
        {
            id: "options",
            name: "options",
            options: options.map((option: Type) => ({ ...option, enabled: true, valid: true }))
        }
    ]
    let activeOptionFound = false
    if (activeId)
        activeOptionFound = options.find(option => option.id === activeId) != null
    const activeOptions: SelectedOptionsType<string> = {
        options: activeOptionFound && activeId != null ? activeId : options[0].id
    }
    const transformedOnSelect = (selectedOptions: SelectedOptionsType<string>) => {
        // selectedOptions = {optionsId: optionId}
        // {id: string, name: string, ...} // the option itself
        const selectedOption: Type = options.find((option: Type) => option.id === selectedOptions['options'])!
        onSelect(selectedOption)
    }

    return (
        <FilterDropdownMultiItem options={transformedOptions}
            onSelect={transformedOnSelect}
            activeOptions={activeOptions}
            style={style}
            filterBody={DefaultFilterBody} />
    )
}
export type Dependancy<OptionIdType> = {
    filterId: string
    optionId: OptionIdType
}
export interface OptionNew<OptionIdType> {
    id: OptionIdType
    name: string
    /** Should this option be enabled/clickable */
    enabled: boolean
    /** Should this option be considered when checking for selected options in the option row for `dependsOn` argument.
    * The first such option is also the default option therefore there must be at least 1 invalid option */
    valid: boolean
    dependsOn?: Array<Dependancy<OptionIdType>>
}
export interface FilterOption<OptionIdType extends number | string> {
    id: string
    name: string
    options: Array<OptionNew<OptionIdType>>
    /** List of `filterId`. Disable filter row if no option is selected from filters with those `filterId`s. All filters should match */
    dependsOn?: Array<string>
}
type FilterMultiProps<OptionIdType extends number | string, FilterOptionType extends FilterOption<OptionIdType>> = {
    activeOptions?: SelectedOptionsType<OptionIdType>
    onSelect: (selectedOptions: SelectedOptionsType<OptionIdType>) => void
    options: FilterOptionType[]
    filterBody: React.ComponentType<TransactionFiltersFundProps<OptionIdType, FilterOptionType>>
    style?: any
}
export function FilterDropdownMultiItem<OptionIdType extends IdType, FilterOptionType extends FilterOption<OptionIdType>>({options, onSelect, activeOptions, style, filterBody: FilterBody}: FilterMultiProps<OptionIdType, FilterOptionType>) {
    const isMobile = useMobileMediaQuery()
    if (options.length < 1) {
        return <></>;
    }

    const activeOptionsNames: string[] = activeOptions !== undefined ?
        options.map(optionGroup => optionGroup.options.filter(option => option.id === activeOptions[optionGroup.id])[0]?.name)
            : [options[0].options[0].name]
    const title = activeOptionsNames.every((subTitle: string) => subTitle === "Alle") ? "Alle" : activeOptionsNames.join(" ")

    return (
        <PageHeaderFilterComponent title="Zeitraum auswählen" dropdownToggleTitle={title}
            variant={"dropdown-plane-text"}
            toggleVariant={"dropdown-plane-text"}
            toggleIcon={process.env.PUBLIC_URL + "/static/img/svg/icon_direction_down_blue.svg"}
            modalBodyClassName="px-2 px-md-3 pb-30px pb-md-0"
            modalDialogClassName={classNames({'h-75': isMobile})}
            modalContentClassName={classNames({'h-100': isMobile})}
            className="fund-filter-dropdown-item">
            <FilterBody
                title={title}
                onSelect={(selectedOptions: SelectedOptionsType<OptionIdType>) => {
                    if (onSelect)
                        onSelect(selectedOptions);
                }}
                selected={activeOptions}
                options={options}
                style={style}
            />

        </PageHeaderFilterComponent>
    );
}
