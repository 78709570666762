import {ReactNode} from "react";
import classNames from "classnames";
import {PageHeaderFilterBaseComponent} from "./PageHeaderFilterBaseComponent";

interface PageHeaderFilterComponentProps {
    title: string | ReactNode;
    dropdownToggleTitle?: string | ReactNode;
    description?: string | ReactNode;
    variant?: string | undefined;
    toggleVariant?: string | undefined;
    toggleIcon?: string | undefined;
    children?: ReactNode | undefined;
    className?: string | undefined;
    selectedId?: string | undefined;
    disabled?: boolean | undefined;
    disableIcon?: boolean | undefined;
    disableDescriptionEffect?: boolean;
    customTitleClass?: string;
    customDescriptionClass?: string;
    customButtonSize?:string;
    click?:() => void;
    isTerminePage?: boolean
    isWerpapierFilter?:boolean
    wertpapierStyle?:string
    dropdownToggleStyle?: React.CSSProperties;
    dropdownToggleWrapperStyle?: React.CSSProperties;
    modalBodyClassName?: string;
    modalDialogClassName?: string;
    modalContentClassName?: string;
    modalScrollable?: boolean;
}

export function PageHeaderFilterComponent({modalScrollable = true, ...props}: PageHeaderFilterComponentProps) {
    return (
        <PageHeaderFilterBaseComponent
            variant={props.variant}
            toggleVariant={props.toggleVariant}
            title={props.title}
            disabled={props.disabled || false}
            className={classNames(props.className, props.description ? "active" : "")}
            dropdownToggleWrapperStyle={props.dropdownToggleWrapperStyle}
            dropdownToggleStyle={props.dropdownToggleStyle}
            modalBodyClassName={props.modalBodyClassName}
            modalDialogClassName={props.modalDialogClassName}
            modalContentClassName={props.modalContentClassName}
            modalScrollable={modalScrollable}
            toggle={
                <div className={"d-flex justify-content-between"} onClick={()=>props.click && props.click()}>
                    <div className={classNames("button-body px-3 py-1 overflow-hidden custom-class-button", props.customButtonSize)}>
                        <span className={classNames("d-block title", !props.description && 'single-line', props.customTitleClass)}>{props.dropdownToggleTitle === undefined ? props.title : props.dropdownToggleTitle}</span>
                        	{props.description && <span className={classNames(props.isTerminePage ? "d-block text-truncate termine-dropdown-btn description" : "d-block description text-truncate",props.wertpapierStyle)}>{props.description}</span>}
                    </div>
                    {props.isWerpapierFilter ? "":
                    <>
                    {
                        (props?.disableIcon) ||
                        <img
                            src={props.toggleIcon || process.env.PUBLIC_URL + "/static/img/svg/icon_direction_down_dark.svg"}
                            className="toggle-icon" alt="Dropdown arrow down"/>
                    }
                    </>
            }
                </div>
            }
        >
            {props.children}
        </PageHeaderFilterBaseComponent>
    )
}
