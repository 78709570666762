import { useQuery } from "@apollo/client";
import { BaseCardInfoComponent } from "components/common/SearchCard/BaseSearchCard";
import { loader } from "graphql.macro";
import { Query, SearchShareCriterion, ShareSearchKeyFigure, ShareSortField, Period } from "graphql/types";
import { ShareSearchResultInfoWrapper } from "./ShareSearchResultInfo";
import { ShareSearchCardDetails } from "./BaseShareSearchCard";
import InfiniteScroll from "components/common/scroller/InfiniteScroller";
import { Button, Spinner, Table } from "react-bootstrap";
import { ResultRowProps } from "./utils";
import { ScreenMode, useScreenMode } from 'hooks/useMediaQuery';
import { Link } from "react-router-dom";
import { getFinanztreffAssetLink, numberFormatDecimals } from "utils";
import { Kurs, Region, ShareName, ShowChangeArrowIcon } from "./cards/common/CommonTableElements";

import { mapShareSearchRow } from "./utils";
import { SortDirection } from "components/devisen/CurrencyFindSection/filters/GenericSortFilter";
import { useState } from "react";
import { ProfileInstrumentAddPopup } from "components/common/modals/ProfileInstrumentAddPopup";
import SvgImage from "components/common/image/SvgImage";
import './ShareTableResult.scss'

export function ShareTableResult(props: BaseCardInfoComponent<SearchShareCriterion, ShareSearchCardDetails> & {columns: ShareTableColumn[]}) {
    let screenMode = useScreenMode();
    let [sort, setSort] = useState<ShareTableSort>(props.details?.sort ?? {field: ShareSortField.Performance, direction: 'asc'})
    let {loading, data, fetchMore} = useQuery<Query>(
        loader('./getShareSearchResult.graphql'),
         {
            variables: {
                criterion: {
                    ...props.criteria
                },
                sort: [{field: sort.field, descending: sort.direction === 'desc'}],
                first: 30,
                after: null
            }
        }
    );

    let filterColumn = props.columns.filter(current => !current.canActivate || current.canActivate({criteria: props.criteria, details: props.details || undefined}));
    const COLUMNS = [DEFAULT_ROW_START, ...filterColumn, DEFAULT_ROW_END];

    return (
        <div className={"mt-4"}>
            <div className="ml-n3 mr-n3">
                <div className="bg-white mt-3 mx-lg-2 mx-md-2 borderless">
                    <h5 className="font-weight-bold pl-3 pt-3 float-left py-2 pb-1" style={{fontSize: '18px', fontFamily: 'Roboto Slab'}}>
                        {loading ?<Spinner animation="border" size="sm"/> : <>Ergebnisse ({data?.searchShare?.count}) </>}
                    </h5>
                    <InfiniteScroll
                        className="w-100"
                        style={{overflow:'none'}}
                        scrollableTarget="share-search-results"
                        next={async () => { await fetchMore({variables: {after: data?.searchShare?.pageInfo?.endCursor}})}}
                        hasMore={!!(data && data.searchShare && data.searchShare.pageInfo && data.searchShare.pageInfo.hasNextPage)}
                        loader={<div className="text-center" style={{height: 25}}><Spinner animation="border" size="sm"/></div>}
                        dataLength={data?.searchShare?.edges?.length || 0}
                    >
                        <Table className="table-borderless">
                            <thead className="bg-gray-light">
                                <tr>
                                    {COLUMNS.map(current => current.renderHead && current.renderHead({screenMode, sort, setSort}))}
                                </tr>
                            </thead>
                            <tbody>
                                {data?.searchShare.edges
                                    .map(current => mapShareSearchRow(current.node, props.details?.regionName || null))
                                    .map(instrument => (
                                        <tr className="border-bottom border-border-gray bg-white" key={instrument.groupId}>
                                            {COLUMNS.map(current => current.renderBody && current.renderBody({result: instrument, screenMode}))}
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </InfiniteScroll>
                </div>
            </div>
        </div>
    );
}

export function generateTableRender(columns: ShareTableColumn[]) {
    return (props: BaseCardInfoComponent<SearchShareCriterion, ShareSearchCardDetails>) => (
        <ShareSearchResultInfoWrapper {...props} ResultTable={() => (<ShareTableResult {...props} columns={columns}/>)}/>
    );
}

// -- To Be separated

interface ShareTableSortIconProps {
    active: boolean;
    direction: SortDirection;
}

function ShareTableSortIcon({active, direction}: ShareTableSortIconProps) {
    if (!active) return (<></>);
    return (
            <span style={{transform: direction === 'asc' ? "rotate(-90deg)" : "rotate(90deg)"}} className={"sort-arrow svg-icon"}>
                <img src={process.env.PUBLIC_URL + "/static/img/svg/icon_arrow_long_right_dark.svg"} alt={""}/>
            </span>
    );
}


interface ShareTableHeadProps {
    screenMode: ScreenMode;
    sort: ShareTableSort;
    setSort?: (sort: ShareTableSort) => void;
}

interface ShareTableRowProps {
    screenMode: ScreenMode;
    result: ResultRowProps;
}

export interface ShareTableSort {
    field: ShareSortField;
    direction: SortDirection;
}

export interface ShareTableActivateProps {
    details?: ShareSearchCardDetails;
    criteria: SearchShareCriterion;
}

interface ShareTableColumn {
    canActivate?: (props: ShareTableActivateProps) => boolean;
    renderHead?: (props: ShareTableHeadProps) => JSX.Element;
    renderBody?: (props: ShareTableRowProps) => JSX.Element;
}

export const DEFAULT_ROW_START = {
    renderHead: ({screenMode}: ShareTableHeadProps) => {
        switch (screenMode) {
            case ScreenMode.PHONE:
                return (
                    <th className="text-left py-3" style={{width: "50px"}}>
                        Bezeichnung <br/> Kurs
                    </th>
                );
            case ScreenMode.TABLET:
                return (
                    <>
                        <th className="text-left py-3" style={{width: "50px"}}>Bezeichnung <br/> Kurs</th>
                        <th className="text-left d-none d-lg-table-cell d-xl-table-cell py-3" style={{width: "100px"}}>Region</th>
                        <th className="text-left d-none d-lg-table-cell d-xl-table-cell d-md-table-cell d-sm-none py-3" style={{width: "80px"}}>Branchen</th>
                    </>
                );

            default:
                return (
                    <>
                        <th className="text-left d-none d-xl-table-cell py-3" style={{width: "250px"}}>Bezeichnung</th>
                        <th className="text-center d-none d-xl-table-cell py-3" style={{width: "145px"}}>Kurs</th>
                        <th className="text-left d-none d-lg-table-cell d-xl-table-cell py-3" style={{width: "100px"}}>Region</th>
                        <th className="text-left d-none d-lg-table-cell d-xl-table-cell d-md-table-cell d-sm-none py-3" style={{width: "80px"}}>Branchen</th>
                    </>
                );
        }
    },
    renderBody: ({result: props, screenMode}: ShareTableRowProps) => {
        switch (screenMode) {
            case ScreenMode.PHONE:
                return (
                    <td className="font-weight-bold share-name-column" style={{maxWidth: "200px"}}>
                        {props.securityCategoryId && props.seoTag ?
                            <Link to={(getFinanztreffAssetLink(props.securityCategoryId, props.seoTag, props.exchangeCode))}
                                className="ml-xl-n2">{props.name}</Link> :
                            <>{props.name}</>
                        }
                        <br/>
                        <span className="font-weight-bold d-flex font-size-15px text-right"><span>{props.lastPrice} {props.lastPrice !== '-' && <span>{props.displayCode}</span>}</span></span>
                        <ShowChangeArrowIcon percentChange={props.percentChange}/>
                    </td>            
                );
            case ScreenMode.TABLET:
                return (
                    <>
                        <td className="font-weight-bold share-name-column" style={{maxWidth: "200px"}}>
                            {props.securityCategoryId && props.seoTag ?
                                <Link to={(getFinanztreffAssetLink(props.securityCategoryId, props.seoTag, props.exchangeCode))}
                                    className="ml-xl-n2">{props.name}</Link> :
                                <>{props.name}</>
                            }
                            <br/>
                            <span className="font-weight-bold d-flex font-size-15px text-right"><span>{props.lastPrice} {props.lastPrice !== '-' && <span>{props.displayCode}</span>}</span></span>
                            <ShowChangeArrowIcon percentChange={props.percentChange}/>
                        </td>
                        <Region region={props.region} />
                        <td className={`text-left font-weight-bold pt-3 mb-0 pb-2`}>{props.sector}</td>
                    </>            
                );

            default:
                return (
                    <>
                        <ShareName seoTag={props.seoTag} bezeichnung={props.name} securityCategoryId={props.securityCategoryId} />
                        <Kurs displayCode={props.displayCode} kurs={props.lastPrice} percentChange={props.percentChange} className="d-flex"/>
                        <Region region={props.region} />
                        <td className={`text-left font-weight-bold pt-3 mb-0 pb-2`}>{props.sector}</td>
                    </>
                );
        }

    }
}

export const DEFAULT_ROW_END = {
    renderHead: () => {
        return (
            <>
                <th className="text-center p-0 m-0">&nbsp;</th>
                <th className="text-center p-0 m-0">&nbsp;</th>
            </>
        );
    },
    renderBody: ({result: props, screenMode}: ShareTableRowProps) => {
        switch (screenMode) {
            case ScreenMode.PHONE:
            case ScreenMode.TABLET:
                return (
                    <>
                        <td className="px-0 text-right pt-3 mb-0 pb-2 ">
                            <ProfileInstrumentAddPopup
                                className="text-white p-0 m-0"
                                instrumentGroupId={props.groupId}
                                instrumentId={props.id}
                                name={props.name}
                                watchlist={true}
                                portfolio={true}
                                emptyPortfolioText={'Noch keinem Portfolio hinzugefügt'}
                                emptyWatchlistText={'Noch nicht beobachtet'}
                            >
                                <SvgImage icon="icon_plus_blue.svg" spanClass="action-icons" imgClass="plus-butt-icon" />
                            </ProfileInstrumentAddPopup>
                        </td>
                        <td className="text-center p-0 m-0">&nbsp;</td>
                    </>
                );
            case ScreenMode.DESKTOP:
            default:
                return (
                    <>
                        <td className="d-xl-table-cell d-none px-0 text-right pt-3 mb-0 pb-2 ">
                            <ProfileInstrumentAddPopup
                                className="text-white p-0 m-0"
                                instrumentGroupId={props.groupId}
                                instrumentId={props.id}
                                name={props.name}
                                watchlist={true}
                                portfolio={true}
                                emptyPortfolioText={'Noch keinem Portfolio hinzugefügt'}
                                emptyWatchlistText={'Noch nicht beobachtet'}
                                direction={'left'}
                            >
                                <SvgImage icon="icon_plus_blue.svg" spanClass="action-icons" imgClass="plus-butt-icon" />
                            </ProfileInstrumentAddPopup>
                        </td>
                        <td className="text-center p-0 m-0">&nbsp;</td>
                    </>
                );
        }
    }
}

export function generateBasicColumn(name: string, sortField: ShareSortField | null, render: (result: ResultRowProps, screenMode: ScreenMode) => JSX.Element) {
    return {
        renderHead: ({sort, setSort}: ShareTableHeadProps) => {
            const toggleSort = () => {
                if (!!sortField && !!setSort) {
                    if (sort.field !== sortField) {
                        setSort({field: sortField, direction: 'asc'});
                    } else {
                        setSort({field: sortField, direction: sort.direction === 'asc' ? 'desc' : 'asc'});
                    }
                }
            };

            return (
                <th className="text-right" style={{width: "250px"}}>
                    {(!!sortField && !!setSort) ?
                        <>
                            <Button variant="link table-header" className={"can-sort text-blue cursor-pointer "} onClick={toggleSort}>
                                {name}
                                <ShareTableSortIcon active={sort?.field === sortField} direction={sort?.direction} />
                            </Button>
                        </>
                        : 
                        <>{name}</>
                    }
                </th>
            );
        },
        renderBody: ({screenMode, result}: ShareTableRowProps) => {
            switch (screenMode) {
                case ScreenMode.PHONE:
                    return (
                        <td className={`text-right pt-xl-3 mb-0 pb-2 pl-0`} style={{width: '250px'}}>
                            {render(result, screenMode)}
                        </td>
                    )
                default:
                    return (                   
                        <td className="text-right" style={{width: "250px"}}>{render(result, screenMode)}</td>
                    );        
            }
        }

    }
}


export function generatePeriodKeyFigureColumn(name: string, criteriaField: ShareSearchKeyFigure, periodId: Period, sortField: ShareSortField | null, 
    render: (result: ResultRowProps, screenMode: ScreenMode) => JSX.Element) {
    return {
        ...generateBasicColumn(name, sortField, render),
        canActivate: ({criteria}: ShareTableActivateProps) => {
            return criteria.period === periodId && !!criteria.ranges.find(current => current.keyFigure === criteriaField);
        },
    }
}

export const COLUMN_VOLATILITY = {
    ...generatePeriodKeyFigureColumn(
            'Volatility', 
            ShareSearchKeyFigure.Volatility, Period.Last_1Month, ShareSortField.Volatility, 
            (result) => (<>{!!result?.volatility1Month && result.volatility1Month < 1 ? numberFormatDecimals(result.volatility1Month, 3, 3, '%') : numberFormatDecimals(result.volatility1Month, 2, 2, '%')}</>)
        ),
    canActivate: ({criteria}: ShareTableActivateProps) => {
        return !!criteria.ranges.find(current => current.keyFigure === ShareSearchKeyFigure.Volatility);
    }
}
