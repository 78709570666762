import {ApolloClient, InMemoryCache, createHttpLink, ApolloLink, split} from '@apollo/client';
import Keycloak from "keycloak-js";
import {NextLink, Operation} from "@apollo/client/link/core/types";
import {getMainDefinition, relayStylePagination} from "@apollo/client/utilities";
//import {WebSocketLink} from "@apollo/client/link/ws";
//import {SubscriptionClient} from "subscriptions-transport-ws";
import {GraphQLWsLink} from '@apollo/client/link/subscriptions';
import {createClient} from 'graphql-ws';
import {applicationContextVar} from 'ApplicationContext';
import {SnapQuote} from "../generated/graphql";

export function creatApolloClient(keycloak: Keycloak.KeycloakInstance) {
    const httpLink = createHttpLink({
        uri: '/graphql',
    });

    const GRAPHQL_ENDPOINT = `${window.location.protocol === "https:" ? "wss://" : "ws://"}${window.location.host}/graphql-ws`
    const wsLink = new GraphQLWsLink(
        createClient({
            url: GRAPHQL_ENDPOINT,
            keepAlive: 10_000,
            on: {
                closed: () => {
                    const applicationContext = applicationContextVar()
                    applicationContextVar({...applicationContext, pushActive: false})
                }
            }
        })
    )
    /*
       const oldWsLink = new WebSocketLink(
       new SubscriptionClient( GRAPHQL_ENDPOINT, { reconnect: false })
       );
       */

    const withToken = new ApolloLink((operation: Operation, forward: NextLink) => {
        if (keycloak.token) {
            operation.setContext({
                // headers: {"JWT-Assert": `Bearer ${keycloak.token}`}
                headers: {"JWT-Assert": keycloak.token}
            });
        }
        return forward(operation);
    });

    const splitLink = split(
        ({ query }) => {
            const definition = getMainDefinition(query);
            return (
                definition.kind === 'OperationDefinition' &&
                    definition.operation === 'subscription'
            );
        },
        withToken.concat(wsLink),
        withToken.concat(httpLink),
    );

    return new ApolloClient({
        link: splitLink,
        defaultOptions: {query : {errorPolicy: "ignore"}},
        cache: new InMemoryCache({
            typePolicies: {
                Query: {
                    fields: {
                        applicationContext: {
                            read: () => {
                                return applicationContextVar()
                            }
                        },
                        searchDerivative: relayStylePagination(["criterion", "sort"]),
                        search: relayStylePagination(["criteria"]),
                        newsSearch: relayStylePagination(["criteria"]),
                        analysisSearch: relayStylePagination(["criteria"]),
                        searchShare: relayStylePagination(["criterion", "sort"]),
                        searchIndex: relayStylePagination(["criteria"]),
                        searchFund: relayStylePagination(["criterion", "sort"]),
                        searchEtf: relayStylePagination(["criterion", "sort"]),
                        searchBond: relayStylePagination(["criterion", "sort"]),
                        searchCrossRate: relayStylePagination(["criteria", "sort"]),
                        searchCalendarEvents: relayStylePagination(["criteria"])
                    }
                },
                SnapQuote: {
                    keyFields: ["instrumentId"],
                },
                News: {
                    merge: true,
                },
                Quote: {
                    merge: true,
                },
                InstrumentGroup: {
                    fields: {
                        analysis: relayStylePagination(["criteria"]),
                    }
                },
                Instrument: {
                    fields: {
                        snapQuote: {
                            merge: (existing: SnapQuote, incoming: SnapQuote, cache) => {
                                if (existing && incoming && existing.lastChange && incoming.lastChange) {
                                    if (existing.lastChange > incoming.lastChange) return existing;
                                    if (incoming.lastChange > existing.lastChange) return incoming;
                                }
                                return incoming;
                            }
                        }
                    }
                }
            }
        })
    });
}
