import { PieChartColors } from "components/profile/utils";
import {EtfDomicile, EtfIssuer, Query} from "../../../generated/graphql";
import {useQuery} from "@apollo/client";
import {loader} from "graphql.macro";
import {Col, Row, Spinner} from "react-bootstrap";
import {FundSearchResultInfo} from "../../funds/FundSearchPage/FundSeachResultInfo";
import {EtfSearchResultInfo} from "../../etf/search/EtfSearchResultInfo";
import FTLChartComponent from "../../common/charts/base/FTLChartComponent";

export function EtfIssuerSection(props: {issuer: EtfIssuer, domicile?: EtfDomicile | null}) {

    return (
        <div className="content-wrapper">
            <h2 className="content-wrapper-heading font-weight-bold">Emittent</h2>
            <h3 className="font-size-18px">{ props.issuer.name }</h3>
            { props.domicile &&
                <div className="mt-3">
                    <div className="font-weight-bold">Domizil:</div>
                    <div> {props.domicile.name} </div>
                </div>
            }

            <h3 className="content-wrapper-heading font-weight-bold mt-4 mb-0">
                Weitere ETF des Emittenten
            </h3>
            <DonutKGV issuerId={props.issuer.id}/>
        </div>
    )
}

function DonutKGV({issuerId}: {issuerId: number}) {
    const {loading, data} = useQuery<Query>(
        loader('./getEtfTypeBucket.graphql'), {
        variables: { issuerId }
    })

    if (loading) {
       return (
           <div className={"p-1"} style={{height: "70px"}}>
                <Spinner animation="border"/>
            </div>
       );
    }

    const buckets = (data?.assetTypeGroupBucket || [])
                    .filter(current => !!current.count)
                    .map(current => ({ id: current.assetTypeGroup?.id, name: current.assetTypeGroup?.name || "", y: (current.count || 0) as number}));

    return (
        <div style={{ maxWidth: "320px" }}>
            <div className="d-flex">
                <FTLChartComponent options={getOptions(buckets)} />
            </div>
        </div>
    );
}

/*
<Row>
    <Col xs="5" className="px-0 ml-n2 kgv-donut"></Col>
    <Col xs="7" className="px-0 mt-2">
        {
            buckets.map((current, index) =>
                <Row key={current.id} className="align-items-center">
                    <span className="dot mr-1" style={{ backgroundColor: PieChartColors[index], height: "10px", width: "10px" }}></span>
                    <EtfSearchResultInfo
                        criteria={{assetTypeGroup: current.id, issuerId}} enabled={true}
                        button={
                            <span className="font-weight-bold text-nowrap fs-13px text-blue cursor-pointer"> {current.name} ({current.y}) </span>
                        }
                    />
                </Row>
            )
    </Col>
</Row>
*/

function getOptions(buckets: {name: string, y: number}[]): any {
    return {
        stockTools: {
            gui: {
                enabled: false
            }
        },
        colors: PieChartColors,
        chart: {
            type: 'pie',
            height: "130px",
            width: 120
        },
        credits: { enabled: false },
        title: {
            text: "",
        },
        legend: { enabled: false },
        plotOptions: {
            pie: {
                innerSize: '65%',
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    connectorShape: 'crookedLine',
                    connectorColor: "black",
                    color: "black",
                    format: '{point.name} ({y})'
                },
                size: "100%",
            },
            series: {
                pointPadding: 0.4,
                borderWidth: 1.5,
                borderColor: "white",
                dataLabels: {
                    enabled: false,
                },
            },
        },
        tooltip: {
            headerFormat: "",
            pointFormat: '<span style="color:{point.color}">\u25CF <span style="color:"black""> {point.name} ({point.y})</span></span>'
        },
        series: [{
            type: 'pie',
            backgroundColor: "white",
            data: buckets,
        }],
    };
}
