import {ReactNode, useContext} from "react";
import {FundKeyFigure, SearchFundCriterion} from "graphql/types";
import FundsSearchContext, {FundsSearchContextProps} from "components/funds/FundSearchPage/FundsSearchContext";
import {
    BaseFundSearchProps,
    CriteriaState,
    EnabledProps,
    generateFundCard,
    GlobalFundSearchProps,
    wrapFundSearchCard
} from "../BaseFundSearchCard";
import {FilterDropdownItem, OptionItem} from "../../../common/SearchCard/FilterDropdownItem";

import {renderDefaultQuery} from "../../utils";

interface FundPerformanceForPeriodSearchCardProps extends BaseFundSearchProps {
    children?: ReactNode;
}

interface FundPerformanceForPeriodSearchCardState extends GlobalFundSearchProps {
    mode: "greater" | "less",
    valueId: string;
    value: number;
    keyFigureId: string;
    keyFigure: FundKeyFigure;
}

function FundPerformanceForPeriodSearchCardView({state, setCriteria}: CriteriaState<FundPerformanceForPeriodSearchCardState>) {
    const metadataContext = useContext(FundsSearchContext);
    const typeOptions = ((metadataContext && metadataContext.fundTypes) || []).map(current => {
        return { id: current?.id || "", name: current?.name || "" };
    });

    return (
        <>
            <FilterDropdownItem<OptionItem>
                onSelect={(value: OptionItem) => {
                    setCriteria({ ...state, typeId: value.id});
                }}
                options={typeOptions}
                activeId={"" + state.typeId}
            />
            <span className="mx-1" style={{ marginTop: "-2px" }}>mit einer Performance</span>
            <FilterDropdownItem<OptionItem>
                options={MODE_OPTIONS}
                onSelect={(value: OptionItem) => {
                    setCriteria({ ...state, mode: value.id === "greater" ? "greater" : "less" });
                }}
                activeId={state.mode}
            />
            <FilterDropdownItem<ValueOptionItem>
                options={PERCENTAGE}
                onSelect={(value: ValueOptionItem) => {
                    setCriteria({ ...state, value: value.value, valueId: value.id });
                }}
                activeId={state.valueId} />
            <span className="mx-1" style={{ marginTop: "-2px" }}>in den letzten</span>
            <FilterDropdownItem<KeyFigureOptionItem>
                    options={PERIOD}
                onSelect={(value: KeyFigureOptionItem) => {
                    setCriteria({ ...state, keyFigure: value.keyfigure, keyFigureId: value.id });
                }}
                activeId={state.keyFigureId}
            />
        </>
    )
}

function renderQuery(state: FundPerformanceForPeriodSearchCardState, metadata: FundsSearchContextProps | null): SearchFundCriterion {
    return {
        ...renderDefaultQuery(state, metadata),
        keyFigures: [{
            keyFigure: state.keyFigure,
            from: state.mode === "greater" ? state.value : undefined,
            to: state.mode === "less" ? state.value : undefined,
        }],
    }
}

interface ValueOptionItem extends OptionItem {
    value: number;
}

const PERCENTAGE: ValueOptionItem[] = [
    {id: "100", name: '100%', value: 100},
    {id: "75", name: '75%', value: 75},
    {id: "50", name: '50%', value: 50},
    {id: "25", name: '25%', value: 25},
    {id: "10", name: '10%', value: 10},
    {id: "5", name: '5%', value: 5}
];

interface KeyFigureOptionItem extends OptionItem {
    keyfigure: FundKeyFigure;
}

const PERIOD: KeyFigureOptionItem[] = [
    {id: "1", name: '30 Tage', keyfigure: FundKeyFigure.PerformanceMonth_1},
    {id: "6", name: '6 Monate', keyfigure: FundKeyFigure.PerformanceMonth_6},
    {id: "12", name: '12 Monate', keyfigure: FundKeyFigure.PerformanceYear_1},
    {id: "36", name: '3 Jahre', keyfigure: FundKeyFigure.PerformanceYear_3},
    {id: "60", name: '5 Jahre', keyfigure: FundKeyFigure.PerformanceYear_5},
    {id: "120", name: '10 Jahre', keyfigure: FundKeyFigure.PerformanceYear_10},
];

const MODE_OPTIONS: OptionItem[] = [{ id: "greater", name: 'größer' }, { id: "less", name: 'kleiner' }]

export const FundPerformanceForPeriodSearchCard = wrapFundSearchCard<FundPerformanceForPeriodSearchCardState>(
    generateFundCard<FundPerformanceForPeriodSearchCardProps & EnabledProps, FundPerformanceForPeriodSearchCardState>(
        FundPerformanceForPeriodSearchCardView,
    ),
    renderQuery,
    {typeId: null, topicId: null, strategyId: null, regionId: null, currencyId: null,
        mode: "greater",
        keyFigureId: PERIOD[2].id, keyFigure: PERIOD[2].keyfigure,
        valueId: PERCENTAGE[4].id, value: PERCENTAGE[4].value
    },
    false,
    "Performance"
);
