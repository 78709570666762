import {useCallback, useEffect, useRef, useState} from "react";
import {Collapse, FormControl} from "react-bootstrap";
import {Instrument} from "../../../../generated/graphql";
import {debounce} from "underscore";
import {SearchInstrumentsComponent} from "./SearchInstrumentsComponent";

interface UnderlyingSearchProps {
    onAssetSelected: (asset: Instrument) => any;
    inputSize?: 'sm' | 'lg';
    clearInput?: boolean
}

export function UnderlyingSearch({onAssetSelected, inputSize, clearInput}: UnderlyingSearchProps) {
    const [searchString, setSearchString] = useState('');

    const searchRef = useRef<HTMLDivElement | null>(null)
    useEffect(() => {
        if (clearInput)
            setSearchString("")
    }, [clearInput])

    return (<div className="underlying-search" ref={searchRef}>
        <UnderlyingSearchInputRow onSearch={setSearchString} size={inputSize} clearInput={searchString === ""} />
        <Collapse in={!!searchString}>
            <SearchInstrumentsComponent searchString={searchString} onAssetSelected={onAssetSelected} />
        </Collapse>
    </div>);
}


interface UnderlyingSearchLgInputRowProps {
    size?: string;
    onSearch: (input: any) => any,
    clearInput: boolean;
}

function UnderlyingSearchInputRow({size, onSearch, clearInput}: UnderlyingSearchLgInputRowProps) {
    const [value, setValue] = useState("")
    const inputRef = useRef<HTMLInputElement>(null)
    //eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceFn = useCallback(debounce((value: string | undefined) => {
        onSearch(value)
    }, 500), [])
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value)
        debounceFn(e.target.value)
    }
    useEffect(() => {
        // Workaround. Because FormControl's `autoFocus` works only on first render
        inputRef.current?.focus()
    })

    useEffect(() => {
        if (clearInput)
            setValue("")
    }, [clearInput])

    return (<>
                <div className="form-inline d-block text-center input-row input-bg">
                    <FormControl autoFocus placeholder="Ergebnisse filtern..." className={`form-control-${size || "md"} w-100`}
                        ref={inputRef}
                        value={value}
                        onChange={handleChange}
                    />
                </div>
    </>)
}
