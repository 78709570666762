export const mockData = [
    {
        currency: 'EUR',
        eur: '',
        usd: '',
        jpy: '',
        gbp: '',
        chf: '',
        aud: '',
        hkd: '',
        rub: '',
        zar: '',
        cny: '',
    },
    {
        currency: 'USD',
        eur: '',
        usd: '',
        jpy: '',
        gbp: '',
        chf: '',
        aud: '',
        hkd: '',
        rub: '',
        zar: '',
        cny: '',
    },
    {
        currency: 'JPY',
        eur: '',
        usd: '',
        jpy: '',
        gbp: '',
        chf: '',
        aud: '',
        hkd: '',
        rub: '',
        zar: '',
        cny: '',
    },
    {
        currency: 'GBP',
        eur: '',
        usd: '',
        jpy: '',
        gbp: '',
        chf: '',
        aud: '',
        hkd: '',
        rub: '',
        zar: '',
        cny: '',
    },

    {
        currency: 'CHF',
        eur: '',
        usd: '',
        jpy: '',
        gbp: '',
        chf: '',
        aud: '',
        hkd: '',
        rub: '',
        zar: '',
        cny: '',
    },
    {
        currency: 'AUD',
        eur: '',
        usd: '',
        jpy: '',
        gbp: '',
        chf: '',
        aud: '',
        hkd: '',
        rub: '',
        zar: '',
        cny: '',
    },
    {
        currency: 'HKD',
        eur: '',
        usd: '',
        jpy: '',
        gbp: '',
        chf: '',
        aud: '',
        hkd: '',
        rub: '',
        zar: '',
        cny: '',
    },
    {
        currency: 'RUB',
        eur: '',
        usd: '',
        jpy: '',
        gbp: '',
        chf: '',
        aud: '',
        hkd: '',
        rub: '',
        zar: '',
        cny: '',
    },
    {
        currency: 'ZAR',
        eur: '',
        usd: '',
        jpy: '',
        gbp: '',
        chf: '',
        aud: '',
        hkd: '',
        rub: '',
        zar: '',
        cny: '',
    },
    {
        currency: 'CNY',
        eur: '',
        usd: '',
        jpy: '',
        gbp: '',
        chf: '',
        aud: '',
        hkd: '',
        rub: '',
        zar: '',
        cny: '',
    }
];

export var arrayOfCellId = [
    0,2485106,2485090,2485127,2485098,2485060,2485395,2503101,2485135,2485752
    ,2502668,0,2485959,2489966,2485841,2489964,2502777,2485226,2502812,2485836
    ,2485344,2487625,0,2487587,2485962,2487572,2487588,2503143,2487630,2487579
    ,2504516,2485417,2487080,0,2485413,2485494,2485471,2485441,2485421,2485482,
    2485833,2485853,2486428,2485872,0,2485879,2485871,2502869,2485852,2485849,
    2485931,2505441,2504281,2485930,2485937,0,11518684,2503131,2486099,2485934,
    2485404,2485389,2487106,2485403,2485407,2485409,0,2503138,2487138,2485405,
    2489228,2502759,2503069,2489230,2505253,2489213,2489231,0,2489277,2489219,
    2485045,11439826,2490257,2490249,2485142,2490234,2485140,2503130,0,2490239,
    2485851,2503091,2503048,2502887,2504436,2502885,2503098,2503111,2486545,0]