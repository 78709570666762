import SvgImage from "../../../../common/image/SvgImage";
import React from "react";

export function TheScreenerRatingView({rating}: {rating: number}) {
    if (rating > 0) {
        return (
            <>
                {Array(rating)
                    .fill(1)
                    .map(((_, i) => i))
                    .map(value =>
                        <SvgImage key={value} icon="icon_star_filled.svg" imgClass="svg-blue mr-n2"
                            convert={false} width="27" alt="voller Stern"
                        />
                    )
                }
            </>
        );
    }
    return <SvgImage icon="icon_star.svg"  imgClass="svg-blue mr-n2" alt="leerer Stern" convert={false} width="27"/>;
}
