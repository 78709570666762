import React, {ReactNode} from "react";
import {
    BaseShareSearchProps,
    CriteriaState, EnabledProps, generateShareSearchCard,
    GlobalShareSearchProps,
    ShareSearchCardText, wrapShareSearchCard
} from "../../BaseShareSearchCard";
import {ShareRegionsDropdown} from "../../ShareRegionsDropdown";
import {FilterDropdownItem, OptionItem} from "../../../../common/SearchCard/FilterDropdownItem";
import {AssetGroup, Period, SearchShareCriterion, ShareSortField} from "../../../../../generated/graphql";
import {CardResults} from "../../utils";
import {marketCapCardTableHeader} from "../../tables/shareTableHeaders";
import moment from "moment";
import { generateBasicColumn, generateTableRender } from "../../ShareTableResult";
import { formatPrice } from "utils";

interface NewAllTimeHighPerformanceCardProps extends BaseShareSearchProps {
    children?: ReactNode
}

interface NewAllTimeHighPerformanceCardState extends GlobalShareSearchProps {
    selectedPeriod: string;
    from: string;
}

function NewAllTimeHighPerformanceCardContentView(props: CriteriaState<NewAllTimeHighPerformanceCardState>) {
    return (
        <>
            <ShareRegionsDropdown state={props.state} setCriteria={props.setCriteria}/>
            <ShareSearchCardText text={"mit einem neuen Allzeit Hoch"}/>
            <ShareSearchCardText text={"in den letzten"}/>
            <FilterDropdownItem<PeriodOptionItem>
                onSelect={(option: PeriodOptionItem) => {
                    props.setCriteria({...props.state, from: option.from, selectedPeriod: option.id});
                }}
                options={PERIOD_OPTIONS}
                activeId={props.state.selectedPeriod}
            />

        </>
    )
}

interface PeriodOptionItem extends OptionItem {
    from: string;
}

const PERIOD_OPTIONS: PeriodOptionItem[] = [
    {id: "1", name: "30 Tage", from: moment().subtract(1, 'month').format('YYYY-MM-DD')},
    {id: "2", name: "3 Monaten", from: moment().subtract(3, 'month').format('YYYY-MM-DD')},
    {id: "3", name: "6 Monaten", from: moment().subtract(6, 'month').format('YYYY-MM-DD')},
    {id: "4", name: "12 Monaten", from: moment().subtract(1, 'year').format('YYYY-MM-DD')},
    {id: "5", name: "3 Jahren", from: moment().subtract(3, 'year').format('YYYY-MM-DD')},
    {id: "6", name: "5 Jahren", from: moment().subtract(5, 'year').format('YYYY-MM-DD')},
    {id: "7", name: "10 Jahren", from: moment().subtract(10, 'year').format('YYYY-MM-DD')},
];

function renderQuery(state: NewAllTimeHighPerformanceCardState): SearchShareCriterion {
    return {
        period: Period.Last_1Year,
        ranges: [],
        regionId: state.regionId,
        trends: [],
        allTimeHigh: {from: state.from}
    }
}

export const NewAllTimeHighPerformanceCard = wrapShareSearchCard(
    generateShareSearchCard<NewAllTimeHighPerformanceCardProps & EnabledProps, NewAllTimeHighPerformanceCardState>(
        NewAllTimeHighPerformanceCardContentView
    ),
    renderQuery,
    {
        marketCapitalization: null,
        regionId: null,
        regionName: null,
        selectedPeriod: PERIOD_OPTIONS[0].id,
        from: PERIOD_OPTIONS[0].from,
        ranges: [], trends: [], period: Period.Last_1Year,
        sort: {field: ShareSortField.AllTimeHighDate, direction: 'desc'}
    },
    false,
    marketCapCardTableHeader,
    CardResults.other,
    ShareSortField.MarketCapitalization,
    ["Performance"],
    generateTableRender([
        generateBasicColumn(
            'Allzeit Hoch', ShareSortField.AllTimeHighDate,
            (result) => (<>
                <span>{formatPrice(result.allTimeHighPrice, AssetGroup.Share)} {!!result.allTimeHighPrice  && <span>{result.displayCode}</span>}</span> 
                <br/>
                <b>{!!result.allTimeHighDate && `(${result.allTimeHighDate.format('YYYY-MM-DD')})`}</b>
            </>)
        )
    ])
);

export default NewAllTimeHighPerformanceCard;
