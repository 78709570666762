import { useEffect, useRef } from 'react';
import SvgImage from 'components/common/image/SvgImage';
import { Col, Row } from 'react-bootstrap';
import { ChangelogModal } from './ChangelogModa';
import './FooterComponent.scss'
import { useLocation } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { WelcomeBeta } from './WelcomeBeta';
import {trigInfonline} from "../common/InfonlineService";

export function FooterComponent() {
    let { initialized, keycloak } = useKeycloak();
    var pathname: string = "/";
    const usePathname = () => {
        const location = useLocation();
        if(location.pathname.split('/')[1].length > 0){
            pathname = location.pathname.split('/')[1];
        }else{
            pathname = "/"
        }
        
    }

    usePathname();
    let footerContainer = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        if(footerContainer.current !== null){
            let elementScriptFooter = document.createElement('script');
            elementScriptFooter.type = 'text/javascript';
            elementScriptFooter.text = `Ads_BA_AD('FOOT');`
            if(footerContainer.current !== null && footerContainer.current.hasChildNodes()){
                footerContainer.current.innerHTML = '';
            }
            if(footerContainer.current !== null && !footerContainer.current.hasChildNodes() && (pathname !== 'mein-finanztreff' || keycloak.authenticated)){
                footerContainer.current.appendChild(elementScriptFooter)
            }
        }
    },[footerContainer, pathname])


    return (
        <>
            <div className="ads-responsive-class-footer" style={{ textAlign: 'center' }}>
                <div id='Ads_BA_FOOT' ref={footerContainer}>
                </div>
            </div>
            <div className='bg-light d-md-flex legend-container align-item-center'>
                <div className='d-flex align-items-center pt-2 pt-md-2 ml-xl-3 ml-md-2 rt-container'>
                    <span className="text-white bg-orange mb-3 mr-2 ml-2 legend-name footer-delay-legend"
                    >RT</span>
                    <p className='footer-legend-text'>Kurse in Realtime (unverzögert)</p>
                    </div>
                <div className='d-flex align-items-center footer-legend-text-container pt-md-3 '>
                    <span className="text-white pl-1 pr-1 bg-gray-dark mx-2 mb-3 legend-name"
                    >+15</span> <p className='footer-legend-text'>Kurs verzögert um entsprechende Minuten</p>
                    </div>
                </div>
            <footer className="mt-0">
                    <Row>
                        <Col md={{ order:'last' }} xl={4} className="mb-sm-5 px-sm-3 px-lg-0 px-xl-2 text-sm-center text-lg-left">
                            <h3 className="font-size-18px font-weight-bold roboto-heading">Mein finanztreff</h3>
                            <div className='text-white d-block font-weight-bold'>Kostenlose Musterportfolios,<br className='d-xl-none d-lg-none' /> Watchlisten & Limits</div>
                            <div className='mb-sm-3 text-white d-block'>Jetzt Vorteile von "Mein finanztreff" nutzen!</div>
                            <button className="btn btn-primary registering-button"  onClick={() => keycloak.register()}>Jetzt kostenlos registrieren</button>
                        </Col>
                        <Col sm={6} lg={4} xl={2} className="mb-sm-5 px-sm-3 px-lg-0 px-xl-2">
                            <h3 className="font-size-18px font-weight-bold roboto-heading">Suchen</h3>
                            <a className="mb-2 mb-md-0 text-white d-block" href={"/suche/"}>Alle Wertpapiere</a>
                            <a className="mb-2 mb-md-0 text-white d-block" href={"/aktien/suche/"}>Aktien</a>
                            <a className="mb-2 mb-md-0 text-white d-block" href={"/fonds/suche/"}>Fonds</a>
                            <a className="mb-2 mb-md-0 text-white d-block" href={"/etf/suche/"}>ETF</a>
                            <a className="mb-2 mb-md-0 text-white d-block" href={"/hebelprodukte/suche/aktienanleihen/dax/"}>Aktienanleihen</a>
                            <a className="mb-2 mb-md-0 text-white d-block" href={"/hebelprodukte/suche/optionsscheine/dax/"}>Optionsscheine</a>
                            <a className="mb-2 mb-md-0 text-white d-block" href={"/hebelprodukte/suche/faktorzertifikate/dax/"}>Faktor-Zertifikate</a>
                            <a className="mb-2 mb-md-0 text-white d-block" href={"/hebelprodukte/suche/discountzertifikate/dax/"}>Discount-Zertifikate</a>
                            <a className="mb-2 mb-md-0 text-white d-block" href={"/hebelprodukte/suche/bonuszertifikate/dax/"}>Bonus-Zertifikate</a>
                            <a className="mb-2 mb-md-0 text-white d-block" href={"/hebelprodukte/suche/knock-outs/dax/"}>Knock-Outs</a>
                        </Col>
                        <Col sm={6} lg={4} xl={2} className="mb-sm-5 px-sm-3 px-lg-0 px-xl-2">
                            <h3 className="font-size-18px font-weight-bold roboto-heading">Aktien</h3>
                            <a className="mb-2 mb-md-0 text-white d-block" href={"/indizes/indexzusammensetzung/DAX-Deutscher-Aktienindex/"}>Alle DAX Werte</a>
                            <a className="mb-2 mb-md-0 text-white d-block" href={"/indizes/indexzusammensetzung/MDAX-Performance-Index/"}>Alle MDAX Werte</a>
                            <a className="mb-2 mb-md-0 text-white d-block" href={"/indizes/indexzusammensetzung/TecDAX-Performance-Index/"}>Alle TecDAX Werte</a>
                            <a className="mb-2 mb-md-0 text-white d-block" href={"/indizes/indexzusammensetzung/SDAX-Performance-Index/"}>Alle SDAX Werte</a>
                            <a className="mb-2 mb-md-0 text-white d-block" href={"/indizes/indexzusammensetzung/ATX-Austrian-Traded-EUR-Preis-Index/"}>Alle ATX Werte</a>
                            <a className="mb-2 mb-md-0 text-white d-block" href={"/indizes/indexzusammensetzung/SMI-Price-Index/"}>Alle SMI Werte</a>
                            <a className="mb-2 mb-md-0 text-white d-block" href={"/indizes/indexzusammensetzung/Dow-Jones-30-Index/"}>Alle Dow Jones Werte</a>
                            <a className="mb-2 mb-md-0 text-white d-block" href={"/indizes/indexzusammensetzung/NASDAQ-100-Index/"}>Alle Nasdaq 100 Werte</a>
                            <a className="mb-2 mb-md-0 text-white d-block" href={"/indizes/indexzusammensetzung/EURO-STOXX-50-EUR-Price-Index/"}>Alle Eurostoxx50 Werte</a>
                            <a className="mb-2 mb-md-0 text-white d-block" href={"/indizes/indexzusammensetzung/NIKKEI-225-Index/"}>Alle NIkkei 225 Werte</a>                            
                        </Col>
                        <Col sm={6} lg={4} xl={2} className="mb-sm-5 px-sm-3 px-lg-0 px-xl-2">
                            <h3 className="font-size-18px font-weight-bold roboto-heading">Service</h3>
                            <a className="mb-2 mb-md-0 text-white d-block" href={"/nachrichten/#finanztreff.de-blog"}>finanztreff.de Blog</a>
                            <ChangelogModal />
                        </Col>
                        <Col sm={6} lg={4} xl={2} className="mb-sm-5 px-sm-3 px-lg-0 px-xl-2">
                            <h3 className="font-size-18px font-weight-bold roboto-heading">Informationen</h3>
                            <a className="mb-2 mb-md-0 text-white d-block" onClick={()=> trigInfonline("Footer", 'Impressum')} target={"_blank"} rel="noreferrer" href={"https://www.finanztreff.de/service/impressum/"}>Impressum</a>
                            <a className="mb-2 mb-md-0 text-white d-block" onClick={()=> trigInfonline("Footer", 'Nutzungshinweise')} target={"_blank"} rel="noreferrer" href={"https://www.finanztreff.de/service/nutzungshinweise/"}>Nutzungshinweise</a>
                            <a className="mb-2 mb-md-0 text-white d-block" onClick={()=> trigInfonline("Footer", 'Datenschutz')} target={"_blank"} rel="noreferrer" href={"https://www.finanztreff.de/service/datenschutz/"}>Datenschutz</a>
                            <a className="mb-2 mb-md-0 text-white d-block" onClick={()=> trigInfonline("Footer", 'Cookierichtlinie')} target={"_blank"} rel="noreferrer" href={"https://www.finanztreff.de/service/cookierichtlinie/"}>Cookie-Richtlinie</a>
                            <a className="mb-2 mb-md-0 text-white d-block" style={{cursor: "pointer"}} onClick={() => appendSettingsToHead()}>Datenschutzeinstellungen</a>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mb-sm-5 text-right px-sm-3 px-lg-0 px-xl-2">
                            <h3 className="mr-1 font-size-18px font-weight-bold roboto-heading">Soziale Medien</h3>
                            <a target={"_blank"} rel="noreferrer" href={"https://www.twitter.com/finanztreff_de/"}><SvgImage icon="icon_social_twitter_white.svg" width={"40"} /></a>
                            <a target={"_blank"} rel="noreferrer" href={"https://www.instagram.com/finanztreff/"}><SvgImage icon="icon_social_instagram_white.svg" width={"40"} /></a>
                            <a target={"_blank"} rel="noreferrer" href={"https://www.facebook.com/finanztreff.de"}><SvgImage icon="icon_social_facebook_white.svg" width={"40"} /></a>
                            <a target={"_blank"} rel="noreferrer"><SvgImage icon="icon_social_xing_white.svg" width={"40"} /></a>
                            <div className="mt-1 text-white-50 font-size-14px">{`© ${new Date().getFullYear()} finanztreff GmbH `}<span id="appVersion"></span></div>
                        </Col>
                    </Row>
            </footer>    
        </>
    );
}

function appendSettingsToHead(){
    let settingsScript = document.createElement('script');
    settingsScript.type = "text/javascript";
    settingsScript.text = 'Ads_BA_privacyManager(578872)';
    document.head.appendChild(settingsScript)
}
