import {AccountEntry, AssetGroup} from 'generated/graphql'
import React, {useState, createContext, useContext} from 'react'
import {TransactionType} from './PortfolioBanner/PortfolioComponents/Modals/utils'

type PortfolioPageStateProps = {
    refreshTrigger: () => void
    transactionType: TransactionType
    selectedYear?: number
    selectedMonth?: number
    selectedAssetGroup?: AssetGroup
    selectedSecurityEntry?: AccountEntry
}

const defaultState = {
    refreshTrigger: () => {},
    transactionType: TransactionType.UNKNOWN, // It will never be set to UNKNOWN for this use case so I'm gonna use it as the default state
    selectedYear: undefined,
    selectedMonth: undefined,
    selectedAssetGroup: undefined,
}

/**
 * setPortfolioBannerState unset values:
 *   month: undefined
 *   year: undefined
 *   transactionType: TransactionType.UNKNOWN
 *   assetGroup: null // will set it to undefined in the state
 *   selectedSecurityEntry: null // will set it to undefined in state
*/
const usePortfolioPageState = (initialState: PortfolioPageStateProps) => {
    const [state, setState] = useState<PortfolioPageStateProps>(initialState)
    return {
        refreshTrigger: () => {state.refreshTrigger()},
        setRefreshTrigger: (trigger: () => void) => setState((currentState: PortfolioPageStateProps) => ({...currentState, refreshTrigger: trigger})),
        transactionType: state.transactionType,
        setTransactionType: (transactionType: TransactionType) => setState((currentState: PortfolioPageStateProps) => ({...currentState, transactionType: transactionType})),
        selectedYear: state.selectedYear,
        setSelectedYear: (year: number | undefined) => setState((currentState: PortfolioPageStateProps) => ({...currentState, selectedYear: year})),
        selectedMonth: state.selectedMonth,
        setSelectedMonth: (month: number | undefined) => setState((currentState: PortfolioPageStateProps) => ({...currentState, selectedMonth: month})),
        selectedAssetGroup: state.selectedAssetGroup,
        setSelectedAssetGroup: (assetGroup: AssetGroup | undefined) => setState((currentState: PortfolioPageStateProps) => ({...currentState, selectedAssetGroup: assetGroup})),
        selectedSecurityEntry: state.selectedSecurityEntry,
        setSelectedSelectedSecurityEntry: (selectedSecurityEntry: AccountEntry | undefined) => setState((currentState: PortfolioPageStateProps) => ({...currentState, selectedSecurityEntry: selectedSecurityEntry})),
        setPortfolioBannerState: (year?: number, month?: number, transactionType?: TransactionType, assetGroup?: AssetGroup | null, selectedSecurityEntry?: AccountEntry | null) => setState((currentState: PortfolioPageStateProps) => ( 
            {
                ...currentState,
                selectedMonth: month,
                selectedYear: year,
                transactionType: transactionType === undefined ? currentState.transactionType : transactionType,
                selectedAssetGroup: assetGroup === undefined ? currentState.selectedAssetGroup : (assetGroup === null ? undefined : assetGroup),
                selectedSecurityEntry: selectedSecurityEntry === undefined ? currentState.selectedSecurityEntry : (selectedSecurityEntry === null ? undefined : selectedSecurityEntry)
            }))
    }
}
export type PortfolioPageContextType = ReturnType<typeof usePortfolioPageState>

export const PortfolioPageContext = createContext<PortfolioPageContextType | null>(null)

export const usePortfolioPageContext = () => useContext(PortfolioPageContext)!

export const PortfolioPageStateProvider = ({children}: {children: React.ReactNode}) => (
    <PortfolioPageContext.Provider value={usePortfolioPageState(defaultState)}>{children}</PortfolioPageContext.Provider>
)

