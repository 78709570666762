import { useQuery } from "@apollo/client";
import SvgImage from "components/common/image/SvgImage";
import { ModalHeaderMeinFinanztreff } from "components/profile/modals/ModalHeaderMeinFinanztreff";
import { loader } from "graphql.macro";
import { NewsCriteria, NewsFeed, NewsTopic, Query } from "graphql/types";
import { useCallback, useMemo, useState } from "react";
import { Modal, Spinner, Row, Button } from "react-bootstrap";
import { NewsGridComponent } from "../NewsGridComponent/NewsGridComponent";
import "./NewsGridModal.scss"
import {guessInfonlineSection, trigInfonline} from "../../InfonlineService";
import { isArray } from "underscore";

export function NewsGridModal({ topic, feed, title }: { topic?: NewsTopic, feed?: NewsFeed, title?: string }) {
    const [isOpen, setOpen] = useState<boolean>(false);

    const handleOpen = (value: boolean) => {
       trigInfonline(guessInfonlineSection(), "more_videos_modal_open");
       setOpen(value)
    };

    return (
        <>
            <div className="cursor-pointer" onClick={() => handleOpen(true)}>
                {topic &&
                    <span className="text-blue ml-1">Weitere Videos - {topic?.name}</span>
                }
            </div>
            <Modal show={isOpen} onHide={() => setOpen(false)} className="modal-dialog-sky-placement modal-background">
                {/* <div className="modal-content bg-white"> */}
                    <ModalHeaderMeinFinanztreff title={title ? title : "Nachrichten"} close={() => setOpen(false)} />
                    <ExposeModalBody key={`feed=${feed}_topic=${topic}`} feed={feed} topic={topic} infOnline="more_videos_modal"/>
                {/* </div> */}
            </Modal>
        </>
    );
}

export interface ExposeModalBodyProps {
    topic?: NewsTopic; 
    feed?: NewsFeed;
    isin?: string | [string];
    infOnline?: string;
}

export function ExposeModalBody({infOnline, feed, topic, isin, ...props}: ExposeModalBodyProps) {

    const searchCriteria: NewsCriteria = useMemo(() => ({
        source: feed ? [feed] : undefined, 
        topic: topic && topic.id ? [topic.id] : undefined,
        isin: !!isin ? (!isArray(isin) ? [isin] : isin) : undefined
    }), [feed, topic, isin]);

    let { loading, data, fetchMore } = useQuery<Query>(
        loader('./getNewsGridModal.graphql'),
        { variables: { first: 12, criteria: searchCriteria}}
    );

    const endCursor = data?.newsSearch?.pageInfo?.endCursor || null;
    const loadMoreNews = useCallback(() => {
        if (!!infOnline) {
            trigInfonline(guessInfonlineSection(), infOnline);
        }
        if (!loading && !!endCursor && !!fetchMore) {
            fetchMore({
                variables: {
                    first: 12,
                    after: endCursor,
                }
            });
        }
    }, [loading, fetchMore, infOnline, endCursor])


    return (
        <Modal.Body className="modal-body bg-white modal-body-xs-news-profile">
            <div className="p-0 bottom-feed pt-xl-3 pb-xl-1 mt-lg-n1 overflow-y">
                <NewsGridComponent searchCriteria={searchCriteria} news={data?.newsSearch?.edges.map(current => current.node) || []} loading={loading} />
                {
                    loading ?
                        <div className="text-center py-2">
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </div>
                        :
                        !!data?.newsSearch?.edges ?
                            <Row>
                                <div className="col-md-4 offset-md-4 col-12">
                                    <div className="text-center">
                                        <Button variant="link" onClick={loadMoreNews}>
                                            {data && data.newsSearch && data.newsSearch.pageInfo && data.newsSearch.pageInfo.hasNextPage &&
                                                <>
                                                    Mehr anzeigen
                                                    <SvgImage spanClass="top-move" convert={false} width={"27"}
                                                        icon="icon_direction_down_blue_light.svg"
                                                        imgClass="svg-primary" />
                                                </>
                                            }
                                        </Button>
                                    </div>
                                </div>
                            </Row>
                            :
                            <></>
                }
            </div>
        </Modal.Body>
    )
}
