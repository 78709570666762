import moment from "moment/moment";
import {Chart, ChartPoint} from "../../../generated/graphql";

export function createSeries(data: any[], dataThreshold?: number | null): any {
    return {
        name: 'Kurs', type: 'area', data: data.map((current): any => ({
            y: current.value,
            x: moment(current.when).toDate().getTime()
        })),
        threshold: dataThreshold,
        color: '#18C48F',
        negativeColor: '#f5335b',
        fillColor: 'transparent',
        lineWidth: 1.5,
        tooltip: { valueDecimals: 2 }
    };
}

export function createIntraDayOptions(data: Chart) {
    let points = data.series[0].data || [];

    let minValue = Math.min(...points.map((current: ChartPoint) => current.value));
    let maxValue = Math.max(...points.map((current: ChartPoint) => current.value));

    return {
        stockTools: {
            gui: {
                enabled: false
            }
        },
        chart: {
            margin: [0, 0, 4, 0],
            backgroundColor: 'transparent',
            height: 48,
            width: 220,
            style: { padding: '0 0 0 0' }
        },
        rangeSelector: { enabled: false },
        scrollbar: { enabled: false },
        navigator: { enabled: false },
        legend: { enabled: false },
        xAxis: {
            visible: false
        },
        yAxis: {
            type: 'linear',
            gridLineWidth: 0,
            floor: minValue * 0.9,
            ceiling: maxValue * 1.25,
            plotLines: data.threshold?.value ? [{
                value: data.threshold?.value,
                width: 1,
                color: '#7c7c7c'
            }] : [],
            labels: { enabled: false }
        },
        plotOptions: {
            series: {
                cursor: 'default',
                allowPointSelect: false,
                enableMouseTracking: false,
                label: { connectorAllowed: false }
            }
        },
        credits: { enabled: false },
        series: data.series.map((serie: any) => createSeries(serie.data, data?.threshold?.value))
    }
}
