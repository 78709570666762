import React, {useState, createContext, useContext} from 'react'

type WatchlistPageStateProps = {
    refreshTrigger: () => void
}

const defaultState = {
    refreshTrigger: () => {},
}
const useWatchlistPageState = (initialState: WatchlistPageStateProps) => {
    const [state, setState] = useState<WatchlistPageStateProps>(initialState)
    return {
        refreshTrigger: () => {state.refreshTrigger()},
        setRefreshTrigger: (trigger: () => void) => setState((currentState: WatchlistPageStateProps) => ({...currentState, refreshTrigger: trigger})),
    }
}
export type WatchlistPageContextType = ReturnType<typeof useWatchlistPageState>

const WatchlistPageContext = createContext<WatchlistPageContextType | null>(null)

export const useWatchlistPageContext = () => useContext(WatchlistPageContext)!

export const WatchlistPageStateProvider = ({children}: {children: React.ReactNode}) => (
    <WatchlistPageContext.Provider value={useWatchlistPageState(defaultState)}>{children}</WatchlistPageContext.Provider>
)

