import {AlternativeDerivativeProductsBucket, AssetClass, Chart, ChartPoint, DerivativeOptionType, InstrumentGroup, Issuer, IssuerBestInstrument, Query, QuoteType, SnapQuote} from "../../../../generated/graphql";
import {Card, Col, Container, Row} from "react-bootstrap";
import classNames from "classnames";
import {Link} from "react-router-dom";
import {formatPrice, getAssetLink, numberFormatWithSign} from "../../../../utils";
import {ProfileInstrumentAddPopup} from "../../modals/ProfileInstrumentAddPopup";
import SvgImage from "../../image/SvgImage";
import moment, {Moment} from "moment";
import {usePercentChangeVisualization} from "../../../../hooks/usePercentChangeVisualization";
import FTLChartComponent from "../../charts/base/FTLChartComponent";
import {Maybe} from "graphql/jsutils/Maybe";
import {PartnerLogo} from "components/Assets/Derivatives/components/PartnerLogo";
import {CopyWKN} from "components/profile/common/CopyWKNComponent";
import {useDesktopMediaQuery, useMobileMediaQuery, useTabletMediaQuery} from "hooks/useMediaQuery";
import {useMemo} from "react";
import {useQuery} from "@apollo/client";
import {GET_ALTERNATIVE_FROM_UNDERLINE} from "graphql/query";


function createSeries(data: any[], dataThreshold?: number | null) {
    return {
        name: 'Kurs', type: 'area', data:
        data.map((current): any => ({
            y: current.value,
            x: moment(current.when).toDate().getTime()
        })),
        threshold: dataThreshold,
        color: '#18C48F',
        negativeColor: '#f5335b',
        fillColor: 'transparent',
        lineWidth: 1.5,
        tooltip: {valueDecimals: 2}
    };
}

function createOptions(series: ChartPoint[], minValue: number, maxValue: number, threshold?: number) {
    return {
        stockTools: {
            gui: {
                enabled: false
            }
        },
        chart: {
            margin: [0, 0, 4, 0],
            backgroundColor: 'transparent',
            height: 32,
            width: 100,
            style: {padding: '0 0 0 0'}
        },
        rangeSelector: {enabled: false},
        scrollbar: {enabled: false},
        navigator: {enabled: false},
        legend: {enabled: false},
        xAxis: {
            visible: false
        },
        yAxis: {
            type: 'linear',
            gridLineWidth: 0,
            floor: minValue * 0.9,
            ceiling: maxValue * 1.25,
            plotLines: threshold ? [{
                value: threshold,
                width: 1,
                color: '#7c7c7c'
            }] : [],
            labels: {enabled: false}
        },
        plotOptions: {
            series: {
                cursor: 'default',
                allowPointSelect: false,
                enableMouseTracking: false,
                label: {connectorAllowed: false}
            }
        },
        credits: {enabled: false},
        series: [createSeries(series, threshold)]
    }
}

interface HotSectionItemProperties {
    id?: number;
    name: string;
    wkn?: Maybe<string>;
    url?: Maybe<string>;
    delay?: Maybe<number>;
    price?: Maybe<number>;
    displayPrice?: Maybe<string>;
    performance?: Maybe<number>;
    currency?: Maybe<string>;
    group: InstrumentGroup;
    className?: string;
    trend?: boolean;
    interest?: boolean;
    activity?: boolean;
    snapQuote?: SnapQuote | null| undefined;
    chart?: Chart;
    bestWarrantRnd?: Maybe<IssuerBestInstrument>;
    assetClasses: Array<AssetClass>;
    isHomePage?: boolean
}

const findAlternativeDerivativeProductOfType = (alternativeDerivativeProducts: AlternativeDerivativeProductsBucket[], type: AssetClass): AlternativeDerivativeProductsBucket | undefined =>
    alternativeDerivativeProducts.find((alternativeDerivativeProductsBucket: AlternativeDerivativeProductsBucket) => alternativeDerivativeProductsBucket.assetClass?.id === type.id)

export function HotSectionInstrumentCard(props: HotSectionItemProperties) {
    const isMobile = useMobileMediaQuery()
    const isTablet = useTabletMediaQuery()
    const isDesktop = useDesktopMediaQuery()

    let chartOptions = {};

    const leverage = props.bestWarrantRnd?.instrument?.keyFigures?.gearing // hebel
    const issuer: Issuer | undefined = props.bestWarrantRnd?.instrument?.group.issuer || undefined
    const derivativeType = props.bestWarrantRnd?.instrument?.group && props.bestWarrantRnd.instrument.group.derivative && props.bestWarrantRnd.instrument.group.derivative.optionType === DerivativeOptionType.Call ? "Call" : "Put";
    const pushEvent =  usePercentChangeVisualization(props.id)
    let currentSnapQuote = pushEvent?.value || props.snapQuote;
    let currentQuote = (currentSnapQuote?.quotes || []).find(current => current?.type == QuoteType.Trade) || props.snapQuote?.quote;
    const maturityDate: Moment | undefined = props.bestWarrantRnd?.instrument?.group?.derivative?.maturityDate != null ?
        moment(props.bestWarrantRnd?.instrument?.group?.derivative?.maturityDate) :
        undefined
    const dueTo = maturityDate !== undefined ? maturityDate.diff(moment(), 'd') + 1 : undefined;
    const isUnderlying = Boolean(props.group.underlying)
    const instrumentLink = props.bestWarrantRnd != null ? getAssetLink(props.bestWarrantRnd.instrument?.group) : getAssetLink(props.group)
    const wkn: Maybe<string> = props.bestWarrantRnd != null ? props.bestWarrantRnd.instrument?.wkn : props.wkn

    if (props.chart && props.chart.series && props.chart.series.length > 0) {
        let points = [...(props.chart.series[0].data || [])];
        // if (!!currentQuote && currentQuote.value) {
        //     points.push({value: currentQuote.value || 0, when: currentQuote.when});
        // }
        let minValue = Math.min(...points.map((current: ChartPoint) => current.value));
        let maxValue = Math.max(...points.map((current: ChartPoint) => current.value));
        chartOptions = createOptions(points, minValue, maxValue, props.chart.threshold?.value || undefined);
    }

    const {data, loading, error} = useQuery<Query>(GET_ALTERNATIVE_FROM_UNDERLINE, {
        variables: { underlyingInstrumentGroupId: props.group.id },
        skip: !isUnderlying || props.bestWarrantRnd != null
    })

    const alternativeDerivativeProducts: AlternativeDerivativeProductsBucket[] | undefined = data?.group?.alternativeDerivativeProducts
        ?.filter((alternativeDerivativeProducts: AlternativeDerivativeProductsBucket) => alternativeDerivativeProducts.count > 0)
    const derivativeSearchAssetClass: AssetClass | undefined = alternativeDerivativeProducts ? 
        props.assetClasses.find((assetClass: AssetClass) => findAlternativeDerivativeProductOfType(alternativeDerivativeProducts, assetClass)) : undefined

    const mobileFooter = useMemo(() => (
        <Container className="px-2">
            <Row className="px-2 py-1 fnt-size-14 bg-white line-height-20px text-body">
                {(isUnderlying && props.bestWarrantRnd != null ) ? (
                    <>
                        <Col xs={2} className="p-0">
                            <span className={classNames("px-2 font-weight-bold text-white", {
                                'bg-green': derivativeType === "Call",
                                'bg-red': derivativeType === "Put"
                            })}>{derivativeType}</span>
                        </Col>
                        <Col xs={3} className="p-0">
                            {wkn != null && (
                                <span className="d-flex ml-1 font-weight-normal text-blue">
                                    {instrumentLink ? (<Link to={instrumentLink}>{wkn}</Link>) : (<span>{wkn}</span>)} <CopyWKN wkn={wkn} width={12} fixImagePadding={true} className="ml-1 p-0" />
                                </span>
                            )}
                        </Col>
                        <Col xs={4} className="ml-10px mr-n20px pl-2px pr-0 py-0"> {/* mr-n4 - 4px = mr-n20px becase next col has to be ml-auto */}
                            {leverage != null && (<span className="flex-grow-1 flex-shrink-0 font-weight-bold">
                                Hebel: {formatPrice(leverage)}
                            </span>)}
                        </Col>
                        <Col xs={3} className="d-flex justify-content-end ml-auto mt-n2px p-0">
                            <PartnerLogo issuer={issuer} fixedImageSize={false} className={classNames("my-n2 font-weight-bold", {'fnt-size-11': true})} />
                        </Col>
                    </>
                ) : (isUnderlying && props.bestWarrantRnd == null) ? (
                    <Col className="px-0">
                        <Link
                            to={{
                                pathname: "/hebelprodukte/suche",
                                search: `?aclass=${derivativeSearchAssetClass?.name}&underlying=${props.group?.id}&issuerId=${props.group?.issuer?.id || ""}&issuerName=${props.group.issuer?.name || ""}`
                            }}>
                            Derivatesuche
                        </Link>
                    </Col>
                ) : (
                    <Col className="px-0 text-kurs-grau">Keine passenden Produkte</Col>
                )}
                </Row>
        </Container>
    ), [isUnderlying, props.bestWarrantRnd, props.group, instrumentLink, derivativeType, derivativeSearchAssetClass, issuer, wkn, leverage])

    const tabletFooter = useMemo(() => (
        <section className="mx-n2 p-2 fnt-size-14 bg-white line-height-20px text-body">
                {(isUnderlying && props.bestWarrantRnd != null ) ? (
                    <>
                        <div className="d-flex gap-1 align-items-center justify-content-between font-weight-bold">
                            <div className="d-flex gap-1">
                                <span className={classNames("px-2 text-white", {
                                    'bg-green': derivativeType === "Call",
                                    'bg-red': derivativeType === "Put"
                                })}>{derivativeType}</span>
                                {wkn != null && (
                                    <span className="d-flex font-weight-normal">
                                        {instrumentLink ? (<Link to={instrumentLink}>{wkn}</Link>) : (<span>{wkn}</span>)} <CopyWKN wkn={wkn} width={12} fixImagePadding={true} className="ml-1 p-0" />
                                    </span>
                                )}
                            </div>
                            <span className="d-flex justify-content-end" style={{flexBasis: "100%"}}>
                                <PartnerLogo issuer={issuer} fixedImageSize={false} className={classNames("my-n2", {'fnt-size-11': true})} />
                            </span>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mt-2px font-weight-bold line-height-20px">
                            <span>
                                Hebel: {formatPrice(leverage)}
                            </span>
                            <span>
                                Restlaufzeit: {dueTo !== undefined ? `${dueTo} T` : "-"}
                            </span>
                        </div>
                    </>
                ) : (isUnderlying && props.bestWarrantRnd == null) ? (
                    <>
                        <Link 
                            to={{
                                pathname: "/hebelprodukte/suche",
                                search: `?aclass=${derivativeSearchAssetClass?.name}&underlying=${props.group?.id}&issuerId=${props.group?.issuer?.id || ""}&issuerName=${props.group.issuer?.name || ""}`
                            }}>
                            Derivatesuche
                        </Link>
                        <div style={{height: "20px"}}></div>
                    </>
                ) : (
                    <>
                        <span className="text-kurs-grau">Keine passenden Produkte</span>
                        <div style={{height: "20px"}}></div>
                    </>
                )}
        </section>
    ), [isUnderlying, props.bestWarrantRnd, props.group, derivativeType, derivativeSearchAssetClass, instrumentLink, issuer, wkn, leverage, dueTo])

    const desktopFooter = useMemo(() => (
        <section className={classNames("d-flex align-items-center gap-1 mx-n2 p-2 fnt-size-14 bg-white line-height-20px text-body")}>
            {(isUnderlying && props.bestWarrantRnd != null ) ? (
                <>
                    <span className={classNames("px-2 bg-green font-weight-bold text-white", {
                        'bg-green': derivativeType === "Call",
                        'bg-red': derivativeType === "Put"
                    })}>{derivativeType}</span>
                    <span className="d-flex">
                        {wkn != null && (
                            <>
                                {instrumentLink ? (<Link to={instrumentLink}>{wkn}</Link>) : (<span>{wkn}</span>)} <CopyWKN wkn={wkn} width={12} fixImagePadding={true} className="ml-1 p-0" />
                            </> 
                        )}
                    </span>
                    <span className="flex-grow-1 flex-shrink-0 font-weight-bold">
                        {leverage != null && (
                            <>
                                Hebel: {formatPrice(leverage)}
                            </>
                        )}
                    </span>
                    <span className="d-flex justify-content-end" style={{flexBasis: "100%"}}>
                        <PartnerLogo issuer={issuer} fixedImageSize={false} className={classNames("my-n2", {'fnt-size-11': true})} />
                    </span>
                </>
            ) : (isUnderlying && props.bestWarrantRnd == null) ? (
                <Link 
                    to={{
                        pathname: "/hebelprodukte/suche",
                        search: `?aclass=${derivativeSearchAssetClass?.name}&underlying=${props.group?.id}&issuerId=${props.group?.issuer?.id || ""}&issuerName=${props.group.issuer?.name || ""}`
                    }}
                >
                    Derivatesuche
                </Link>
            ) : (
                <span className="text-kurs-grau">Keine passenden Produkte</span>
            )}
        </section>
    ), [isUnderlying, props.bestWarrantRnd, props.group, derivativeType, derivativeSearchAssetClass, instrumentLink, issuer, wkn, leverage])

    return (
        <>
            <Card className={classNames("instrument-card px-2 pt-2 overflow-auto border-none rounded-0 bg-dark text-white", props.className)}>
                <Card.Header className="d-flex align-items-center justify-content-between p-0 pt-xl-2px border-bottom-none line-height-24px bg-transparent">
                        <Link className="text-white text-truncate" to={getAssetLink(props.group) || ""}
                            style={{fontSize: 17}}>{props.name}</Link>
                        {props.group && props.group.id && props.id
                            && <ProfileInstrumentAddPopup
                                instrumentId={props.id}
                                instrumentGroupId={props.group.id}
                                name={props.name}
                                className="p-0"
                                dropdownToggleWrapperClassName="line-height-1"
                                watchlist={true} portfolio={true}>
                                <SvgImage icon="icon_plus_white.svg" convert={false} width="20"/>
                            </ProfileInstrumentAddPopup>
                        }
                </Card.Header>
                <Card.Body className="d-flex gap-2 p-0 fnt-size-15 line-height-20px">
                    <div className="text-nowrap">
                        {formatPrice(currentQuote?.value, props.group.assetGroup)}
                        <span className="fnt-size-11 line-height-1">{props.currency}</span>
                    </div>
                    <div className={classNames({
                        'text-green': (currentQuote?.percentChange || 0) > 0,
                        'text-pink': (currentQuote?.percentChange || 0) < 0,
                        'asset-value-movement-blinker': props.isHomePage && pushEvent.toggle
                    })}>
                        {numberFormatWithSign(currentQuote?.percentChange!,"%")}
                    </div>
                    <div>
                    {
                        !!currentQuote?.percentChange && (
                            (currentQuote?.percentChange > 0) ?
                                <SvgImage icon="icon_arrow_short_up_green.svg" imgClass="pb-1" className="move-arrow" />
                                : currentQuote?.percentChange < 0 ?
                                    <SvgImage icon="icon_arrow_short_down_red.svg" imgClass="pb-1" className="move-arrow" />
                                    :
                                    <SvgImage icon="icon_arrow_short_right_grey.svg" imgClass="pb-1" className="move-arrow" width="27" />
                        )
                    }
                    </div>
                    {props.chart &&
                        <FTLChartComponent
                            constructorType='stockChart'
                            options={chartOptions}
                        />
                    }
                </Card.Body>
                <Card.Footer className="mb-0 px-0 pt-1 pt-xl-10px pb-0 border-top-1 border-gray">
                    <section className="d-flex mx-n2 mt-2px mt-xl-0 mb-0 mb-xl-5px px-2 pb-2">
                        <div className={classNames("d-flex align-content-center ml-n1px", props.trend ? "mr-2" : "op-3")}>
                            <SvgImage icon="icon_hot_flame_white.svg" imgClass="svg-white ml-n2px mr-0"
                                spanClass="fire-icon line-height-1" convert={false} width="18"/>
                            <span className="fnt-size-13">Trend</span>
                        </div>
                        <div className={classNames("d-flex align-content-center", props.interest ? "mr-2" : "op-3")}>
                            <SvgImage icon="icon_hot_flame_white.svg" imgClass="svg-white mx-0"
                                spanClass="fire-icon line-height-1" convert={false} width="18"/>
                            <span className="fnt-size-13">Interesse</span>
                        </div>
                        <div className={classNames("d-flex align-content-center", props.activity ? "mr-2" : "op-3")}>
                            <SvgImage icon="icon_hot_flame_white.svg" imgClass="svg-white mx-0"
                                spanClass="fire-icon line-height-1" convert={false} width="18"/>
                            <span className="fnt-size-13">Aktivität</span>
                        </div>
                    </section>
                    {isMobile && mobileFooter}
                    {isTablet && tabletFooter}
                    {isDesktop && desktopFooter}
                </Card.Footer>
            </Card>
        </>
    );
}
