import {TheScreenerRatingValueAtRisk} from "../../../../../generated/graphql";
import React from "react";
import {Card} from "react-bootstrap";
import {numberFormat} from "../../../../../utils";

export interface TheScreenerRatingModalValueAtRiskProps {
    valueAtRisk: TheScreenerRatingValueAtRisk;
}

export function TheScreenerRatingModalValueAtRisk(props: TheScreenerRatingModalValueAtRiskProps) {
    return (
        <Card>
            <Card.Body>
                <Card.Title as={'h5'} className="d-flex justify-content-between">
                    <span className={"ml-n1"}>Value at Risk</span>
                    <span role="">{props.valueAtRisk.value}</span>
                </Card.Title>
                <Card.Text className="ml-n1" role="title">
                    Das geschätzte mittlere Value at Risk beträgt {numberFormat(props.valueAtRisk.value)} oder {numberFormat(props.valueAtRisk.relative, '%')}
                </Card.Text>
                <Card.Text className="ml-n1" role="description">
                    Das geschätzte mittlere Value at Risk beträgt {numberFormat(props.valueAtRisk.value)}. Das Risiko liegt deshalb bei {numberFormat(props.valueAtRisk.relative, '%')}. Dieser Wert basiert auf der mittelfristigen historischen Volatilität.
                </Card.Text>
            </Card.Body>
        </Card>
    );
}
