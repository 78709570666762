import React from "react";
import classNames from "classnames";
import {useMediaQuery} from "react-responsive";
import "./CalendarCard.scss";
import {useQuery} from "@apollo/client";
import {Query} from "../../generated/graphql";
import {loader} from "graphql.macro";
import moment from "moment";
import { trigInfonline,guessInfonlineSection} from "components/common/InfonlineService";

export interface calenderCardProps {
    day: string,
    date?: string,
    isActive?: boolean,
    description?: string,
    wideCard?: boolean,
    isWeekCard: boolean,
    setCalendarInputState?: React.Dispatch<React.SetStateAction<{from: moment.Moment , to: moment.Moment}>>,
    isCurrentDay?:boolean,
    currentDate?: moment.Moment,
    weekInputDates?:{start:moment.Moment, end: moment.Moment}
}



export default function CalendarCard(props: calenderCardProps) {

    const isDesktop = useMediaQuery({
        query: '(min-width: 1100px)'
    });

    const isTablet = useMediaQuery({
        query: '(max-width: 1099px)'
    });

    const isMobile = useMediaQuery({
        query: '(max-width: 698px)'
    });

    let {data: calendarSummary, loading: calendarSummaryLoading} = useQuery<Query>(
        loader('./calendarEventSummary.graphql'),
        {
            variables: {
                from: props.isWeekCard ? props?.weekInputDates?.start.format("YYYY-MM-DD") :  props?.currentDate?.format("YYYY-MM-DD"),
                to: props.isWeekCard ? props?.weekInputDates?.end.format("YYYY-MM-DD") : props?.currentDate?.format("YYYY-MM-DD")
            }
        }
    );



    const totalEvents : number | undefined =  calendarSummary?.calendarEventSummary?.reduce((total, current) => (total + (current?.count ? current.count : 0)), 0) ;

    const responsiveCalenderClass = isMobile ? "m-calender-card" : isTablet ? "t-calender-card" : "calender-card";
    const calenderCardClass = classNames("d-flex flex-column align-items-center", props.isActive && "calender-card-active", responsiveCalenderClass);

    const shortFormForDays = (dayName: string): string => (
        props.isWeekCard ? dayName
            : dayName.substring(0, 2)
    )

    const handleClick = ()=> {
        trigInfonline(guessInfonlineSection(),"termine_page");

        if( !props.isWeekCard && props.currentDate && props.setCalendarInputState ) {
            props.setCalendarInputState({from : props.currentDate, to: props.currentDate });
        }
        else if( props.isWeekCard && props.weekInputDates && props.setCalendarInputState  ) {
            props.setCalendarInputState({from : props?.weekInputDates?.start, to: props?.weekInputDates?.end});
        }
    }

    return (
        <div className="calender-card-wrapper mr-md-1 mr-lg-0" onClick={handleClick} data-testid="calendar-card">
            <div className={classNames(calenderCardClass, isMobile || props.wideCard && "t-calender-card-wide")}>
                <div
                    className="calender_border"></div>
                <span
                    className="calender_day_name pb-lg-2 pb-sm-0 pt-lg-2 pt-sm-0">
                    { props.isCurrentDay && "Heute, " }{isDesktop ? props.day : shortFormForDays(props.day)}
                </span>
                {props.isWeekCard ? <span className="calender_date pb-xl-2   pb-sm-0">{props.description}</span> :
                    <span className="calender_date  pb-xl-2 pb-sm-0">{props.date}</span>
                }
                        <span className=" calender_bottom_content pt-md-2">  { calendarSummaryLoading ? 0 : totalEvents} {(isTablet && !isMobile) && " Termine" }</span>
            </div>
        </div>
    )

}

