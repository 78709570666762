import classNames from "classnames"
import SvgImage from "components/common/image/SvgImage"
import {useMobileMediaQuery} from "hooks/useMediaQuery"
import {useState} from "react"
import {Button, Modal, Row} from "react-bootstrap"
import "./InfoModal.scss"

export default function InfoModal() {
    const isMobile = useMobileMediaQuery()
    const [isOpen, setIsOpen] = useState(false)
    return (
        <>
            <button className="d-flex align-items-center font-roboto fnt-size-14 text-blue font-weight-normal bg-transparent border-none cursor-pointer" onClick={() => setIsOpen(true)}>
                {!isMobile && "Was sehe ich hier?"}
                <span className="information ml-1" style={{'--width': "16px", '--height': "16px", '--fnt-size': "12px"} as React.CSSProperties}>i</span>
            </button>

            <Modal show={isOpen} className={"info-modal modal-dialog-sky-placement modal-z-2"}
                backdropClassName="modal-z-2__backdrop" contentClassName="px-2 px-md-3 bg-white">
                <Modal.Header className="m-0 p-0 border-none bg-white shadow-none line-height-22px">
                    <div className="px-0 py-10px w-100">
                        <Row className="mx-0 my-0 justify-content-between align-items-center rounded-top" style={{ minWidth: "inherit" }}>
                            <div className="d-flex align-content-center">
                                {/* <SvgImage icon="information.svg" spanClass="mr-1" imgClass="svg-blue" convert={true} width="24px" height="24px" /> */}
                                <span className="information">i</span>
                                <span className="ml-2px text-truncate roboto-heading fnt-size-17 line-height-24px font-weight-bold">Information</span>
                            </div>
                            <span className="text-blue fnt-size-14 cursor-pointer" data-dismiss="modal" aria-label="Schliessen" onClick={() => setIsOpen(false) }>
                                <span>schließen</span>
                                <span className="close-modal-butt svg-icon">
                                    <SvgImage icon="icon_close_blue.svg" width="27" />
                                </span>
                            </span>
                        </Row>
                    </div>
                </Modal.Header>
                <Modal.Body className="px-0 pt-10px pb-0 fnt-size-15 line-height-22px">
                    <p className="fnt-size-15 line-height-22px">
                        Die <span className="font-weight-bold">finanztreff.de HOT! Aktien</span> zeigen Ihnen die Werte, die aktuell den Markt bewegen. Wir beobachten für Sie starke Kursbewegungen, Änderungen wichtiger Kennzahlen sowie Aktivität und Interessen unserer Nutzer und zeigen Ihnen hier die Werte an, die Sie aktuell im Auge behalten sollten!
                    </p>
                    <section className="mt-3 py-3 border-top-2 border-gray-light">
                        <div className="mt-2px fnt-size-14 line-height-20px">
                            <span className="px-1 bg-green font-weight-bold text-white">Call</span>
                            <span className="ml-1 px-1 bg-red font-weight-bold text-white">Put</span>
                        </div>
                        <p className="mt-1 fnt-size-15 line-height-18px">
                            Mit unserer speziellen Optionsschein-Auswahl können Sie überproportional an den Kursbewegungen der entsprechenden Hot Aktien partizipieren.
                        </p>
                        <p className="fnt-size-13 line-height-18px text-kurs-grau">
                            Optionsscheine sind sie aufgrund ihrer komplexen Struktur und des hohen Risikos, nicht für alle Anleger geeignet. Es ist daher ratsam, sich vor dem Handel mit Optionsscheinen gründlich zu informieren und gegebenenfalls professionelle Beratung einzuholen.
                        </p>
                    </section>
                </Modal.Body>
                <Modal.Footer className="px-0 pt-0 pb-3 border-none">
                    <Button variant="light" className="text-blue" onClick={() => setIsOpen(false)}>Alles klar!</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

