import { Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import SvgImage from "components/common/image/SvgImage";
import { ProfileInstrumentAddPopup } from "components/common/modals/ProfileInstrumentAddPopup";
import { NewsInstrumentInfo } from "components/common/news/NewsInstrumentInfo";
import { Chart, InstrumentGroup, ChartPoint, SnapQuote } from "generated/graphql";
import { className, getFinanztreffAssetLink, numberFormat } from "utils";
import styles from "./IndexFindInstrumentCard.module.scss";
import classNames from "classnames";
import FTLChartComponent from "../../common/charts/base/FTLChartComponent";
import { getAssetForUrl } from "components/profile/utils";


export function createSeries(data: any[], dataThreshold?: number | null): any {
    return {
        name: 'Kurs', type: 'area', data: data.map((current): any => ({
            y: current.value,
            x: moment(current.when).toDate().getTime()
        })),
        threshold: dataThreshold,
        color: '#18C48F',
        negativeColor: '#f5335b',
        fillColor: 'transparent',
        lineWidth: 1.5,
        tooltip: { valueDecimals: 2 }
    };
}

export function createOptions(data: Chart, minValue: number, maxValue: number) {
    return {
        chart: {
            margin: [0, 0, 4, 0],
            backgroundColor: 'transparent',
            height: 36,
            width: 80,
            style: { padding: '0 0 0 0' },
        },
        rangeSelector: { enabled: false },
        scrollbar: { enabled: false },
        navigator: { enabled: false },
        legend: { enabled: false },
        xAxis: {
            visible: false
        },
        yAxis: {
            type: 'linear',
            gridLineWidth: 0,
            floor: minValue * 0.9,
            ceiling: maxValue * 1.25,
            plotLines: data.threshold?.value ? [{
                value: data.threshold?.value,
                width: 1,
                color: '#7c7c7c'
            }] : [],
            labels: { enabled: false }
        },
        plotOptions: {
            series: {
                cursor: 'default',
                allowPointSelect: false,
                enableMouseTracking: false,
                label: { connectorAllowed: false }
            }
        },
        stockTools: {
            gui: {
                enabled: false
            }
        },
        credits: { enabled: false },
        series: data.series.map((serie: any) => createSeries(serie.data, data?.threshold?.value))
    }
}

interface IndexFindInstrumentCardProps {
    id: number;
    name: string;
    url?: string | null;
    delay?: number | null;
    price?: number | null;
    displayPrice?: string | null;
    performance?: number | null;
    currency?: string | null;
    group: InstrumentGroup;
    className?: string;
    country?: string;
    isoAlphaCode?: string;
    showCardFooter?: boolean;
    snapQuote?: SnapQuote;
    skip?: boolean;
    chart?: Chart
}

export function IndexFindInstrumentCard(props: IndexFindInstrumentCardProps) {

    let chartOptions = {};
    let minValue: number | null = null, maxValue: number | null = null;
    if (props.chart && props.chart.series && props.chart.series.length > 0) {
        let points = props.chart.series[0].data || [];
        minValue = Math.min(...points.map((current: ChartPoint) => current.value));
        maxValue = Math.max(...points.map((current: ChartPoint) => current.value));
        chartOptions = createOptions(props.chart, minValue, maxValue);
    }
    return (
        <>
            <Card className={classNames("instrument-card text-dark p-2 borderless mb-sm-3 mb-xl-0 mb-md-0", props.className)}>
                <div className="d-flex justify-content-between">
                    {(props.group?.name && props.group?.assetGroup && props.group?.seoTag) ? (
                        <Link
                            to={getFinanztreffAssetLink(props.group.assetGroup, props.group.seoTag)}
                            className={className("card-title", styles)}>
                            {props.group.name}
                        </Link>
                    ) : (
                        <div className={className("card-title", styles)}>
                            {props.group.name}
                        </div>
                    )}
                    {props.group && props.group.id && props.id && (
                        <ProfileInstrumentAddPopup
                            instrumentId={props.id}
                            instrumentGroupId={props.group.id}
                            name={props.name}
                            className="p-0 mr-n1"
                            watchlist={true} portfolio={true}>
                            <SvgImage icon="icon_plus_blue.svg" convert={false} imgClass="shrink-08" width="28" style={{ marginTop: "-5px", marginBottom: "5px" }} />
                        </ProfileInstrumentAddPopup>
                    )}
                </div>
                <Card.Body className="d-flex justify-content-between px-0 pt-2 pb-10px line-height-22px" style={{minHeight: "unset"}}>
                    <NewsInstrumentInfo className="font-weight-bolder text-normal" id={props.id} showCurrency={true} showPrice={true} snapQuote={props.snapQuote} />
                    {
                        props.chart && props.chart.series.length>0 && (<FTLChartComponent
                            constructorType='stockChart'
                            containerProps={{className: "mt-n3 pr-5px"}}
                            options={chartOptions}
                        />)
                    }
                </Card.Body>
                {props.showCardFooter &&
                    <Card.Footer className="d-flex justify-content-between align-items-center px-0 pt-5px pb-0 border-top-1 bg-white fnt-size-15 line-height-15px">
                        <div className="d-flex gap-2 align-items-center mt-2px">
                            {props.isoAlphaCode && (
                                <SvgImage icon={`flags/${props.isoAlphaCode}.svg`} width="24" spanClass="d-inline-flex" imgClass="svg-blue my-n1" />
                            )}
                            <span>{props.country}</span>
                        </div>
                        <Link
                            to={{
                                key: '',
                                pathname: "/" + getAssetForUrl(props.group.assetGroup).toLowerCase() + "/indexzusammensetzung/" + props?.group?.seoTag + "/",
                            }}>
                            <Button variant={props.group.compositions.length > 0 ? 'inline-action' : "inline-action in-active"} data-testid="open-indexcompostion"
                                className="d-flex align-items-center gap-2 mt-2px p-0 border-none fnt-size-13 line-height-15px">
                                <SvgImage icon="icon_list_lightgrey.svg" width="21" convert={true} fixImagePadding={true}
                                    imgClass={classNames("mx-0", {'svg-blue': props.group.compositions.length > 0})} spanClass="fire-icon" />
                                <span className="d-inline-flex">
                                    Einzelwerte
                                </span>
                            </Button>
                        </Link> 
                    </Card.Footer>
                }
            </Card>
        </>
    );
}
