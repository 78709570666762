import { OptionItem } from "components/common/SearchCard/FilterDropdownItem";
import { CrossRateSortField, InstrumentPerformance, CalculationPeriod, Quote, ChartScope, Instrument} from "graphql/types";

export interface OptionItemWithPeriod extends OptionItem {
    field?: CrossRateSortField;
}
export const NAME_OPTION_ID = "NAME";
export const PERFORMANCE_OPTION_ID = "PERFORMANCE";

export const SORTING_OPTIONS: OptionItemWithPeriod[] = [
    {id: NAME_OPTION_ID, name: "Name", field: CrossRateSortField.ForeignCurrencyCode},
    {id: PERFORMANCE_OPTION_ID, name: "Performance"}
];

export const PERIOD_INTRADAY_OPTION_ID = "intraday"
export const PERIOD_OPTIONS: OptionItemWithPeriod[] = [
    {id: PERIOD_INTRADAY_OPTION_ID, name: "Intraday"},
    {id: "week1", name: "1 Woche", field: CrossRateSortField.PerformanceWeek1},
    {id: "month1", name: "1 Monat", field: CrossRateSortField.PerformanceMonth1},
    {id: "month6", name: "6 Monate", field: CrossRateSortField.PerformanceMonth6},
    {id: "year1", name: "1 Jahr", field: CrossRateSortField.PerformanceWeek52},
    {id: "year3", name: "3 Jahre", field: CrossRateSortField.PerformanceYear3},
];

export function extractFromPerformance(last: number | undefined, performance: InstrumentPerformance[], period: CalculationPeriod): Quote | undefined {
    let value = performance.find(current => current.period === period);
    if (!value) {
        return undefined;
    }
    return {value: last || value.averagePrice, percentChange: value.performance, change: value.performanceAbsolute};
}

export function mapPeriod(field?: CrossRateSortField): ChartScope {
    if (!!field) {
        switch (field) {
            case CrossRateSortField.PerformanceWeek1:
                return ChartScope.Week;
            case CrossRateSortField.PerformanceMonth1:
                return ChartScope.Month;
            case CrossRateSortField.PerformanceMonth6:
                return ChartScope.SixMonth;
            case CrossRateSortField.PerformanceWeek52:
                return ChartScope.Year;
            case CrossRateSortField.PerformanceYear3:
                return ChartScope.ThreeYear;
        }
    }
    return ChartScope.Intraday;
}

export function extractQuote(instrument: Instrument | undefined, scope: ChartScope): Quote | undefined {
    switch (scope) {
        case ChartScope.Intraday:
            if (!!instrument?.snapQuote) {
                return instrument?.snapQuote?.quote || undefined;
            }
            break;
        case ChartScope.Week:
            return extractFromPerformance(instrument?.snapQuote?.quote?.value || undefined, instrument?.performance || [], CalculationPeriod.Week1);
        case ChartScope.Month:
            return extractFromPerformance(instrument?.snapQuote?.quote?.value || undefined, instrument?.performance || [], CalculationPeriod.Month1);
        case ChartScope.SixMonth:
            return extractFromPerformance(instrument?.snapQuote?.quote?.value || undefined, instrument?.performance || [], CalculationPeriod.Month6);
        case ChartScope.Year:
            return extractFromPerformance(instrument?.snapQuote?.quote?.value || undefined, instrument?.performance || [], CalculationPeriod.Week52);
        case ChartScope.ThreeYear:
            return extractFromPerformance(instrument?.snapQuote?.quote?.value || undefined, instrument?.performance || [], CalculationPeriod.Year3);
    }
    return undefined;
}


export function getPeriodOptions(sortOptionId: string) {
    if (sortOptionId === PERFORMANCE_OPTION_ID) {
        return PERIOD_OPTIONS.map(current => ({
            ...current,
            disabled: current.id === PERIOD_INTRADAY_OPTION_ID
        }));
    }
    return PERIOD_OPTIONS;
}

export function mapToCountryFlag(currencyCode: string) {
    const currency2CountryMap: {[key: string]: Array<string>} = {
        "SLL": [
            "SLE"
        ],
        "CNY": [
            "CHN"
        ],
        "BHD": [
            "BHR"
        ],
        "KES": [
            "KEN"
        ],
        "SYP": [
            "SYR"
        ],
        "KZT": [
            "KAZ"
        ],
        "CHF": [
            "CHE"
        ],
        "JPY": [
            "JPN"
        ],
        "USD": [
            "USA",
            "ECU",
            "SLV",
            "MHL",
            "FSM",
            "PLW",
            "TLS"
        ],
        "BAM": [
            "BIH"
        ],
        "ZAR": [
            "ZAF"
        ],
        "TRY": [
            "TUR"
        ],
        "BWP": [
            "BWA"
        ],
        "RON": [
            "ROU"
        ],
        "NGN": [
            "NGA"
        ],
        "KHR": [
            "KHM"
        ],
        "JMD": [
            "JAM"
        ],
        "MVR": [
            "MDV"
        ],
        "TTD": [
            "TTO"
        ],
        "MUR": [
            "MUS"
        ],
        "MRU": [
            "MRT"
        ],
        "NAD": [
            "NAM"
        ],
        "GEL": [
            "GEO"
        ],
        "STN": [
            "STP"
        ],
        "MGA": [
            "MDG"
        ],
        "ETB": [
            "ETH"
        ],
        "ERN": [
            "ERI"
        ],
        "QAR": [
            "QAT"
        ],
        "UYU": [
            "URY"
        ],
        "GYD": [
            "GUY"
        ],
        "AUD": [
            "AUS",
            "KIR",
            "NRU",
            "TUV"
        ],
        "ANG" :[
            "CUW",
            "SXM"
        ],
        "AWG": [
            "ABW"
        ],
        "COP": [
            "COL"
        ],
        "UGX": [
            "UGA"
        ],
        "NOK": [
            "NOR"
        ],
        "NPR": [
            "NPL"
        ],
        "SOS": [
            "SOM"
        ],
        "TMT": [
            "TKM"
        ],
        "MKD": [
            "MKD"
        ],
        "ILS": [
            "ISR"
        ],
        "MNT": [
            "MNG"
        ],
        "AZN": [
            "AZE"
        ],
        "PGK": [
            "PNG"
        ],
        "TND": [
            "TUN"
        ],
        "PKR": [
            "PAK"
        ],
        "RWF": [
            "RWA"
        ],
        "KGS": [
            "KGZ"
        ],
        "VEF": [
            "VEN"
        ],
        "SCR": [
            "SYC"
        ],
        "RSD": [
            "SRB"
        ],
        "LYD": [
            "LBY"
        ],
        "XOF": [
            "BEN",
            "BFA",
            "MLI",
            "NER",
            "SEN",
            "TGO"
        ],
        "PYG": [
            "PRY"
        ],
        "BOB": [
            "BOL"
        ],
        "HTG": [
            "HTI"
        ],
        "PAB": [
            "PAN"
        ],
        "ZMK": [
            "ZMB"
        ],
        "KMF": [
            "COM"
        ],
        "BGN": [
            "BGR"
        ],
        "SGD": [
            "SGP"
        ],
        "AOA": [
            "AGO"
        ],
        "MAD": [
            "MAR"
        ],
        "SDG": [
            "SDN"
        ],
        "INR": [
            "IND"
        ],
        "BND": [
            "BRN"
        ],
        "WST": [
            "WSM"
        ],
        "FJD": [
            "FJI"
        ],
        "TZS": [
            "TZA"
        ],
        "IQD": [
            "IRQ"
        ],
        "BYN": [
            "BLR"
        ],
        "CUP": [
            "CUB"
        ],
        "BTN": [
            "BTN"
        ],
        "CRC": [
            "CRI"
        ],
        "GMD": [
            "GMB"
        ],
        "GNF": [
            "GIN"
        ],
        "VND": [
            "VNM"
        ],
        "BDT": [
            "BGD"
        ],
        "LAK": [
            "LAO"
        ],
        "DKK": [
            "DNK"
        ],
        "PHP": [
            "PHL"
        ],
        "AMD": [
            "ARM"
        ],
        "DOP": [
            "DOM"
        ],
        "KPW": [
            "PRK"
        ],
        "KWD": [
            "KWT"
        ],
        "SEK": [
            "SWE"
        ],
        "UAH": [
            "UKR"
        ],
        "XAF": [
            "CMR",
            "CAF",
            "TCD",
            "COG",
            "CIV",
            "GNQ",
            "GAB",
            "GNB"
        ],
        "SSP": [
            "SSD"
        ],
        "DZD": [
            "DZA"
        ],
        "GHS": [
            "GHA"
        ],
        "IRR": [
            "IRN"
        ],
        "PLN": [
            "POL"
        ],
        "MXN": [
            "MEX"
        ],
        "BSD": [
            "BHS"
        ],
        "MZN": [
            "MOZ"
        ],
        "ALL": [
            "ALB"
        ],
        "YER": [
            "YEM"
        ],
        "LBP": [
            "LBN"
        ],
        "CLP": [
            "CHL"
        ],
        "BBD": [
            "BRB"
        ],
        "AED": [
            "ARE"
        ],
        "EGP": [
            "EGY"
        ],
        "CDF": [
            "COD"
        ],
        "NZD": [
            "COK",
            "NZL",
            "NIU"
        ],
        "LSL": [
            "LSO"
        ],
        "NIO": [
            "NIC"
        ],
        "CAD": [
            "CAN"
        ],
        "IDR": [
            "IDN"
        ],
        "BIF": [
            "BDI"
        ],
        "KRW": [
            "KOR"
        ],
        "LRD": [
            "LBR"
        ],
        "SRD": [
            "SUR"
        ],
        "MDL": [
            "MDA"
        ],
        "GBP": [
            "GBR"
        ],
        "LKR": [
            "LKA"
        ],
        "SZL": [
            "SWZ"
        ],
        "DJF": [
            "DJI"
        ],
        "THB": [
            "THA"
        ],
        "UZS": [
            "UZB"
        ],
        "HNL": [
            "HND"
        ],
        "RUB": [
            "RUS"
        ],
        "JOD": [
            "JOR"
        ],
        "PEN": [
            "PER"
        ],
        "MYR": [
            "MYS"
        ],
        "TOP": [
            "TON"
        ],
        "HUF": [
            "HUN"
        ],
        "HKD" : [
          "HKG"
        ],
        "CVE": [
            "CPV"
        ],
        "AFN": [
            "AFG"
        ],
        "HRK": [
            "HRV"
        ],
        "XCD": [
            "ATG",
            "DMA",
            "GRD",
            "KNA",
            "LCA",
            "VCT"
        ],
        "GTQ": [
            "GTM"
        ],
        "ZWD": [
            "ZWE"
        ],
        "SAR": [
            "SAU"
        ],
        "BRL": [
            "BRA"
        ],
        "BMD" : [
            "BMU"
        ],
        "CZK" : [
            "CZE"
        ],
        "GIP" : [
            "GIB"
        ],
        "SVC" : [
            "SLV"
        ],
        "KYD" : [
            "CYM"
        ],
        "MOP" : [
            "MAC"
        ],
        "TWD" : [
            "TWN"
        ],
        "VES" : [
            "VEN"
        ],
        "EUR": [
            "EUR", // Europe flag
            "AND",
            "AUT",
            "BEL",
            "CYP",
            "CZE",
            "EST",
            "FIN",
            "FRA",
            "GRC",
            "IRL",
            "ITA",
            "LVA",
            "LTU",
            "LUX",
            "MLT",
            "MCO",
            "MNE",
            "NLD",
            "PRT",
            "SMR",
            "SVK",
            "SVN",
            "ESP"
        ],
        "VUV": [
            "VUT"
        ],
        "TJS": [
            "TJK"
        ],
        "MMK": [
            "MMR"
        ],
        "OMR": [
            "OMN"
        ],
        "BZD": [
            "BLZ"
        ],
        "MWK": [
            "MWI"
        ],
        "ARS": [
            "ARG"
        ],
        "ISK": [
            "ISL"
        ],
        "SBD": [
            "SLB"
        ],
        "CNH": [
            "CHN"
        ],
        "DEM": [
            "DEU"
        ],
        "ITL": [
            "ITA"
        ],
        "NLG": [
            "EUR"
        ],
        "ZAL": [
            "ZAR"
        ]
    }
    const countryNames:string[] = currency2CountryMap[currencyCode.toUpperCase()];
    if(countryNames && countryNames.length > 0) {
        return countryNames[0].toLowerCase()
    }
    return 'no_flag';
}
