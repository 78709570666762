import React, {ReactNode} from "react";
import {
    BaseShareSearchProps,
    CriteriaState, EnabledProps, generateShareSearchCard,
    GlobalShareSearchProps,
    ShareSearchCardText, wrapShareSearchCard
} from "../../BaseShareSearchCard";
import {ShareRegionsDropdown} from "../../ShareRegionsDropdown";
import {FilterDropdownItem, OptionItem} from "../../../../common/SearchCard/FilterDropdownItem";
import {Period, SearchShareCriterion, ShareSearchKeyFigure, ShareSortField} from "../../../../../generated/graphql";
import {CardResults} from "../../utils";
import {marketCapCardTableHeader} from "../../tables/shareTableHeaders";
import { COLUMN_VOLATILITY, generateTableRender } from "../../ShareTableResult";

interface PerformanceVolatilityCardProps extends BaseShareSearchProps {
    children?: ReactNode
}

interface PerformanceVolatilityCardState extends GlobalShareSearchProps {
    valueId: string;
    valueFrom?: number;  
    valueTo?: number;  
}

function PerformanceVolatilityCardContentView(props: CriteriaState<PerformanceVolatilityCardState>) {
    return (
        <>
            <ShareRegionsDropdown state={props.state} setCriteria={props.setCriteria}/>
            <ShareSearchCardText text={"Aktien mit einer"}/>
            <FilterDropdownItem<OptionItemFloatRange>
                onSelect={(option: OptionItemFloatRange) => {
                    props.setCriteria({ ...props.state, 
                        valueId: option.id,
                        valueFrom: option.valueFrom,
                        valueTo: option.valueTo,
                    });
                }}
                options={VOLATILITY_OPTIONS}
                activeId={props.state.valueId}
            />
            <ShareSearchCardText text={"Volatilität"}/>
        </>
    )
}

interface OptionItemFloatRange extends OptionItem {
    valueFrom?: number;
    valueTo?: number;
}

const VOLATILITY_OPTIONS: OptionItemFloatRange[] = [
    {id: 'hohen', name: 'hohen', valueFrom: 1},
    {id: 'niedriegen', name: 'niedriegen', valueFrom: 0.01, valueTo: 1.0},
]

function renderQuery(state: PerformanceVolatilityCardState): SearchShareCriterion {
    return {
        regionId: state.regionId,
        ranges: [{keyFigure: ShareSearchKeyFigure.Volatility, from: state.valueFrom, to: state.valueTo}],
        trends: [],
        period: Period.Last_1Month
    }
}

export const PerformanceVolatilityCard = wrapShareSearchCard(
    generateShareSearchCard<PerformanceVolatilityCardProps & EnabledProps, PerformanceVolatilityCardState>(
        PerformanceVolatilityCardContentView
    ),
    renderQuery,
    {
        regionId: null,
        regionName: null,
        valueId: VOLATILITY_OPTIONS[0].id,
        valueFrom: VOLATILITY_OPTIONS[0].valueFrom,
        valueTo: VOLATILITY_OPTIONS[0].valueTo,
        period: Period.Last_1Month,
        ranges: [],
        trends: [],
        marketCapitalization: null,
        sort: {field: ShareSortField.Volatility, direction: 'asc'}
    },
    false,
    marketCapCardTableHeader,
    CardResults.volatility,
    ShareSortField.Volatility,
    ["Volatilität"],
    generateTableRender([COLUMN_VOLATILITY])
);


