import React, {useRef} from "react";
import {DerivativeOptionType, InstrumentGroup, Quote, SnapQuote} from "../../../../../generated/graphql";
import {Link} from "react-router-dom";
import {extractQuotes, formatPrice, getAssetLink, quoteFormat, QuoteValueType} from "../../../../../utils";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {Button, Spinner} from "react-bootstrap";
import SvgImage from "../../../../common/image/SvgImage";
import {SnapQuoteDelayIndicator} from "../../../../common";
import classNames from "classnames";
import {computeDueToCss} from "../../DerivativeSearchResult";
import {useMediaQuery} from "react-responsive";
import {PercentChangeVisualizationResult, usePercentChangeVisualization} from "hooks/usePercentChangeVisualization";
import {SnapQuoteSubscriptionResult, useSnapQuoteSubscription} from "hooks/useSnapQuoteSubscription";
import {Maybe} from "graphql/jsutils/Maybe";

export interface CommonProps{
    wkn?: string | undefined | null
    group?: InstrumentGroup
    loading?: boolean
    bid?: Quote | undefined
    ask?: Quote | undefined
    id?: number
    dueTo?: number | null
    typeNameValue?: any
    classNameLong?: string
    classNameShort?: string
    assetTypeValue?: string
    showOnTablet?: boolean
    toggle?: boolean
}

export enum SnapValueType {
  Bid = 'BID',
  Ask = 'ASK',
  When = "WHEN",
  Delay = "DELAY",
  WhenText = "WhenText"
}

export interface SnapProps {
    snapQuote: SnapQuote | null | undefined,
    type: SnapValueType,
    group?: InstrumentGroup
    loading?: boolean,
    bidAskClassName?: string,
    whenTextValueClass?: string,
    showTimeOnTablet?: boolean
 }


export function WKN({group, wkn}: CommonProps){
    const isDesktop = useMediaQuery({
        query: '(min-width: 1281px)'
    });

    return(
        <>
            <Link className="font-weight-bold" to={{pathname: getAssetLink(group) || "#"}}>{wkn}</Link>
            {wkn && isDesktop &&
            <CopyToClipboard text={wkn}>
                <Button variant="link"
                        className="move-arrow svg-icon top-move my-n2 copy-button d-inline-block d-xl-none">
                  <SvgImage icon= 'icon_copy_white.svg' convert={false} spanClass="copy-icon" width={'28'} />
                </Button>
            </CopyToClipboard>
            }
        </>
    )
}

export function SnapValue({snapQuote, type, group, loading, bidAskClassName = "", whenTextValueClass, showTimeOnTablet}: SnapProps) {
    let quoteValueType
    switch (type) {
        case SnapValueType.Ask:
            quoteValueType = QuoteValueType.ASK
            break
        case SnapValueType.Bid:
        case SnapValueType.Delay:
        case SnapValueType.When:
        case SnapValueType.WhenText:
            quoteValueType = QuoteValueType.BID
            break
    }
    const blinkColor = type === SnapValueType.Bid || type === SnapValueType.Ask
    const {value, toggle}: SnapQuoteSubscriptionResult = useSnapQuoteSubscription(snapQuote?.instrumentId, quoteValueType, {
        blink: type === SnapValueType.Bid || type === SnapValueType.Ask
    })
    const currentSnapQuote = value || snapQuote
    const {bid, ask} = extractQuotes(currentSnapQuote);
    const delay: Maybe<number> = useRef(extractQuotes(snapQuote).bid?.delay).current
    let quoteValue
    switch (type) {
        case SnapValueType.Bid:
            quoteValue = bid?.value
            break
        case SnapValueType.Ask:
            quoteValue = ask?.value
            break
        case SnapValueType.Delay:
            quoteValue = bid?.delay
            break
        case SnapValueType.When:
            quoteValue = bid?.when
            break
        case SnapValueType.WhenText:
            quoteValue = bid?.when
            break
        default:
            break
    }

    const oldValue = useRef(quoteValue)

    let resultElement
    switch (type) {
        case SnapValueType.Bid:
        case SnapValueType.Ask:
            const blinkColorRed = blinkColor && quoteValue && !!oldValue.current && oldValue.current > quoteValue
            const blinkColorGreen = blinkColor && quoteValue && !!oldValue.current && oldValue.current < quoteValue
            if (oldValue.current !== quoteValue)
                oldValue.current = quoteValue

            resultElement = (
                <td className={classNames(`font-weight-bold text-nowrap text-right ${bidAskClassName}`,{
                })}>
                    {loading ? <Spinner animation="border"/> :
                        <span className={classNames({
                            'asset-value-movement-color-blinker-font--red': toggle && blinkColorRed,
                            'asset-value-movement-color-blinker-font--geeen': toggle && blinkColorGreen,
                        })}>
                            {formatPrice(quoteValue, group?.assetGroup)}
                        </span>}
                </td>
            )
            break
        case SnapValueType.Delay:
            resultElement = (
                <td className="text-right d-none d-xl-table-cell px-0">
                    {!loading && (
                        <SnapQuoteDelayIndicator className={"d-none d-xl-inline-block"} delay={delay}/>
                    )}
                </td>
            )
            break
        case SnapValueType.When:
            resultElement = (
                <td className={"text-left pl-1 pr-0 d-none " + (showTimeOnTablet ? "d-md-table-cell" : "d-xl-table-cell " ) } >
                    {loading ? <Spinner animation="border"/> :
                    <>
                        {quoteFormat(bid?.when)}
                    </>
                    }
                </td>
            )
            break
        case SnapValueType.WhenText:
            return (
                <>
                    Zeit: <span className={whenTextValueClass}>{quoteFormat(bid?.when)}</span>
                </>
        )
        default:
            return (<></>)
    }

    return resultElement
}

export function OptionType({group, classNameLong, classNameShort}: CommonProps){
    return (
        <>
            <span
                className={classNames(group?.derivative?.optionType === DerivativeOptionType.Call ? "btn-green" : "btn-pink", `px-1 text-center text-white font-size-12px ${classNameLong}`)}>
                {group?.derivative?.optionType}
            </span>

            <span
                className={classNames(group?.derivative?.optionType === "CALL" ? "btn-green" : "btn-pink", "px-0 ml-sm-n2 mr-sm-2 px-1 text-center text-white font-size-12px d-inline-block d-md-block d-xl-none")}>
                        {group?.derivative?.optionType === "CALL" ? "C" : "P"}
            </span>
        </>
    )
}

export function DueTo({dueTo, classNameLong}: CommonProps){
    return(
            <td className={classNames(computeDueToCss(dueTo), `text-center px-0 px-md-1 text-nowrap ${classNameLong}`)}>
                <DueToValue dueTo={dueTo} />
            </td>
    )
}
export function DueToValue({dueTo}: CommonProps){
    return(<>
                {dueTo != null ? (dueTo >= 0 ? dueTo + "T" : "-") : "Open End"}
            </>)
}

export function DueToLeftAligh({dueTo, classNameLong}: CommonProps){
    return(
            <td className={classNames(computeDueToCss(dueTo), `text-left px-0 px-md-1 text-nowrap ${classNameLong}`)}>
                <DueToValue dueTo={dueTo} />
            </td>
    )
}

export default WKN
