import React, {Component, ReactSVGElement, useEffect, useState} from "react";
import classNames from "classnames";
import {iconViewportCorrections} from "utils";

interface SvgImageProps extends React.HTMLAttributes<HTMLImageElement> {
    icon: string;
    imgClass?: string;
    spanClass?: string;
    width?: string;
    childBeforeImage?: boolean;
    convert?: boolean
    alt?: string;
    fixImagePadding?: boolean;
}

interface SvgImageState {
    svg?: ReactSVGElement;
}

export default class SvgImage extends Component<SvgImageProps, SvgImageState> {
    baseUrl: string;

    constructor(props: SvgImageProps) {
        super(props);
        this.state = {};
        this.baseUrl = process.env.PUBLIC_URL + "/static/img/svg/";
    }

    render() {
        return (
            <span className={classNames(this.props.spanClass, 'svg-icon')} style={{lineHeight: this.props.fixImagePadding ? "1px" : undefined}} onClick={this.props.onClick}>
                {this.props.childBeforeImage && this.props.children}
                {this.props.convert ?
                    <Svg url={this.baseUrl + this.props.icon} {...getHtmlElementAttr(this.props)} alt={this.props.alt}
                         className={classNames(this.props.imgClass, 'svg-convert')}
                         viewPortCorrections={this.props.fixImagePadding ? iconViewportCorrections[this.props.icon] : undefined}
                         /> :
                    (<img src={this.baseUrl + this.props.icon} alt={this.props.alt || ""} {...getHtmlElementAttr(this.props)}
                          className={this.props.imgClass}
                          />)
                }
                {!this.props.childBeforeImage && this.props.children}
            </span>
        );
    }
}

function getAttributes(el: SVGSVGElement): object {
    const attrs = {};
    let attributeNames = el.getAttributeNames();
    attributeNames.forEach((name: string) => {
        // @ts-ignore
        attrs[name] = el.getAttribute(name);
    });
    return attrs;
}

function Svg(props: any) {
    let [svgBody, setSvgBody] = useState('');
    let [svgAttrs, setSvgAttrs] = useState();
    let [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const imgAttrs = {...props};
        delete imgAttrs.url;
        delete imgAttrs.viewPortCorrections;
        fetch(props.url).then(t => t.text()).then(e => {
            const svg = (new DOMParser()).parseFromString(e, "image/svg+xml").querySelector("svg");
            if (svg) {
                setSvgBody(svg.innerHTML);
                const svgAttrs = getAttributes(svg)
                if (props.viewPortCorrections && 'height' in svgAttrs)
                    delete (svgAttrs as object & {height?: number}).height
                setSvgAttrs({...svgAttrs, ...imgAttrs});
            } else {
                setSvgBody('');
                setSvgAttrs({...imgAttrs});
            }
            setLoaded(true);
        });
    }, [props]);

    return (<>
        {loaded &&
            (props.viewPortCorrections ?
             (<svg {...svgAttrs} dangerouslySetInnerHTML={{__html: svgBody}} viewBox={props.viewPortCorrections || ""} />) :
             (<svg {...svgAttrs} dangerouslySetInnerHTML={{__html: svgBody}} />)
            )
        }
    </>);
}

function getHtmlElementAttr(props: any): any {
    const attrs = {...props};
    delete attrs.icon;
    delete attrs.imgClass;
    delete attrs.spanClass;
    delete attrs.childBeforeImage;
    delete attrs.convert;
    delete attrs.children;
    delete attrs.onClick;
    delete attrs.fixImagePadding;
    return attrs;
}
