import { useQuery } from "@apollo/client";
import { NewsAssetPageSection, PageBannerComponent } from "components/common";
import { AssetGroup, Query } from "generated/graphql";
import { loader } from "graphql.macro";
import { GET_ASSET_PAGE } from "graphql/query";
import { useEffect, useRef } from "react";
import { Spinner } from "react-bootstrap";
import { Link, useParams, useLocation } from "react-router-dom";
import { EtfQuoteAndExchangeSection } from "./EtfQuoteAndExchangeSection";
import {guessInfonlineSection, trigInfonline, trigInfonlineAction} from "../../common/InfonlineService";
import { Helmet } from "react-helmet";
import { Page404 } from "components/common/page404/Page404";
import { getInstrumentIdByExchnageCode, SECTIONS } from "utils";
import { generateTargetingObject, guessTargetingZone, generateRoSvariable, generateLoginVariable } from "components/common/TargetingService";
import keycloak from "keycloak";
import { HorizontalNaviationBar } from "components/common/horizontal-navigation-bar/HorizontalNaviationBar";
import { getAssetForUrl } from "components/profile/utils";

interface Child {
    changeInstrument: (id: number) => void;
}

export const EtfPage = () => {
    const pathParam = useParams<{ seoTag: string, section: string }>();
    const seoTag = pathParam.seoTag;

    useEffect(() => {
        trigInfonline(guessInfonlineSection(), "portrait_page")
    }, [])

    const {loading, data} = useQuery(GET_ASSET_PAGE, {
        variables: {seoTag: seoTag},
        skip: !seoTag
    });

    if (loading) {
        return (
            <div className={"mt-4 d-flex justify-content-center"} style={{height: "70px"}}>
                <Spinner animation="border"/>
            </div>
        );
    }

    if (data.assetPage.assetGroup !== AssetGroup.Etf || !data.assetPage.groupId) {
        return (<Page404 />)
    }

    if (!SECTIONS.includes(pathParam.section)) {
        return (<Page404 />)
    }

    return (
        <EtfPageContent groupId={data.assetPage.groupId}
                        assetGroup={data.assetPage.assetGroup}
                        index={data.assetPage.index || false}
                        follow={data.assetPage.follow || false}
        />
    );
}

interface EtfPageContentProps {
    groupId: number;
    assetGroup: AssetGroup;
    index: boolean;
    follow: boolean
}

export const EtfPageContent = ({groupId, assetGroup, follow, index}: EtfPageContentProps) => {
    let location = useLocation();
    const pathParam = useParams<{ seoTag: string, section: string }>();
    let childRef = useRef<Child>();

    const {loading, data} = useQuery<Query>(loader('./getEtfPage.graphql'), {
        variables: { groupId },
    });

    const instrumentChange = (instrumentId: number) => {
        if (childRef && childRef.current) {
            childRef.current.changeInstrument(instrumentId);
        }
    }

    if (!data || loading) {
        return (
            <div className={"p-1"} style={{height: "70px"}}>
                <Spinner animation="border"/>
            </div>
        );
    }

    if (!data.group || !data.group.content || data.group.content.length < 1) {
        return (<Page404 />)
    }

    return (
        <>
            <Helmet>
                {index && follow ?
                    <>
                        <title>{`${data.group.name} - ${data.group.wkn ? data.group.wkn + ' - ' : ''} ${data.group.isin} - Kurs - Chart - Performance`}</title>
                        <meta name="description"
                              content={`Überblick des ETF ${data.group.name} - WKN ${data.group.wkn}, ISIN ${data.group.isin}, Kursvergleich, Performance, Risiko, Anlageidee ➨ bei finanztreff.de topaktuell und kostenlos!`} />
                        <meta name="keywords"
                              content={`Ausführliches ETF-Portrait des ${data.group.name}', WKN ${data.group.wkn}, ISIN ${data.group.isin} - finanztreff: Exchange Traded Funds, ETFs, ETF Kurse, Bester ETF, ETF Aktienfonds, ETF Fonds`} />
                    </> :
                    <meta name="Robots" content="NOINDEX,NOFOLLOW" />
                }
                <script type="text/javascript">{`var Ads_BA_DATA = ` + JSON.stringify(generateTargetingObject(guessTargetingZone(), generateRoSvariable(guessTargetingZone()), generateLoginVariable(keycloak.authenticated), data.group?.wkn, data.group?.isin, data.group?.assetType?.name, localStorage.getItem('pVariable') ? localStorage.getItem('pVariable') : null, localStorage.getItem('mfVariable') ? localStorage.getItem('mfVariable') : null)) + `;`}</script>
                <script type="text/javascript" id="refresh-script">Ads_BA_refresh();</script>
            </Helmet>
            <PageBannerComponent group={data.group}
                                 assetClass={assetGroup} assetClassName={assetGroup.toLowerCase()} assetTypeGroupName={data.group.assetType?.name + " "}
                                 change={instrumentChange}
            />
            <HorizontalNaviationBar links={[
                <Link key="weitere_borsenplatze" className="text-white" onClick={trigInfonlineAction('weitere_borsenplatze')}
                    to={{
                        key: '',
                        pathname: "/" + getAssetForUrl(data.group.assetGroup).toLowerCase() + "/kurse/" + pathParam.seoTag + "/",
                        hash: '#boersen',
                        state: getInstrumentIdByExchnageCode(data.group, location)
                    }}>
                    Börsenplätze
                </Link>,
                <Link key="times_sales" className="text-white" onClick={trigInfonlineAction('times_sales')}
                    to={{
                        key: 'times',
                        pathname: "/" + getAssetForUrl(data.group.assetGroup).toLowerCase() + "/kurse/" + pathParam.seoTag + "/",
                        hash: '#times',
                        state: getInstrumentIdByExchnageCode(data.group, location)
                    }}>
                    Times &amp; Sales
                </Link>,
                <Link key="historische_kurse" className="text-white" onClick={trigInfonlineAction('historische_kurse')}
                    to={{
                        key: 'historie',
                        pathname: "/" + getAssetForUrl(data.group.assetGroup).toLowerCase() + "/kurse/" + pathParam.seoTag + "/",
                        hash: '#historie',
                        state: getInstrumentIdByExchnageCode(data.group, location)
                    }}>
                    Historische Kurse
                </Link>,
                <Link key="kursvergleich" className="text-white" onClick={trigInfonlineAction('kursvergleich')}
                    to={{
                        key: 'chart',
                        pathname: "/" + getAssetForUrl(data.group.assetGroup).toLowerCase() + "/kurse/" + pathParam.seoTag + "/",
                        hash: '#chart',
                        state: getInstrumentIdByExchnageCode(data.group, location)
                    }}>
                    Kursvergleich letzte Handelstage
                </Link>,
                <>
                {data.group.etf?.investmentConcept &&
                    <a className="text-white" href='#anlageidee'>
                        Anlageidee
                    </a>
                }
                </>,
            ]} />
            <EtfQuoteAndExchangeSection instrumentId={getInstrumentIdByExchnageCode(data.group, location)} instrumentGroup={data.group} />
            {
                data.group.name && data.group.isin &&
                    <NewsAssetPageSection groupName={data.group.name} isin={data.group.isin} title="Etf Nachrichten" />
            }
        </>
    )
}
