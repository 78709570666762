import classNames from "classnames";
import { PieChartColors } from "components/profile/utils"
import {CalculationPeriod, InstrumentGroupFundTranche, Instrument} from "generated/graphql";
import { useBootstrapBreakpoint } from "hooks/useBootstrapBreakpoint";
import {formatKeyFigureValue} from "../../../../utils";
import { useMediaQuery } from "react-responsive";
import FTLChartComponent from "../../../common/charts/base/FTLChartComponent";

const DONUT_COLORS = ["#63BD5C", "#CAC11F", "#FFC300", "#FF8D38", "#E65456", "#E03C68", "#B6325F"];

function getSeries(riskFactor: number, inner: boolean) {
    let series: any[] = [];
    DONUT_COLORS.map((current: string, index: number) => {
        series.push({
            name: "", y: 10, color:  inner || riskFactor === index + 1 ? (riskFactor===0 ? "gray" :current) : "white",
            innerSize: '85%',
        });
        series.push(
            {
                name: "", y: 0.5, color: "white",
                innerSize: '85%',
            });
    });
    return series;
}

export function RiskSection(props: RiskSectionProps) {

    return (
        <>
            <div className="content-wrapper">
                <h2 className="content-wrapper-heading font-weight-bold">Risiko</h2>
                <div className="content">
                    <RiskSectionDonut riskFactor={props.fundTranche.srri?.value || 0}/>
                    <h3 className={classNames("content-wrapper-heading font-weight-bold text-center", !props.fundTranche.srri?.value && "text-gray")}>Risiko (KID)</h3> 
                    <RiskSectionContent instrument={props.instrument} />
                </div>
            </div>
        </>
    )
}


interface RiskSectionProps {
    className?: string;
    fundTranche: InstrumentGroupFundTranche;
    instrument: Instrument;
}

export function RiskSectionContent(props: {instrument: Instrument}) {
    let quoteKeyFigure1Year = props.instrument.performance.find(current => current.period == CalculationPeriod.Week52);
    let quoteKeyFigure3Year = props.instrument.performance.find(current => current.period == CalculationPeriod.Year3);

    return (
        <table style={{width: "100%"}}>
            <tbody>
                <tr>
                    <td className="py-2">Volatilität (1J / 3J)</td>
                    <td className="text-right font-weight-bold">
                        {formatKeyFigureValue(quoteKeyFigure1Year?.vola, 2, 2, ' %')}
                    </td>
                    <td className="text-right font-weight-bold">
                        {formatKeyFigureValue(quoteKeyFigure3Year?.vola, 2, 2, ' %')}
                    </td>
                </tr>
                <tr>
                    <td className="py-2">Sharpe (1J / 3J)</td>
                    <td className="text-right font-weight-bold">
                        {formatKeyFigureValue(quoteKeyFigure1Year?.sharpe, 2, 2)}
                    </td>
                    <td className="text-right font-weight-bold">
                        {formatKeyFigureValue(quoteKeyFigure3Year?.sharpe, 2, 2)}
                    </td>
                </tr>
                <tr>
                    <td className="py-2">Alpha (1J / 3J)</td>
                    <td className="text-right font-weight-bold">
                        {formatKeyFigureValue(quoteKeyFigure1Year?.alpha, 2, 2, ' %')}
                    </td>
                    <td className="text-right font-weight-bold">
                        {formatKeyFigureValue(quoteKeyFigure3Year?.alpha, 2, 2, ' %')}
                    </td>
                </tr>
                <tr>
                    <td className="py-2">Beta (1J / 3J)</td>
                    <td className="text-right font-weight-bold">
                        {formatKeyFigureValue(quoteKeyFigure1Year?.beta, 2, 2, ' %')}
                    </td>
                    <td className="text-right font-weight-bold">
                        {formatKeyFigureValue(quoteKeyFigure3Year?.beta, 2, 2, ' %')}
                    </td>
                </tr>
                <tr>
                    <td className="py-2">Beta Bear (1J / 3J)</td>
                    <td className="text-right font-weight-bold">
                        {formatKeyFigureValue(quoteKeyFigure1Year?.betaBear, 2, 2, ' %')}
                    </td>
                    <td colSpan={2} className="text-right font-weight-bold">
                        {formatKeyFigureValue(quoteKeyFigure3Year?.betaBear, 2, 2, ' %')}
                    </td>
                </tr>
                <tr>
                    <td className="py-2">Beta Bull (1J / 3J)</td>
                    <td className="text-right font-weight-bold">
                        {formatKeyFigureValue(quoteKeyFigure1Year?.betaBull, 2, 2, ' %')}
                    </td>
                    <td colSpan={2} className="text-right font-weight-bold">
                        {formatKeyFigureValue(quoteKeyFigure3Year?.betaBull, 2, 2, ' %')}
                    </td>
                </tr>
            </tbody>
        </table>
    )
}

export function RiskSectionDonut(props: { riskFactor: number, width?: number, height?: number, fontSize?: number, titlePosition?: number }) {
    const isDesktop=useMediaQuery({
        query:'(min-width:1100px) and (max-width:1279px)'
    })

    const donutWidth = useBootstrapBreakpoint({
        xl:isDesktop?230:275,
        md: 300,
        sm: 170,
        default: 275
    });

    const donutHeight = useBootstrapBreakpoint({
        xl: 300,
        md: 300,
        sm: 250,
        default: 300
    });

    let width = props.width ||  donutWidth;
    let height = (props.height && props.height * 2) || donutHeight;
    let textSize = height / 5;

    let options = {
        stockTools: {
            gui: {
                enabled: false
            }
        },
        colors: PieChartColors,
        chart: {
            animation: false,
            plotBackgroundColor: null,
            plotBorderWidth: 0,
            plotShadow: false,
            width, height,
            margin: [(height/-10),  width / -14, (height/-4),  width / -14],
            style: {
                marginBottom: (height/-3) + "px",
                // marginLeft: (props.riskFactor < 3 ? width/10 : (props.riskFactor > 5 ? -width/10 : 0)) + "px"
                //     marginLeft: (width / -4) + "px",
                //     marginRight: (width / -4) + "px"
            }
        },
        title: {
            text: props.riskFactor,
            align: 'center',
            verticalAlign: 'middle',
            style: {
                color: props.riskFactor === 0 ? "gray" : DONUT_COLORS[props.riskFactor - 1],
                fontWeight: 'bold',
                fontSize: textSize + "px"
            },
            y: height / 5,
            // x: textSize / 8,
        },
        credits: {
            enabled: false
        },
        tooltip: {
            enabled: false
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                dataLabels: {
                    enabled: false,
                },
                startAngle: -90,
                endAngle: 90,
            },
            series: {
                states: {
                    inactive: {
                        opacity: 1
                    },
                    hover: {
                        enabled: false
                    }
                }
            }
        },
        series: [{
            type: 'pie',
            innerSize: '85%',
            size: '75%',
            borderWidth: 0,
            data: getSeries(props.riskFactor, true)
        },
        {
            type: 'pie',
            innerSize: '75%',
            borderWidth: 0,
            data: getSeries(props.riskFactor, false)
        }]
    }
    return (
        <FTLChartComponent
            options={options}
        />
    );
}
