import { useQuery } from "@apollo/client";
import { Instrument, Query } from "../../../generated/graphql";
import { gql, loader } from "graphql.macro";
import { Button, Container, Spinner } from "react-bootstrap";
import { useState } from "react";
import classNames from "classnames";
import { className, switchTopsFlopsIVW } from "../../../utils";
import { SelectBottomBox } from "../../common/select/SelectBottomBox/SelectBottomBox";
import { guessInfonlineSection, trigInfonline } from "../../common/InfonlineService";
import {useDesktopMediaQuery} from "hooks/useMediaQuery";
import styles from "./TopFlopSection.module.scss";
import SvgImage from "components/common/image/SvgImage";
import { TopFlopCard } from "./TopFlopGroup";

export const TopFlopSection = (props: { className?: string, isHomePage?: boolean , showOtherTopsAndFlops?: boolean, instruments?: Instrument[] }) => {
	const { data, loading, error } = useQuery(loader("./getTopFlopTab.graphql"), {
            skip: !!props.instruments,
            errorPolicy: "all"
        });

	if (loading) {
		return <div className="text-center py-2"><Spinner animation="border" /></div>
	}

	if (data && data.list && data.list.content) {

		return (
			<section className={classNames("main-section", props.className)}>
				<Container className={className("main-section-container", styles)}>
					<TopFlopContent list={props.instruments ? props.instruments : data?.list?.content} isHomePage={props.isHomePage} />
				</Container>
			</section>
		);
	}
	return <></>;
}

export function TopFlopContent(props: { list: Instrument[], isHomePage?: boolean}) {
    const isDesktop = useDesktopMediaQuery()
	const [activeInstrument, setActiveInstrument] = useState<Instrument>(props.list[0]);
	return (<>
            <div className="d-flex justify-content-between py-1px py-md-1">
                <h2 className="section-heading line-height-1 font-weight-bold">Tops &amp; Flops</h2>
                {isDesktop ? (
                    <nav className={className("sub-navigation m-0 nav-wrapper nav align-content-end gap-2", styles)}>
                        {props.list.map(current => (
                            <li>
                                <Button variant="link" key={current?.isin}
                                    onClick={() => { setActiveInstrument(current); trigInfonline(guessInfonlineSection(), switchTopsFlopsIVW(current.group.seoTag)) }}
                                    className={className(classNames("mx-2 p-0 fnt-size-16 line-height-20px font-weight-bold text-dark-blue", {
                                        'active': activeInstrument.id === current.id
                                    }), styles)}>
                                    {current?.group?.name}
                                </Button>
                            </li>))
                        }
                    </nav>
                ) : (
                    <SelectBottomBox
                        defaultValue={activeInstrument}
                        title="Top &amp; Flops"
                        className="d-flex align-items-center p-0 font-weight-bold text-dark-blue"
                        icon={<SvgImage icon="icon_direction_down_blue_light.svg" width="14" fixImagePadding={true} convert={true} spanClass="ml-2 pb-2px pb-md-0" />}
                        onSelect={(value => setActiveInstrument(value))}
                        options={
                            props.list.map(current => {
                                return {
                                    value: current,
                                    name: current?.group?.name
                                }
                            })
                        }
                    />
                )}
            </div>
            <TopFlopCard activeInstrument={activeInstrument} isHomePage={props.isHomePage} />
	</>);
}
