import classNames from "classnames";
import {useMediaQuery} from "react-responsive";
import {CarouselWrapper} from "components/common/carousel";
import {Link} from "react-router-dom";
import {Carousel, Col, Row} from 'react-bootstrap';
import "./TeaserBoxCarousel.scss";
import SvgImage from "components/common/image/SvgImage";
import {useQuery} from "@apollo/client";
import {loader} from "graphql.macro";
import moment from "moment";
import { Query } from "../../../generated/graphql";

interface TeaserBoxItem {
    date: moment.Moment;
    event: string;
}

interface ShareEventBoxCarouselItemProps {
    items: TeaserBoxItem[];
    isMobile: boolean;
    isTablet: boolean;
    isDesktop: boolean
}

const CAROUSEL_PAGE_SIZE = 6;

function getCarouselItems({items, isMobile, isTablet, isDesktop}: ShareEventBoxCarouselItemProps) {
    return Array.from({length: items.length / CAROUSEL_PAGE_SIZE + 1}, (_, i) => i)
            .map(page =>
                (<Carousel.Item>
                    <div
                        className={`${isMobile ? "m-teaserbox-carousel_wrapper" : isTablet ? "t-teaserbox-carousel_wrapper" : "teaserbox-carousel_wrapper"}`}>
                        {(isDesktop || isMobile) ?
                            (<div className="teaserbox-carousel_wrapper-borders">
                                {
                                    items.slice(page * CAROUSEL_PAGE_SIZE, Math.min((page + 1) * CAROUSEL_PAGE_SIZE, items.length))
                                        .map((data: TeaserBoxItem) => (
                                            <div className={`${isMobile ? "m-teaserbox-carousel_cell" : "teaserbox-carousel_cell"}`}>
                                                <div>{data.date.format("DD.MM.YYYY")}</div>
                                                <div className={"font-weight-bold"}>{data.event}</div>
                                            </div>
                                        ))
                                }
                            </div>) :
                            (<Row md={2} className="px-3 h-100">
                                {items.slice(page * CAROUSEL_PAGE_SIZE, Math.min((page + 1) * CAROUSEL_PAGE_SIZE, items.length))
                                    .map((data: TeaserBoxItem) => (
                                            <Col
                                                className="  t-teaserbox-carousel_col px-0">
                                                <div
                                                    className="t-teaserbox-carousel_col_container d-flex align-items-center ">
                                                    <div
                                                        style={{marginRight: "12px"}}>{data.date.format("DD.MM.YYYY")} </div>
                                                    <div className={"font-weight-bold"}> {data.event} </div>
                                                </div>
                                            </Col>
                                        )
                                    )}
                                <Col className="p-0">
                                    <div className="t-teaserbox-carousel_col_container_borders"></div>
                                </Col>
                                <Col className="p-0">
                                    <div className="t-teaserbox-carousel_col_container_borders"></div>
                                </Col>
                                <Col className="p-0">
                                    <div className="t-teaserbox-carousel_col_container_borders"></div>
                                </Col>
                            </Row>)
                        }
                    </div>
                </Carousel.Item>)
            );
}

interface ShareEventBoxCarouselProps {
    groupId: number;
}

export function ShareEventBoxCarousel({groupId}: ShareEventBoxCarouselProps) {
    let {data, loading} = useQuery<Query>(
        loader('./getProductCalendarEvents.graphql'), {
        variables: { groupId: groupId, from: moment().format('YYYY-MM-DD'), count: 12 },
    });
    const isDesktop = useMediaQuery({
        query: '(min-width: 1280px)'
    });

    const isTablet = useMediaQuery({
        query: '(max-width: 1279px)'
    })

    const isMobile = useMediaQuery({
        query: '(max-width: 767px)'
    })

    const totalEvents = data?.group?.calendarEvents?.edges?.length || 0;
    if (!totalEvents) {
        return <></>
    }

    return (
        <div className={classNames(isDesktop ? "teaserbox-carousel content-wrapper" :
            isMobile ? "m-teaserbox-carousel content-wrapper" : "t-teaserbox-carousel")}>
            <h3 className={classNames("font-weight-bold", isDesktop ? "teaserbox-carousel_heading" : isMobile ? "m-teaserbox-carousel_heading" : "t-teaserbox-carousel_heading")}>
                Nächste Termine
            </h3>
            <div className="content" style={{overflowX: 'clip'}}>
                <Carousel
                    as={CarouselWrapper}
                    slide={true}
                    wrap={true}
                    controlclass={classNames("dark-version", isDesktop ? "teaserbox-carousel_controls" :
                        isMobile ? "m-teaserbox-carousel_controls" : "t-teaserbox-carousel_controls")}
                    touch={true}
                    prevIcon={
                        <SvgImage spanClass="move-arrow " onClick={() => {
                        }}
                                  icon="icon_direction_left_dark.svg" convert={false}>

                        </SvgImage>
                    }
                    nextIcon={
                        <SvgImage spanClass="move-arrow" onClick={() => {
                        }}
                                  icon="icon_direction_right_dark.svg" convert={false}>
                        </SvgImage>
                    }
                >
                    {
                        getCarouselItems({
                            items: (data?.group?.calendarEvents?.edges || [])
                                        .map(current => current.node)
                                        .map(current => ({date: moment(current.when), event: current.title || ""})),
                            isMobile, isTablet, isDesktop
                        })
                    }
                </Carousel>
            </div>
            <div className="d-flex justify-content-end ">
                <Link className={classNames("text-blue", isDesktop ?
                    "teaserbox-carousel_link" : isMobile ? "m-teaserbox-carousel_link" : "t-teaserbox-carousel_link")}
                      to={{pathname: "/termine", state: {scrollToTop: "true"}}}>
                    Alle Termine...
                </Link>
            </div>
        </div>
    )
}
