import {QuoteType, SnapQuote, UpdateStickyInstrumentDocument} from "../generated/graphql";
import {useRef, useState} from "react";
import {ApplicationContextType, useApplicationContext} from "../ApplicationContext";
import {ApolloError, useApolloClient} from "@apollo/client";
import {QuoteValueType} from "utils";
import {getQuoteValue, getTradeQuote} from "components/profile/utils";
import {Subscription} from "zen-observable-ts";


export interface SnapQuoteSubscriptionResult {
    value: SnapQuote | undefined;
    error: ApolloError | undefined;
    toggle: boolean;
}

export type SnapQuoteSubscriptionOptions = {
    blink?: boolean
    skip?: boolean
    log?: boolean
}
interface SnapQuoteSubscriptionType  {
    (instrumentId: number | undefined, quoteValueType?: QuoteValueType, options?: SnapQuoteSubscriptionOptions): SnapQuoteSubscriptionResult
}
type SnapQuoteSubscriptionStateType = {
    toggle: boolean
    lastValue: SnapQuote | undefined
    error: ApolloError | undefined
}

export const useSnapQuoteSubscription: SnapQuoteSubscriptionType = (instrumentId, quoteValueType, options = {blink: false, log: false, skip: false}) => {
    const {blink, log, skip} = options
    const {pushActive}: ApplicationContextType = useApplicationContext()
    const subscription = useRef<Subscription | undefined>(undefined)
    const [state, setState] = useState<SnapQuoteSubscriptionStateType>({
        toggle: false,
        lastValue: undefined,
        error: undefined
    })
    const stateRef: React.MutableRefObject<SnapQuoteSubscriptionStateType> = useRef(state)
    const timeoutId: React.MutableRefObject<number | undefined> = useRef(undefined)
    stateRef.current = state

    const apolloClient = useApolloClient()

    if ((!subscription.current || subscription.current.closed) && !skip && pushActive && instrumentId != null) {
        subscription.current = apolloClient.subscribe({
            query: UpdateStickyInstrumentDocument,
            variables: {
                id: Number(instrumentId)
            }
        }).subscribe({
            next(data) {
                let update = false
                if (data.data?.update) {
                    const retData = data.data?.update
                    if (log) console.log(stateRef.current.lastValue)

                    if (quoteValueType === undefined) {
                        update = true
                    } else {
                        if (update === false && (quoteValueType & QuoteValueType.ASK) === QuoteValueType.ASK)
                            update = getQuoteValue(retData, QuoteType.Ask) !== getQuoteValue(stateRef.current.lastValue, QuoteType.Ask)
                        if (update === false && (quoteValueType & QuoteValueType.BID) === QuoteValueType.BID)
                            update = getQuoteValue(retData, QuoteType.Bid) !== getQuoteValue(stateRef.current.lastValue, QuoteType.Bid)
                        if (update === false && (quoteValueType & QuoteValueType.CHANGE) === QuoteValueType.CHANGE)
                            update = getTradeQuote(undefined, retData)?.change !== getTradeQuote(undefined, stateRef.current.lastValue)?.change
                        if (update === false && (quoteValueType & QuoteValueType.PERCENT_CHANGE) === QuoteValueType.PERCENT_CHANGE)
                            update = getTradeQuote(undefined, retData)?.percentChange !== getTradeQuote(undefined, stateRef.current.lastValue)?.percentChange
                        if (update === false && (quoteValueType & QuoteValueType.TURNOVER) === QuoteValueType.TURNOVER)
                            update = retData?.cumulativeTurnover !== stateRef.current.lastValue?.cumulativeTurnover
                        if (update === false && (quoteValueType & QuoteValueType.TRADE) === QuoteValueType.TRADE)
                            update = getTradeQuote(undefined, retData)?.value !== getTradeQuote(undefined, stateRef.current.lastValue)?.value
                        if (update === false && (quoteValueType & QuoteValueType.VOLUME) === QuoteValueType.VOLUME)
                            update = retData?.cumulativeVolume !== stateRef.current.lastValue?.cumulativeVolume
                    }
                    if (!update)
                        return

                    let newState = {lastValue: retData, toggle: false}
                    if (blink) {
                        timeoutId.current = window.setTimeout(() => {
                            setState(currentState => {
                                return {...currentState, toggle: false}
                            })
                            window.clearTimeout(timeoutId.current)
                        }, 250)
                        newState = {lastValue: retData, toggle: true}
                    }
                    setState(currentState => {
                        return {...currentState, ...newState}  
                    })
                }
            },
            error(err) {
                console.error(err)
            }
        })
    }
    if (!subscription.current?.closed && !pushActive)
        subscription.current?.unsubscribe()

    return {value: state.lastValue, error: state.error, toggle: state.toggle}
}
