import { useQuery } from '@apollo/client';
import { SearchInstrumentsComponent } from 'components/search/SearchInstrumentsComponent';
import {loader} from "graphql.macro";
import {Instrument, Query} from 'graphql/types';
import React, {useState} from "react";
import {Collapse, Spinner} from "react-bootstrap";
import SvgImage from "../../image/SvgImage";
import "./ChartToolSearchModal.scss";

export interface ChartToolSearchModal {
    searchString: string;
    onSelectInstrument: (instrument: Instrument) => void;
    onClose: () => void;
    showExpanded: boolean
}

function ChartToolSearchModal(props: ChartToolSearchModal) {
    const { loading: commonInstrumentsLoading, data: commonInstruments} = useQuery<Query>(loader('./getTopsFlopsIndices.graphql'))
    return (
        <Collapse in={props.showExpanded || !!props.searchString} className="securities-results mt-5 chart-tool-search-modal px-3 py-1">
            <div id="instruments-search-result " style={{ overflowX: 'clip' }} className="result-col">
                <div className="d-flex justify-content-between chart-tool-modal-search-heading chart-tool-modal-search-border pb-1" >
                    <div className="align-self-end">
                        Wertpapier auswählen
                    </div>
                    <button onClick={props.onClose} className='bg-white border-none'>
                        <SvgImage icon="icon_close_dark.svg" convert={false} width="28"/>
                    </button>
                </div>

                <div className='d-flex flex-wrap my-2 chart-search-border chart-tool-modal-search-heading chart-tool-modal-search-border'>
                    {commonInstrumentsLoading
                        ? <Spinner animation={'border'}/>
                        : commonInstruments?.list?.content?.map((value: Instrument) => {
                            return (
                                <div onClick={() => props.onSelectInstrument(value)} className='all-realtime-index mr-3 cursor-pointer'>
                                    {value.group?.name}
                                </div>
                            )
                        }).slice(0, 6)
                    }
                </div>
                {
                    props.searchString.length>0 &&
                    <div>
                        <h5 className='chart-tool-modal-search-heading mb-0'>Suchergebnisse</h5>
                        <SearchInstrumentsComponent searchString={props.searchString}
                                                    closeTrigger={props.onSelectInstrument} linkToSeoTag={false}/>
                    </div>
                }
            </div>
        </Collapse>
    
    )
}
export default ChartToolSearchModal