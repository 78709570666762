import { FundKeyFigure, SearchFundCriterion } from "graphql/types";
import { ReactNode, useContext } from "react";
import FundsSearchContext, {FundsSearchContextProps} from "../FundsSearchContext";
import {
    BaseFundSearchProps,
    CriteriaState, EnabledProps, generateFundCard,
    GlobalFundSearchProps,
    wrapFundSearchCard
} from "../BaseFundSearchCard";
import { FilterDropdownItem, OptionItem } from "../../../common/SearchCard/FilterDropdownItem";
import {renderDefaultQuery} from "../../utils";

interface FundSharpeRatioSearchCardProps extends BaseFundSearchProps {
    children?: ReactNode;
}

interface FundSharpeRatioSearchCardState extends GlobalFundSearchProps {
    keyfigureId: string;
    keyfigure: FundKeyFigure;
    valueId: string;
    from?: number;
    to?: number;
}

function FundSharpeRatioSearchCardView({state, setCriteria}: CriteriaState<FundSharpeRatioSearchCardState>) {
    const metadataContext = useContext(FundsSearchContext);
    const typeOptions = ((metadataContext && metadataContext.fundTypes) || []).map(current => {
        return { id: current?.id || "", name: current?.name || "" };
    });

    return (
        <>
            <FilterDropdownItem<OptionItem>
                onSelect={(value: OptionItem) => {
                    setCriteria({ ...state, typeId: value.id });
                }}
                options={typeOptions}
                activeId={"" + state.typeId}
            />
            <div className="text-nowrap mx-1" style={{ marginTop: "-2px" }}>mit einem Sharpratio von</div>
            <FilterDropdownItem<KeyFigureOptionItem>
                options={KEYFIGURE}
                onSelect={(value: KeyFigureOptionItem) => {
                    setCriteria({ ...state, keyfigure: value.keyfigure, keyfigureId: value.id });
                }}
                activeId={state.keyfigureId}
            />
            <FilterDropdownItem<OptionItem>
                options={SHARPE_RATIO_LEVEL}
                onSelect={(value: RangeOptionItem) => {
                    setCriteria({ ...state, valueId: value.id, from: value.from, to: value.to});
                }}
                activeId={state.valueId} />
        </>
    )
}

function renderQuery(state: FundSharpeRatioSearchCardState, metadata: FundsSearchContextProps | null): SearchFundCriterion {
    return {
        ...renderDefaultQuery(state, metadata),
        keyFigures: [{
            keyFigure: state.keyfigure,
            from: state.from,
            to: state.to
        }],
    }
}

interface KeyFigureOptionItem extends OptionItem {
    keyfigure: FundKeyFigure;
}

const KEYFIGURE: KeyFigureOptionItem[] = [
//    {id: "Week1", name: '1 Woche', keyfigure: FundKeyFigure.SharperatioWeek_1},
    {id: "Month1", name: '1 Monat', keyfigure: FundKeyFigure.SharperatioMonth_1},
    {id: "Month6", name: '6 Monate', keyfigure: FundKeyFigure.SharperatioMonth_6},
    {id: "Year1", name: '12 Monate', keyfigure: FundKeyFigure.SharperatioYear_1},
    {id: "Year3", name: '3 Jahre', keyfigure: FundKeyFigure.SharperatioYear_3},
    {id: "Year5", name: '5 Jahre', keyfigure: FundKeyFigure.SharperatioYear_5},
    {id: "Year10", name: '10 Jahre', keyfigure: FundKeyFigure.SharperatioYear_10},
];

interface RangeOptionItem extends OptionItem {
    from?: number;
    to?: number;
}

const SHARPE_RATIO_LEVEL: RangeOptionItem[] = [
    {id: "1", name: '> 1', from: 1.2},
    {id: "2", name: '= 1', from: 0.98, to: 1.2},
    {id: "3", name: '< 1', from: 0.2, to: 0.98},
    {id: "4", name: '= 0', from: -0.2, to: 0.2},
    {id: "5", name: '< 0', to: -0.2}
];

export const FundSharpeRatioSearchCard = wrapFundSearchCard<FundSharpeRatioSearchCardState>(
    generateFundCard<FundSharpeRatioSearchCardProps & EnabledProps, FundSharpeRatioSearchCardState>(
        FundSharpeRatioSearchCardView,
    ),
    renderQuery,
    {
        typeId: null, topicId: null, strategyId: null, regionId: null, currencyId: null,
        keyfigure: KEYFIGURE[2].keyfigure, keyfigureId: KEYFIGURE[2].id,
        valueId: SHARPE_RATIO_LEVEL[0].id, from: SHARPE_RATIO_LEVEL[0].from, to: SHARPE_RATIO_LEVEL[0].to
    },
    false,
    "Performance"
);

