import {
    CurrencyEntriesDropdownComponent,
    DEFAULT_CURRENCY_OPTION
} from "components/devisen/CurrencyPageDropdown/CurrencyEntriesDropdown";
import {useState} from "react";
import { CurrencyPerformanceChart } from './CurrencyPerformanceChart/CurrencyPerformanceChart';
import "./PerformanceChartSection.scss";
import {getInfonlineTag, guessInfonlineSection, trigInfonline} from "../../common/InfonlineService";
import {OptionItem} from "../../common/SearchCard/FilterDropdownItem";

interface PerformanceChartSectionProps {
    title: String;
}

interface PerformanceChartState {
    idCurrency: OptionItem;
} 

export function  PerformanceChartSection(props: PerformanceChartSectionProps) {
    const [state, setState] = useState<OptionItem>(DEFAULT_CURRENCY_OPTION);

    return (
        <section className="main-section" id="performance-chart-section">
            <div>
                <div className="mx-lg-3 flex-grow-1 m-auto">
                    <h2 className="section-heading-devisen font-weight-bold performance-comparison">{props.title}</h2>
                    <CurrencyEntriesDropdownComponent
                        description={state.name}
                        onSelect={(e) => {
                            trigInfonline(guessInfonlineSection(),getInfonlineTag(guessInfonlineSection(), "currency_perf_chart_drop") + '_' + e.id.toUpperCase());
                            setState(e)
                        }} 
                    />
                </div>
                <CurrencyPerformanceChart trim={true} hasLegendFlags={true} idCurrency={state.id}/>
            </div>     
        </section>
    );
}
