import {gql, makeVar, ReactiveVar, useQuery} from '@apollo/client';
import React, {useState, createContext, useContext, useRef, useEffect} from 'react'

/**
 * For internal usage by ApplicationContext and GraphQL client only. Use ApplicationContextType with useApplicationContext
 */
type ApplicationStateProps = {
    pushActive: boolean
}

const defaultState = {
    pushActive: true
}

/**
 * For internal usage by ApplicationContext and GraphQL client only
 */
export const applicationContextVar: ReactiveVar<ApplicationStateProps> = makeVar<ApplicationStateProps>(defaultState);
const GetApplicationContext = gql`
  query GetApplicationContext {
    applicationContext @client {
      pushActive
    }
  }
`

const useApplicationState = () => {
    const {data} = useQuery(GetApplicationContext)
    const state = data.applicationContext

    return {
        pushActive: state.pushActive,
        /**
         * Use togglePushActive unless you want to set a specific value for some reason
         */
        setPushActive: (pushActive: boolean) => {
            applicationContextVar({...state, pushActive})
        },
        togglePushActive: () => {
            applicationContextVar({...state, pushActive: !state.pushActive})
        }
    }
}
export type ApplicationContextType = ReturnType<typeof useApplicationState>

export const ApplicationContext = createContext<ApplicationContextType | null>(null)

export const useApplicationContext = (): ApplicationContextType => useContext(ApplicationContext)!

export const ApplicationStateProvider = ({children}: {children: React.ReactNode}) => (
    <ApplicationContext.Provider value={useApplicationState()}>{children}</ApplicationContext.Provider>
)
