import {useQuery} from "@apollo/client";
import classNames from "classnames";
import {getAssetGroup} from "components/profile/utils";
import {AssetGroup, AssetGroupBucket, Instrument, Query} from "generated/graphql";
import {gql} from "graphql.macro";
import {useRef, useState} from "react";
import {Spinner, Tab, Tabs} from "react-bootstrap";
import {useMediaQuery} from "react-responsive";
import {UnderlyingSearchResult} from "./UnderlyingSearchResult"

const SEARCH_QUERY = gql`
    query getInstruments($assetGroup: [AssetGroup!], $searchString: String!, $first: Int!, $after: ID, $underlying: Boolean) {
        search(criteria: { assetGroup: $assetGroup, searchString: $searchString, underlying: $underlying }, first: $first, after: $after) {
            edges {
                cursor
                node {
                    id
                    name
                    seoTag
                    wkn
                    isin
                    group {
                        id
                        assetGroup
                        name
                        seoTag
                        underlying
                    }
                    snapQuote {
                        lastChange
                        instrumentId
                        quote(type: TRADE) {
                            type
                            when
                            delay
                            value
                            percentChange
                            change
                        }
                    }
                    currency {
                        displayCode
                    }
                    exchange {
                        code
                    }
                }
            }
            pageInfo {
                endCursor
                hasNextPage
            }
        }
    }
`;

const GET_INSTRUMENTS_COUNT = gql`
query getInstrumentsCount($searchString: String!) {
    search(criteria: { searchString: $searchString, underlying: true }, first: 0) {
        assetGroups {
            assetGroup
            count
        }
    }
}
`


type SearchQueryVars = {
    assetGroup?: [AssetGroup] | null;
    searchString: string;
}
export interface SearchInstrumentsComponentProps {
    searchString: string;
    onAssetSelected: (asset: Instrument) => any;
}
export const SearchInstrumentsComponent = ({searchString, onAssetSelected}: SearchInstrumentsComponentProps) => {
    const [key, setKey] = useState<string>("");
    const searchVariables: SearchQueryVars = { searchString: searchString };
    const tabRef = useRef<HTMLDivElement>(null)
    const isDesktop  = useMediaQuery({
        query: "(min-width: 1280px)"
    })
    const { data, loading } = useQuery<Query>(GET_INSTRUMENTS_COUNT, { variables: { ...searchVariables } })
    if (loading) {
        return <div className="mt-5 text-center"><Spinner animation="border" /></div>
    }
    const totalCount = (!!data?.search.assetGroups && data.search.assetGroups.reduce((a, b) => a + b.count, 0)) || 0;
    if (totalCount === 0) {
        if (searchString === "")
            return <div></div>
        return <div className="text-center mt-5">Keine Wertpapiere gefunden</div>
    }

    const activeAssetGroups: AssetGroupBucket[] = data?.search.assetGroups.filter(current => current.count > 0) || [];
    const filteredAssetGroups = activeAssetGroups.filter(assetGroupBucket => ![AssetGroup.Knock, AssetGroup.Warr, AssetGroup.Cert].includes(assetGroupBucket.assetGroup))
    const activeTab = (!!key && key) || "Alle";
    return (
        <Tabs activeKey={activeTab} onSelect={(k) => {  k && setKey(k) }} variant="tabs" className="border-none mb-2">
            <Tab eventKey={"Alle"} title={<span>Alle</span>} tabClassName={classNames("default", "asset-type-tag", "mr-2", "line-height-18px fs-12px", "rounded-0 asset-tab-hover")}>
                {activeTab === "Alle" &&
                    <div className="overflow-auto" style={{height: isDesktop ? "350px" : "100%"}} ref={tabRef}>
                        <SearchResult containerRef={tabRef} search={{ ...searchVariables, assetGroup: null }} onSelect={(asset: Instrument) => {onAssetSelected(asset); setKey("")}} />
                    </div>
                }
            </Tab>
            {
                filteredAssetGroups.map(current =>
                    <Tab key={current.assetGroup} eventKey={current.assetGroup} title={<span>{getAssetGroup(current.assetGroup)} ({current.count})</span>} tabClassName={classNames(current.assetGroup, "asset-type-tag", "mr-2", "line-height-18px fs-12px rounded-0 asset-tab-hover")}>
                        {activeTab === current.assetGroup &&
                            <div className="overflow-auto" style={{height: isDesktop ? "350px" : "100%"}} ref={tabRef}>
                                <SearchResult containerRef={tabRef} search={{ ...searchVariables, assetGroup: [current.assetGroup] }} onSelect={(asset: Instrument) => {onAssetSelected(asset); setKey("")}} />
                            </div>
                        }
                    </Tab>
                )
            }
        </Tabs>
    );
}

interface SearchInstrumentsPerAssetProps {
    search: SearchQueryVars;
    onSelect: (asset: Instrument) => any;
    containerRef: React.RefObject<HTMLDivElement> // For the InfiniteScroll component
}
const SearchResult = ({ search, onSelect, containerRef }: SearchInstrumentsPerAssetProps) => {
    const first = 15 //items to load initially
    const result = useQuery<Query>(SEARCH_QUERY, {variables: {...search, first, underlying: true}})

    return <UnderlyingSearchResult containerRef={containerRef} onCustomAssetSelect={onSelect} result={result} />
}
