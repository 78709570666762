import {CalculationPeriod, EtfKeyFigures as EtfValue, Instrument} from "../../../generated/graphql";
import {formatKeyFigureValue} from "../../../utils";

export function EtfKeyFigures(props: {instrument: Instrument, keyFigures?: EtfValue}) {
    let quoteKeyFigure1Year = props.instrument.performance.find(current => current.period == CalculationPeriod.Week52);
    let quoteKeyFigure3Year = props.instrument.performance.find(current => current.period == CalculationPeriod.Year3);

    return (
        <div className="content-wrapper">
            <h2 className="content-wrapper-heading font-weight-bold">Risiko</h2>
            <div className="content">
                <table style={{width: "100%"}}>
                    <tr>
                        <td className="py-2">Volatilität (1J / 3J)</td>
                        <td className="text-right font-weight-bold">
                            {formatKeyFigureValue(quoteKeyFigure1Year?.vola, 2, 2, ' %')}
                        </td>
                        <td className="text-right font-weight-bold">
                            {formatKeyFigureValue(quoteKeyFigure3Year?.vola, 2, 2, ' %')}
                        </td>
                    </tr>
                    <tr>
                        <td className="py-2">Tracking Error</td>
                        <td colSpan={2} className="text-right font-weight-bold">
                            {formatKeyFigureValue(props.keyFigures?.trackingError, 2, 2, ' %')}
                        </td>
                    </tr>
                    <tr>
                        <td className="py-2">Alpha (1J / 3J)</td>
                        <td className="text-right font-weight-bold">
                            {formatKeyFigureValue(quoteKeyFigure1Year?.alpha, 2, 2, ' %')}
                        </td>
                        <td className="text-right font-weight-bold">
                            {formatKeyFigureValue(quoteKeyFigure3Year?.alpha, 2, 2, ' %')}
                        </td>
                    </tr>
                    <tr>
                        <td className="py-2">Beta (1J / 3J)</td>
                        <td className="text-right font-weight-bold">
                            {formatKeyFigureValue(quoteKeyFigure1Year?.beta, 2, 2, ' %')}
                        </td>
                        <td className="text-right font-weight-bold">
                            {formatKeyFigureValue(quoteKeyFigure3Year?.beta, 2, 2, ' %')}
                        </td>
                    </tr>
                    <tr>
                        <td className="py-2">Beta Bear (1J / 3J)</td>
                        <td className="text-right font-weight-bold">
                            {formatKeyFigureValue(quoteKeyFigure1Year?.betaBear, 2, 2, ' %')}
                        </td>
                        <td colSpan={2} className="text-right font-weight-bold">
                            {formatKeyFigureValue(quoteKeyFigure3Year?.betaBear, 2, 2, ' %')}
                        </td>
                    </tr>
                    <tr>
                        <td className="py-2">Beta Bull (1J / 3J)</td>
                        <td className="text-right font-weight-bold">
                            {formatKeyFigureValue(quoteKeyFigure1Year?.betaBull, 2, 2, ' %')}
                        </td>
                        <td colSpan={2} className="text-right font-weight-bold">
                            {formatKeyFigureValue(quoteKeyFigure3Year?.betaBull, 2, 2, ' %')}
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    )
}
