import { PageHeaderFilterComponent } from "components/layout/PageHeaderFilterComponent/PageHeaderFilterComponent";
import { ListContentComponent } from "components/layout/PageHeaderFilterComponent/ListSelectorContentComponent";
import React from "react";
import "./CurrencyEntriesDropdown.scss";
import {OptionItem} from "../../common/SearchCard/FilterDropdownItem";

interface InstituteSelectorComponentProps {
    name?: string;
    onSelect?: (ev: OptionItem) => void;
    withAllButton?: boolean;
    description?: string;
    toggleVariant?: string;
    render?: (opt: OptionItem) => React.ReactNode;
}

export function CurrencyEntriesDropdownComponent({withAllButton, description, onSelect, toggleVariant, ...props}: InstituteSelectorComponentProps) {
    let options = [
        ...(withAllButton ? [{id: ALL_CURRENCIES, name: "Alle"}] : []),
        ...CURRENCY_OPTIONS
    ];

    const propagateSelection = (ev: OptionItem) => onSelect && onSelect(ev);
    return (
        <PageHeaderFilterComponent
            title={""}
            description={(<span className="">{description}</span>)}
            toggleVariant={toggleVariant || "devisen-dropdown"}
            toggleIcon={process.env.PUBLIC_URL + "/static/img/svg/icon_direction_down_blue.svg"}
        >
            <ListContentComponent<CrossRateOptionItem> options={options} onSelect={propagateSelection} render={props.render} />
        </PageHeaderFilterComponent>
    );
}

function render(opt: OptionItem) {
    return opt.name;
}
export const ALL_CURRENCIES: string = "all"

export interface CrossRateOptionItem extends OptionItem {
    name: string;
}
export const DEFAULT_CURRENCY_OPTION: OptionItem = {
    id: "EUR",
    name: "EUR (Euro)"
};

export const CURRENCY_OPTIONS: OptionItem[] = [
    DEFAULT_CURRENCY_OPTION,
    {
        id: "USD",
        name: "USD (US Dollar)"

    },
    {
        id: "JPY",
        name: "JPY (Japanische Yen)"
    },
    {
        id: "GBP",
        name: "GBP (Pfund Sterling)"
    },
    {
        id: "CHF",
        name: "CHF (Schweizer Franken)"
    },
    {
        id: "HKD",
        name: "HKD (Hong Kong Dollar)"
    },
    // {
    //     id: "RUB",
    //     name: "RUB (Russischer Rubel) zu...",
    //     currencyId: 2485104,
    // },
    {
        id: "CNY",
        name: "CNY (Yuan Renminbi)"
    },
    {
        id: "AUD",
        name: "AUD (Australischer Dollar)"

    },
    {
        id: "CAD",
        name: "CAD (Kanadischer Dollar)"

    },
    {
        id: "NZD",
        name: "NZD (Neuseeland-Dollar)"

    },
    {
        id: "DKK",
        name: "DKK (Dänische Krone)"
    },
    // {
    //     id: "ZAR",
    //     name: "ZAR (Südafrikanischer Rand) zu...",
    //     currencyId: 2485104,
    // },
    {
        id: "HUF",
        name: "HUF (Ungarische Forint)"
    },
    {
        id: "TWD",
        name: "TWD (Neuer Taiwan-Dollar)"
    },
    {
        id: "SGD",
        name: "SGD (Singapur Dollar)"

    },
    {
        id: "SEK",
        name: "SEK (Schwedische Krone)"

    },
    {
        id: "NOK",
        name: "NOK (Norwegische Krone)"

    },
    {
        id: "KWD",
        name: "KWD (Kuwaitischer Dinar)"
    },
]
