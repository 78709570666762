import {ReactNode} from "react";
import {
    BaseShareSearchProps,
    CriteriaState, EnabledProps, generateShareSearchCard,
    GlobalShareSearchProps,
    ShareSearchCardText, wrapShareSearchCard
} from "../../BaseShareSearchCard";
import {ShareRegionsDropdown} from "../../ShareRegionsDropdown";
import {FilterDropdownItem, OptionItem} from "../../../../common/SearchCard/FilterDropdownItem";
import {Period, SearchShareCriterion, ShareSearchKeyFigure, ShareSortField} from "../../../../../generated/graphql";
import {CardResults} from "../../utils";
import {marketCapCardTableHeader} from "../../tables/shareTableHeaders";
import { SortDirection } from "components/devisen/CurrencyFindSection/filters/GenericSortFilter";
import { generatePeriodKeyFigureColumn, generateTableRender } from "../../ShareTableResult";
import { PercentageChange } from "../common/CommonTableElements";

interface PerformanceStatusCardProps extends BaseShareSearchProps {
    children?: ReactNode
}

interface PerformanceStatusCardState extends GlobalShareSearchProps {
    selectedDirection: string
    selectedPeriod: string
}

function PerformanceStatusCardContentView(props: CriteriaState<PerformanceStatusCardState>) {
    return (
        <>
            <ShareRegionsDropdown state={props.state} setCriteria={props.setCriteria}/>
            <ShareSearchCardText text={"Aktien mit der"}/>
            <FilterDropdownItem<SortDirectionOptionItem>
                onSelect={(option: SortDirectionOptionItem) => {
                    props.setSorting({field: ShareSortField.Performance, direction: option.direction})
                }}
                options={DIRECTION_OPTIONS}
                activeId={props.sort?.direction ?? 'asc'}
            />
            <ShareSearchCardText text={"Performance in den letzten"}/>
            <FilterDropdownItem<OptionItem & { value: Period }>
                onSelect={(option: OptionItem & { value: Period }) => {
                    props.setCriteria({...props.state, period: option.value, selectedPeriod: option.id});
                }}
                options={PERIOD_OPTIONS}
                activeId={props.state.selectedPeriod}
            />

        </>
    )
}

interface SortDirectionOptionItem extends OptionItem {
    direction: SortDirection;   
}

const DIRECTION_OPTIONS: SortDirectionOptionItem[] = [
    {id: 'asc', name: 'höchsten', direction: 'asc'},
    {id: 'desc', name: 'niedriegsten', direction: 'desc'},
]

const PERIOD_OPTIONS: (OptionItem & { value: Period })[] = [
    {id: "1", name: "30 Tage", value: Period.Last_1Month},
    // {id: "2", name: "3 Monaten", value: Period.Last_1Year},
    {id: "3", name: "6 Monaten", value: Period.Last_6Month},
    {id: "4", name: "12 Monaten", value: Period.Last_1Year},
    {id: "5", name: "3 Jahren", value: Period.Last_3Years},
    {id: "6", name: "5 Jahren", value: Period.Last_5Years},
    {id: "7", name: "10 Jahren", value: Period.Last_10Years},
];

function renderQuery(state: PerformanceStatusCardState): SearchShareCriterion {
    return {
        period: state.period,
        ranges: [{keyFigure: ShareSearchKeyFigure.Performance}],
        regionId: state.regionId,
        trends: state.trends,
    }
}

export const PerformanceStatusCard = wrapShareSearchCard(
    generateShareSearchCard<PerformanceStatusCardProps & EnabledProps, PerformanceStatusCardState>(
        PerformanceStatusCardContentView
    ),
    renderQuery,
    {
        marketCapitalization: null,
        regionId: null,
        regionName: null,
        ranges: [], trends: [],
        selectedPeriod: PERIOD_OPTIONS[2].id,
        period: PERIOD_OPTIONS[2].value,
        selectedDirection: DIRECTION_OPTIONS[0].id,
        sort: {field: ShareSortField.Performance, direction: 'asc'}
    },
    false,
    marketCapCardTableHeader,
    CardResults.marketCap,
    ShareSortField.MarketCapitalization,
    ["Performance"],
    generateTableRender([
        generatePeriodKeyFigureColumn(
            'Performance 1M', 
            ShareSearchKeyFigure.Performance, Period.Last_1Month, ShareSortField.Performance, 
            (result) => (<PercentageChange percentChange={result.performance1Month}/>)
        ),  
        generatePeriodKeyFigureColumn(
            'Performance 6M', 
            ShareSearchKeyFigure.Performance, Period.Last_6Month, ShareSortField.Performance, 
            (result) => (<PercentageChange percentChange={result.performance6Month}/>)
        ),
        generatePeriodKeyFigureColumn(
            'Performance 12M', 
            ShareSearchKeyFigure.Performance, Period.Last_1Year, ShareSortField.Performance, 
            (result) => (<PercentageChange percentChange={result.performance1Year}/>)
        ),
        generatePeriodKeyFigureColumn(
            'Performance 3J', 
            ShareSearchKeyFigure.Performance, Period.Last_3Years, ShareSortField.Performance, 
            (result) => (<PercentageChange percentChange={result.performance3Year}/>)
        ),
        generatePeriodKeyFigureColumn(
            'Performance 5J', 
            ShareSearchKeyFigure.Performance, Period.Last_5Years, ShareSortField.Performance, 
            (result) => (<PercentageChange percentChange={result.performance5Year}/>)
        ),
        generatePeriodKeyFigureColumn(
            'Performance 10J', 
            ShareSearchKeyFigure.Performance, Period.Last_10Years, ShareSortField.Performance, 
            (result) => (<PercentageChange percentChange={result.performance10Year}/>)
        )
    ]) 

);

export default PerformanceStatusCard;
