import {useMediaQuery} from "react-responsive"

export const useMobileMediaQuery = () => {
    return  useMediaQuery({
        query: "(max-width: 698px)"
    })
}
export const useTabletMediaQuery = () => {
    return  useMediaQuery({
        query: "(min-width: 699px) and (max-width: 1099px)"
    })
}
export const useDesktopMediaQuery = () => {
    return  useMediaQuery({
        query: "(min-width: 1100px)"
    })
}

export enum ScreenMode {PHONE = 1, TABLET = 3 , DESKTOP = 5};

export function useScreenMode(): ScreenMode {
    const isDesktop = useDesktopMediaQuery();
    const isTablet = useTabletMediaQuery();
    if (isDesktop) {
        return ScreenMode.DESKTOP;
    }
    if (isTablet) {
        return ScreenMode.TABLET;
    }
    return ScreenMode.PHONE;
}

