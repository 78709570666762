import {useQuery} from "@apollo/client";
import {Instrument, InstrumentTopFlop, Issuer, IssuerBestInstrument, Query, Quote, QuoteType} from "../../../generated/graphql";
import {loader} from "graphql.macro";
import {Button, Card, Col, Container, ProgressBar, Row, Spinner} from "react-bootstrap";
import {calculatePercentChange, formatPrice, getFinanztreffAssetLink, numberFormat, numberFormatWithSign, quoteFormat, getTradeQuote, QuoteValueType, className as classNameFn} from "utils";
import classNames from "classnames";
import { AssetLinkComponent } from "components/profile/common/AssetLinkComponent";
import {NavLink} from "react-router-dom";
import {Maybe} from "graphql/jsutils/Maybe";
import {SnapQuoteValue} from "components/common/misc/SnapQuoteValue";
import CircularProgressbar from "components/common/indicators/Progress";
import {useMemo, useRef, useState} from "react";
import SvgImage from "components/common/image/SvgImage";
import {PartnerLogo} from "components/Assets/Derivatives/components/PartnerLogo";
import {ProfileInstrumentAddPopup} from "components/common/modals/ProfileInstrumentAddPopup";
import moment, {Moment} from "moment";
import styles from "./TopFlopGroup.module.scss";
import {useDesktopMediaQuery, useMobileMediaQuery, useTabletMediaQuery} from "hooks/useMediaQuery";
import {GET_BEST_WARRANT} from "graphql/query";
import {CopyWKN} from "components/profile/common/CopyWKNComponent";

const InstrumentSnapQuoteRow = ({instrument, className}: {instrument: Instrument, className?: string}) => {
    const isDesktop = useDesktopMediaQuery()
    const {data} = useQuery<Query>(
        loader("src/components/common/card/InstrumentSnapQuoteCard/getInstrumentSnapQuoteCardInfo.graphql"), {
            variables: { instrumentId: instrument.id },
            fetchPolicy: "no-cache"
        }
    );
    const instrumentShortNameMap: {[key: number]: string} = {
        12557: "Citi DAX",
        12692: "Citi TecDAX",
        12685: "Citi MDAX",
        23404: "Citi EStox 50",
        22636: "Citi Dow 30",
        22584: "Citi Nasdaq 100",
        23403: "EStox 50",
        43553: "Snale All"
    }
    const quoteDelay = getTradeQuote(data?.instrument, data?.instrument?.snapQuote)?.delay
    return (
        <div className={classNames("d-flex gap-2 line-height-20px fnt-size-15", className)}>
            <AssetLinkComponent instrument={data?.instrument} title={(!isDesktop && instrument.id in instrumentShortNameMap) ? instrumentShortNameMap[instrument.id] : undefined}
                className={classNameFn("snap-quote-row__link mr-0 mr-md-2px text-nowrap font-weight-bold", styles)} />
            <span className={classNameFn("snap-quote-row__delay ml-0 ml-md-5px ml-xl-1 mr-n1px", styles)}>
                {quoteDelay === 1 ?
                    <span className="font-size-11px text-white px-1 px-xl-2 bg-orange" style={{ width: "11px" }}>RT</span>
                    :
                    <span className="font-size-11px text-white px-1 px-xl-2 bg-gray-dark" style={{ width: "11px" }}>15</span>
                }
            </span>
            <SnapQuoteValue quoteValueType={QuoteValueType.VALUE} instrument={data?.instrument} snapQuote={data?.instrument?.snapQuote} blink={true}
                className={classNameFn("snap-quote-row__trade-value ml-n2px mr-n2px", styles)} currencyCodeClassName="fnt-size-11" />
            <SnapQuoteValue quoteValueType={QuoteValueType.PERCENT_CHANGE} instrument={data?.instrument} snapQuote={data?.instrument?.snapQuote} blink={true}
                className={classNameFn("snap-quote-row__pct-change-value text-right font-weight-bold", styles)} />
            <span className={classNameFn("snap-quote-row__time-value text-right fnt-size-11", styles)}>
                <SnapQuoteValue quoteValueType={QuoteValueType.WHEN} instrument={data?.instrument} snapQuote={data?.instrument?.snapQuote} />
            </span>
        </div>
    )
}

export type TopFlopTableProps = {
    topFlops: InstrumentTopFlop[]
    loading: boolean
    isHomePage?: boolean
}

export function TopFlopTable(props: TopFlopTableProps) {
    const isMobile = useMobileMediaQuery()
    let positiveMax = Math.max(...(props.topFlops || []).map(current => current?.snapQuote?.quote?.percentChange || -1).filter(current => current >= 0));
    let negativeMax = Math.min(...(props.topFlops || []).map(current => current?.snapQuote?.quote?.percentChange || 1).filter(current => current < 0));
    const middle = Math.ceil(props.topFlops.length / 2);
    return (
        <>{props.loading ? (
            <Spinner animation="border"/>)
            : (
                <>
                <table className={classNameFn("table-top-flop", styles)}>
                    <col/> <col/> <col/> {!isMobile && (<> <col/> <col/> <col/> </>)}
                    <tbody>
                        {props.topFlops.slice()
                            .sort((a, b) =>
                                  (b?.snapQuote?.quote?.percentChange || 0) - (a?.snapQuote?.quote?.percentChange || 0))
                                  .map((current: InstrumentTopFlop | null, index: number) => (
                                      <TopFlopRow index={index} current={current} middle={middle} positiveMax={positiveMax} negativeMax={negativeMax} isHomePage={props.isHomePage}  />
                                  ))
                        }
                    </tbody>
                </table>
                </>
            )
        }
        </>
    );
}

type TopFlopRowProps = {
    index: number,
    current: InstrumentTopFlop | null,
    middle: number
    isHomePage?: boolean,
    positiveMax: number,
    negativeMax : number
}
const TopFlopRow = (props: TopFlopRowProps) => {
    const isMobile = useMobileMediaQuery()
    return (
        <>
            <tr key={2 * props.index + 1} className={classNameFn(classNames({'delimiter': props.index === props.middle}), styles)}>
                <td className={classNameFn("font-weight-bold text-blue pl-0 py-1 py-md-2 text-truncate", styles)}>
                    <AssetLinkComponent instrument={props.current?.instrument}/>
                </td>
                <td className={classNameFn("text-right py-1 py-md-2", styles)}>
                    {formatPrice(props.current?.snapQuote.quote?.value, props.current?.instrument?.group?.assetGroup, props.current?.snapQuote.quote?.value, props.current?.instrument?.currency?.displayCode || "")}
                </td>
                <td className={classNames( "font-weight-bold py-1 py-md-2 pr-1 text-right",
                                          props.current?.snapQuote?.quote?.percentChange && props.current?.snapQuote?.quote?.percentChange >= 0 ? "text-color-green" : "text-color-pink"
                                         )}>
                    {numberFormatWithSign(props.current?.snapQuote?.quote?.percentChange, "%")}
                </td>
                {!isMobile && (
                    <>
                        <td className={classNameFn("py-1 py-md-2 pl-1 pt-3 text-left", styles)}>
                            {props.current?.snapQuote?.quote?.percentChange !== null && props.current?.snapQuote?.quote?.percentChange !== undefined &&
                            <ProgressBar variant={props.current?.snapQuote?.quote?.percentChange >= 0 ? "success" : "danger"} className="bg-transparent rounded-0" style={{height: "8px"}}
                                now={props.current?.snapQuote?.quote?.percentChange * 100 / (props.current?.snapQuote?.quote?.percentChange >= 0 ? props.positiveMax : props.negativeMax)} />
                            }
                        </td>
                        <td className={classNameFn("py-1 py-md-2 text-nowrap", styles)}>
                            {props.current?.instrument?.group?.sector?.name}
                        </td>
                        <td className="py-1 py-md-2 text-right">
                            {quoteFormat(props.current?.snapQuote?.quote?.when)}
                        </td>
                    </>
                )}
            </tr>
            {isMobile && (
                <tr key={2 * props.index}>
                    <td colSpan={3} className="px-0 pt-0">
                        {props.current?.snapQuote?.quote?.percentChange !== null && props.current?.snapQuote?.quote?.percentChange !== undefined &&
                        <ProgressBar className="bg-transparent rounded-0" variant={props.current?.snapQuote?.quote?.percentChange >= 0 ? "success" : "danger"}
                            now={props.current?.snapQuote?.quote?.percentChange * 100 / (props.current?.snapQuote?.quote?.percentChange >= 0 ? props.positiveMax : props.negativeMax)}
                            style={{height: "8px"}}
                        />
                        }
                    </td>
                </tr>
            )}
        </>
    )
}

interface RowItemProps extends React.HTMLAttributes<HTMLElement> {
    xs?: number;
    sm?: number;
    md?: number;
    xl?: number;
    name: string;
    value: React.ReactNode;
}
const RowItem = (props: RowItemProps) => {
    const isDesktop = useDesktopMediaQuery()

    console.log("FR", isDesktop)
    return (
        <Col {...props}>
            {isDesktop ? (
                <div className="fnt-size-14">
                    <div className="text-truncate">{props.name}</div>
                    <div className="font-weight-bold">{props.value}</div>
                </div>
            ) : (
                <div className="fnt-size-14 font-weight-bold">
                    {props.name}: {props.value}
                </div>
            )}
        </Col>
    );
}
enum ProductType {
    TOP = "Top",
    FLOP = "Flop"
}
type TopFlopProductCardProps = {
    type: ProductType
    wkn: string
    issuer: Maybe<Issuer>
    strike: number
    gearing: number
    dueTo: number
    bidQuote: Maybe<Quote>
    askQuote: Maybe<Quote>
    pieChartValue: number
    instrument: Maybe<Instrument>
    pieChartColor?: string
    className?: string
}
const TopFlopProductCard = ({type, wkn, issuer, strike, gearing, dueTo, bidQuote, askQuote, pieChartValue, instrument, pieChartColor, className}: TopFlopProductCardProps) => {
    const isDesktop = useDesktopMediaQuery()
    const productIsin = instrument?.isin || ""
    const nameWkn = `${issuer?.name} - ${wkn}`

    return (
        <Card className={classNameFn(classNames("top-flop-product-card", className), styles)}>
            <Card.Title className={classNames("mb-0 pl-2 fnt-size-15 line-height-24px text-center text-white", {'bg-green': type === ProductType.TOP, 'bg-red': type === ProductType.FLOP })}>
                <span className="font-weight-bold">OS {type === ProductType.TOP ? "Call" : "Put"}</span> auf {type} Wert
            </Card.Title>
            <Card.Body className="px-2 px-xl-20px pt-2 pb-2">
                <div className="d-flex justify-content-between mx-0 mx-xl-n2 px-md-0 px-xl-2px">
                    {isDesktop ? (
                        <span className="fnt-size-17 line-height-24px font-weight-bold">
                            WKN {wkn} <CopyWKN wkn={wkn} className="mt-n1 p-0" fixImagePadding={true} width={14} />
                        </span>
                    ) : (
                        <span className="fnt-size-14 line-height-30px">
                            <span className="font-weight-bold">
                                WKN:
                            </span>
                            <span className="ml-1">{wkn}</span>
                            <CopyWKN wkn={wkn} className="ml-1 mt-n1 p-0" fixImagePadding={true} width={14} />
                        </span>
                    )}
                    {issuer && <PartnerLogo className="" issuer={issuer} />}
                </div>
                <Row className={classNames("mt-0 mt-xl-2 mb-0 mb-xl-1 fnt-size-14", {'line-height-20px': !isDesktop, 'line-height-18px': isDesktop})}>
                    <RowItem xs={4} md={4} xl={3} name="Hebel" value={numberFormat(gearing)} className="pr-0" />
                    <RowItem xs={3} md={3} xl={3} name="Strike" value={formatPrice(strike)} className="ml-n10px ml-xl-0 mr-10px mr-xl-0 pl-0 pl-xl-3 pr-0" />
                    <RowItem xs={5} md={5} xl={4} name="Restlaufzeit" value={`${dueTo} ${dueTo <= 1 ? "Tag" : "Tage"}`} className="ml-n5px pl-0 pl-xl-3 pr-0" />
                    {isDesktop && (
                        <Col xl={2} className="pl-5px">
                            <CircularProgressbar value={pieChartValue}
                                counterClockwise={true}
                                styles={{
                                    path: {
                                        stroke: pieChartColor || "#000000",
                                },
                                trail: {
                                    stroke: '#f1f1f1',
                                },
                                root: {
                                    height: "32px"
                                }
                                }} />
                        </Col>
                    )}
                </Row>
                {isDesktop && (
                    <div className="d-flex justify-content-between mt-0 mt-xl-2px mb-0 mb-xl-10px px-10px pt-0 pt-xl-2 border-top-1 border-gray-light text-center">
                        <div>
                            <div className="fnt-size-14 line-height-20px font-weight-bold">Bid: <span>{formatPrice(bidQuote?.value, instrument?.group?.assetGroup)}</span></div>
                            <div className="d-flex justify-content-between fnt-size-12 line-height-16px">
                                {/*<span>{quoteFormat(bidQuote?.when)}</span>*/}
                                <span>{numberFormat(bidQuote?.size, " Stück")}</span>
                            </div>
                        </div>
                        <div>
                            <span className="fnt-size-14 line-height-20px font-weight-bold">Ask: <span>{formatPrice(askQuote?.value, instrument?.group?.assetGroup)}</span></span>
                            <div className="d-flex justify-content-between fnt-size-12 line-height-16px">
                                {/*<span>{quoteFormat(askQuote?.when)}</span>*/}
                                <span className="font-size-12px">{numberFormat(askQuote?.size, " Stück")}</span>
                            </div>
                        </div>
                    </div>
                )}
            </Card.Body>
            {isDesktop && (
                <Card.Footer className="px-20px px-xl-2 py-0 border-none bg-white">
                    <section className="d-flex justify-content-between mx-0 mx-xl-1 mb-0 mb-xl-1 pt-0 pt-xl-2 pb-0 pb-xl-2 border-top-1 border-gray-light">
                        {instrument?.id && instrument?.group?.id && (
                            <ProfileInstrumentAddPopup
                                instrumentId={instrument.id}
                                instrumentGroupId={instrument.group.id}
                                name={instrument.group.name}
                                className="p-0 border-none"
                                watchlist={true} portfolio={true}
                                direction="up"
                                productIsin={productIsin}
                                nameWkn={nameWkn}>
                                <SvgImage icon="icon_plus_blue.svg" fixImagePadding={true} convert={true} width="24" />
                            </ProfileInstrumentAddPopup>
                        )}
                        <AssetLinkComponent instrument={instrument} size={30} title="zum Portrait" productIsin={productIsin} nameWkn={nameWkn} className="fnt-size-14 line-height-24px" />
                    </section>
                </Card.Footer>
            )}
        </Card>
    )
}
type TopFlopCardsProps = {
    loading: boolean
    topInstrumentBestWarrant: Maybe<IssuerBestInstrument>
    topInstrumentDueTo: number
    topInstrumentPieChartValue: number
    flopInstrumentBestWarrant: Maybe<IssuerBestInstrument>
    flopInstrumentDueTo: number
    flopInstrumentPieChartValue: number
}
const TopFlopWarrantCards = ({loading, topInstrumentBestWarrant, topInstrumentDueTo, topInstrumentPieChartValue, flopInstrumentBestWarrant, flopInstrumentDueTo, flopInstrumentPieChartValue}: TopFlopCardsProps) => {
    const isTablet = useTabletMediaQuery()
    return (
        <>
            {loading ? (
                <Spinner animation="border"/>
            )
            : (
                <section className={classNames(classNameFn("cards d-flex gap-3", styles), {'flex-column': !isTablet, 'flex-row': isTablet})}>
                    <div>
                        {topInstrumentBestWarrant?.instrument !== undefined &&
                            (<TopFlopProductCard 
                                type={ProductType.TOP}
                                wkn={topInstrumentBestWarrant.instrument!.wkn || ""}
                                issuer={topInstrumentBestWarrant.instrument!.group.issuer}
                                strike={topInstrumentBestWarrant.instrument!.group.underlyings?.[0].strike || 0}
                                gearing={topInstrumentBestWarrant.instrument!.keyFigures?.gearing || 0}
                                dueTo={topInstrumentDueTo}
                                bidQuote={topInstrumentBestWarrant.instrument!.snapQuote?.quotes.find(quote => quote?.type === QuoteType.Bid)}
                                askQuote={topInstrumentBestWarrant.instrument!.snapQuote?.quotes.find(quote => quote?.type === QuoteType.Ask)}
                                pieChartValue={topInstrumentPieChartValue}
                                instrument={topInstrumentBestWarrant.instrument}
                                pieChartColor="#ff4d7d"
                            />)
                        }
                    </div>
                    <div>
                        {flopInstrumentBestWarrant?.instrument !== undefined &&
                            (<TopFlopProductCard 
                                type={ProductType.FLOP}
                                wkn={flopInstrumentBestWarrant.instrument!.wkn || ""}
                                issuer={flopInstrumentBestWarrant.instrument!.group.issuer}
                                strike={flopInstrumentBestWarrant.instrument!.group.underlyings?.[0].strike || 0}
                                gearing={flopInstrumentBestWarrant.instrument!.keyFigures?.gearing || 0}
                                dueTo={flopInstrumentDueTo}
                                bidQuote={flopInstrumentBestWarrant.instrument!.snapQuote?.quotes.find(quote => quote?.type === QuoteType.Bid)}
                                askQuote={flopInstrumentBestWarrant.instrument!.snapQuote?.quotes.find(quote => quote?.type === QuoteType.Ask)}
                                pieChartValue={flopInstrumentPieChartValue}
                                instrument={flopInstrumentBestWarrant.instrument}
                                pieChartColor="#ff4d7d"
                            />)
                        }
                    </div>
                </section>
            )}
        </>
    )
}

export const TopFlopCard = ({activeInstrument, isHomePage = false}: {activeInstrument: Instrument, isHomePage?: boolean}) => {
    const currentTime = useRef<Moment>(moment())
    const {data, loading} = useQuery<Query>(loader("./getTopFlopContent.graphql"), {
        variables: {
            groupId: activeInstrument.group.id || 0,
            historyFrom: currentTime.current.subtract(6, "months"),
            historyTo: currentTime.current
        }
    });
    const group = data?.group
    const mainInstrument = group?.main;

    const topInstrument = group?.topFlop?.[0]
    const topInstrumentExpectedValue = topInstrument?.instrument?.monteCarlo?.max
    const topInstrumentHistoryQuoteEdges = topInstrument?.instrument?.historyQuote?.edges || []
    const topInstrumentStartValue = topInstrumentHistoryQuoteEdges.length > 0 ? topInstrumentHistoryQuoteEdges[topInstrumentHistoryQuoteEdges.length - 1].node?.lastPrice ?? 1 : 1
    const { data: topInstrumentData, loading: topInstrumentLoading, error: topInstrumentError } = useQuery<Query>(
        GET_BEST_WARRANT,
        {
            variables: {
                underlyingIsin: topInstrument?.instrument?.isin, pctChange: calculatePercentChange(topInstrumentStartValue, topInstrumentExpectedValue || 0), // will never be undefined
                months: 6
            }, skip: !topInstrument || topInstrumentExpectedValue === undefined
        }
    );

    const topInstrumentBestWarrant = topInstrumentData?.bestWarrantRnd
    const topInstrumentMaturityDate = moment(topInstrumentBestWarrant?.instrument?.group?.derivative?.maturityDate);
    const topInstrumentDueTo = topInstrumentMaturityDate.diff(moment(), 'd') + 1;
    const topInstrumentStartDate = topInstrumentBestWarrant?.instrument?.group?.derivative?.issueDate;
    const topInstrumentStart = Math.abs(moment(topInstrumentStartDate).diff(moment(), 'd') + 1);
    const topInstrumentPieChartValue = topInstrumentDueTo / (topInstrumentStart + topInstrumentDueTo) * 100;

    const flopInstrument = group?.topFlop?.[group.topFlop?.length - 1]
    const flopInstrumentExpectedValue = flopInstrument?.instrument?.monteCarlo?.max
    const flopInstrumentHistoryQuoteEdges = flopInstrument?.instrument?.historyQuote?.edges || []
    const flopInstrumentStartValue = flopInstrumentHistoryQuoteEdges.length > 0 ? flopInstrumentHistoryQuoteEdges[flopInstrumentHistoryQuoteEdges.length - 1].node?.lastPrice ?? 1 : 1
    const { data: flopInstrumentData, loading: flopInstrumentLoading, error: flopInstrumentError } = useQuery<Query>(
        GET_BEST_WARRANT,
        {
            variables: {
                underlyingIsin: flopInstrument?.instrument?.isin, pctChange: calculatePercentChange(flopInstrumentStartValue, flopInstrumentExpectedValue || 0), // will never be undefined
                months: 6
            }, skip: !flopInstrument || flopInstrumentExpectedValue === undefined
        }
    );
    const flopInstrumentBestWarrant = flopInstrumentData?.bestWarrantRnd

    const flopInstrumentMaturityDate = moment(flopInstrumentBestWarrant?.instrument?.group?.derivative?.maturityDate);
    const flopInstrumentDueTo = flopInstrumentMaturityDate.diff(moment(), 'd') + 1;
    const flopInstrumentStartDate = flopInstrumentBestWarrant?.instrument?.group?.derivative?.issueDate;
    const flopInstrumentStart = Math.abs(moment(flopInstrumentStartDate).diff(moment(), 'd') + 1);
    const flopInstrumentPieChartValue = flopInstrumentDueTo / (flopInstrumentStart + flopInstrumentDueTo) * 100;

    return (
        <Card className={classNameFn("top-flop-card content-wrapper mt-2 px-2 px-xl-3", styles)}>
            <TopFlopTable loading={loading} topFlops={group?.topFlop || []} isHomePage={isHomePage}/>
            <TopFlopWarrantCards
                loading={topInstrumentLoading || flopInstrumentLoading}
                topInstrumentBestWarrant={topInstrumentBestWarrant} topInstrumentDueTo={topInstrumentDueTo} topInstrumentPieChartValue={topInstrumentPieChartValue}
                flopInstrumentBestWarrant={flopInstrumentBestWarrant} flopInstrumentDueTo={flopInstrumentDueTo} flopInstrumentPieChartValue={flopInstrumentPieChartValue} />
            <Container className={classNameFn("quotes", styles)}>
                <Row>
                    {mainInstrument && (
                        <Col className="px-0">
                            <InstrumentSnapQuoteRow instrument={mainInstrument} className={classNameFn("snap-quote-row", styles)} />
                        </Col>
                    )}
                    <Col className="pl-0 pl-xl-2 pr-0">
                        {(!mainInstrument || activeInstrument.id !== mainInstrument?.id) && (<><InstrumentSnapQuoteRow instrument={activeInstrument} className={classNameFn("snap-quote-row", styles)} /></>)}
                    </Col>
                </Row>
            </Container>
            <div className={classNameFn("individual-link d-flex justify-content-end", styles)}>
                {activeInstrument.group.assetGroup && activeInstrument.group.seoTag &&
                <NavLink
                    to={getFinanztreffAssetLink(activeInstrument.group.assetGroup, activeInstrument.group?.seoTag)}
                >
                    <Button variant="primary">{activeInstrument.group.name} Einzelwerte</Button>
                </NavLink>
                }
                {/* { props.showOtherTopsAndFlops &&
                    <button className="btn btn-primary">Weitere Tops &amp; Flops...</button>
                    } */}
            </div>
        </Card>
    )
}

