import {useQuery} from "@apollo/client";
import {loader} from "graphql.macro";
import {useDelayedState} from "hooks/useDelayedState";
import { useMediaQuery } from "react-responsive";
import moment from 'moment';
import React, {lazy, memo, Suspense, SyntheticEvent, useEffect, useRef, useState} from "react";
import {Button, Dropdown, FormControl, Spinner} from "react-bootstrap";
import {useLocation} from "react-router-dom";
import {Chart, ChartScope, HistoryQuote, HistoryQuoteAggregation, HistoryQuoteConnection, HistoryQuoteEdge, Instrument} from "../../../../graphql/types";
import {useBootstrapBreakpoint} from "../../../../hooks/useBootstrapBreakpoint";
import {CHART_TOOLS_RANGESELECTOR_BUTTONS, rangeSelectorBtn, shortNumberFormat} from "../../../../utils";
import '../ChartComponent.css'
import {guessInfonlineSection, trigInfonline} from "../../InfonlineService";
import {ChartAnnotationComponent} from "./ChartAnnotationComponent";
import ChartToolSearchModal from "./ChartToolSearchModal";
import {CompareSeriesLoader} from "./CompareSeriesLoader";
import {
    aggregationFromChartScope,
    calculatePeriodFromChartScope, calculateXMinForSmaFromChartScope,
    ChartComponentProps,
    ChartToolModalState,
    ChartTypes,
    compareSeriesColors,
    createComparisonSeries,
    createOptions,
    createSmaSeries,
    getIndicatorParams,
    getSmaSeries,
    indicators,
    oscillators,
    smaIndicators,
    technicalAverageType,
    technicalIndicatorType
} from "./utils";

const FTChartToolComponent = lazy(() => import('../../charts/base/FTChartToolComponent'));
export type ChartOptionsType = {
    data: any,
    props: ChartComponentProps,
    state: ChartToolModalState
    setState: (value: ChartToolModalState) => void;
    setToggleChartOptions: (value: boolean) => void
    toggleChartOptions: boolean
    volumeData: HistoryQuoteConnection
    comparisonData: ComparisonSeriesData[]
}

export type ChartSeriesType = {
    data: Chart,
    mappedData: any[][]
    chartThreshold: number
    volumeData: HistoryQuoteConnection
    state: ChartToolModalState,
    comparisonData: ComparisonSeriesData[]
}


interface ChartToolsComponentModalProps {
    instrumentId: number | undefined
    setToggleChartOptions: (value: boolean) => void;
    toggleChartOptions: boolean
    showSearchModal: boolean
}

interface ComparisonSeriesData {
    instrument: Instrument
    series: any
}
export const ChartToolsComponentModal = (property: ChartToolsComponentModalProps) => {
    const MAX_COMPARISON_SERIES = 28;
    const location = useLocation();
    const props: any = location.state;
    const [searchString, setSearchString, transitionalSearchString] = useDelayedState<string>('', 500);
    const [showSearchModal, setShowSearchModal] = useState<boolean>(property.showSearchModal);
    const [loadComparisonInstrument, setLoadComparisonInstrument] = useState<Array<number>>([])
    const [comparisonData, setComparisonData]= useState<ComparisonSeriesData[]>([])
    const [compareMode, setCompareMode] = useState<boolean>(comparisonData.length > 1)
    const [legendHoverID, setLegendHoverID] = useState<Number | null>(null)
    const chartComponent = useRef(null);
    useEffect(() => {
        if((searchString && searchString.length > 0)) {
            setShowSearchModal(true)
        }
    }, [searchString])

    useEffect(() => {
        if (comparisonData && comparisonData.length > 1) {
            setCompareMode(true)
        } else {
            setCompareMode(false)
        }
    }, [comparisonData])
    const inputRef = useRef<HTMLInputElement>(null);

    if(props.showSearchModal) inputRef?.current?.focus();
    const [state, setState] = useState<ChartToolModalState>({
        chartScope: ChartScope.Intraday,
        currentIndicator: indicators[0],
        currentOscillator: oscillators[0],
        chartType: ChartTypes.Line,
        showIndicator: false,
        showOscillator: false,
        showAnnotations: true,
        activeRangeSelectorButtonId: CHART_TOOLS_RANGESELECTOR_BUTTONS[0].id,
        sma20Selected: false,
        sma50Selected: false,
        sma100Selected: false,
        sma200Selected: false,
    });
    let { loading, data} = useQuery(
        loader('./getInstrumentChartToolModal.graphql'),
        {
            variables: {
                instrumentId: property.instrumentId,
                chartScope: state.chartScope,
                criteria: {
                    from: moment(calculatePeriodFromChartScope(state.chartScope)),
                    to: moment(moment().local(true).endOf('day')),
                    aggregation: aggregationFromChartScope(state.chartScope)
                }
            },
            skip: !property.instrumentId || !state.chartScope
        }
    );
    let {data: smaData} = useQuery(
        loader('./getInstrumentChartToolModal.graphql'),
        {
            variables: {
                instrumentId: property.instrumentId,
                chartScope: ChartScope.Intraday,
                criteria: {
                    from: moment(calculatePeriodFromChartScope(state.chartScope)).subtract(1, 'year'),
                    to: moment(moment().local(true).endOf('day')),
                    aggregation: HistoryQuoteAggregation.Day
                }
            },
            skip: !property.instrumentId
        }
    );
    useEffect(() => {
        if (data && data.instrument?.chart?.series && data.instrument?.chart?.series.length > 0) {
            const ins:ComparisonSeriesData = {
                instrument: data.instrument,
                series: createComparisonSeries(data.instrument, true, compareSeriesColors[0])
            }
            setComparisonData([ins]);
            adjustXAxis(chartComponent, state);
        }
    }, [data]);
    useEffect(() => {
        if (smaData && smaData.instrument) {
            const series:any = createSmaSeries(smaData?.instrument)
            setState({...state, smaSeries: series});
        }
    }, [smaData]);

    const setInstrumentToCompare = (instrument: Instrument) => {
        if (instrument) {
            setToggleChartOptions(true)
            setState({...state, chartType: ChartTypes.Line})
            if (!loadComparisonInstrument.includes(instrument.id)) {
                if (comparisonData[0].instrument?.id !== instrument.id) {
                    setLoadComparisonInstrument([...loadComparisonInstrument, instrument.id])
                    setShowSearchModal(false)
                    setSearchString('')
                }
            }
        }
    }
    const addToComparisonData = (instrument: Instrument) => {
        if (instrument && instrument.chart?.series && instrument.chart?.series.length > 0 && comparisonData.length < MAX_COMPARISON_SERIES) {
            const ins:ComparisonSeriesData = {
                instrument: instrument,
                series: createComparisonSeries(instrument, true, compareSeriesColors[comparisonData.length])
            }
            if (!comparisonData.find(current => current?.instrument?.id === instrument.id)) {
                if (loadComparisonInstrument.includes(instrument?.id)) {
                    setComparisonData([...comparisonData, ins])
                }
            }
        }
    }

    const {toggleChartOptions, setToggleChartOptions} = property;

    const getOtherChartOptions = (ohlcData: HistoryQuoteEdge[] | null | undefined, state: ChartToolModalState) => {
        let ohlcSeries = [];
        let volumeSeries = [];
        if (ohlcData && ohlcData.length > 1) {
            for (let i = 0; i < ohlcData.length; i++) {
                let curr: HistoryQuote | null | undefined = ohlcData[i].node;
                ohlcSeries.push([new Date(curr?.start).getTime(), curr?.lowPrice, curr?.highPrice, curr?.firstPrice, curr?.lastPrice]);
                // @ts-ignore
                if (curr.cumulativeVolume) {
                    volumeSeries.push([new Date(curr?.start).getTime(), curr?.cumulativeVolume]);
                }
            }
            return {
                ...createOptions(options),
                plotOptions: {
                    candlestick: {
                        color: '#ff4d7d',
                        upColor: '#18C48F',
                    },
                    series: {
                        connectNulls: true
                    }
                },
                series: createOtherChartSeries(ohlcSeries, volumeSeries, state)
            }
        }
        else return createOptions(options)
    }

    function createOtherChartSeries(ohlcSeries: any[], volumeSeries: any[], state: ChartToolModalState) {
        let series: any[] = [];
        const {chartType, currentIndicator, currentOscillator} = state;
        let chartThreshold = data?.threshold;
        let linkedTo = 'ohlc' + chartType;
        if (ohlcSeries.length > 0) {
            series.push(
                {
                    id: 'ohlc' + chartType,
                    lineWidth: 1.5,
                    threshold : chartThreshold,
                    name: chartType.charAt(0).toUpperCase() + chartType?.slice(1),
                    color: chartType === ChartTypes.CandleStick ? "#ff4d7d" : "black",
                    type: chartType,
                    data: ohlcSeries,
                    yAxis: 0
                },
                {
                    id: currentIndicator.value,
                    lineWidth: 1.5,
                    linkedTo: linkedTo,
                    name: currentIndicator.name,
                    type: currentIndicator.value,
                    yAxis: 0,
                    params: getIndicatorParams(currentIndicator.value),
                    connectNulls: true
                })
            if (volumeSeries.length > 0) {
                series.push({
                    min: 0,
                    id: 'volume-series',
                    colorByPoint: true,
                    colors: ['red', 'green'],
                    type: "column",
                    name: 'Volume',
                    data: volumeSeries,
                    yAxis: 1,
                     tooltip: {
                        pointFormatter: function (): any {
                            // @ts-ignore
                            return `<br><strong>Volume</strong>: ${shortNumberFormat(this.y)}<br>`
                        }
                    }
                })
            }
            if (currentOscillator.id !== 0) {
                series.push({
                    id: currentOscillator.value,
                    lineWidth: 1.5,
                    linkedTo: linkedTo,
                    name: currentOscillator.name,
                    type: currentOscillator.value,
                    yAxis: 2,
                    params: getIndicatorParams(currentOscillator.value),
                    connectNulls: true
                })
            }

            const smaLineSeriesName = 'smaLineSeries';
            if(state.sma20Selected) {
                let smaSelectedIndicator: technicalAverageType = smaIndicators.filter(value => value.period == 20)[0];
                series.push(getSmaSeries(smaSelectedIndicator, smaLineSeriesName))
            }
            if(state.sma50Selected) {
                let smaSelectedIndicator: technicalAverageType = smaIndicators.filter(value => value.period == 50)[0];
                series.push(getSmaSeries(smaSelectedIndicator, smaLineSeriesName))
            }
            if(state.sma100Selected) {
                let smaSelectedIndicator: technicalAverageType = smaIndicators.filter(value => value.period == 100)[0];
                series.push(getSmaSeries(smaSelectedIndicator, smaLineSeriesName))
            }
            if(state.sma200Selected) {
                let smaSelectedIndicator: technicalAverageType = smaIndicators.filter(value => value.period == 200)[0];
                series.push(getSmaSeries(smaSelectedIndicator, smaLineSeriesName))
            }
            if (state.smaSeries) {
                let smaSeries: any[] = [state.smaSeries];
                series = series && [...series, ...smaSeries]
            }
        }
        return series;
    }

    const handleIndicatorValueChange = (indicator: technicalIndicatorType) => {
        let curr: technicalIndicatorType = indicators[0];
        indicators.map((ind: technicalIndicatorType) => {
            if (ind.value === indicator.value) {
                curr = indicator;
            }
        })
        setState({...state, currentIndicator: curr, showIndicator: !state.showIndicator});
    }

    const handleOscillatorValueChange = (oscillator: technicalIndicatorType) => {
        let curr: technicalIndicatorType = oscillators[0];
        oscillators.map((osc: technicalIndicatorType) => {
            if (osc.value === oscillator.value) {
                curr = oscillator;
            }
        })
        setState({...state, currentOscillator: curr, showOscillator: !state.showOscillator});
    }

    function adjustXAxis(chartComponent: any, state: ChartToolModalState) {
        if (chartComponent.current) {
            const min = moment(calculateXMinForSmaFromChartScope(state.chartScope, data)).valueOf();
            const max = moment(moment().local(true).endOf('day')).valueOf();
            chartComponent.current.update({
                xAxis: [
                    {
                        id: 'instrumentAxis',
                        min: min,
                        max: max,
                    }
                ]
            });
        }
    }

    const toggleSmaValue = (smaValue: technicalAverageType, chartComponent: any ) => {
        switch (smaValue.period) {
            case 20: setState({...state, sma20Selected: !state.sma20Selected}); break;
            case 50: setState({...state, sma50Selected: !state.sma50Selected}); break;
            case 100: setState({...state, sma100Selected: !state.sma100Selected}); break;
            case 200: setState({...state, sma200Selected: !state.sma200Selected}); break;
        }
        adjustXAxis(chartComponent, state);
    }

    const getSmaIndicatorBgColor = (smaValue: technicalAverageType): string => {
        let bgColor = '#F1F1F1';
        smaIndicators.forEach((sma: technicalAverageType) => {
            if (sma.period == smaValue.period) {
                if(sma.period === 20 && state.sma20Selected) {
                    bgColor = sma.color;
                    return;
                }
                if(sma.period === 50 && state.sma50Selected) {
                    bgColor = sma.color;
                    return;
                }
                if(sma.period === 100 && state.sma100Selected) {
                    bgColor = sma.color;
                    return;
                }
                if(sma.period === 200 && state.sma200Selected) {
                    bgColor = sma.color;
                    return;
                }
            }
        })
        return bgColor;
    }
    const options: ChartOptionsType = {
        data: data?.instrument?.chart,
        props,
        volumeData: data?.instrument?.historyQuote,
        state,
        setState,
        setToggleChartOptions,
        toggleChartOptions,
        comparisonData: comparisonData
    }

    const chartStyles = useBootstrapBreakpoint({
        xl: {
            width: '100%', marginLeft: 0, marginRight: 0
        },
        md: {
            width: '100%', marginLeft: 0, marginRight: 0
        },
        sm: {
            width: '100%', marginLeft: 0, marginRight: 0, backgroundColor: 'white'
        }
    })
    const isDesktop=useMediaQuery({
        query:'(max-width:1279px)'
    })
    function rangeSelectorHandler(e: SyntheticEvent, scope: ChartScope, buttonId: number) {
        e.stopPropagation();
        if (scope) {
            setToggleChartOptions(toggleChartOptions);
            setState({
                ...state,
                chartScope: scope,
                currentIndicator: state.currentIndicator,
                currentOscillator: state.currentOscillator,
                activeRangeSelectorButtonId: buttonId
            });
        }
    }

    function removeLegend(id: number) {
        const updatedCompareData = comparisonData.filter((item: ComparisonSeriesData) => item.instrument?.id !== id);
        const updateLoadComparisonInstrument = loadComparisonInstrument.filter((toRemove: number)=> toRemove !== id )
        setLoadComparisonInstrument(updateLoadComparisonInstrument)
        setComparisonData(updatedCompareData)
    }

    function highchartsCallback(chart: any) {
        chartComponent.current = chart;
    }
    return (
        <>
            {
                loadComparisonInstrument &&
                loadComparisonInstrument.map((value: any)=>{
                    return <CompareSeriesLoader
                        instrumentId={value}
                        onInstrumentDataLoaded={addToComparisonData}
                        chartScope={state.chartScope}
                    />
                }) 
            }
            {loading ? <div className={"p-1 text-center"} style={{ height: "40px", color: 'white' }}>
                <Spinner animation="border" style={{ color: "black" }} role={'loading-spinner'} />
            </div> :
                <div className="main-chart-movement stock-chart-in-modal portrait-chart" style={{ height: 670 }}>
                    {data?.instrument.chart.series.length > 0 &&
                        <>
                            <div className={"d-flex justify-content-between pb-2"} style={{backgroundColor:"#f1f1f1"}}>
                                    <div className={"chart-type-btn-wrapper align-self-center"}>
                                        <div>
                                        <Button key={"1"}
                                                className={`chart-type-btn ${(state.chartType === ChartTypes.Area || state.chartType === ChartTypes.Line) ? "active" : "active-icon"}`}
                                                id={"1"} onClick={() => {
                                            setToggleChartOptions(true);
                                            if (state.chartScope === ChartScope.Intraday) {
                                                setState({...state, chartType: ChartTypes.Area})
                                            } else {
                                                setState({...state, chartType: ChartTypes.Line})
                                            }
                                            trigInfonline(guessInfonlineSection(),"line_chart")
                                        }}>
                                            <span id={"1"}><img src={"https://code.highcharts.com/8.2.2/gfx/stock-icons/series-line.svg"} alt={"line"}/></span>
                                            <span id={"1"}>Linie</span>
                                        </Button>
                                        {
                                            !compareMode && data?.instrument && data?.instrument?.historyQuote?.edges?.length > 1 &&
                                            <Button key={"2"}
                                                    className={`chart-type-btn ${state.chartType === ChartTypes.CandleStick ? "active" : "active-icon"}`} id={"2"}
                                                    onClick={() => {
                                                        setToggleChartOptions(false);
                                                        setState({...state, chartType: ChartTypes.CandleStick})
                                                        trigInfonline(guessInfonlineSection(),"candlestick_chart")
                                                    }}>
                                            <span id={"2"}><img
                                                src={"https://code.highcharts.com/8.2.2/gfx/stock-icons/series-candlestick.svg"}
                                                alt={"candlestick"}/></span>
                                                <span id={"2"}>Candlestick</span>
                                            </Button>}
                                        {!compareMode &&  data?.instrument && data?.instrument?.historyQuote?.edges?.length > 1 &&
                                            <Button key={"3"}
                                                    className={`chart-type-btn ${state.chartType === ChartTypes.OHLC ? "active" : "active-icon"}`}
                                                    id={"3"}
                                                    onClick={() => {
                                                        setToggleChartOptions(false);
                                                        setState({...state, chartType: ChartTypes.OHLC})
                                                        trigInfonline(guessInfonlineSection(),"ohlc_chart")
                                                    }}>
                                            <span id={"3"}><img
                                                src={"https://code.highcharts.com/8.2.2/gfx/stock-icons/series-ohlc.svg"}
                                                alt={"ohlc"}/></span>
                                            <span id={"3"}>OHLC</span>
                                        </Button>}
                                    </div>
                                    </div>
                                <div className={"rangeSelector-btns-container align-self-center my-2 d-flex"}>
                                    {
                                        CHART_TOOLS_RANGESELECTOR_BUTTONS.map((btn: rangeSelectorBtn) => (
                                            <button className={`rangeselector-btn align-items-center font-size-14px text-center`} style={state.activeRangeSelectorButtonId === btn.id ? {color:"#FFFFFF", backgroundColor: "#5B9DE1" } : {color:"#5B9DE1" }}
                                                    onClick={(e) => {
                                                        rangeSelectorHandler(e, btn.scope, btn.id);
                                                        if(btn.ivw_code) trigInfonline(guessInfonlineSection(), btn.ivw_code)
                                                    }}
                                            >
                                                    {btn.text}
                                            </button>
                                        ))
                                    }
                                </div>
                                <div className="form-inline text-center">
                                    <div className="d-flex rounded-left">
                                    <FormControl placeholder="Vergleichen mit..." className="w-100 border-none rounded-none chart-tool-modal-search-input font-size-15px" style={{ height: 40, minWidth: 300 }}
                                        ref={inputRef}
                                        value={transitionalSearchString}
                                        onChange={(control: any) => { setSearchString(control?.target?.value); trigInfonline(guessInfonlineSection(), "chart_analyse") }}
                                    />
                                        <div className="bg-white rounded-right cursor-pointer">
                                            <img src={process.env.PUBLIC_URL + "/static/img/svg/icon_search_dark.svg"} height="18" alt="" className="mr-1" style={{marginTop: 10}}/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {showSearchModal &&
                                <ChartToolSearchModal
                                    searchString={searchString}
                                    onSelectInstrument={setInstrumentToCompare}
                                    onClose={() => {
                                        setShowSearchModal(false);
                                        trigInfonline(guessInfonlineSection(),"select_for_compare")
                                    }}
                                    showExpanded={showSearchModal}
                                />
                            }

                            {
                            !compareMode ?
                                <div className={"d-md-flex d-none views-container justify-content-between px-1"}>
                                    <div className="d-flex ml-1">
                                        <div style={{ maxWidth: '267px', marginRight: '0.5%' }}>
                                            <label className={"font-weight-bold ml-1 mt-1 mb-0 indicator-labels"} htmlFor={"indicators"}>Indikatoren im Chart</label>
                                            <Dropdown onToggle={() => setState({...state, showIndicator: !state.showIndicator})}  show={state.showIndicator} drop={"down"} id={"chart-indicators"}>
                                                <Dropdown.Toggle className={"d-flex justify-content-between align-items-center pl-3 pr-2"}>
                                                    <span className={"text-truncate font-size-14px"}>{state.currentIndicator.name}</span>
                                                    <img alt="Dropdown arrow down" src={process.env.PUBLIC_URL + "/static/img/svg/icon_direction_down_dark.svg"}/>
                                                </Dropdown.Toggle>
                                            <Dropdown.Menu className={"chart-indicators"} >
                                            {indicators.map((indicator: technicalIndicatorType) => (
                                                    <button onClick={() => {
                                                        handleIndicatorValueChange(indicator);
                                                        trigInfonline(guessInfonlineSection(), "indicator_in_chart")
                                                    }} value={indicator.value} key={indicator.id} className={'font-size-14px'}>
                                                        {indicator.name}
                                                    </button>
                                                ))}
                                            </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div style={{ maxWidth: '267px', marginRight: '0.5%', zIndex: 10000 }}>
                                            <label style={{ marginLeft: 8 }} className={"font-weight-bold mt-1 mb-0 indicator-labels"} htmlFor={"oscillators"}>Indikatoren unter dem Chart</label>
                                            <Dropdown onToggle={() => setState({...state, showOscillator: !state.showOscillator})}  show={state.showOscillator} drop={"down"} id={"chart-indicators"}>
                                                <Dropdown.Toggle className={"d-flex justify-content-between align-items-center pl-3 pr-2"}>
                                                    <span className={"text-truncate font-size-14px"}>{state.currentOscillator.name}</span>
                                                    <img alt="Dropdown arrow down" src={process.env.PUBLIC_URL + "/static/img/svg/icon_direction_down_dark.svg"}/>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu id={"chart-oscillators"} className={"chart-indicators"} >
                                                    {oscillators.map((oscillator: technicalIndicatorType) => (
                                                        <button onClick={() => {
                                                            handleOscillatorValueChange(oscillator);
                                                            trigInfonline(guessInfonlineSection(), "indicator_under_chart")
                                                        }} value={oscillator.value} key={oscillator.id} className={'font-size-14px'}>
                                                            {oscillator.name}
                                                        </button>
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className={"d-flex flex-column"}>
                                            <label style={{ marginLeft: 8 }} className={"font-weight-bold mt-1 mb-0 indicator-labels"} htmlFor={"oscillators"}>Gleit. Durchschnitt</label>
                                            <div className="d-flex justify-content-around">
                                                {
                                                    smaIndicators.map((smaIndicator: technicalAverageType) => (
                                                        <button className={`rangeselector-btn align-items-center font-size-14px text-center`} onClick={() => {
                                                            toggleSmaValue(smaIndicator, chartComponent);
                                                            trigInfonline(guessInfonlineSection(), smaIndicator.ivw_code)
                                                        }} style={{minWidth: isDesktop ? 45 : 80, height:40, backgroundColor: getSmaIndicatorBgColor(smaIndicator), color: getSmaIndicatorBgColor(smaIndicator) === '#F1F1F1' ? '#383838' : '#FFFFFF'}} value={smaIndicator.value} key={smaIndicator.id}>
                                                            { isDesktop ? smaIndicator.name.replace(/\s*Tage\s*/, "T") : smaIndicator.name }
                                                        </button>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {state.showAnnotations && <ChartAnnotationComponent />}
                                </div>
                                :
                                <div className={"d-md-flex d-none views-container justify-content-center align-items-center flex-wrap px-1 mt-4"}>
                                    {
                                        comparisonData && comparisonData.map((value: ComparisonSeriesData, index :number) => {
                                            return (
                                                <div className="d-flex justify-content-center align-items-center charttool-legend-wrapper px-4" >
                                                    <hr style={{width:12, borderColor:value?.series?.color, borderWidth:2, marginRight: 8}} />
                                                    {value?.instrument?.group?.name}
                                                    {   index!=0 &&
                                                        <span onClick={(e)=> {
                                                            removeLegend(value?.instrument?.id);
                                                            trigInfonline(guessInfonlineSection(), "remove_from_comparison")
                                                        }} className="cursor-pointer" onMouseEnter={()=>{setLegendHoverID(value?.instrument?.id)}} onMouseLeave={()=>setLegendHoverID(null)} >
                                                            <img src={legendHoverID == value?.instrument?.id ? process.env.PUBLIC_URL + "/static/img/svg/icon_close_dark_red.svg" : "/static/img/svg/icon_close_gray.svg"} alt={'Aus dem Vergleich entfernen'} className="svg-convert" width="20" title={"Aus dem Vergleich entfernen"}/>
                                                        </span>
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }

                            {/*Charts Section */}
                            <div className='d-flex px-1'>
                                <Suspense fallback={<Spinner animation={'border'}/>}>
                                <FTChartToolComponent className={"bg-white stock-tool-main-chart"} containerProps={{
                                    style: {height: compareMode? 665 : 705, ...chartStyles}
                                }}
                                    constructorType={'stockChart'} callback={highchartsCallback}
                                    options={toggleChartOptions ? createOptions(options) : getOtherChartOptions(data?.instrument?.historyQuote.edges, state)}
                                />
                                </Suspense>
                            </div>
                            {
                                compareMode &&
                                <div className="font-weight-bold d-flex justify-content-center bg-white py-3 mt-n2 font-size-14px">
                                    Bei einem Chartvergleich sind manche Funktionen nicht verfügbar.&nbsp;
                                    <span className="charttool-remove-all-legend pb-n1 font-size-14px" style={{color: "#326EAC"}} onClick={() => {
                                        setLoadComparisonInstrument([]);
                                        setComparisonData([comparisonData[0]])
                                        trigInfonline(guessInfonlineSection(), "remove_all_comparison")
                                    }}>
                                        Alle Vergleichs-Charts entfernen...
                                   </span>
                                </div>
                            }
                        </>
                    }
                </div>
            }
        </>
    )
}

export default memo(ChartToolsComponentModal);

