import {useQuery} from "@apollo/client";
import {Query} from "../../generated/graphql";
import {loader} from "graphql.macro";
import {Button, Card, Spinner} from "react-bootstrap";
import React, {useCallback, useContext, useState} from "react";
import {guessInfonlineSection, trigInfonline} from "../common/InfonlineService";
import PageHeaderFilterContext from "../layout/PageHeaderFilterComponent/PageHeaderFilterContext";
import {PageHeaderFilterComponent} from "../layout/PageHeaderFilterComponent/PageHeaderFilterComponent";
import "./TerminePageDropdown.scss";
import classNames from "classnames";
import {useMediaQuery} from "react-responsive";

interface CountryDropDownBodyProps {
    setCountry: (country: { name: string | undefined | null, id: string | undefined | null }) => void;
}

interface CategoryDropdownBodyProps {
    setCategory: (category: { name: string | null | undefined, id: string | null | undefined }) => void;
}

interface CountrySelectDropDown {
    setCountry: (country: { name: string | null | undefined, id: string | null | undefined }) => void;
    country: { name: string | undefined | null, id: string | null | undefined }
}

interface CategorySelectDropDown {
    setCategory: (category: { name: string | null | undefined, id: string | null | undefined }) => void;
    category: { name: string | undefined | null, id: string | null | undefined }
}

const triggerInfonlineTag = () => {
    trigInfonline(guessInfonlineSection(), "termine_page")
}

 const CountryDropdownBody = (props: CountryDropDownBodyProps) => {
    let {data, loading} = useQuery<Query>(loader("./calendarCountries.graphql"));
    const [valueForTablet, setValueForTablet] = useState<{ name: string | undefined | null, id: string | undefined | null }>({
        name: "Alle",
        id: ""
    });

    const isMobile = useMediaQuery({
        query: '(max-width: 767px)'
    });

    const isDesktop = useMediaQuery({
        query: '(min-width: 1280px)'
    });

    let context = useContext(PageHeaderFilterContext);

    let closeAction = useCallback(() => {
        if (context) {
            context.close();
        }
    }, [context]);

    const setRegion = (name: string | undefined | null, id: string | undefined | null) => {
        props.setCountry(
            {name: name, id: id});
        closeAction();
    }

     const sortedCountries = data?.calendarCountries.slice().sort((a, b) => {
         if (a?.name && b?.name) {
             return a.name.localeCompare(b.name);
         } else {
             return 0;
         }
     });
    return (
        <Card
            className={classNames("regions-filter-modal p-1 bg-white border-0", isDesktop ? "p-2 d-filter-modal-size regions-filter-modal-border" : !isMobile && "t-region-filter-modal")}>
            {isDesktop &&
                <Card.Header
                    className={classNames("bg-white p-1  d-flex justify-content-between dropdown-header")}>
                    <h6 className="font-weight-bold font-size-17px header-title">Länder</h6>
                    <span className="close-modal-butt svg-icon mr-n1 align-self-center cursor-pointer"
                          onClick={() => closeAction()}>
                    <img src={process.env.PUBLIC_URL + "/static/img/svg/icon_close_gray.svg"} alt=""
                         className="svg-convert" width="27"/>
                </span>
                </Card.Header>
            }
            {loading ? <span className="mt-5 ml-5 pt-5 pl-5"><Spinner color={"black"} animation={"border"}/></span> :
                (isMobile || isDesktop) ?
                    <Card.Body
                        className={classNames(isMobile ? "m-regions-filter-modal-body p-0" : "regions-filter-modal-body")} role={"card-body-mobile-desktop"}>

                        <div
                            className={"d-flex flex-column border-gray-light"}>
                    <span
                        className={classNames("regions-filter-modal-body-button py-2 font-weight-bold border-bottom-1 border-gray-light ", isDesktop ? "d-modal-button-width " : "t-modal-button-width")}
                        role={"select-all-country-button-desktop"}
                        onClick={() => {
                            setRegion(null, null);
                            closeAction()
                        }}>
                        <span style={{
                            paddingLeft: "34px"
                        }}> Alle </span></span>
                            <>
                                {sortedCountries?.map(current => (

                                    <div
                                        key={current?.id}
                                        className={classNames("regions-filter-modal-body-button py-2 border-bottom-1 border-gray-light", isMobile && "w-100 pr-4", isDesktop ? "d-modal-button-width" : "t-modal-button-width")}
                                        onClick={() => {
                                            setRegion(current?.name, current?.id); 
                                            triggerInfonlineTag()
                                        }}
                                        role="select-one-country-button-desktop"
                                    >
                                        {current?.id &&
                                            <img
                                                src={process.env.PUBLIC_URL + "/static/img/svg/flags/" + current?.id.toLowerCase() + ".svg"}
                                                alt="" className="mr-2" width="24px" height="16px"
                                            />
                                        }
                                        {current?.name}
                                    </div>))
                                }
                            </>

                        </div>
                    </Card.Body>
                    :
                    <Card.Body className="p-0">
                        <div className="t-regions-filter-modal-body mx-n3 bg-white">
                            <div
                                className="d-flex flex-column border-gray-light align-items-center t-regions-filter-modal-body-content pt-2 mx-n4">
                                    <span
                                        className="regions-filter-modal-body-button py-2 font-weight-bold border-bottom-1 border-gray-light t-modal-button-width"
                                        onClick={() => {
                                            setValueForTablet({name: "", id :""})
                                            triggerInfonlineTag()
                                        }}
                                        role={"select-all-country-button-tablet"}
                                    >
                                        <span style={{paddingLeft: "32px"}}> Alle </span> </span>
                                <>
                                    {sortedCountries?.map(current => (

                                        <div
                                            key={current?.id}
                                            className="regions-filter-modal-body-button py-2 border-bottom-1 border-gray-light t-modal-button-width"
                                            onClick={() => {
                                                setValueForTablet({name: current?.name, id: current?.id});
                                                triggerInfonlineTag()
                                            }}
                                            role={"select-one-country-button-tablet"}
                                        >
                                            {current?.id &&
                                                <img
                                                    src={process.env.PUBLIC_URL + "/static/img/svg/flags/" + current?.id.toLowerCase() + ".svg"}
                                                    alt="" className="mr-2" width="24px" height="16px"/>}
                                            {current?.name}
                                        </div>))
                                    }
                                    <span></span>
                                </>

                            </div>

                        </div>
                        <div
                            style={{marginTop: "60px"}}
                            className="d-flex justify-content-end py-1 w-100 regions-filter-modal-footer-button-container align-self-end">
                            <Button
                                onClick={() => {
                                    setRegion("Alle", "");
                                    closeAction();
                                    triggerInfonlineTag()

                                }}
                                role={"close-country-modal-tablet"}

                            >
                                Abbrechen
                            </Button>
                            <Button className="ml-3"
                                    onClick={() => {
                                        setRegion(valueForTablet.name, valueForTablet.id);
                                        closeAction();
                                        triggerInfonlineTag()
                                    }}
                                    role={"select-country-button-tablet"}
                            >
                                Anwenden
                            </Button>
                        </div>
                    </Card.Body>
            }
        </Card>

    )
}

export function CountrySelectDropdown(props: CountrySelectDropDown) {
    const isMobile = useMediaQuery({
        query: '(max-width: 767px)'
    })

    return (

        <div className={classNames(isMobile && "mb-n1")}>
            <PageHeaderFilterComponent
                className={"dropdown-btn"}
                toggleVariant={"dropdown-wrapper"}
                customButtonSize={"region-dropdown-width"}
                disableDescriptionEffect={true}
                title={<span className="dropdown-btn-text">Länder</span>}
                disableIcon={true}
                customTitleClass={'termine-page-modal-button-title'}
                customDescriptionClass={"termine-page-modal-button-description"}
                isTerminePage={true}
                description={
                    <>
                                <span className="dropdown-btn-text dropdown-btn-text-selected">
                            {props.country.name}
                                </span>
                    </>
                }
            >
                <CountryDropdownBody
                    setCountry={props.setCountry}
                />
            </PageHeaderFilterComponent>
        </div>
    )
}

 const CategoryDropdownBody = (props: CategoryDropdownBodyProps) => {
    let {loading, data} = useQuery<Query>(loader("./calendarCategories.graphql"));
    const [valueForTablet, setValueForTablet] = useState<{ name: string | undefined | null, id: string | undefined | null }>({
        name: "Alle",
        id: ""
    });

    let context = useContext(PageHeaderFilterContext);

    const isDesktop = useMediaQuery({
        query: '(min-width: 1280px)'
    });

    const isMobile = useMediaQuery({
        query: '(max-width: 767px)'
    })

    const isTablet = useMediaQuery({
        query: '(max-width: 1280px)'
    })



    let closeAction = useCallback(() => {
        if (context) {
            context.close();
        }
    }, [context]);

    const setCategory = (name: string | undefined | null, id: string | undefined | null) => {
        props.setCategory({name: name, id: id});
        triggerInfonlineTag();
        closeAction();
    }
     const sortedCategories = data?.calendarCategories.slice().sort((a, b) => {
         if (a?.name && b?.name) {
             return a.name.localeCompare(b.name);
         } else {
             return 0;
         }
     });
    return (
        <Card
            className={classNames("regions-filter-modal p-1 bg-white  border-0 termine-custom-modal", isDesktop ? "p-2 d-filter-modal-size regions-filter-modal-border" : !isMobile && "t-category-filter-modal")}>
            {isDesktop &&
                <Card.Header className="bg-white p-1 d-flex justify-content-between dropdown-header">
                    <h6 className="font-weight-bold font-size-17px header-title">Kategorie</h6>
                    <span className="close-modal-butt svg-icon mr-n1 align-self-center cursor-pointer"
                          onClick={() => closeAction()}>
                                <img src={process.env.PUBLIC_URL + "/static/img/svg/icon_close_gray.svg"} alt=""
                                     className="svg-convert" width="27"/>
                                </span>
                </Card.Header>
            }

            {(isMobile || isDesktop) ?
                loading ? <span className="mt-5 ml-5 pt-5 pl-5"><Spinner color={"black"} animation={"border"}/></span> :
                    <Card.Body
                        className={classNames(isMobile ? "m-regions-filter-modal-body p-0 pb-4" : "regions-filter-modal-body")}>
                        <div
                            className={classNames("d-flex flex-column")}>
                                <span
                                    className={classNames("regions-filter-modal-body-button py-2 font-weight-bold border-bottom-1 border-gray-light", isDesktop ? "d-modal-button-width" : "t-modal-button-width")}
                                    onClick={() => setCategory("", "")}
                                    role={"select-all-category-button-tablet"}>
                            {isDesktop ? <span>Alle</span> : <span>Alle Kategorien</span>}
                                </span>
                            <>
                                {sortedCategories?.map(current => (
                                    <div
                                        key={current?.id}
                                        className={classNames("regions-filter-modal-body-button py-2 border-bottom-1 border-gray-light", isDesktop ? "d-modal-button-width" : "t-modal-button-width")}
                                        onClick={() => {
                                            setCategory(current?.name, current?.id);
                                        }}
                                        role={"select-one-category-button-desktop"}
                                    >
                                        {current?.name}
                                    </div>))
                                }
                            </>

                        </div>
                    </Card.Body>
                :
                <Card.Body className="p-0">
                    <div className="t-regions-filter-modal-body mx-n3 bg-white">
                        <div
                            className="d-flex flex-column border-gray-light align-items-center t-regions-filter-modal-body-content pt-2 mx-n4">
                            <div
                                className={classNames("d-flex flex-column align-items-center", isTablet && "t-termine-dropdown-height")}>
                                <span
                                    className="regions-filter-modal-body-button py-2 font-weight-bold border-bottom-1 border-gray-light t-modal-button-width"
                                    onClick={() => {
                                        setValueForTablet({name: "", id :""});
                                        triggerInfonlineTag()
                                    }}
                                    role={"select-all-category-button-tablet"}
                                >
                                <span>Alle Kategorien</span>
                                </span>
                                <>
                                    {sortedCategories?.map(current => (

                                        <div
                                            key={current?.id}
                                            className="regions-filter-modal-body-button py-2 border-bottom-1 border-gray-light t-modal-button-width"
                                            onClick={() => {
                                                setValueForTablet({name: current?.name, id: current?.id});
                                                triggerInfonlineTag()
                                            }}
                                            role={"select-one-category-button-tablet"}
                                        >
                                            {current?.name}
                                        </div>))
                                    }
                                </>

                            </div>
                        </div>
                    </div>
                    <div style={{marginTop: "30px", paddingBottom: "30px"}}
                         className="d-flex justify-content-end py-1 w-100 regions-filter-modal-footer-button-container align-self-end">
                        <Button
                            onClick={() => {
                                setValueForTablet({name: "", id: ""});
                                closeAction();
                                triggerInfonlineTag()
                            }}
                            role={"close-category-modal-tablet"}

                        >
                            Abbrechen
                        </Button>
                        <Button className="ml-3"
                                onClick={() => {
                                    setCategory(valueForTablet.name, valueForTablet.id);
                                    closeAction();
                                    triggerInfonlineTag()

                                }}
                                role={"select-category-button-tablet"}


                        >
                            Anwenden
                        </Button>
                    </div>

                </Card.Body>
            }
        </Card>

    )
}

export function CategorySelectDropdown(props: CategorySelectDropDown) {
    const isMobile = useMediaQuery({
        query: '(max-width: 767px)'
    })

    return (

        <div className={classNames(isMobile && "mb-n1")}>
            <PageHeaderFilterComponent
                className={"dropdown-btn"}
                toggleVariant={"dropdown-wrapper"}
                customButtonSize={"category-dropdown-width"}
                disableDescriptionEffect={true}
                title={<span className="dropdown-btn-text">Kategorie</span>}
                disableIcon={true}
                customTitleClass={'termine-page-modal-button-title'}
                customDescriptionClass={"termine-page-modal-button-description"}
                isTerminePage={true}
                description={
                    <>
                        <span className="dropdown-btn-text dropdown-btn-text-selected">
                            {props.category.name}
                        </span>
                    </>
                }
            ><CategoryDropdownBody setCategory={props.setCategory}/>
            </PageHeaderFilterComponent>
        </div>

    )

}
