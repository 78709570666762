import { useKeycloak } from "@react-keycloak/web";
import classNames from "classnames";
import { Component} from "react";
import { Container, Row, Col, Button} from "react-bootstrap";
import './HotSectionAdvertisement.scss';

import { MostTradedModal } from "./StatisticsModal/StatisticsModal";
import { MostTraded } from "./MostTraded";
// import CarouselSlideContent from "./CarouselSlideContent";
import BlogTeaser from "./BlogTeaser";
export class HostSectionAdvertisement extends Component<{}, {}> {
    render() {
        return (
            <section className="main-section home-statistics-mf">
                <Container className="banner-container">
                    <div className="row">
                        <div className="col-xl-8 col-lg-12 col-md-12 col-sm-12 pr-sm-0 pb-md-4 custom-padding-right" >
                            {/* <CarouselSlideContent /> */}
                            <BlogTeaser/>
                        </div>

                        <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 custom-padding-left">
                            <MostTradedAssets />
                        </div>
                    </div>
                </Container>
            </section>
        );
    }

}

export function MostTradedAssets({ titleClassName, buttonClassName, assetNameLenght }: { titleClassName?: string, buttonClassName?: string, assetNameLenght?: number }) {
    let { initialized, keycloak } = useKeycloak();
    const logedIn: boolean = initialized && !keycloak?.authenticated;
    return (
        <>
            <Row className="bg-dark-blue text-white pt-2 mx-lg-0 mx-md-1 mx-sm-0 most-traded-section mb-4">
                <Col className={classNames("trading-title roboto-heading col-sm-12 font-weight-bold px-lg-n2", titleClassName ? titleClassName : "text-nowrap")}>
                    So handeln finanztreff.de Nutzer
                </Col>
                <Col className={classNames("col-xl-12 col-lg-6 col-md-12 col-sm-12", logedIn ? "mt-xl-2" : "mt-xl-5 mt-lg-0")}>
                    <MostTraded trades={false} purchased={true} logedIn={logedIn} assetNameLenght={assetNameLenght} itemsReturned={5} days={7} chartWidth={50} className="statistics" />
                </Col>
                <Col className="col-xl-12 d-xl-block d-sm-none my-1">
                    <Row className="border-top-1 border-gray-light mx-2"></Row>
                </Col>
                <Col className={classNames("col-xl-12 col-lg-6 col-md-12 col-sm-12", logedIn ? "mb-xl-2" : "mb-xl-5 mb-lg-0")}>
                    <MostTraded trades={false} purchased={false} logedIn={logedIn} assetNameLenght={assetNameLenght} itemsReturned={5} days={7} chartWidth={50} className="statistics" />
                </Col>
                {logedIn ?
                    <>
                        <Col className={classNames("col-xl-12 col-lg-6 col-md-12 col-sm-12 font-weight-bold mb-xl-n2 mb-lg-n1 line-height-1", buttonClassName ? buttonClassName : "font-size-20px")}>
                            Angemeldete Nutzer sehen mehr!
                        </Col>
                        <Col className="col-xl-12 col-lg-6 col-md-12 col-sm-12 mb-xl-n2 mb-lg-n3 mb-md-n4 mb-sm-n4 button-position">
                            <Button variant="primary" className="bg-white text-dark-blue border-0 font-size-14px mt-xl-2 mt-lg-0 mb-xl-4 mb-lg-n1 mb-sm-4" onClick={() => keycloak.login()}>
                                Jetzt registrieren oder anmelden
                            </Button>
                        </Col>
                    </>
                    :
                    <Col className="">
                        <MostTradedModal />
                    </Col>

                }
            </Row>
        </>
    );
}
