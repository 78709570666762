import React, {useContext} from "react";
import {ShareRegionOption} from "./utils";
import {BaseCriteriaState} from "./BaseShareSearchCard";
import ShareSearchContext from "./ShareSearchContext";
import {FilterDropdownItem} from "../../common/SearchCard/FilterDropdownItem";

export const ShareRegionsDropdown = (props: BaseCriteriaState<any>) => {
    const metaDataContext = useContext(ShareSearchContext);
    return (
        <>
            <FilterDropdownItem<ShareRegionOption & {value: string}>
                onSelect={(option: ShareRegionOption & {value: string}) => {
                    props.setCriteria({ ...props.state, regionId: parseInt(option?.id), regionName: option.id != null && option.name});
                }}
                options={metaDataContext?.shareRegions as any}
                activeId={props.state?.regionId as unknown as string}
            />
        </>
    )
}
