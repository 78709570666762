import { useQuery } from "@apollo/client";
import React, {useRef, useEffect, useState} from "react";
import {
    Chart,
    ChartPoint,
    ChartScope,
    ChartSeries,
    HistoryQuoteAggregation,
    QuoteType
} from "../../../graphql/types";
import { numberFormat } from "../../../utils";
import moment from 'moment';
import { Spinner } from "react-bootstrap";
import { loader } from "graphql.macro";
import FTLChartComponent from "../charts/base/FTLChartComponent";
import { calculatePeriodFromChartScope, calculateXMinForSmaFromChartScope, createSmaSeries } from "./ChartTool/utils";

function createSeries(data: Chart, underlyingData: Chart, mappedData: any[][], chartThreshold: number, smaSeries:any, props:ChartComponentProps) {
    let seriesPrefix = 0;
    let smaIndicatorArray :any[] = []
    let current = data.series.map((serie: ChartSeries, i: number) => {
        seriesPrefix++;
        return {
            yAxis: 0,
            id: 'dataseries' + serie.type + seriesPrefix,
            name: serie.type === QuoteType.Trade ? 'Kurs' : serie.type,
            type: serie.type === QuoteType.Trade ? 'area' : 'line',
            data: mappedData[i],
            threshold: chartThreshold,
            color: serie.type === QuoteType.Trade ? (!!chartThreshold ? 'rgba(31, 220, 162, 1)' : 'white') :
               ( serie.type === QuoteType.Bid ? 'rgba(255, 77, 125, 1)' : 'rgba(31, 220, 162, 1)'),
            fillColor: serie.type === QuoteType.Trade ? (!!chartThreshold ? 'rgba(31, 220, 162, 0.3)' : 'rgba(255, 255, 255, 0)') :
                (serie.type === QuoteType.Bid ? 'rgba(31, 220, 162, 0.3)' : undefined),
            //
            negativeColor: serie.type === QuoteType.Trade ? 'rgba(255, 77, 125, 1)' : undefined,
            negativeFillColor: serie.type === QuoteType.Trade ? 'rgba(255, 77, 125, 0.3)' : undefined,
            lineWidth: 1.5,
            trackByArea: false,
            tooltip: {
                distance: 30,
                valueDecimals: 3,
            }
        }
    }).sort((a,b)=>a.name.localeCompare(b.name))
    if(props.gd50Average) {
        smaIndicatorArray?.push({
            name: 'GD 50',
            xAxis: 'instrumentAxis',
            id: 'GD50',
            type: 'sma',
            lineWidth: 1.5,
            color:'#FF8D38',
            linkedTo: "smaLineSeries" ,
            visible: true,
            params: {
                period: 50
            },
            yAxis:0
        })
    }
    if(props.gd200Average){
        smaIndicatorArray?.push({
            name: 'GD 200',
            xAxis: 'instrumentAxis',
            id: 'GD200',
            type: 'sma',
            lineWidth: 1.5,
            color:'#FF337F',
            linkedTo: "smaLineSeries" ,
            visible: true,
            params: {
                period: 200
            },
            yAxis:0
        })
    }
    let underlying;
    if (underlyingData) {
        underlying = underlyingData.series.map((serie: ChartSeries, i: number) => {
            seriesPrefix++;
            return {
                yAxis: 1,
                id: 'dataseries' + serie.type + seriesPrefix,
                name: serie.type === QuoteType.Trade ? 'Basiswert' : serie.type,
                type: 'line',
                data: mappedData[current.length + i],
                threshold: underlyingData.threshold?.value || 0,
                color: serie.type === QuoteType.Bid ? 'rgba(255, 77, 125, 1)' : 'rgba(0, 0, 220, 1)',
                fillColor: serie.type === QuoteType.Trade ? 'rgba(0, 0, 220, 1)' : undefined,
                negativeColor: serie.type === QuoteType.Trade ? 'rgba(0, 0, 220, 1)' : undefined,
                negativeFillColor: serie.type === QuoteType.Trade ? 'rgba(0, 0, 220, 0.6)' : undefined,
                lineWidth: 1.5,
                trackByArea: false,
                tooltip: {
                    distance: 30,
                    valueDecimals: 2,
                }
            }
        });
    }
    if (underlying) {
        return current.concat(underlying);
    }
    if(smaSeries.smaSeries) {
        current = [...current, smaSeries.smaSeries]
    }
    return [...current, ...smaIndicatorArray];
}

function createYAxis(minPointData: number, maxPointData: number, chartThreshold: number, props: ChartComponentProps, underlyingData: Chart | undefined, minPointData1: number | undefined, maxPointData1: number | undefined) {
    let arr: any[] = [];
    arr.push({
        type: 'linear',
        title: { text: '' },
        lineColor: 'rgba(255, 255, 255, 0.3)',
        gridLineColor: 'rgba(255, 255, 255, 0.14)',
        min: minPointData,
        max: maxPointData,
        plotLines: createPlotLines(chartThreshold),
        opposite: true,
        labels: {
            style: {
                color: '#9c9c9c',
                fontFamily: "Roboto"
            }
        }
    })
    if (underlyingData) {
        arr.push({
            type: 'linear',
            offset: -10,
            title: { text: '' },
            lineColor: 'rgba(255, 255, 255, 0.3)',
            gridLineColor: 'rgba(255, 255, 255, 0.14)',
            min: minPointData1,
            max: maxPointData1,
            plotLines: createPlotLines(null, props.strike, props.stoploss),
            opposite: false,
            labels: {
                style: {
                    color: '#9c9c9c',
                    fontFamily: "Roboto"
                }
            }
        });
    }
    return arr;
}

function populateSeriesData(serie: ChartSeries, maxPointData: number, foundMax: boolean, data: Chart, minPointData: number, foundMin: boolean) {
    let array: any[] = [];
    serie.data.forEach((entry: ChartPoint) => {
        let dataObj = {
            x: moment(entry.when).valueOf(),
            y: entry.value,
        };
        if (entry.value === maxPointData && !foundMax && data.series.length === 1) {
            dataObj = Object.assign(dataObj, {
                marker: {
                    enabled: true,
                    lineWidth: 2,
                    radius: 5,
                    symbol: 'circle',
                    lineColor: 'white',
                    fillColor: 'transparent',
                    fillOpacity: 0,
                },
                dataLabels: {
                    enabled: true,
                    style: {
                        color: 'var(--green)',
                        fontSize: '10px',
                        fontWeight: 'bold',
                        textOutline: 0,
                    },
                    formatter: function () {
                        return '<span class="chart-marker-label">hoch</span>';
                    },
                    y: -3,
                    verticalAlign: 'bottom',
                    useHTML: true,
                },
            });
            foundMax = true;
        }
        if (entry.value === minPointData && !foundMin && data.series.length === 1) {
            dataObj = Object.assign(dataObj, {
                marker: {
                    enabled: true,
                    lineWidth: 2,
                    radius: 5,
                    symbol: 'circle',
                    lineColor: 'white',
                    fillColor: 'transparent',
                    fillOpacity: 0,
                },
                dataLabels: {
                    enabled: true,
                    style: {
                        color: 'var(--pink)',
                        fontSize: '10px',
                        fontWeight: 'bold',
                        textOutline: 0,
                    },
                    formatter: function () {
                        return '<span class="chart-marker-label">tief</span>';
                    },
                    y: 3,
                    verticalAlign: 'top',
                    useHTML: true,
                },
            });
            foundMin = true;
        }
        array.push(dataObj);
    });
    return array.sort((a,b) => a.x - b.x);
}

function createPoints(data: Chart, underlyingData: Chart) {
    let points: any[] = [];
    if (data?.series.length === 1) {
        points.push(createPoint());
    }
    if (underlyingData?.series.length === 1) {
        points.push(createPoint());
    }
    return points;
}

function createPoint() {
    return {
        marker: {
            enabled: true,
            lineWidth: 2,
            radius: 5,
            symbol: 'circle',
            lineColor: 'white',
            fillColor: 'transparent',
            fillOpacity: 0,
        },
        dataLabels: {
            enabled: true,
            style: {
                color: 'var(--green)',
                fontSize: '10px',
                fontWeight: 'bold',
                textOutline: 0,
            },
            formatter: function () {
                return '<span class="chart-marker-label">hoch</span>';
            },
            y: -3,
            verticalAlign: 'bottom',
            useHTML: true,
        },
    }
}

const findMinMaxFromSmaSeries = (smaSeries: any, minPointData: number, maxPointData: number, props:ChartComponentProps) =>{ 
    if (smaSeries && smaSeries.smaSeries && smaSeries.smaSeries.data.length > 0){
        let period = 0;
        let ma = 0;
        if(props.gd50Average) {
            period = 50;
            ma = calculateMaForPeriod(props, period, smaSeries);
            minPointData = Math.min(minPointData, ma);
            maxPointData = Math.max(maxPointData, ma);
        }
        if(props.gd200Average) {
            period = 200;
            ma = calculateMaForPeriod(props, period, smaSeries);
            minPointData = Math.min(minPointData, ma);
            maxPointData = Math.max(maxPointData, ma);
        }

    }
    return {minPointData, maxPointData};
}
function calculateMaForPeriod(props:ChartComponentProps, period: number, smaSeries: any) {
    const startTime = moment(calculatePeriodFromChartScope(props.chartType)).valueOf();
    let smaLength = smaSeries.smaSeries.data.length;
    let ma = 0;
    for (let i = smaLength - 1; i !== 0; i--) {
        if (smaSeries.smaSeries.data[i].x < startTime) {
            for (let j = 0; j < period; j++) {
                if (smaSeries.smaSeries.data[i]) {
                    ma = ma + smaSeries.smaSeries.data[i].y;
                    i--;
                }
            }
            ma = ma / period;
            break;
        }
    }
    return ma;
}

const createOptions = (data: Chart, underlyingData: Chart, props: ChartComponentProps, smaSeries:any) => {
    let chartThreshold = data?.threshold;
    const mappedData: any[][] = [];
    let minPointData = chartThreshold?.value || 99999999;
    let maxPointData = chartThreshold?.value || 0;
    let minPointData1 = underlyingData?.threshold?.value || 99999999;
    let maxPointData1 = underlyingData?.threshold?.value || 0;
    let foundMin = false;
    let foundMax = false;
    let foundMin1 = false;
    let foundMax1 = false;
    const timezone = new Date().getTimezoneOffset()

    data?.series.forEach((serie: ChartSeries, i: number) => {
        minPointData = findMinPoint(minPointData, data);
        maxPointData = findMaxPoint(maxPointData, data);
        mappedData.push(populateSeriesData(serie, maxPointData, foundMax, data, minPointData, foundMin));
    });
    const minMax = findMinMaxFromSmaSeries(smaSeries, minPointData, maxPointData, props);
    minPointData = minMax.minPointData;
    maxPointData = minMax.maxPointData;

    if (underlyingData && underlyingData.series) {
        let currentStart = mappedData[0].sort((a, b) => a - b)[0];
        underlyingData.series.forEach((serie: ChartSeries, i: number) => {
            minPointData1 = findMinPoint(minPointData1, underlyingData);
            maxPointData1 = findMaxPoint(maxPointData1, underlyingData);
            if (props.strike) {
                maxPointData1 = Math.max(props.strike * 1.01, maxPointData1);
                minPointData1 = Math.min(props.strike * 0.99, minPointData1);
            }
            if (props.stoploss) {
                maxPointData1 = Math.max(props.stoploss * 1.01, maxPointData1);
                minPointData1 = Math.min(props.stoploss * 0.99, minPointData1);
            }
            let underlyingArr = populateSeriesData(serie, maxPointData1, foundMax1, underlyingData, minPointData1, foundMin1);
            let filtered = underlyingArr.sort((a, b) => a - b).filter(value => value?.x > currentStart?.x || value.x === currentStart?.x);
            mappedData.push(filtered);
        });
    }
    return {
        chart: {
            backgroundColor: null,
            margin: underlyingData ? [0, 0, 30, 30] : [0, 0, 30, 0],
            points: createPoints(data, underlyingData)
        },
        rangeSelector: {
            enabled: false,
        },
        scrollbar: {
            enabled: false,
        },

        navigator: {
            enabled: false,
        },
        time: {
            timezoneOffset: timezone
        },

        legend: {
            enabled: false,
        },

        tooltip: {
            shared: true,
            split: false,
            dateTimeLabelFormats: {
                millisecond: '%H:%M:%S.%L',
                second: '%d.%m.%Y %H:%M:%S',
                minute: '%d.%m.%Y %H:%M:%S',
                hour: '%d.%m.%Y %H:%M:%S',
                day: '%d.%m.%Y',
                week: '%d.%m.%Y',
                month: '%d.%m.%Y',
                year: '%Y'
            }
        },

        xAxis: {
            lineColor: 'rgba(255, 255, 255, 0.3)',
            type: "datetime",
            id: 'instrumentAxis',
            //    tickInterval: Infinity,
            offset: 8,
            tickLength: 0,
            labels: {
                style: {
                    color: '#9c9c9c',
                    fontFamily: "Roboto"
                }
            }
        },
        yAxis: createYAxis(minPointData, maxPointData, chartThreshold?.value || 0, props, underlyingData, minPointData1, maxPointData1),

        plotOptions: {
            series: {
                marker: {
                    enabled: false,
                },
                label: {
                    connectorAllowed: false,
                },
            },
        },

        credits: {
            enabled: false,
        },
        stockTools: {
          gui: {
              enabled: false
          }
        },
        series:
            createSeries(data, underlyingData, mappedData, chartThreshold?.value || 0, smaSeries, props)
    };

}

interface ChartComponentProps {
    instrumentId: number,
    underlyingInstrumentId?: number | null,
    strike?: number | null,
    stoploss?: number | null,
    chartType: ChartScope,
    gd200Average?: boolean,
    gd50Average?: boolean,
}

export const ChartComponent = (props: ChartComponentProps) => {
    const chartComponent = useRef(null);
    const [smaSeries, setSmaSeries] = useState({})
    let { loading, data } = useQuery(
        loader('./getInstrumentChartBanner.graphql'),
        { variables: { instrumentId: props.instrumentId, chartScope: props.chartType } }
    );
    let { data: underlyingData } = useQuery(
        loader('./getInstrumentChartBanner.graphql'),
        {
            variables: { instrumentId: props.underlyingInstrumentId, chartScope: props.chartType },
            skip: !props.underlyingInstrumentId
        }
    );
    let {data:smaData} = useQuery(
        loader('./ChartTool/getInstrumentChartToolModal.graphql'),
        {
            variables: {
                instrumentId: props.instrumentId,
                chartScope: props.chartType,
                criteria: {
                    from: moment(calculatePeriodFromChartScope(props.chartType)).subtract(1, 'year'),
                    to: moment(moment().local(true).endOf('day')),
                    aggregation: HistoryQuoteAggregation.Day
                }
            },
            skip: !props.instrumentId
        }
    );

    useEffect(() => {
        if (smaData && smaData.instrument) {
            const series:any = createSmaSeries(smaData?.instrument)
            setSmaSeries({smaSeries: series});
            adjustXAxis(chartComponent)
        }
    }, [smaData]);

    function adjustXAxis(chartComponent: any) {
        if (chartComponent.current) {
            const min = moment(calculateXMinForSmaFromChartScope(props.chartType, data)).valueOf();
            const max = moment(moment().local(true).endOf('day')).valueOf();
            chartComponent.current.update({
                xAxis: [
                    {
                        id: 'instrumentAxis',
                        min: min,
                        max: max,
                    }
                ]
            });
        }
    }

    function highchartsCallback(chart: any) {   
        chartComponent.current = chart;
    }

    return (
        <>
            <div className="main-chart-movement stock-chart" style={{ height: '200px' }}>
                {loading ?
                    <div className={"p-1 d-flex mx-auto"} style={{ height: "70px", color: "white"}}>
                        <Spinner animation="border" data-testid="loading-spinner" />
                    </div> :
                    (
                        data.instrument.chart.series.length > 0 &&
                        <FTLChartComponent
                            containerProps={{ style: { height: "200px", width: '100%' } }}
                            constructorType={'stockChart'}
                            options={createOptions(data.instrument.chart, underlyingData?.instrument?.chart, props, smaSeries)}
                            callback={highchartsCallback}
                        />
                    )
                }
            </div>
        </>
    )
}

const createPlotLines = (chartThreshold?: number | null, strike?: number | null, stoploss?: number | null) => {
    let plotLines: any[] = [];
    if (chartThreshold) {
        plotLines.push({
            value: chartThreshold,
            color: 'rgba(255, 255, 255, 0.7)',
            width: 2,
            label: {
                text: 'Schlusskurs ' + numberFormat(chartThreshold || 0),
                style: {
                    color: 'rgba(255, 255, 255, 0.7)',
                }
            }
        })
    }
    if (stoploss) {
        plotLines.push({
            value: stoploss,
            color: 'rgba(255, 255, 255, 0.7)',
            width: 2,
            label: {
                text: 'Stoploss ' + numberFormat(stoploss || 0),
                style: {
                    color: 'rgba(255, 255, 255, 0.7)',
                }
            }
        })
    }
    if (strike) {
        plotLines.push({
            value: strike,
            color: 'rgba(255, 255, 255, 0.7)',
            width: 2,
            label: {
                text: 'Strike ' + numberFormat(strike || 0),
                style: {
                    color: 'rgba(255, 255, 255, 0.7)',
                }
            }
        })
    }
    return plotLines;
}

export const findMinPoint = (minPointData: number, data: Chart) => {
    let minValue = Math.min(minPointData, 99999999);
    if (data) {
        data.series.flatMap(serie => serie.data.map(value => value.value)).forEach(value => {
            minValue = Math.min(value, minValue)
        })
    }
    return minValue;
}

export const findMaxPoint = (maxPointData: number, data: Chart) => {
    let maxValue = Math.max(maxPointData, 0);
    if (data) {
        data.series.flatMap(serie => serie.data.map(value => value.value)).forEach(value => {
            maxValue = Math.max(value, maxValue)
        })
    }
    return maxValue;
}
